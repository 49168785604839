.twentyPx {
    width: 20px;
}

.fiftyPx {
    width: 50px;
}

.sixtyPx {
    width: 60px;
}

.seventyPx {
    width: 70px;
}

.hundredPx1-5 {
    width: 150px;
}

.hundredPx2 {
    width: 200px;
}

.Version {
    color: #645F88;
    font-size: 0.6rem;
    margin: auto;
    margin-top: -5px;
}

.btn-light {
    color: rgba(0, 0, 0, .5) !important;
    background-color: transparent !important;
    border-color: transparent !important;
    margin: auto;
}

.btn-light.transfer-icon button,
.btn-light.transfer-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-light.transfer-icon button,
.btn-light.transfer-icon {
    outline: none;
    box-shadow: none;
    border: none;
}

.btn-light.transfer-icon.ant-popover-disabled-compatible-wrapper,
.btn-light.transfer-icon button:disabled {
    color: rgba(0, 0, 0, .3) !important;
    background: none !important;
}
.btn-light.btn-light-active {
    color: #fff !important;
    background-color: #6B54F9 !important;
    border-color: #6B54F9 !important;
}
.btn-light.btn-light-active svg path {
    fill: #fff;
}
.dropdown-item {
    color: rgba(0, 0, 0, .5) !important;;
}

.btn-outline-dark {
    color: rgba(0, 0, 0, .5) !important;
    border-color: rgba(0, 0, 0, .5) !important;
}

.btn-outline-dark:hover {
    color: #fff !important;
}
.btn-outline-lg {
    border-color: #6B54F9;
    color: #6B54F9;
    font-weight: 500;
    width: 100%;
    height: 42px;
    outline: none !important;
}
.btn-outline-lg:hover {
    background: #6B54F9 !important;
    color: #fff !important;
    border-color: #6B54F9 !important;
}
.dropdown-item.active {
    background: rgba(0, 0, 0, .5) !important;
    color: #fff !important;
}

.lander {
    padding: 80px 0;
    text-align: center;
}

.lander h1 {
    font-weight: 600;
}

body.busable-app .app-main {
    min-height: 100vh;
}

body.busable-app .ant-layout-sider.ant-layout-sider-collapsed .sider-logo .navbar-brand > img:nth-of-type(2) {
    display: none !important;
}

body.busable-app .sider-logo {
    padding: 20px 20px 30px;
}

body.busable-app .sider-logo .navbar-brand {
    margin: 0 !important;
    padding: 0 !important;
}

body.busable-app .sider-logo .navbar-brand > img:nth-of-type(2) {
    margin-left: 10px;
}

body.busable-app .app-main .app-sider {
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    min-height: 740px !important;
    overflow-x: auto;
}

@media screen and (max-width: 991px) {
    body.busable-app .app-main .app-sider {
        overflow: visible !important;
    }
}

body.busable-app .app-main .app-sider .ant-layout-sider-children {
    min-height: 800px !important;
}

@media screen and (min-height: 740px) {
    body.busable-app .app-main .app-sider .ant-layout-sider-children {
        min-height: inherit !important;
    }
}

body.busable-app .app-main .app-sider .dropdown-menu.show,
body.busable-app .app-main .app-sider .region-dropdown .dropdown-menu {
    left: 235px !important;
    z-index: 99999 !important;
    top: 0 !important;
}

body.busable-app .app-main .app-sider.ant-layout-sider-collapsed .region-dropdown .dropdown-menu {
    left: 66px !important;
}

body.busable-app .app-main .app-sider,
body.busable-app .app-main .app-sider .ant-menu.ant-menu-dark {
    background: #6B54F9 !important;
}

.app-main .app-sider .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.app-main .app-sider .ant-menu-dark .ant-menu-sub .ant-menu-item:hover,
    /* .app-main .app-sider .ant-menu-dark .ant-menu-submenu:hover, */
body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical .ant-menu-item:hover,
body.busable-app .ant-menu-item-selected a, .ant-menu-item-selected a:hover,
body.busable-app .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
    /* .app-main .app-sider .ant-menu-dark .ant-menu-submenu-selected, */
body.busable-app .ant-menu.ant-menu-vertical .ant-menu-submenu-selected,
.app-main .app-sider .operator-switch:hover {
    background: #6753ea !important;
}

body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical,
.app-main .app-sider .region-dropdown .dropdown-menu {
    background: #6B54F9 !important;
}

.app-main .app-sider .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #745ffc !important;
}

.app-main .app-sider .ant-menu-dark .ant-menu-item:hover,
body.busable-app .ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover,
body.busable-app .ant-menu-dark > .ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover,
body.busable-app .ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:active,
body.busable-app .ant-menu-dark > .ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:active,
body.busable-app .ant-menu-dark > .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-submenu-title {
    background: #614CE1 !important;
}

.app-main .app-sider .ant-menu-dark .ant-menu-item,
.app-main .app-sider .ant-menu-dark .ant-menu-inline.ant-menu-sub > li,
body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical li,
body.busable-app .ant-menu-inline .ant-menu-submenu-title,
body.busable-app .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    margin: 0 !important;
}

body.busable-app .ant-menu-inline .ant-menu-submenu-title,
body.busable-app .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    color: #fff;
}

.app-sider .ant-menu-inline .ant-menu-submenu-title,
.app-sider .ant-menu.ant-menu-sub .ant-menu-item,
.app-sider .ant-menu-dark .ant-menu-item,
body.busable-app .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title,
.app-sider.ant-layout-sider-collapsed .ant-menu-dark .ant-menu-submenu-selected,
body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical li {
    width: 100% !important;
    border-radius: 0 !important;
}

.app-main .app-sider.ant-layout-sider-collapsed .ant-menu-dark .ant-menu-item,
body.busable-app .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    text-align: center !important;
}

.app-main .app-sider .ant-menu-dark .ant-menu-item,
.app-main .app-sider .ant-menu-dark .ant-menu-item > a,
.app-main .app-sider .ant-menu-dark .ant-menu-item > span > a,
body.busable-app .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub,
body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical li,
.app-main .app-sider .operator-switch a {
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
}

.app-main .app-sider .ant-menu-dark .ant-menu-item > a,
.app-main .app-sider .ant-menu-dark .ant-menu-item > span > a,
body.busable-app .ant-menu-dark .ant-menu-item > span > a,
body.busable-app .ant-tooltip .ant-tooltip-inner > a {
    padding: 0 !important;
}

body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical li a {
    display: inline-block !important;
}

.app-main .app-sider.ant-layout-sider-collapsed + .app-layout {
    margin-left: 66px;
}

.app-main .app-sider + .app-layout {
    margin-left: 235px;
}

.app-main .app-layout .app-content {
    margin: 0;
    overflow: initial;
    padding-bottom: 30px;
}

@media screen and (min-width: 1024px) {
    .app-main .app-layout .app-content {
        margin: 0 16px;
    }
}

.app-main .app-sider img.ant-menu-item-icon {
    height: 19.5px;
}

.app-main .app-sider .region-dropdown {
    position: absolute !important;
    width: 100% !important;
}

.app-main .app-sider .region-dropdown .dropdown-menu {
    position: fixed !important;
    height: 100vh !important;
    border: none !important;
    border-left: 1px solid #6B54F9 !important;
    border-radius: 0 !important;
    transform: none !important;
    padding: 0 !important;
    overflow-y: auto !important;
    min-width: 240px !important;
}

.app-main .app-sider .region-dropdown .dropdown-menu ul a {
    font-size: 13px !important;
    color: #fff !important;
    font-weight: 500 !important;
    padding: 5px 20px 5px 24px !important;
}

.app-main .app-sider .region-dropdown .dropdown-menu ul a:hover,
.app-main .app-sider .region-dropdown .region-toggle:hover,
.app-main .app-sider .region-dropdown.show .region-toggle {
    background: #614CE1 !important;
    text-decoration: none;
}

.app-main .app-sider .region-dropdown .dropdown-menu ul a.new-region {
    display: flex !important;
    align-items: center !important;
    background: rgba(0, 0, 0, 0.1);
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    /* justify-content: center !important;
    color: rgba(0,0,0,.6) !important;
    font-size: 14px !important;
    background: none !important;
    font-weight: bold !important;
    border: 2px solid rgba(0,0,0,.35) !important;
    border-radius: 50px !important;
    width: 24px !important;
    height: 24px !important;
    padding: 0 !important;
    position: absolute !important;
    top: 15px !important;
    right: 20px !important; */
}

.app-main .app-sider .region-dropdown .dropdown-menu ul a.new-region svg {
    margin-right: 6px !important;
}

/* .app-main .app-sider .region-dropdown .dropdown-menu ul a.new-region:hover {
    color: #fff !important;
    border-color: #fff !important;
} */
.app-main .app-sider .region-dropdown .dropdown-menu > input {
    background: rgba(255, 255, 255, .6) !important;
    margin: 12px 18px !important;
    padding: 0 10px !important;
    height: 28px !important;
    border: none !important;
    border-radius: 50px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    outline: none !important;
    max-width: 165px !important;
}

.app-main .app-sider .region-dropdown .dropdown-menu > input:focus {
    box-shadow: none !important;
    background: rgba(255, 255, 255, .9) !important;
}

.app-main .app-sider .region-dropdown .region-toggle {
    position: relative;
    width: 100%;
    display: flex;
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    padding: 10px 24px !important;
    align-items: center !important;
    border-radius: 0 !important;
}

.app-main .app-sider .region-dropdown .region-toggle svg {
    width: 18px !important;
    height: 18px !important;
    flex-shrink: 0 !important;
}

.app-main .app-sider .region-dropdown .region-toggle svg g {
    stroke: #c4bbfd !important;
}

.app-main .app-sider .region-dropdown .region-toggle span {
    margin-left: 15px !important;
    text-align: left !important;
    line-height: 12px !important;
}

.app-main .app-sider.ant-layout-sider-collapsed .region-dropdown .region-toggle span,
.app-main .app-sider.ant-layout-sider-collapsed .region-dropdown .region-toggle i {
    display: none !important;
}

.app-main .app-sider .menu-icon-right {
    opacity: 0.45;
    transition: all 0.3s;
    position: absolute;
    top: 50%;
    right: 14px;
    width: 10px;
    color: rgba(0, 0, 0, 0.85);
    transform: translateY(-50%);
}

.app-main .app-sider .menu-icon-right:before,
.app-main .app-sider .menu-icon-right:after {
    background: #fff;
    position: absolute;
    width: 6px;
    height: 1.5px;
    border-radius: 2px;
    content: '';
    color: #fff;
}

.app-main .app-sider .menu-icon-right:before {
    transform: rotate(135deg) translateX(2.5px);
}

.app-main .app-sider .menu-icon-right:after {
    transform: rotate(45deg) translateX(-2.5px);
}

.app-main .ant-menu-item .ant-menu-item-icon + span,
.app-main .ant-menu-submenu-title .ant-menu-item-icon + span,
.app-main .ant-menu-item .anticon + span,
.app-main .ant-menu-submenu-title .anticon + span {
    margin-left: 15px !important;
}

.app-main .ant-menu.ant-menu-sub .ant-menu-item {
    padding-left: 40px !important;
}

body.busable-app .ant-menu.ant-menu-sub .ant-menu-item .ant-menu-title-content {
    font-size: 13px !important;
}

body.busable-app .ant-layout .ant-layout-sider-trigger {
    background: #614CE1;
}

body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical {
    border-radius: 6px !important;
    margin-left: -2px !important;
}

.app-main .app-sider:not(.ant-layout-sider-collapsed) .ant-menu-item .ant-menu-item-icon,
.app-main .app-sider:not(.ant-layout-sider-collapsed) .ant-menu-submenu .ant-menu-item-icon {
    position: absolute;
}

.app-main .app-sider:not(.ant-layout-sider-collapsed) .ant-menu-item .ant-menu-item-icon + span,
.app-main .app-sider:not(.ant-layout-sider-collapsed) .ant-menu-submenu .ant-menu-item-icon + span,
body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical li .ant-menu-item-icon + span {
    margin-left: 34px !important;
}

.app-main .app-sider:not(.ant-layout-sider-collapsed) .ant-menu-sub .ant-menu-item-icon + span {
    margin-left: 30px !important;
}

body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical li .ant-menu-item-icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

@media screen and (max-width: 1023px) {
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child {
        position: absolute;
        bottom: 55px;
    }
}

@media screen and (max-height: 740px) {
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child {
        bottom: 125px;
    }
}

@media screen and (min-width: 1024px) {
    /* .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child,
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(2),
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(3),
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(4),
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(5),
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(6),
    .app-main .app-sider .operator-switch {
        position: absolute;
        width: 100%;
    } */
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom {
        position: absolute;
        width: 100%;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom:last-child {
        bottom: 50px;
        overflow: visible !important;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom:nth-last-child(2) {
        bottom: 145px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom:nth-last-child(3) {
        bottom: 190px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom:nth-last-child(4) {
        bottom: 235px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom:nth-last-child(5) {
        bottom: 280px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom:nth-last-child(6) {
        bottom: 325px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom:nth-last-child(7) {
        bottom: 370px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom:nth-last-child(8) {
        bottom: 415px;
    }

    /* .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child {
        bottom: 50px;
        overflow: visible !important;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(2) {
        bottom: 145px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(3) {
        bottom: 190px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(4) {
        bottom: 235px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(5) {
        bottom: 280px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(6) {
        bottom: 325px;
    } */

    .app-main .app-sider .operator-switch,
    .app-main .app-sider .region-dropdown {
        bottom: 100px;
    }
}

.app-main .app-sider.ant-layout-sider-collapsed .ant-layout-sider-children > .ant-menu > li:last-child,
.app-main .app-sider.ant-layout-sider-collapsed .ant-layout-sider-children > .ant-menu > li:nth-last-child(2),
.app-main .app-sider.ant-layout-sider-collapsed .ant-layout-sider-children > .ant-menu > li:nth-last-child(3),
.app-main .app-sider.ant-layout-sider-collapsed .ant-layout-sider-children > .ant-menu > li:nth-last-child(4) {
    width: 66px;
}

@media screen and (max-width: 1023px) {
    .app-main .app-sider.ant-layout-sider-collapsed .ant-layout-sider-children,
    .app-main .app-sider.ant-layout-sider-collapsed .region-dropdown {
        overflow: hidden;
    }
}

.app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child .user-image {
    overflow: hidden;
    border-radius: 50px;
    position: relative;
    margin: 8px 0 0 0;
}

.app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child .user-image,
.app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child .user-image svg {
    display: block;
    width: 24px;
    height: 24px;
}

.app-main .app-sider:not(.ant-layout-sider-collapsed) .ant-layout-sider-children > .ant-menu > li:last-child .user-image {
    margin: 0px 0 0 -6px;
}

.app-main .app-sider:not(.ant-layout-sider-collapsed) .ant-layout-sider-children > .ant-menu > li:last-child :not(.user-image) svg {
    position: absolute;
    right: 20px !important;
    top: 50%;
    transform: translate(0, -50%);
}

.app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child .ant-menu-title-content {
    margin-left: 14px !important;
}

.app-main .app-sider.ant-layout-sider-collapsed .ant-layout-sider-children > .ant-menu > li .ant-menu-title-content {
    width: 66px !important;
    left: 0 !important;
    margin-left: 0 !important;
    position: absolute !important;
    top: 0 !important;
}

.app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child .user-image img {
    height: 100%;
    width: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.app-main .app-sider .operator-switch {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 20px;
    color: #fff;
}

.app-main .app-sider .operator-switch svg {
    margin: 0 0 0 5px;
    position: absolute;
}

.app-main .app-sider .operator-switch svg polyline,
.app-main .app-sider .operator-switch svg line {
    stroke: #C4BBFD;
}

.app-main .app-sider .operator-switch > a {
    padding-left: 36px;
    background: none !important;
    position: relative;
    z-index: 2;
}

.app-main .app-sider.ant-layout-sider-collapsed .operator-switch > a {
    text-indent: -99999px;
}

.app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child::before {
    content: '';
    width: calc(100% - 40px) !important;
    height: 1px !important;
    background: #8976FA;
    opacity: 1 !important;
    transform: none !important;
    top: -5px !important;
    left: 20px;
    position: absolute;
}

.app-main .app-sider .menu-version {
    line-height: 16px;
    display: flex;
    flex-direction: column;
}

.app-main .app-sider .menu-version span {
    font-size: 10px;
    width: 100%;
    color: #C4BBFD;
}

.ant-tooltip.ant-menu-inline-collapsed-tooltip .menu-version span,
.ant-tooltip.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner svg {
    display: none !important;
}

.app-main,
.app-main .app-layout {
    background: #F8FAFB !important;
}

.app-main .app-layout .app-header {
    padding: 15px 15px !important;
    background: transparent !important;
    height: auto !important;
    position: relative;
}

@media screen and (min-width: 1024px) {
    .app-main .app-layout .app-header {
        padding: 27px 30px 15px !important;
    }
}

.app-main .app-layout .app-header .app-breadcrumb {
    margin: 10px 0 0;
    font-size: 13px;
}

.app-main .app-layout .app-header .app-breadcrumb li:last-child {
    color: #6B54F9 !important;
}

.app-main .app-layout .App.container {
    max-width: 100%;
}

.filter-switches > button.ant-switch,
.filter-switches > .filter-switches-popup button.ant-switch,
.filter-switches > span > button.ant-switch,
.filter-switches > .ant-radio-group > .ant-radio-button-wrapper,
.filter-dropdown .filter-dropdown-overlay button.ant-switch {
    margin: 2.5px !important;
    padding: 0 10px !important;
    background: none !important;
    outline: none !important;
    border: none !important;
    height: 22px !important;
    line-height: 22px !important;
    font-size: 12px !important;
    border-radius: 100px !important;
}

.filter-switches > .ant-radio-group > .ant-radio-button-wrapper {
    display: inline-flex !important;
    align-items: center !important;
    box-shadow: none !important;
}

.filter-switches > .ant-radio-group > .ant-radio-button-wrapper:not(:first-child)::before {
    display: none !important;
}

@media screen and (min-width: 1024px) {
    .filter-switches > button.ant-switch,
    .filter-switches > .filter-switches-popup button.ant-switch,
    .filter-switches > span > button.ant-switch,
    .filter-switches > .ant-radio-group > .ant-radio-button-wrapper,
    .filter-dropdown .filter-dropdown-overlay button.ant-switch {
        padding: 0 12px !important;
        margin: 0 8px 0 0 !important;
        height: 26px !important;
    }
}

.filter-switches > button.ant-switch:last-of-type,
.filter-switches > .filter-switches-popup button.ant-switch:last-of-type,
.filter-switches > .ant-radio-group > .ant-radio-button-wrapper:last-of-type,
.filter-dropdown .filter-dropdown-overlay button.ant-switch {
    margin-right: 0 !important;
}

.filter-switches > button.ant-switch > .ant-switch-handle,
.filter-switches > .filter-switches-popup button.ant-switch > .ant-switch-handle,
.filter-switches > span > button.ant-switch > .ant-switch-handle,
.filter-dropdown .filter-dropdown-overlay button.ant-switch > .ant-switch-handle {
    display: none !important;
}

.filter-switches > button.ant-switch > .ant-switch-inner,
.filter-switches > .filter-switches-popup button.ant-switch > .ant-switch-inner,
.filter-switches > span > button.ant-switch > .ant-switch-inner,
.filter-switches > .ant-radio-group > .ant-radio-button-wrapper > span:not(.ant-radio-button),
.filter-switches > .ant-switch .ant-switch-inner .ant-switch-inner-checked,
.filter-switches > .ant-switch .ant-switch-inner .ant-switch-inner-unchecked,
.filter-switches > .filter-switches-popup .ant-switch .ant-switch-inner .ant-switch-inner-checked,
.filter-switches > .filter-switches-popup .ant-switch .ant-switch-inner .ant-switch-inner-unchecked,
.filter-dropdown .filter-dropdown-overlay .ant-switch > .ant-switch-inner,
.filter-dropdown .filter-dropdown-overlay .ant-switch > .ant-switch-inner .ant-switch-inner-checked,
.filter-dropdown .filter-dropdown-overlay .ant-switch > .ant-switch-inner .ant-switch-inner-unchecked {
    margin: 0 !important;
    font-weight: 600 !important;
    color: #7C7D7D !important;
    text-transform: uppercase !important;
    outline: none !important;
}

.filter-switches > .ant-switch .ant-switch-inner .ant-switch-inner-checked,
.filter-switches > .filter-switches-popup .ant-switch .ant-switch-inner .ant-switch-inner-checked,
.filter-switches > span > .ant-switch .ant-switch-inner .ant-switch-inner-checked,
.filter-dropdown .filter-dropdown-overlay .ant-switch > .ant-switch-inner .ant-switch-inner-checked {
    height: 26px !important;
    display: flex !important;
    align-items: center !important;
}

.filter-switches > .ant-switch.ant-switch-checked .ant-switch-inner,
.filter-switches > .ant-switch .ant-switch-inner,
.filter-switches > .filter-switches-popup .ant-switch.ant-switch-checked .ant-switch-inner,
.filter-switches > .filter-switches-popup .ant-switch .ant-switch-inner,
.filter-switches > span > .ant-switch.ant-switch-checked .ant-switch-inner,
.filter-switches > span > .ant-switch .ant-switch-inner,
.filter-dropdown .filter-dropdown-overlay .ant-switch.ant-switch-checked .ant-switch-inner,
.filter-dropdown .filter-dropdown-overlay .ant-switch .ant-switch-inner {
    padding: 0 !important;
}

.filter-switches > button.ant-switch:hover,
.filter-switches > button.ant-switch.ant-switch-checked,
.filter-switches > .filter-switches-popup button.ant-switch:hover,
.filter-switches > .filter-switches-popup button.ant-switch.ant-switch-checked,
.filter-switches > span > button.ant-switch:hover,
.filter-switches > span > button.ant-switch.ant-switch-checked,
.filter-switches > .ant-radio-group > .ant-radio-button-wrapper:hover,
.filter-switches > .ant-radio-group > .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
.filter-dropdown .filter-dropdown-overlay button.ant-switch:hover,
.filter-dropdown .filter-dropdown-overlay button.ant-switch.ant-switch-checked {
    background: #E9E6FA !important;
}

.filter-switches > button.ant-switch.ant-switch-checked > .ant-switch-inner,
.filter-switches > .filter-switches-popup button.ant-switch.ant-switch-checked > .ant-switch-inner,
.filter-switches > span > button.ant-switch.ant-switch-checked > .ant-switch-inner,
.filter-switches > .ant-radio-group > .ant-radio-button-wrapper.ant-radio-button-wrapper-checked > span:not(.ant-radio-button),
.filter-switches > .ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-checked,
.filter-switches > .filter-switches-popup .ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-checked,
.filter-switches > span > .ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-checked,
.filter-dropdown .filter-dropdown-overlay .ant-switch.ant-switch-checked > .ant-switch-inner,
.filter-dropdown .filter-dropdown-overlay .ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-checked {
    color: #6B54F9 !important;
}

.filter-switches {
    position: relative;
}

.filter-switches .filter-overlay {
    position: absolute !important;
    width: 300px !important;
    right: 0 !important;
    z-index: 9;
    background: #fff !important;
    padding: 4px 25px !important;
    box-shadow: 0 0 15px rgba(107, 84, 249, .2);
    border-radius: 8px;
    margin-top: 5px;
}

.filter-switches .filter-overlay-stop-type {
    position: absolute !important;
    width: 300px !important;
    right: 0 !important;
    z-index: 9;
    padding: 8px 0 8px 8px !important;
    background: #fff !important;
    box-shadow: 0 0 15px rgba(107, 84, 249, .2);
    border-radius: 8px;
    margin-top: 5px;
}

.filter-switches .filter-overlay-stop-type .ant-select .ant-select-selector {
    height: auto !important;
    border-radius: 0 !important;
    box-shadow: none !important;
}

.filter-switches .filter-overlay.overlay-center,
.filter-switches .filter-overlay-stop-type.overlay-center {
    left: 50%;
    transform: translate(-50%, 0);
}

.filter-switches .icon-button {
    color: #7C7D7D !important;
    background: none !important;
    height: 26px !important;
    padding: 0 12px !important;
}

.filter-switches .icon-button:hover,
.filter-switches .icon-button.ant-dropdown-open {
    color: #6B54F9 !important;
    background: #E9E6FA !important;
}

.points-filter-options .filter-switches {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 8px;
}

.points-filter-options .filter-switches > .filter-switches-popup button,
.points-filter-options .filter-switches > button {
    margin: 0 !important;
}

.points-filter-options .filter-switches > .filter-switches-popup {
    position: relative;
}

.filter-options-main,
.page-main-controls {
    margin-bottom: 25px;
}

.page-main-controls .page-sec-controls {
    gap: 10px;
    flex-wrap: wrap;
}

.page-main-controls.top-float,
.filter-options-main.top-float {
    margin-bottom: 10px;
}

.page-main-controls.top-float .page-sec-controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

@media screen and (min-width: 1180px) {
    .page-main-controls.top-float {
        position: absolute;
        top: 25px;
        right: 30px;
    }
}

@media screen and (min-width: 1024px) {
    .filter-options-main.top-float {
        position: absolute;
        top: 25px;
        right: 30px;
    }

    .App.publicContainer .filter-options-main.top-float {
        top: 20px !important;
    }

    .App.publicContainer .Timetable {
        margin-top: 20px !important;
    }
}

@media screen and (max-width: 1023px) {
    .filter-options-main {
        flex-direction: column !important;
        align-items: flex-start !important;
        gap: 15px !important;
    }

    .filter-options-main > div {
        width: 100%;
    }
}

.filter-options-main .search-filter input,
.filter-options-main .search-filter .input-group-text {
    border: none !important;
    background: none !important;
    outline: none !important;
    border-radius: 50px !important;
    box-shadow: none !important;
}

.filter-options-main .search-filter,
.filter-calendar > .ant-picker,
.filter-options-main .ant-select .ant-select-selector {
    background: #fff !important;
    border-radius: 50px !important;
    box-shadow: 0 0 10px rgba(107, 84, 249, .08) !important;
}

.filter-options-main .search-filter input {
    font-size: 12px !important;
    font-weight: 500 !important;
    padding: 0 5px 0 15px !important;
}

.filter-options-main .ant-select .ant-select-selector {
    height: 28px;
}

.filter-options-main .ant-select .ant-select-selector input {
    font-size: 12px !important;
    height: auto !important;
}

.filter-options-main .search-filter .input-group-text {
    padding: 0 10px 0 0 !important;
    color: #B3B3B3 !important;
}

.filter-options-main .search-filter input:focus + .input-group-text {
    color: #6B54F9 !important;
}

.filter-options-main .ant-select .ant-select-selector {
    border: none;
}

.StopManagerContainer .filter-options-main .search-filter {
    border: 1px solid transparent;
}

.StopManagerContainer .filter-options-main .search-filter.search-filter-filled {
    border-color: #6B54F9;
}

.filter-options-main .w-separator {
    padding-right: 15px;
    margin-right: 15px;
    position: relative;
}

.filter-options-main .w-separator:after {
    content: '';
    position: absolute;
    background: #DBD9FB;
    width: 1px;
    height: 80%;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}

.filter-calendar > .ant-picker {
    border: none !important;
}

.filter-calendar > .ant-picker input {
    font-size: 13px;
}

.filter-calendar > .ant-picker input::-webkit-input-placeholder {
    color: #6c6c6c !important;
}

.filter-calendar > .ant-picker input::-moz-placeholder {
    color: #6c6c6c !important;
}

.filter-calendar > .ant-picker input:-moz-placeholder {
    color: #6c6c6c !important;
}

.filter-calendar > .ant-picker input:-ms-input-placeholder {
    color: #6c6c6c !important;
}

.filter-calendar > .ant-picker input::placeholder {
    color: #6c6c6c !important;
}

.filter-dropdown .icon-button.btn-filled svg {
    width: 13px;
    height: 13px;
}

.filter-dropdown .icon-button.btn-filled svg path,
.filter-dropdown .icon-button.btn-filled:hover svg path {
    fill: none !important;
}

.filter-dropdown .icon-button.btn-filled svg path,
.filter-dropdown .icon-button.btn-filled svg line {
    stroke: #999;
}

.filter-dropdown .icon-button.btn-filled:hover,
.filter-dropdown .icon-button.ant-dropdown-open {
    background: #6B54F9 !important;
    outline: none !important;
    color: #fff !important;
}

.filter-dropdown .icon-button.btn-filled {
    outline: none !important;
}

.filter-dropdown .icon-button:hover svg path,
.filter-dropdown .icon-button:hover svg line,
.filter-dropdown .icon-button.ant-dropdown-open svg path,
.filter-dropdown .icon-button.ant-dropdown-open svg line {
    stroke: #fff;
}

.filter-dropdown-overlay > .ant-radio-group,
.filter-dropdown .filter-dropdown-overlay {
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 0 15px rgba(107, 84, 249, .15);
    border-radius: 8px;
    overflow: hidden;
    margin-top: 4px;
    min-width: 150px;
}

.filter-dropdown-overlay > .ant-radio-group .ant-radio-button-wrapper,
.filter-dropdown .filter-dropdown-overlay button.ant-switch {
    border: none;
    border-radius: 0 !important;
    background: none !important;
    padding-inline: 20px !important;
    height: 40px !important;
    line-height: 40px !important;
    border-bottom: 1px solid #f0edff !important;
}

.filter-dropdown .filter-dropdown-overlay button.ant-switch {
    display: flex;
    align-items: center;
}

.filter-dropdown .filter-dropdown-overlay .ant-switch > .ant-switch-inner {
    width: 100%;
}

.filter-dropdown .filter-dropdown-overlay .ant-switch > .ant-switch-inner .ant-switch-inner-checked,
.filter-dropdown .filter-dropdown-overlay .ant-switch > .ant-switch-inner .ant-switch-inner-unchecked {
    font-size: 14px !important;
    font-weight: 400 !important;
    text-transform: none !important;
    color: rgba(0, 0, 0, 0.88) !important;
    width: 100% !important;
    display: block !important;
    text-align: left !important;
}

.filter-dropdown-overlay > .ant-radio-group .ant-radio-button-wrapper:last-of-type,
.filter-dropdown .filter-dropdown-overlay button.ant-switch:last-of-type {
    border-bottom: none !important;
}

.filter-dropdown-overlay > .ant-radio-group .ant-radio-button-wrapper:before {
    display: none !important;
}

.filter-dropdown-overlay > .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    color: #6B54F9 !important;
    background: #E9E6FA !important;
}

.filter-dropdown .filter-dropdown-overlay.filter-slider {
    min-width: 350px;
    padding: 10px 30px;
}

.icon-button {
    border-radius: 50px !important;
    background: #E9E6FA !important;
    color: #6B54F9 !important;
    text-transform: uppercase !important;
    border: none !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    padding: 0 15px !important;
    line-height: 16px !important;
    display: inline-flex !important;
    align-items: center !important;
    box-shadow: none !important;
    text-shadow: none !important;
    height: 28px !important;
    outline: none !important;
}

.icon-button.btn-icon-only {
    padding: 0 !important;
    width: 28px !important;
    justify-content: center !important;
}

.icon-button:hover,
.ant-btn-primary.icon-button.active {
    background: #6B54F9 !important;
    color: #fff !important;
}

.icon-button .anticon {
    line-height: 10px !important;
}

.icon-button.inline-icon {
    gap: 6px;
}

.icon-button.inline-icon svg {
    height: 12px;
}

.icon-button.inline-icon svg g {
    stroke: #6B54F9 !important;
}

.icon-button.inline-icon:hover svg g {
    stroke: #fff !important;
}

.icon-button.btn-filled {
    background: #fff !important;
    box-shadow: 0 0 10px rgba(107, 84, 249, .10) !important;
    gap: 8px !important;
    flex: none;
}

.icon-button.btn-filled.btn-fade {
    background: rgba(255, 255, 255, .5) !important;
    color: #888 !important;
}

.icon-button.btn-filled.btn-fade:hover {
    color: #fff !important;
}

.icon-button.btn-filled.btn-fade svg path {
    fill: #888 !important;
}

.icon-button.btn-filled > span.ant-btn-icon {
    margin-right: 0 !important;
}

.icon-button.btn-filled > span:not(.ant-btn-icon) {
    margin-left: 0;
}

.icon-button.btn-filled svg path,
.icon-button.print svg path,
.mapboxgl-ctrl.mapboxgl-ctrl-group .ant-btn.transfer-icon.icon-button.btn-filled.btn-fade:hover svg path,
.mapboxgl-ctrl.mapboxgl-ctrl-group .ant-btn.icon-button.btn-filled.transfer-icon:hover svg path {
    fill: #6B54F9 !important;
}

.icon-button.print svg {
    width: 16px;
    height: 16px;
}

.icon-button.btn-filled:hover {
    background: #6B54F9 !important;
}

.icon-button.btn-filled:hover svg path,
.icon-button.print:hover svg path {
    fill: #fff !important;
}
.mapboxgl-ctrl.mapboxgl-ctrl-group .ant-btn.transfer-icon.icon-button.btn-filled.btn-fade,
.mapboxgl-ctrl.mapboxgl-ctrl-group .ant-btn.icon-button.btn-filled.transfer-icon {
    background: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    text-align: center;
    display: flex !important;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.mapboxgl-ctrl.mapboxgl-ctrl-group .ant-btn.transfer-icon.icon-button.btn-filled.btn-fade svg path,
.mapboxgl-ctrl.mapboxgl-ctrl-group .ant-btn.icon-button.btn-filled.transfer-icon svg path {
    fill: rgba(0, 0, 0, 0.4) !important;
}
.icon-check-success,
.icon-button.btn-success {
    background: #7ED668 !important;
    color: #fff !important;
}

.icon-check-success {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}

.icon-check-success svg {
    width: 12px;
    height: 12px;
}

.icon-button.btn-success:hover {
    background: #55be3b !important;
}

.icon-button.btn-success svg path,
.icon-button.btn-success:hover svg path {
    fill: none !important;
    stroke: #fff !important;
}

.icon-button svg.spinning path {
    fill: #6B54F9 !important;
    stroke: #6B54F9 !important;
    margin: 0 !important;
}

.icon-button.btn-success svg.spinning path {
    fill: #fff !important;
    stroke: #fff !important;
}

.icon-button.btn-error {
    color: #FF4D4D !important;
}

.icon-button.btn-error svg path {
    fill: #FF4D4D !important;
}

.icon-button.btn-error:hover {
    background: #FF4D4D !important;
    color: #fff !important;
}

.icon-button.sb-add-btn {
    float: right !important;
    margin: 0 -10px 0 0 !important;
}

.icon-button.btn-outline {
    border: 1px solid #6a54f942 !important;
    background: none !important;
    height: 25px !important;
    padding: 0 12px !important;
    font-size: 11px !important;
    align-items: center !important;
}

.icon-button.btn-outline:hover {
    background: #6B54F9 !important;
}
.btn-secondary.icon-button.btn-outline {
    border-radius: 5px !important;
    height: 32px !important;
    font-size: 14px !important;
    color: #555 !important;
}
.btn-secondary.icon-button.btn-outline:hover {
    color: #fff !important;
}
.btn-secondary.icon-button.btn-outline:hover svg path {
    fill: #fff;
}
.btn-secondary.icon-button.btn-outline.btn-lg,
body.busable-app .btn-secondary.icon-button.btn-outline.btn-lg {
    height: 52px !important;
    font-size: 15px !important;
    text-transform: none !important;
    width: 100%;
    font-weight: 500 !important;
    color: #666 !important;
    background: #fff !important;
    border: 1px solid #6a54f942 !important;
}
.btn-secondary.icon-button.btn-outline.btn-lg:hover,
body.busable-app .btn-secondary.icon-button.btn-outline.btn-lg:hover {
    color: #6B54F9 !important;
    background: #E9E6FA !important;
}
.btn-secondary.icon-button.btn-outline.btn-lg svg path {
    fill: #C1C5CE !important;
}
.btn-secondary.icon-button.btn-outline.btn-lg.icon-dark svg path {
    fill: #999 !important;
}
.btn-secondary.icon-button.btn-outline.btn-lg:hover svg path {
    fill: #6B54F9 !important;
}
.btn-secondary.icon-button.btn-outline.btn-lg.LoaderButton svg {
    margin-right: 8px;
}
.share-btn-wrap .icon-button.btn-outline {
    border-radius: 50px !important;
}

.icon-button.btn-sm {
    font-size: 11px !important;
    padding: 0 10px !important;
    height: 21px !important;
    line-height: 11px !important;
}

.icon-button.btn-sm svg {
    width: 11px;
    height: 11px;
}

.icon-button.btn-xs {
    font-size: 12px !important;
    padding: 0 10px !important;
    height: 28px !important;
    line-height: 11px !important;
    text-transform: none !important;
    margin: 0 15px 0 0;
}

.icon-button.btn-xs svg {
    width: 8px;
    height: 8px;
}

.icon-button.btn-filled.btn-error {
    background: #FFE6E6 !important;
    box-shadow: none !important;
}

.icon-button.btn-filled.btn-error.active,
.icon-button.btn-filled.btn-error:hover {
    background: #FF4D4D !important;
}
.icon-button.btn-filled.btn-error i {
    background: #FF4D4D !important;
    color: #fff !important;
    font-style: normal !important;
    border-radius: 20px;
    padding: 1px 2px;
}
.icon-button.btn-filled.btn-error:hover i,
.icon-button.btn-filled.btn-error.active i {
    background: #fff !important;
    color: #FF4D4D !important;
}
.app-sider.ant-layout-sider-collapsed {
    /* overflow-y: hidden; */
}

@media screen and (max-width: 1023px) {
    .app-sider.ant-layout-sider-collapsed,
    .app-sider.ant-layout-sider-collapsed .ant-layout-sider-trigger {
        width: 0 !important;
        min-width: 0 !important;
    }

    .app-main .app-sider.ant-layout-sider-collapsed + .app-layout,
    .app-main .app-sider + .app-layout {
        margin-left: 0 !important;
    }

    .menu-sm-open {
        position: absolute;
        z-index: 999;
        right: 20px;
        top: 20px;
        padding: 8px 10px !important;
        background: #DBD9FB !important;
        color: #6B54F9 !important;
        border: none !important;
        box-shadow: none !important;
        line-height: 10px !important;
    }

    .menu-sm-open:hover,
    .menu-sm-open.menu-expanded {
        color: #fff !important;
        background: #6B54F9 !important;
    }
}

@media screen and (min-width: 1024px) {
    .menu-sm-open {
        display: none !important;
    }

    .filter-options-main .w-separator {
        padding-right: 25px;
        margin-right: 25px;
    }
}

.App.container .list-group .list-group-item,
.card-main:not(.card-pass) {
    background: #fff !important;
    border-radius: 20px !important;
    border: none !important;
    box-shadow: 0 0 15px rgba(107, 84, 249, .15) !important;
    margin-bottom: 10px !important;
    padding: 10px 20px !important;
}
.CardTable .ant-table {
    background: none;
}
.CardTable .ant-table .ant-table-tbody > tr {
    box-shadow: none !important;
}
.CardTable .ant-table .ant-table-tbody > tr td {
    background: #fff !important;
    border-top: 1px solid #f0f0f0 !important;
    border-bottom: 1px solid #f0f0f0 !important;
}
.CardTable .ant-table .ant-table-tbody {
    z-index: 1;
    position: relative;
}
.CardTable.ReviewTable .ant-table .ant-table-tbody > tr td {
    border: none !important;
    padding: 0;
}
.CardTable.ReviewTable .ant-table table {
    border-spacing: 0 20px;
}
.CardTable.ReviewTable .ant-table .ant-table-thead,
.CardTable.ReviewTable .ant-table .ant-card.card-main.card-info .ant-card-head:before,
.CardTable.ReviewTable .ant-table .ant-card.card-main.card-info .ant-card-head:after,
.CardTable.ReviewTable .ant-table .ant-card.card-main.card-info .ant-card-body:before,
.CardTable.ReviewTable .ant-table .ant-card.card-main.card-info .ant-card-body:after {
    display: none;
}
.CardTable.ReviewTable .ant-table .ant-card.card-main.card-info .ant-card-body > .ant-row:not(:last-of-type) {
    margin-bottom: 20px;
}
.ant-card.card-main.card-info .CardHeaderInfo {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 400;
    color: #888;
}
.ant-card.card-main.card-info .CardHeaderInfo label {
    color: #888;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}
.CardTable .ant-table .ant-table-tbody > tr td:first-child {
    border-radius: 20px 0 0 20px !important;
}
.CardTable .ant-table .ant-table-tbody > tr td:last-child {
    border-radius: 0 20px 20px 0 !important;
}
.CardTable .ant-table .ant-table-tbody > tr.dragged-row td {
    background: #FAFCFC !important;
}
.CardTable .ant-table .ant-table-tbody > tr td .ant-btn.ant-btn-link {
    outline: none !important;
    font-weight: 500 !important;
    color: rgba(0, 0, 0, 0.88) !important;
    box-shadow: none !important;
    border: none !important;
}
.CardTable .ant-table .ant-table-tbody > tr td .ant-btn.ant-btn-link span {
    outline: none !important;
}
.CardTable .ant-table .ant-table-tbody > tr td .ant-btn.ant-btn-link:hover {
    color: #6B54F9 !important;
}
.CardTable .ant-table .ant-table-tbody > tr.dragged-row,
.CardTable .ant-table .ant-table-tbody > tr.droppable-row {
    background: none !important;
}
.CardTable .ant-table .ant-table-tbody > tr.droppable-row td {
    background: #E8E7FC !important;
}
.CardTable .ant-table table {
    width: 100% !important;
    border-spacing: 0 10px;
    margin-top: -10px;
}
.CardTable .ant-table .ant-table-measure-row {
    display: none !important;
}
.CardTable .ant-table-wrapper .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    display: none !important;
}
.CardTable .ant-table .ant-table-thead > tr {
    background: none !important;
}
.CardTable .ant-table .ant-table-thead > tr > th {
    background: none !important;
    border-bottom: none !important;
    padding-bottom: 5px !important;
}
.CardTable .ant-table .ant-table-thead > tr > th:before {
    display: none !important;
}
.App.container .list-group .list-group-item {
    color: #666;
}

@media screen and (max-width: 991px) {
    .App.container .list-group .list-group-item .col-sm-4 {
        padding: 4px 0;
    }
}

#busable-embed .SchoolStopList ul,
.SchoolStopList > ul {
    list-style: none !important;
    padding: 0 !important;
    margin: 0 !important;
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 16px !important;
}

.SchoolStopList li,
.SchoolStopList.SchoolViewList li,
#busable-embed .SchoolStopList.SchoolViewList ul li {
    width: 100% !important;
    border: none !important;
    padding: 0 !important;
}

@media screen and (min-width: 992px) {
    .SchoolStopList.SchoolViewGrid li,
    #busable-embed .SchoolStopList.SchoolViewGrid ul li {
        width: calc(50% - 8px) !important;
    }
}

@media screen and (min-width: 1400px) {
    .SchoolStopList.SchoolViewGrid li,
    #busable-embed .SchoolStopList.SchoolViewGrid ul li {
        width: calc(33.33% - 11px) !important;
    }
}

#busable-embed .SchoolStopList ul li a,
.SchoolStopList li a {
    color: #565f71 !important;
    font-size: 17px !important;
    font-weight: 400 !important;
    width: 100% !important;
    background: #fff !important;
    border-radius: 20px !important;
    padding: 20px 22px !important;
    display: flex !important;
    gap: 20px !important;
    align-items: center !important;
    line-height: 20px !important;
    border: none !important;
    box-shadow: 0 0 15px rgba(107, 84, 249, .1) !important;
    text-align: left !important;
}

.SchoolStopList li a:hover,
#busable-embed .SchoolStopList ul li a:hover {
    color: #6B54F9 !important;
    box-shadow: 0 0 15px rgba(107, 84, 249, .2) !important;
}

.SchoolStopList li a svg,
#busable-embed .SchoolStopList ul li a svg {
    opacity: 0.6 !important;
}

.SchoolStopList li a:hover svg,
#busable-embed .SchoolStopList ul li a:hover svg {
    opacity: 1 !important;
}

/* #busable-embed .SchoolStopList ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
#busable-embed .SchoolStopList ul li {
    border-bottom: 2px solid #d4ecfb;
    padding: 7px 0;
}
#busable-embed .SchoolStopList ul li:last-child {
    border-bottom: none;
}
#busable-embed .SchoolStopList ul li a {
    font-size: 16px;
    font-weight: 500;
} */
.card-main.mb-5 {
    margin-bottom: 3rem !important;
}

.card-main.pa-3 {
    padding: 30px 25px !important;
}

.card-main.no-pad,
.card-main.ant-card {
    padding: 0 !important;
}

.card-main.ant-card {
    margin: 0 !important;
    height: 100%;
}

.card-main p {
    color: #666;
    font-size: 15px;
}

.card-main.ant-card .ant-card-head {
    border-bottom: none !important;
    padding-top: 20px !important;
    min-height: auto !important;
    margin: 0 !important;
}

.card-main.ant-card .ant-card-head .ant-card-head-title,
.card-main .card-row-header h3 {
    font-size: 18px !important;
    overflow: visible !important;
    white-space: unset !important;
    line-height: 24px !important;
    font-weight: 600;
}

.card-main.ant-card .ant-card-body {
    padding-top: 20px !important;
}

.card-main.ant-card.body-pad-0 .ant-card-body {
    padding: 0 !important;
}

.card-main.card-stats h4 {
    font-size: 13px !important;
    color: #7C7D7E !important;
    text-transform: uppercase !important;
    margin: 0 !important;
}

.card-main.card-stats .ant-row .ant-col:first-of-type {
    padding-right: 30px !important;
}

.card-main.card-stats.card-stats-flex .ant-card-body {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: flex-start;
}
.card-main.card-stats.card-stats-flex .ant-card-body:before {
    display: none;
}

.card-main.card-stats .ant-row .ant-col:first-of-type:after {
    content: "";
    background: #DBD9FB !important;
    width: 1px;
    height: 49px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}

.card-main.card-stats:not(.card-stats-profit) .ant-row .ant-col:last-of-type {
    padding-left: 30px !important;
}

.card-main.card-stats.card-stats-profit .ant-row .ant-col:last-of-type .text-lg,
.card-main.card-stats.card-stats-profit .ant-card-body > div:last-of-type .text-lg {
    color: #52C41A !important;
}
.card-main.card-stats.card-stats-profit .ant-row .ant-col .text-lg.text-light {
    color: #D9D3FD !important;
    padding-top: 14px;
}
.card-main.card-stats .ant-row .ant-col .text-lg span {
    font-size: 32px;
}
@media screen and (max-width: 1200px) {
    .card-main.card-stats h4 {
        font-size: 12px !important;
    }
    .card-main.card-stats .ant-row .ant-col:first-of-type {
        padding-right: 15px !important;
    }
    .card-main.card-stats:not(.card-stats-profit) .ant-row .ant-col:last-of-type {
        padding-left: 15px !important;
    }
    .card-main.card-stats .ant-card-body {
        padding: 15px 20px !important;
    }
    .card-main.card-stats .ant-row .ant-col .text-lg {
        font-size: 38px !important;
    }
    .card-main.card-stats .ant-row .ant-col .text-lg span {
        font-size: 28px !important;
    }
}
.card-main.card-stats .ant-card-body svg {
    flex-shrink: 0;
}
.card-main.card-stats.card-error .ant-row .ant-col .text-lg {
    color: #FF5555 !important;
}
.card-main.card-stats.card-success .ant-row .ant-col .text-lg {
    color: #51B238 !important;
}
.card-main.card-stats.card-warning .ant-row .ant-col .text-lg {
    color: #F29F18 !important;
}
.card-main.card-stats.card-links {
    cursor: pointer;
    border: 1px solid transparent !important;
}
.card-main.card-stats.card-links.active {
    background: #F1F0FB !important;
    box-shadow: none !important;
    border: 1px solid #E9E6FA !important;
}
.card-main.CardMenu {
    overflow: hidden;
}
.card-main.CardMenu .ant-card-body {
    display: flex;
}
.card-main.CardMenu .ant-card-body .profile-summary {
    padding: 0;
}
.card-main.CardMenu .ant-card-body .profile-summary h2 {
    margin: 0 !important;
}
.card-main.CardMenu .ant-menu.profile-menu {
    display: flex;
}
.card-main.CardMenu .ant-menu.profile-menu .ant-menu-item {
    border-top: none !important;
    border-left: 1px solid rgba(5, 5, 5, 0.06);
    height: 60px !important;
}
.card-main.CardMenu .ant-menu.profile-menu .ant-menu-item:last-of-type {
    border-radius: 0 !important;
}
.card-main.CardMenu .ant-menu.profile-menu .ant-menu-item span {
    font-size: 15px;
}
.card-main.CardMenu .ant-menu.profile-menu .ant-menu-item:first-of-type {
    border-left: none;
}
.card-main .card-row-header {
    padding-bottom: 20px;
}

.card-main .card-row-header h3 {
    margin: 0 !important;
}

.card-main .card-row-header > .ant-col:first-of-type {
    gap: 20px !important;
}

.card-main .card-row-header > .ant-col:not(:first-of-type),
.card-main .card-row-content > .ant-col:not(:first-of-type) {
    text-align: center !important;
}

.card-main .card-row-header > .ant-col.text-left,
.card-main .card-row-content > .ant-col.text-left {
    text-align: left !important;
}

.card-main .card-row-header > .ant-col.text-right,
.card-main .card-row-content > .ant-col.text-right {
    text-align: right !important;
}

.card-main .card-row-header > .ant-col > span {
    color: #ACACAC !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px;
    display: inline-flex;
}

.card-main .card-row-content {
    position: relative;
    padding: 10px 0;
}

.card-main .card-row-content > .ant-col:first-of-type {
    display: flex !important;
    flex-direction: column !important;
    line-height: 18px;
    word-wrap: break-word;
}

.card-main .card-row-content > .ant-col:first-of-type strong {
    font-weight: 500 !important;
}

.card-main .card-row-content > .ant-col:first-of-type span {
    color: #666 !important;
}
.card-main a.table-row-link {
    color: rgba(26, 26, 26, 0.9);
}

/* .card-main .card-row-content.border-full:last-of-type {
    padding-bottom: 0 !important;
} */
.card-main .card-row-content:after {
    content: "";
    background: #EFEDFD;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.card-main .card-row-content.border-full:after {
    width: calc(100% + 48px);
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}
.card-main .card-row-content.border-full.no-border:after {
    display: none !important;
}

.card-main .card-row-content .row-col-actions {
    display: flex !important;
    gap: 12px !important;
    justify-content: center !important;
}

.modal-info > .ant-row > .ant-col,
.card-main.card-info .ant-card-body > .ant-row > .ant-col,
.card-main .ant-card-body > .ant-form .ant-row .ant-col .ant-form-item .ant-row.ant-form-item-row {
    display: flex !important;
    flex-direction: column !important;
    line-height: 22px !important;
}
.card-main .ant-card-body > .ant-form .ant-row .ant-col .ant-form-item {
    margin-bottom: 0;
}
.card-main .ant-card-body > .ant-form .ant-row .ant-col .ant-form-item .ant-form-item-label {
    text-align: left !important;
    padding: 0 !important;
}
.card-main .ant-card-body > .ant-form .ant-row .ant-col .ant-form-item .ant-form-item-label label,
.card-main .ant-card-body > .ant-form .ant-row .ant-col .ant-form-item .ant-form-item-control-input {
    height: auto !important;
    min-height: auto !important;
}
.card-main .ant-card-body > .ant-form .ant-row .ant-col .ant-form-item .ant-form-item-label label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    display: none !important;
}
.card-main .ant-card-body > .ant-form .ant-row .ant-col .ant-form-item .ant-form-item-label label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
    visibility: visible;
}
.modal-info > .ant-row > .ant-col.flex-row {
    flex-direction: row !important;
    gap: 10px;
}
.modal-info > .ant-row > .ant-col label,
.card-main .ant-card-body > .ant-row .ant-col label,
.card-main .ant-card-body > .ant-form .ant-row .ant-col label {
    font-size: 14px !important;
    color: #888 !important;
    margin: 0 !important;
}

.modal-info > .ant-row > .ant-col strong,
.card-main.card-info .ant-card-body > .ant-row > .ant-col strong,
.card-main .ant-card-body > .ant-form .ant-row .ant-col .ant-form-item .ant-form-item-control strong {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: rgba(26, 26, 26, 0.9);
    word-wrap: break-word;
}
.modal-info.text-light > .ant-row > .ant-col strong,
.card-main.card-info.text-light .ant-card-body > .ant-row > .ant-col strong {
    font-weight: 400 !important;
    color: rgba(26, 26, 26, 0.9);
}
.modal-info > .ant-row > .ant-col p,
.card-main.card-info .ant-card-body > .ant-row > .ant-col p {
    font-weight: 500;
    margin-bottom: 10px;
}

.modal-info > .ant-row > .ant-col a:not(.rdw-dropdown-selectedtext),
.card-main.card-info .ant-card-body > .ant-row > .ant-col a {
    font-size: 15px;
    font-weight: 500;
    color: #6B54F9;
    text-decoration: underline;
    display: inline-block;
    width: fit-content;
}
.modal-info-rows {
    padding: 3px 0;
}
.modal-info-rows .ant-row {
    border-bottom: 1px solid #F1F1F2;
    padding: 5px 24px;
}
.modal-info-rows .ant-row:last-of-type {
    border-bottom: none;
}
.modal-info-rows .info-col-status {
    font-weight: 500;
}
.modal-info-rows .info-col-status.success {
    color: #52C41A;
}
.modal-info-rows .info-col-status.error {
    color: #FF4D4F;
}
.modal-info .ant-list {
    overflow: hidden;
}
.modal-info .ant-list .ant-list-item {
    padding: 0 6px 0 0;
}
.modal-info .ant-list .ant-list-item > div {
    padding: 10px 20px;
    width: 100%;
}
.modal-info .ant-list .ant-list-item:hover {
    background: #F8F7FE;
}
.modal-info .ant-list .ant-list-item:hover > div {
    color: #442ed3;
}
.modal-info .ant-list .ant-list-item .ant-list-item-action {
    margin-left: 0;
}
.modal-info .ant-list .ant-list-item .ant-list-item-action .btn-primary {
    background: none !important;
    border: none !important;
    padding: 2px !important;
}
.modal-info .ant-list .ant-list-item .ant-list-item-action .btn-primary svg path {
    fill: #BEBEBE;
}
.ant-modal-body.modal-info.modal-body-fixed {
    overflow-x: auto;
    max-height: calc(100vh - 180px);
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 8px;
    margin-top: 24px;
}
.collapse-body-0 .ant-collapse-item .ant-collapse-content-box {
    padding: 0 !important;
}
.card-main.card-col .ant-card-body > .ant-row:not(.card-row-header) > .ant-col {
    border-right: 1px solid #EFEDFD;
}

.card-main.card-col .ant-card-body > .ant-row:not(.card-row-header) > .ant-col:last-of-type {
    border: none !important;
}

.card-main.row-separator > .ant-card-body > .ant-row:first-of-type:before,
.card-main.row-separator > .ant-card-body > .ant-form > .ant-row:first-of-type:before {
    display: none;
}

.card-main.row-separator > .ant-card-body > .ant-row:before,
.card-main.row-separator > .ant-card-body > .ant-form > .ant-row:before {
    content: '';
    width: calc(100% - 30px);
    height: 1px;
    background: #EFEDFD;
    margin: 30px auto 0;
}
.card-main.row-separator > .ant-card-body > .ant-form > .ant-row:before {
    width: calc(100%);
}
.card-main.school-card .school-logo {
    width: 100%;
    height: 165px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F8FAFB;
    border: 1px solid #EFEDFD;
    border-radius: 8px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, .04);
}

.card-main.school-card .school-logo img {
    height: 80%;
}

.card-main.school-card .ant-card-body > .ant-row > .ant-col {
    flex-direction: row !important;
    align-items: center;
    gap: 20px;
    font-size: 15px;
    font-weight: 500;
    color: rgba(26, 26, 26, 0.9);
}

.card-main.school-card .ant-card-body > .ant-row > .ant-col > svg path {
    fill: #C1C5CE;
}

.card-main.school-card .ant-card-body > .ant-row > .ant-col > a {
    color: #6B54F9;
}

.card-main.school-card .ant-card-body > .ant-row > .ant-col > a:hover {
    text-decoration: underline !important;
}

.card-main.card-pass {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 275px;
    min-width: 300px;
}

.card-main.card-pass .pass-year-sm {
    background: #fff;
    text-align: center;
    font-size: 24px !important;
    font-weight: bold;
    padding: 10px 0;
}

.card-main.card-pass .pass-body {
    background: #fff;
    margin: 15px 0 0;
    padding: 5px 18px;
}

.modal-info > .ant-row > .ant-col,
.card-main.card-info .ant-card-body > .ant-row > .ant-col {
    font-size: 16px;
}

.card-main.card-pass .ant-card-body > .ant-row > .ant-col strong {
    font-size: 18px !important;
}

.card-main.card-pass .ant-card-body > .ant-row > .ant-col strong.text-bold {
    font-weight: 600 !important;
}

.card-main.card-pass .pass-body .ant-qrcode {
    border: 2px solid #654595;
    margin: 10px 0;
    padding: 0 !important;
    width: 100px !important;
    height: 94px !important;
    border-radius: 3px;
}

.card-main.card-pass .pass-body .ant-qrcode canvas {
    width: 90px !important;
    height: 90px !important;
}

.cards-wrap {
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 10px !important;
}

.cards-wrap .card-main {
    width: 100% !important;
    margin: 0 !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

@media screen and (min-width: 1024px) {
    .cards-wrap .card-main:not(.w-100) {
        width: calc(50% - 5px) !important;
    }
}

.App.container .list-group .list-group-item.active {
    background: #C4BBFD !important;
    font-weight: 500 !important;
    color: #262626 !important;
}

.App.container .StopListContainer .list-group .list-group-item,
.App.container .DuplicateStopList.list-group .list-group-item {
    margin-bottom: 6px !important;
    padding: 10px 12px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}

.App.container .list-group .list-group-item.mb-5 {
    margin-bottom: 36px !important;
}

.App.container .DuplicateStopList.list-group > .list-group-item {
    cursor: pointer;
}

.App.container .StopListContainer .list-group .list-group-item .route-step-name {
    padding-left: 8px !important;
}

.App.container .list-group .list-group-item > .row:not(.align-items-start) {
    align-items: center !important;
}

.btn-icon-control {
    background: none !important;
    border: none !important;
    margin: 0 !important;
    outline: none !important;
}

.btn-icon-control:hover svg path {
    fill: #8976FA !important;
}

.btn-icon-control.btn-delete:hover svg path {
    fill: #dd2a2d !important;
}

.App.container .DuplicateStopList.list-group .list-group-item.active .icon-button.btn-sm svg path,
.App.container .list-group .list-group-item.active svg path {
    fill: #fff !important;
}

.App.container .DuplicateStopList.list-group .list-group-item.active svg path {
    fill: #6B54F9 !important;
}

.btn-icon-control:focus {
    box-shadow: none !important;
}

.App.container .list-group .list-group-header {
    padding: 0 20px 15px !important;
}
.App.container .list-group .list-group-header,
.CardTable .ant-table .ant-table-thead tr th,
.CardTable .ant-table .ant-table-column-title {
    color: #989898;
    font-weight: 600 !important;
    font-size: 12px !important;
    text-transform: uppercase;
}
.CardTable .ant-table-wrapper .ant-table.ant-table-middle .ant-table-cell,
.CardTable .ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody>tr>td {
    padding: 16px;
}

@media screen and (max-width: 991px) {
    .App.container .list-group .list-group-header {
        display: none !important;
    }
}

@media screen and (max-width: 1023px) {
    .App.container .list-group .list-group-header {
        flex-wrap: nowrap !important;
    }

    .card-main {
        flex-direction: column !important;
        align-items: flex-start !important;
        gap: 15px !important;
    }

    .ShiftBat .list-group-item .RouteTitle.flex-nowrap {
        flex-wrap: wrap !important;
    }

    .ShiftBat .list-group-item .RouteTitle.flex-nowrap .RouteInfo {
        padding: 10px 0 5px !important;
        width: 100% !important;
    }

    .ShiftBat .list-group-item .simple-tags {
        justify-content: flex-start !important;
    }

    .ShiftBat .list-group-item .sb-controls {
        justify-content: flex-end !important;
        position: absolute;
        top: 23px;
        right: 0;
    }
}

.simple-tags .ant-tag {
    margin: 0 !important;
    font-weight: 500 !important;
    border-radius: 50px !important;
}
.tag-lg {
    font-size: 16px;
    font-weight: 600;
    border-radius: 50px;
    border: none;
}
.tag-lg.ant-tag.ant-tag-error {
    color: #FF5555;
    background: #FFECEC;
    padding: 5px 12px;
}
.tag-lg.ant-tag.ant-tag-success {
    color: #51B238;
    background: #E5F3E1;
    padding: 5px 12px;
}
.tag-lg.ant-tag.ant-tag-warning {
    color: #F29F18;
    background: #FDF1DC;
    padding: 5px 12px;
}
.info-text-wrap {
    gap: 8px;
    margin-top: 4px;
}

.info-text-wrap .icon-info-text {
    display: flex;
    gap: 8px;
    align-items: center;
    font-weight: 500;
    color: #555;
    background: #F9F7FD;
    padding: 3px 6px;
    border-radius: 6px;
    border: 1px solid #F1EEFB;
}
.info-text-wrap .icon-info-text.no-bg {
    background: none;
    border: none;
    padding: 0;
}

.info-text-wrap.w-label .icon-info-text svg {
    width: 18px !important;
}
@media screen and (max-width: 1200px) {
    .info-text-wrap.w-label.FlexColumn > div {
        gap: 4px !important;
    }
    .info-text-wrap.w-label.FlexColumn .icon-info-text {
        flex-direction: column;
        gap: 4px;
    }
}
.info-text-wrap.w-label .icon-info-text > div {
    display: flex !important;
    flex-direction: column !important;
    line-height: 16px !important;
}

.info-text-wrap.w-label .icon-info-text > div > span {
    font-size: 12px !important;
    color: #888 !important;
}
.map-info.info-text-wrap {
    position: absolute;
    top: 8px;
    left: 50px;
    display: flex;
    margin-top: 0;
}
.map-info.info-text-wrap .icon-info-text {
    background: rgba(255,255,255,.8);
    border: 2px solid #D7D8D8;
}
body.busable-app h2.title {
    font-size: 20px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
    margin-bottom: 0 !important;
}
body.busable-app h3.title,
.card-title {
    font-size: 16px !important;
    text-transform: uppercase !important;
    color: #888 !important;
    font-weight: 600;
}

h3.title.mt-4 {
    margin-top: 30px !important;
}

body.busable-app h4.title {
    font-size: 14px !important;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
    font-weight: 600;
}

body.busable-app h5.title {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #8C8C8C !important;
    font-weight: 600;
}

.inputtext-small,
.inputselect-small .ant-select-selector,
.inputtext-small .ant-input-group input,
.inputtext-small .ant-input-group > input + .ant-input-group-addon {
    border-radius: 50px !important;
    font-size: 12px !important;
    height: 28px !important;
    padding: 0 8px !important;
    border-width: 1px !important;
    border-color: #DAD9D9 !important;
    border-style: solid !important;
}

.inputselect-small .ant-select-selector input {
    height: 28px !important;
}

.inputselect-small .ant-select-selector .ant-select-selection-item {
    line-height: 26px !important;
}

.inputtext-small:hover,
.inputtext-small:focus,
.inputtext-small.ant-input-focused,
.inputselect-small:not(.ant-select-disabled):hover .ant-select-selector,
.inputselect-small.ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
    border-color: #C3BAFC !important;
}

.inputtext-small:focus,
.inputtext-small.ant-input-focused,
.inputselect-small.ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
    box-shadow: 0 0 3px 0 rgba(195, 186, 252, 0.8) !important;
    border-right-width: 1px;
    outline: 0 !important;
}

.inputtext-small.ant-input-group-wrapper {
    padding: 0 !important;
}

.inputtext-small .ant-input-group > input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.inputtext-small .ant-input-group > input + .ant-input-group-addon {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    background: #EFECFB !important;
}

.App.container .list-group .list-group-item .RouteLogo {
    flex-shrink: 0 !important;
}

.row-col-actions {
    padding-left: 0px !important;
    padding-right: 0px !important;
    flex-wrap: wrap !important;
}
.row-col-actions.icon-only > .icon-button.btn-filled {
    background: none !important;
    box-shadow: none !important;
    height: auto !important;
}
.row-col-actions.icon-only > .icon-button.btn-filled svg path {
    fill: #999 !important;
}
.row-col-actions.icon-only > .icon-button.btn-filled:hover svg path {
    fill: #6B54F9 !important;
}
.list-row-wrap {
    position: relative !important;
}

.list-row-wrap .btn-float {
    position: absolute !important;
    right: 5px;
    top: calc(50% - 5px);
    transform: translate(0, -50%);
}

@media screen and (min-width: 1024px) {
    .list-row-wrap .btn-float {
        right: 10px;
    }
}

@media screen and (min-width: 1380px) {
    .list-row-wrap .btn-float {
        right: 20px;
    }
}

@media screen and (min-width: 1500px) {
    .list-row-wrap .btn-float {
        right: 30px;
    }
}

.row-col-actions .btn-icon-control {
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.row-col-actions.gap-8 .btn-icon-control {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.row-col-actions .btn-icon-control svg {
    height: 18px;
}

.row-col-actions .btn-icon-control .anticon.anticon-eye svg {
    width: 18px;
}

.row-col-actions .btn-icon-control svg path {
    fill: #BEBEBE;
}

.app-main .app-layout .app-header .public-header {
    width: calc(100% + 30px) !important;
    background: #6B54F9 !important;
    padding: 0 15px !important;
    margin: -15px -15px 0 -15px !important;
    text-align: center !important;
}

.app-main .app-layout .app-header .public-header > img:nth-of-type(2) {
    margin-left: 10px;
}

@media screen and (min-width: 1024px) {
    .app-main .app-layout .app-header .public-header {
        margin: -27px -30px 0 !important;
        width: calc(100% + 60px) !important;
    }
}

.app-main .app-layout .app-content .Login .btn-primary,
.app-main .app-layout .app-content .ResetPassword .btn-primary {
    background: #6B54F9 !important;
    border: none !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    text-transform: uppercase !important;
}

.app-main .app-layout .app-content .Login .btn-primary:hover,
.app-main .app-layout .app-content .ResetPassword .btn-primary:hover {
    background: #816EFA !important;
}

.app-main .app-layout .app-content .Login form > a,
.app-main .app-layout .app-content .ResetPassword p > a {
    color: #6B54F9 !important;
    font-weight: 500 !important;
    margin-bottom: 15px !important;
    display: inline-block !important;
}

.app-main .app-layout .app-content .Login form > a:hover,
.app-main .app-layout .app-content .ResetPassword p > a:hover {
    color: #816EFA !important;
}

.app-loading {
    text-align: center;
    padding: 60px 0 !important;
}

.app-loading .app-loading-logo {
    text-align: center;
}

.app-loading .app-loading-text {
    text-align: center;
    color: #9F9F9F !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
}

input.color-picker {
    width: 53px !important;
    height: 28px !important;
    padding: 0 !important;
    background-color: transparent;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    border-radius: 6px !important;
    flex-shrink: 0 !important;
}

.icon-10 svg {
    width: 10px !important;
}

.icon-12 svg {
    width: 12px !important;
}

.shift-bat-map .map-shiftbats,
.map-holder-wrap .map-items {
    padding: 20px;
    box-shadow: 0 0 20px rgba(107, 84, 249, 0.15);
    border-radius: 0 20px 20px 0;
    max-height: 100% !important;
    min-height: 100% !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    height: 85vh;
}
.map-holder-wrap .map-items.scroll-inner {
    padding: 0;
    overflow: hidden !important;
    height: auto;
}
.map-holder-wrap .map-items.scroll-inner .scroll-item {
    padding: 0 20px 20px;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    height: calc(85vh - 183px);
    border-top: 1px solid #E9E5FE;
}
.shift-bat-map .map-shiftbats {
    background: #f7f9fa !important;
    padding-top: 0 !important;
}

@media screen and (max-width: 1023px) {
    .shift-bat-map .map-shiftbats,
    .shift-bat-map .map-holder,
    .map-holder-wrap .map-holder,
    .map-holder-wrap .map-items {
        width: 100% !important;
    }
}

.icon-logo .ant-upload-list-picture-card-container {
    margin: 0 !important;
    width: 75px !important;
    height: 75px !important;

}

.icon-logo .ant-upload-list-picture-card-container .ant-upload-list-item-list-type-picture-card {
    padding: 0 !important;
    border-radius: 6px !important;
}

.icon-logo .ant-upload-list-picture-card-container .ant-upload-list-item-list-type-picture-card .ant-upload-list-item-thumbnail img {
    border-radius: 6px !important;
}

.badge-outline {
    background: none !important;
    color: #6c757d !important;
    border: 1px solid #6c757d !important;
    text-align: center !important;
    white-space: break-spaces !important;
}
.App.container .list-group .badge-outline .list-group-item {
    background: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
}
.badge-outline div div.ml-1 {
    text-align: left !important;
}

.badge-outline.badge-light {
    color: #899197 !important;
    border: 1px solid #E5E5E5 !important;
}
.calendar-tags .ant-tag {
    white-space: normal !important;
    line-height: 14px;
    text-align: center;
}
.App.container .list-group .list-group-item.active .badge-outline.badge-light {
    color: #6c757d !important;
    border: 1px solid #E9E6FA !important;
    background: #E9E6FA !important;
}

@media screen and (min-width: 1024px) {
    .col-separator > div[class^='col-']:not(:last-of-type),
    .col-separator > div[class*=' col-']:not(:last-of-type) {
        border-right: 1px solid #E1DDFE;
    }
}

@media screen and (max-width: 1023px) {
    .col-separator > div[class^='col-']:last-of-type,
    .col-separator > div[class*=' col-']:last-of-type {
        margin-top: 40px;
    }

    .col-separator > div.px-4 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

body.busable-app .app-list {
    margin: 0 !important;
    padding: 0 0 0 17px !important;
    list-style: none !important;
}

body.busable-app .app-list li {
    margin-bottom: 10px;
    position: relative;
}

body.busable-app .app-list li:before {
    content: "\2022";
    font-size: 20px;
    line-height: normal;
    color: #6B54F9;
    position: absolute;
    left: -18px;
}

body.busable-app .app-list.text-md {
    font-size: 16px;
}

body.busable-app .app-list strong {
    font-weight: 600;
    background: #f2f2f2;
    padding: 0 6px;
    border-radius: 5px;
    color: #333;
    font-size: 15px;
}

.MapMenu .Filter label {
    width: auto !important;
}

.BusRouteDetails .MapPlaceholder,
.map-holder-wrap .MapPlaceholder {
    width: 100%;
    height: 85vh;
    overflow: hidden;
    position: relative;
}

.loading-spin {
    width: 100% !important;
    padding: 0 0 20px !important;
}

.loading-spin svg {
    height: 34px !important;
}

.btn-edit-controls {
    flex-shrink: 0 !important;
    display: flex !important;
    gap: 8px !important;
}

.btn-edit-controls .btn-icon-fill {
    border: none !important;
    border-radius: 50px !important;
    width: 25px !important;
    height: 25px !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-shrink: 0 !important;
    overflow: hidden !important;
}

.btn-edit-controls .btn-icon-fill svg {
    height: 9px !important;
    flex-shrink: 0 !important;
}

.btn-edit-controls .btn-icon-fill.btn-save svg:nth-of-type(2) {
    display: none !important;
}

.btn-edit-controls .btn-icon-fill.btn-save {
    background: #D9F3D2 !important;
}

.btn-edit-controls .btn-icon-fill.btn-save:hover {
    background: #7ED668 !important;
}

.btn-edit-controls .btn-icon-fill.btn-save svg path {
    stroke: #7ED668 !important;
}

.btn-edit-controls .btn-icon-fill.btn-save:hover svg path {
    stroke: #fff !important;
}

.btn-edit-controls .btn-icon-fill.btn-save svg.spinning {
    height: 16px !important;
}

.btn-edit-controls .btn-icon-fill.btn-close {
    background: #FFE6E6 !important;
}

.btn-edit-controls .btn-icon-fill.btn-close svg path {
    fill: #FF4545 !important;
}

.btn-edit-controls .btn-icon-fill.btn-close:hover {
    background: #FF4545 !important;
}

.btn-edit-controls .btn-icon-fill.btn-close:hover svg path {
    fill: #fff !important;
}

.App.container .Transfers .connect-transfer-arrow {
    display: flex !important;
    padding: 0 6px !important;
    align-items: center !important;
}

.App.container .Transfers .connect-transfer-arrow svg path {
    fill: #bbb !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active,
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #6B54F9 !important;
    border-color: #6B54F9 !important;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: #8976FA !important;
}

.timetable-schedule .Schedule .ant-radio-group .ant-radio-button-wrapper {
    border-radius: 20px !important;
    margin-right: 8px !important;
}

.timetable-schedule .Schedule .ant-radio-group .ant-radio-button-wrapper:last-of-type {
    margin-right: 0 !important;
}

.timetable-schedule .Schedule .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
    display: none !important;
}

.timetable-schedule .Schedule .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover {
    color: #fff !important;
}

.ant-radio-button-wrapper:hover {
    color: #6B54F9 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
    background-color: #6B54F9 !important;
}

.sb-row .sb-controls {
    display: flex !important;
    flex-shrink: 0 !important;
    align-items: center !important;
}

.sb-row .sb-info .sb-controls {
    margin-left: auto !important;
}

.sb-row .sb-controls button,
.sb-row button.sb-add-comment {
    border: none !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-shrink: 0 !important;
    border-radius: 0 !important;
}

.sb-row .sb-controls button {
    padding: 0 !important;
    background: none !important;
    box-shadow: none !important;
    width: 22px !important;
    height: 20px !important;
    outline: none !important;
}

.sb-row .sb-controls button:hover {
    background: none !important;
}

.sb-row button.sb-add-comment {
    flex-shrink: 0 !important;
}

.sb-row .sb-controls button svg {
    width: 13px !important;
    height: 13px !important;
}

.sb-row .sb-controls button.sb-edit-row svg path,
.sb-row .sb-controls button.sb-directions svg path {
    fill: #bebebe !important;
}

.sb-row button.sb-add-comment:hover svg path,
.sb-row .sb-controls button.sb-edit-row:hover svg path,
.sb-row .sb-controls button.sb-directions:hover svg path {
    fill: #6B54F9 !important;
}

.sb-row button.sb-add-row:hover svg path,
.sb-row button.sb-add-comment:hover svg path,
.add-initial-row .sb-add-row:hover svg path {
    fill: #fff !important;
}

.sb-row button.sb-add-row:hover,
.sb-row button.sb-add-comment:hover,
.add-initial-row .sb-add-row:hover {
    background: #6B54F9 !important;
    border-color: #6B54F9 !important;
}

.sb-row .sb-controls button.sb-delete-row:hover svg path {
    fill: #FF4545 !important;
}

.sb-row,
.sb-row .sb-row-routes .row,
.add-initial-row {
    position: relative;
}

.sb-row button.sb-add-row,
.sb-row button.sb-add-comment,
.add-initial-row .sb-add-row {
    background: #E9E6FA !important;
    border-radius: 50px !important;
    width: 19px !important;
    height: 19px !important;
    padding: 0 0 0.5px 0.5px !important;
    align-items: center !important;
    justify-content: center !important;
    position: absolute !important;
    bottom: -9.5px !important;
    left: 50% !important;
    transform: translate(-50%, 0) !important;
    z-index: 2 !important;
    outline: none !important;
    display: none !important;
}

.add-initial-row .sb-add-row:hover {
    transform: translate(-50%, 0) !important;
}

.sb-items-wrap.edit-mode .sb-row .sb-row-routes > div > .row:not(.sb-row-transfers) {
    border-bottom: 1px solid transparent !important;
}

.sb-items-wrap.edit-mode .sb-row .sb-row-routes .row:not(.sb-row-transfers):hover {
    border-bottom-color: #E9E5FE !important;
}

.sb-row .sb-row-routes .row .sb-info {
    position: static !important;
}

.sb-row:hover > button.sb-add-row,
.sb-row .sb-row-routes .row:hover button.sb-add-row,
.sb-row .sb-row-routes .row:hover button.sb-add-comment,
.sb-items-wrap.edit-mode:hover .add-initial-row .sb-add-row {
    display: flex !important;
}

.sb-row button.sb-add-row svg,
.sb-row button.sb-add-comment svg,
.add-initial-row .sb-add-row svg {
    width: 20px !important;
}

.sb-row .sb-row-routes .sb-controls button {
    width: 20px !important;
    height: 18px !important;
}

.map-shiftbats .sb-first-row {
    text-align: center !important;
    padding: 50px 0 !important;
}

.map-shiftbats .sb-first-row div {
    width: 100% !important;
    color: #999 !important;
    margin-bottom: 10px !important;
}

.map-shiftbats .sb-first-row button {
    float: none !important;
}

.map-shiftbats .sb-first-row button:disabled {
    opacity: 0.6 !important;
}

.sb-row-heading {
    padding: 8px 0 !important;
}

.sb-row-heading,
.info-heading {
    font-size: 12px !important;
    text-transform: uppercase !important;
    color: #989898 !important;
    font-weight: 600 !important;
    line-height: 14px !important;
}
.sb-row-heading .ant-col {
    font-size: 12px !important;
}
.StopTimes .sb-row {
    padding: 8px 0 !important;
}

.input-btn-wrap {
    width: 100% !important;
    position: relative;
}

.input-btn-wrap input {
    padding-right: 32px !important;
}

.input-btn-wrap button {
    position: absolute !important;
    top: 1px !important;
    right: 1px !important;
    border-radius: 50px !important;
    width: 26px !important;
    height: 26px !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background: #E9E5FE !important;
    border: none !important;
    outline: none !important;
}

.input-btn-wrap button:hover {
    background: #6B54F9 !important;
}

.input-btn-wrap button svg {
    width: 14px !important;
}

.input-btn-wrap button svg path {
    fill: #6a54f9a5 !important;
}

.input-btn-wrap button:hover svg path {
    fill: #fff !important;
}

@media screen and (min-width: 1024px) {
    .tab-menu-float > .ant-tabs-nav {
        position: absolute !important;
        top: 26px !important;
        margin: 0 0 0 186px !important;
    }
}

.tab-menu-float > .ant-tabs-nav:before {
    display: none !important;
}

.tab-menu-float .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background: #8775F9 !important;
    height: 26px !important;
    z-index: -1 !important;
    border-radius: 50px !important;
}

.tab-menu-float .ant-tabs-tab {
    padding: 3px 15px !important;
}

.tab-menu-float .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 10px !important;
}

.tab-menu-float .ant-tabs-tab .ant-tabs-tab-btn {
    color: #7C7D7E !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
    font-size: 13px !important;
}

.tab-menu-float .ant-tabs-tab:hover .ant-tabs-tab-btn {
    color: #6B54F9 !important;
}

.tab-menu-float .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
}
.tab-menu-float.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tab-menu.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}
.ant-tab-menu.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
    line-height: 21px;
}
.ant-tab-menu.ant-tabs.ant-tabs-top >.ant-tabs-nav:before {
    display: none;
}
.primary-btn {
    min-width: 100px !important;
    text-align: center !important;
    font-size: 15px !important;
    background: #6B54F9 !important;
    font-weight: 600 !important;
    border: none !important;
    margin-top: 10px !important;
    padding: 7px 20px !important;
    border-radius: 8px !important;
    outline: none !important;
    height: auto !important;
}
.primary-btn.secondary-btn {
    background: #fff !important;
    border: 1px solid #d9d9d9 !important;
    color: #777 !important;
    font-weight: 500 !important;
}
.primary-btn.secondary-btn:hover {
    background: #fff !important;
    border-color: #5b45e9 !important;
    color: #5b45e9 !important;
}
.primary-btn:hover,
.primary-btn:focus {
    background: #5b45e9 !important;
    color: #fff !important;
}
.primary-btn.mt-0 {
    margin-top: 0 !important;
}
.card-main .card-info-box {
    background: #F7F7F7 !important;
    padding: 10px 15px !important;
    border: 1px solid #eee !important;
    border-radius: 6px !important;
    font-size: 15px !important;
    display: block !important;
    word-wrap: break-word !important;
}
.info-box {
    background: #fff !important;
    padding: 40px 50px !important;
    border: 1px solid #eee !important;
    border-radius: 20px !important;
    width: 100%;
    gap: 30px;
    flex-direction: column;
    align-items: center;
}
.card-main code.card-info-box {
    font-size: 14px !important;
}

.copy-btn-wrap {
    position: relative !important;
}

.copy-btn-wrap .copy-btn {
    position: absolute !important;
    right: 1px !important;
    bottom: 1px !important;
    background: #E9E6FA !important;
    padding: 3px 6px !important;
    cursor: pointer !important;
    border-radius: 6px 0 6px 0 !important;
}

.copy-btn-wrap .copy-btn svg path {
    fill: #6B54F9 !important;
}

.copy-btn-wrap .copy-btn:hover {
    background: #8775F9 !important;
}

.copy-btn-wrap .copy-btn:hover svg path {
    fill: #fff !important;
}

.stop-tt-row .stop-tt-time {
    font-size: 12px !important;
    padding: 0 4px !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    border: 1px solid #D9D9D9 !important;
    background: #fff !important;
    border-radius: 4px !important;
    min-width: 42px !important;
}

.stop-tt-row .stop-tt-name {
    font-size: 13px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
}

.stop-tt-row.stop-tt-line div:nth-child(2):after {
    content: '' !important;
    background: #DFDFDF !important;
    width: 1px !important;
    height: 14px !important;
}

.StopTimesTimetable > div:last-of-type > .stop-tt-row.stop-tt-line div:nth-child(2):after {
    background: transparent !important;
}

.TimetableSchedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 6px 6px 0 0 !important;
    padding: 4px !important;
    background-color: #e9e9e9 !important;
}

.TimetableSchedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
    margin-left: 0 !important;
}

.TimetableSchedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #fff !important;
    color: #6B54F9 !important;
    border-color: #e9e9e9;
    border-bottom-color: #fff !important;
}

.TimetableSchedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab:hover {
    color: #6B54F9 !important;
}

.TimetableSchedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn .ant-input,
.ant-tabs-dropdown .ant-tabs-dropdown-menu-item .ant-input {
    border-radius: 6px !important;
    padding: 0 !important;
    background: none !important;
    height: 25px !important;
    border: 1px solid #e9e9e9 !important;
    cursor: pointer;
}
.TimetableSchedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .ant-input {
    cursor: auto;
}

.TimetableSchedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn .ant-input.error {
    border-color: #ff4d4f !important;
    background-color: rgba(255, 77, 79, 0.1) !important;
    color: #FF4545 !important;
}
.TimetableSchedule .ant-tabs-card > .ant-tabs-nav:before {
    border-color: #e9e9e9;
}
.TimetableSchedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-nav-more {
    padding: 0 6px;
    color: #999;
}
.row.row-compact {
    margin: 0 -3px !important;
    width: 100% !important;
}

.row.row-compact > div[class^='col-'],
.row.row-compact > div.col {
    padding: 0 3px !important;
}

.row > .row-col-gap {
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 6px;
}

.sb-row .sb-row-edit .row > .row-col-gap .inputselect-small:not(.w-100),
.sb-row .sb-row-edit .row > .row-col-gap .ant-input.inputtext-small:not(.input-desc) {
    width: calc(50% - 3px) !important;
}

.sb-row .sb-row-edit .row.row-compact .sb-time {
    padding: 5px 0 0 !important;
    display: inline-block !important;
    width: auto !important;
}

.sb-row .sb-row-edit .row.row-compact .ant-input-group-wrapper.inputtext-small {
    width: 48% !important;
}

.sb-edit-controls.dir-row {
    flex-direction: row !important;
}

.Home .list-group .list-group-item .RouteInfo.w-100,
.Timetable .timetable-row .RouteInfo.w-100,
.ShiftBat .list-group .list-group-item .RouteInfo.w-100 {
  gap: 0 !important;
}
.ShiftBat .list-group .list-group-item .RouteInfo.w-100 {
    width: calc(100% - 112px) !important;
}
.ShiftBat .list-group .list-group-item .RouteInfo.w-100 .RouteDetails {
    min-width: 100% !important;
}
.Timetable .timetable-row .RouteInfo .RouteDetails {
    min-width: auto;
}
.transfer-row-info {
    background: #fff !important;
    margin: 10px 0 0 !important;
    padding: 5px 0 !important;
    width: 100% !important;
    border: 1px solid #E9E9E9 !important;
    border-radius: 6px !important;
    gap: 8px !important;
    color: #777 !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    letter-spacing: 0.1px !important;
    flex-wrap: wrap !important;
    cursor: pointer;
    position: relative;
}

.transfer-row-info .show-transfers {
    position: absolute;
    left: 10px;
}

.transfer-row-info .show-transfers svg path {
    fill: #bbb;
}

.transfer-row-info.Selected {
    background: rgba(106, 84, 249, 0.12) !important;
    border-color: rgba(106, 84, 249, 0.12) !important;
    color: #555 !important;
}

.transfer-single {
    padding: 3px;
    border: 1px solid transparent;
    border-radius: 6px;
}

.transfer-row-info:not(.Selected) .transfer-single.Selected {
    background: rgba(106, 84, 249, 0.14);
    border-color: rgba(106, 84, 249, 0.14);
}

.transfer-row-info.Selected .show-transfers svg path {
    fill: #6B54F9;
}

.transfer-row-info .RouteLogo {
    background: #fff;
}

.share-btn-wrap {
    /* position: absolute;
    right: 6px;
    top: 7px;
    cursor: pointer !important;
    padding: 0 5px 3px !important; */
    width: 100% !important;
    display: flex !important;
    justify-content: flex-end !important;
}

.share-btn-wrap .icon-button svg path {
    stroke: #6B54F9 !important;
}

.share-btn-wrap .icon-button:hover svg path {
    stroke: #fff !important;
}

/* .share-btn-wrap:hover {
    background: #6B54F9 !important;
    border-radius: 100px !important;
}
.share-btn-wrap:hover svg path {
    stroke: #fff !important;
}
.share-btn-wrap button {
    padding: 0 !important;
}*/
body.busable-app .header-title {
    margin: 0 0 15px !important;
}

@media screen and (min-width: 1024px) {
    body.busable-app .header-title {
        margin: -40px 0 25px !important;
    }
}

.logo-upload svg path {
    fill: #999 !important;
}

.BusRouteDetails .map-items .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px !important;
}

.BusRouteDetails .map-items .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
    line-height: 26px !important;
}

.map-wp-selector {
    position: absolute !important;
    bottom: 40px !important;
    left: 10px !important;
    right: 10px !important;
    background: rgba(255, 255, 255, .6) !important;
    border-radius: 50px !important;
    padding: 0 1px 0 12px !important;
    border: 1px solid #ddd !important;
    display: flex !important;
    align-items: center !important;
}

.map-wp-selector .ant-slider.ant-slider-horizontal {
    width: 90% !important;
    margin-right: 15px !important;
}

.map-wp-selector .ant-slider.ant-slider-horizontal .ant-slider-rail {
    background-color: #ddd !important;
}

.map-wp-selector .map-wp-selector-actions button {
    background: #E9E6FA !important;
    border-color: #fff !important;
    border-radius: 30px;
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 32px !important;
    width: 38px !important;
    outline: none !important;
    box-shadow: none !important;
}

.map-wp-selector .ant-slider.ant-slider-horizontal .ant-slider-track,
.map-wp-selector .map-wp-selector-actions button:hover {
    background: #6B54F9 !important;
}

.map-wp-selector .map-wp-selector-actions button.actio-btn-close svg {
    width: 11px !important;
}

.map-wp-selector .map-wp-selector-actions button svg path {
    fill: #6B54F9 !important;
}

.map-wp-selector .map-wp-selector-actions button:hover svg path {
    fill: #fff !important;
}

.map-wp-selector .ant-slider.ant-slider-horizontal .ant-slider-handle:after {
    box-shadow: 0 0 0 2px #6B54F9 !important
}

.map-wp-selector .ant-slider.ant-slider-horizontal .ant-slider-handle:hover::after,
.map-wp-selector .ant-slider.ant-slider-horizontal .ant-slider-handle:active::after,
.map-wp-selector .ant-slider.ant-slider-horizontal .ant-slider-handle:focus::after {
    box-shadow: 0 0 0 4px #6B54F9 !important
}

.service-rows-wrap {
    height: 100% !important;
    overflow-y: scroll !important;
    overflow-x: hidden;
    width: calc(100% + 20px);
    margin: 0 -10px;
    padding: 0 0 50px;
}

.Export .ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff !important;
}

.Export .ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper:hover {
    border-color: #6B54F9 !important;
}

body.busable-app .ant-modal .ant-modal-header .ant-modal-title,
#busable-embed .ant-modal .ant-modal-header .ant-modal-title {
    font-size: 18px !important;
}

.ant-modal .btn-primary,
.btn-secondary,
body.busable-app .ant-modal .ant-modal-footer .ant-btn-primary,
body.busable-app .ant-modal .ant-modal-footer .ant-btn-default,
#busable-embed .ant-modal .ant-modal-footer .ant-btn-primary,
#busable-embed .ant-modal .ant-modal-footer .ant-btn-default,
.ButtonGroup .btn-primary {
    box-shadow: none !important;
    outline: none !important;
}

body.busable-app .ant-modal .btn-primary:not(.btn-icon-control),
body.busable-app .ant-modal .ant-modal-footer .ant-btn-primary,
#busable-embed .ant-modal .btn-primary,
#busable-embed .ant-modal .ant-modal-footer .ant-btn-primary,
.ButtonGroup .btn-primary,
.ant-modal-header.hide-print .btn-primary.icon-btn,
.info-box .btn-primary {
    background: #6B54F9 !important;
    border-color: #6B54F9 !important;
    color: #fff !important;
    border-radius: 5px;
}

.ant-modal .btn-primary:not(.btn-icon-control):hover,
body.busable-app .ant-modal .ant-modal-footer .ant-btn-primary:hover,
#busable-embed .ant-modal .ant-modal-footer .ant-btn-primary:hover,
.ButtonGroup .btn-primary:hover,
.info-box .btn-primary:hover {
    background: #624aff !important;
    border-color: #624aff !important;
}

.ant-modal .btn-primary.icon-btn {
    text-transform: uppercase;
    font-weight: 600;
}

.ant-modal .btn-primary.icon-btn svg {
    height: 16px;
}

.ant-modal .btn-primary.icon-btn svg path,
.ant-modal-header.hide-print .btn-primary.icon-btn svg path {
    fill: #fff;
}
.ant-modal-header.hide-print .ant-modal-title {
    font-size: 18px;
    font-weight: 600;
}

.ant-modal-body.letter-modal {
    background: #F8FAFB;
    padding: 50px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 14px;
}

.ant-modal-body.letter-modal .letter-content {
    font-size: 16px;
    line-height: 26px;
}

.btn-secondary,
body.busable-app .ant-modal .ant-modal-footer .ant-btn-default,
#busable-embed .ant-modal .ant-modal-footer .ant-btn-default,
.ButtonGroup .btn-secondary {
    color: #444 !important;
    border-color: #d9d9d9 !important;
    background: none !important;
    border-radius: 5px;
}

.btn-secondary:hover,
body.busable-app .ant-modal .ant-modal-footer .ant-btn-default:hover,
#busable-embed .ant-modal .ant-modal-footer .ant-btn-default:hover,
.ButtonGroup .btn-secondary:hover,
.info-box .btn-secondary:hover {
    color: #6B54F9 !important;
    border-color: #6B54F9 !important;
    background: none !important;
}

body.busable-app .ant-modal .ant-modal-close,
#busable-embed .ant-modal .ant-modal-close {
    border-radius: 50px !important;
    width: 24px !important;
    height: 24px !important;
}

body.busable-app .ant-modal .ant-modal-close:hover,
#busable-embed .ant-modal .ant-modal-close:hover {
    background: #E9E6FA !important
}

body.busable-app .ant-modal .ant-modal-close svg,
#busable-embed .ant-modal .ant-modal-close svg {
    font-size: 16px !important;
}

body.busable-app .ant-modal .ant-modal-close:hover svg,
#busable-embed .ant-modal .ant-modal-close:hover svg {
    fill: #6B54F9 !important;
}

body.busable-app .ant-collapse .anticon.ant-collapse-arrow svg,
#busable-embed .ant-collapse .anticon.ant-collapse-arrow svg {
    fill: #999 !important;
}

svg.icon-grey path {
    fill: rgba(0, 0, 0, 0.4) !important;
}

svg.icon-grey-stroke path {
    stroke: rgba(0, 0, 0, 0.4) !important;
}

.text-primary {
    color: #8876FA !important;
    font-weight: 600 !important;
    font-size: 14px !important;
}

.text-info {
    color: #ACACAC !important;
    font-weight: 600 !important;
    font-size: 14px !important;
}
.text-info-md {
    color: #989898 !important;
    font-size: 14px !important;
    line-height: 20px;
}
.text-info-lg {
    color: #888;
    font-size: 15px;
    line-height: 22px;
}
.text-title {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
}
.text-sm-info {
    color: #777 !important;
}

.text-lg {
    font-size: 42px !important;
    line-height: 42px !important;
    color: #6B54F9 !important;
    font-weight: 600 !important;
}
.text-xl {
    font-size: 52px !important;
    line-height: 52px !important;
    color: #6B54F9 !important;
    font-weight: 600 !important;
}
.text-xl > span {
    font-size: 42px;
}
.DataTable table .ant-table-thead th.ant-table-cell,
.DataTable table .ant-table-tbody td.ant-table-cell {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.DataTable table tr.RowModified {
    background: #f1fce8 !important;
}

.DataTable table .ant-typography.ant-typography-success {
    color: #389e0d !important;
}

.DataTable .ant-pagination {
    gap: 5px;
}

.DataTable .ant-pagination li a {
    font-size: 13px;
    color: #989898;
}

.DataTable .ant-pagination li.ant-pagination-item-active {
    border-color: #6B54F9;
}

.DataTable .ant-pagination li.ant-pagination-item-active a {
    color: #6B54F9;
}

.transfers-modal .DataTable {
    height: 527px;
    overflow-y: scroll;
}

.transfers-modal .DataTable table .ant-table-thead th.ant-table-cell {
    line-height: 16px;
}

.transfers-modal .DataTable table .ant-table-thead {
    height: 54px;
}

.sb-row.alert-danger,
.ant-input.alert-danger {
    color: #FF4545 !important;
    background-color: #FEE5E5 !important;
    border-color: #ffd4d4 !important;
}

.ant-input.alert-danger {
    font-weight: 600 !important;
}

.graph-wrap .graph-info {
    gap: 15px;
    margin: 0 0 30px !important;
}

.graph-wrap .graph-info strong,
.graph-wrap .graph-info-lg strong {
    font-size: 28px !important;
    line-height: 28px !important;
    color: #6B54F9;
}

.graph-wrap .graph-info-lg strong {
    font-size: 42px !important;
    line-height: 42px !important;
}

.graph-wrap .graph-info .ant-tag {
    border-radius: 50px !important;
    color: #51B238 !important;
    font-weight: 600 !important;
}

.graph-wrap canvas {
    max-height: 280px !important;
}

.graph-wrap .graph-legend label {
    margin: 0 !important;
    font-size: 13px !important;
}

.graph-wrap .graph-legend strong {
    font-size: 20px !important;
    color: #5A5A5A !important;
    line-height: 22px !important;
}

.icon-switch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}

.icon-switch svg path {
    fill: #c8c8c8 !important;
    fill-opacity: 1 !important;
}

.icon-switch svg.active path {
    fill: #6B54F9 !important;
}

.icon-switch > .ant-switch {
    position: absolute;
    opacity: 0 !important;
}

.employee-dashboard .ant-input.ant-input-status-error {
    background: #FEE5E5 !important;
    color: #FF4545 !important;
}

.card-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    box-shadow: none;
}

.upload-btn-wrap {
    width: 100%;
    background: #f4f4f4;
    padding: 25px 20px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.info-blocks {
    text-align: center;
    margin: 35px 0;
}

.info-blocks strong {
    font-size: 42px;
    line-height: 42px;
    color: #6B54F9;
    font-weight: 600;
}

.color-error,
.info-blocks .ant-col:first-of-type strong {
    color: #FF4545;
}

.color-warning,
.info-blocks .ant-col:last-of-type strong {
    color: #fd7e14;
}

.transfers-header .route-step-number svg {
    width: 25px;
    height: 25px;
}

.transfers-header .route-step-number svg circle {
    transform: scale(1.2, 1.2);
}

.transfers-header .route-step-number svg text {
    font-size: 13px;
}

.transfers-modal .RouteLogo {
    background: #fff;
}

.transfers-modal .row.RouteTitle {
    padding: 4px 0;
}

.transfers-modal .row.RouteTitle .RouteInfo {
    gap: 2px !important;
}

.transfers-modal .DataTable table .ant-table-row .ant-table-cell:nth-last-of-type(2),
.transfers-modal .DataTable table .ant-table-thead .ant-table-cell:nth-last-of-type(2) .ant-table-column-title,
.transfers-modal .DataTable table .ant-table-row .ant-table-cell:last-of-type,
.transfers-modal .DataTable table .ant-table-thead .ant-table-cell:last-of-type .ant-table-column-title,
.transfers-modal .ant-row .ant-col:last-of-type .DataTable table .ant-table-row .ant-table-cell:nth-last-of-type(3),
.transfers-modal .ant-row .ant-col:last-of-type .DataTable table .ant-table-thead .ant-table-cell:nth-last-of-type(3) .ant-table-column-title {
    text-align: center;
}

.transfers-modal .DataTable table .ant-table-row .ant-table-cell:nth-last-of-type(2),
.transfers-modal .DataTable table .ant-table-row .ant-table-cell:last-of-type,
.transfers-modal .ant-row .ant-col:last-of-type .DataTable table .ant-table-row .ant-table-cell:nth-last-of-type(3) {
    padding-left: 20px;
    padding-right: 20px;
}

.row.sb-row.Selected {
    background-color: #E9E6FA;
}

.BusRouteDetails .map-items {
    position: relative;
    overflow: visible !important;
    padding: 0 !important;
}
.BusRouteDetails .card-main.map-holder-wrap {
    overflow: hidden;
}

.timetable-footer {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1px;
}

.timetable-footer .icon-switch,
.timetable-footer .ant-btn.transfer-icon,
.timetable-footer .btn.icon-button.inline-icon {
    background: #E9E6FA !important;
    border-radius: 0 !important;
    padding: 0 !important;
    width: 44px !important;
    height: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timetable-footer .icon-switch svg {
    width: 14px;
}

.timetable-footer .icon-switch svg path,
.timetable-footer .ant-btn.transfer-icon svg path {
    fill: #999 !important;
}

.timetable-footer .icon-switch:hover svg path,
.timetable-footer .icon-switch svg.active path,
.timetable-footer .ant-btn.transfer-icon:hover svg path {
    fill: #6B54F9 !important;
}

.timetable-footer .btn.icon-button.inline-icon svg g {
    stroke: #999 !important;
}

.timetable-footer .btn.icon-button.inline-icon:hover svg g {
    stroke: #6B54F9 !important;
}

.timetable-footer .transfer-icon.ant-popover-disabled-compatible-wrapper {
    background: #f2f2f2 !important;
}

.timetable-footer .transfer-icon.ant-popover-disabled-compatible-wrapper button {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 30px !important;
}

body.busable-app .ant-alert.alert-warning,
#busable-embed .ant-alert.alert-warning {
    padding: 10px 15px !important;
    border-radius: 6px !important;
    border: 1px solid #ffd494 !important;
    background: #fffaea !important;
}

body.busable-app .ant-alert.alert-warning .anticon.ant-alert-icon,
#busable-embed .ant-alert.alert-warning .anticon.ant-alert-icon {
    width: 20px;
}

body.busable-app .ant-alert.alert-warning .ant-alert-content .ant-alert-message,
#busable-embed .ant-alert.alert-warning .ant-alert-content .ant-alert-message {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 15px;
}

.ShiftBatView .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl,
.TripPlanner .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl,
.StopManagerContainer .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl,
.Timetable .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    top: -130px;
    min-width: 30vw !important;
}

@media screen and (max-width: 1023px) {
    .ShiftBatView .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--icon-search,
    .TripPlanner .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--icon-search,
    .Timetable .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--icon-search {
        left: 7px;
        width: 20px;
        height: 20px;
        top: 8px;
    }

    .ShiftBatView .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--input,
    .TripPlanner .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--input,
    .Timetable .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--input {
        font-size: 15px !important;
        padding: 6px 35px !important;
        height: 36px !important;
    }

    .ShiftBatView .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--pin-right .mapboxgl-ctrl-geocoder--icon-close,
    .TripPlanner .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--pin-right .mapboxgl-ctrl-geocoder--icon-close,
    .Timetable .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--pin-right .mapboxgl-ctrl-geocoder--icon-close {
        width: 16px;
        height: 16px;
        margin-top: 3px;
        margin-right: 0;
    }


    .ShiftBatView .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .suggestions-wrapper .suggestions,
    .TripPlanner .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .suggestions-wrapper .suggestions,
    .StopManagerContainer .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .suggestions-wrapper .suggestions,
    .Timetable .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .suggestions-wrapper .suggestions {
        font-size: 13px !important;
        height: 400px;
        overflow-y: scroll;
    }

    .ShiftBatView .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl,
    .TripPlanner .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl,
    .Timetable .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
        min-width: calc(100vw - 100px) !important;
    }
}

@media screen and (min-width: 1024px) {
    .ShiftBatView .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .suggestions-wrapper .suggestions,
    .TripPlanner .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .suggestions-wrapper .suggestions,
    .StopManagerContainer .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .suggestions-wrapper .suggestions,
    .Timetable .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .suggestions-wrapper .suggestions {
        height: 600px;
        overflow-y: scroll;
    }
}

.user-list-icon {
    width: auto;
    height: 46px;
}

.user-list-icon path {
    fill: #EAEAEA;
}

@media screen and (min-width: 992px) {
    .employee-dashboard > .ant-row:not(.w-100) > .ant-col:first-of-type,
    .w-secondary-menu > .ant-row:not(.w-100) > .ant-col:first-of-type {
        max-width: 277px !important;
    }

    .employee-dashboard > .ant-row:not(.w-100) > .ant-col:last-of-type,
    .w-secondary-menu > .ant-row:not(.w-100) > .ant-col:last-of-type {
        flex: 0 0 calc(100% - 277px) !important;
        max-width: calc(100% - 277px) !important;
    }
}

.employee-dashboard .profile-summary,
.w-secondary-menu .profile-summary {
    padding: 20px 20px 15px;
    text-align: center;
}

.employee-dashboard .profile-summary h2,
.w-secondary-menu .profile-summary h2 {
    font-size: 20px;
    margin: 10px 0 0 !important;
}

.employee-dashboard .profile-summary .ant-badge sup.ant-badge-dot,
.w-secondary-menu .profile-summary .ant-badge sup.ant-badge-dot {
    bottom: 8px !important;
    top: auto !important;
    right: 8px !important;
    width: 15px !important;
    height: 15px !important;
    transform: translate(0) !important;
}

.employee-dashboard .profile-summary .ant-badge.success sup.ant-badge-dot,
.w-secondary-menu .profile-summary .ant-badge.success sup.ant-badge-dot {
    background: #52C41A !important;
}

.employee-dashboard .profile-summary .ant-badge .ant-avatar,
.w-secondary-menu .profile-summary .ant-badge .ant-avatar {
    background: #EAEAEA;
}

.employee-dashboard .profile-menu,
.w-secondary-menu .profile-menu {
    border-radius: 0 0 20px 20px !important;
}

.employee-dashboard .profile-menu .ant-menu-item,
.w-secondary-menu .profile-menu .ant-menu-item {
    border-top: 1px solid #EFEDFD;
    margin: 0 !important;
    padding: 0 20px !important;
    border-radius: 0 !important;
    height: 50px !important;
    width: 100% !important;
}

.employee-dashboard .profile-menu .ant-menu-item:last-of-type,
.w-secondary-menu .profile-menu .ant-menu-item:last-of-type {
    border-radius: 0 0 20px 20px !important;
}

.employee-dashboard .profile-menu .ant-menu-item span,
.w-secondary-menu .profile-menu .ant-menu-item span {
    color: #666 !important;
    font-weight: 500 !important;
}

.employee-dashboard .profile-menu .ant-menu-item svg path,
.w-secondary-menu .profile-menu .ant-menu-item svg path {
    fill: #BEBEBE !important;
    fill-opacity: 1 !important;
}

.employee-dashboard .profile-menu .ant-menu-item:hover,
.employee-dashboard .profile-menu .ant-menu-item.ant-menu-item-selected,
.w-secondary-menu .profile-menu .ant-menu-item:hover,
.w-secondary-menu .profile-menu .ant-menu-item.ant-menu-item-selected {
    background: #F8F7FE !important;
}

.employee-dashboard .profile-menu .ant-menu-item.ant-menu-item-selected span,
.w-secondary-menu .profile-menu .ant-menu-item.ant-menu-item-selected span {
    color: #6B54F9 !important
}

.employee-dashboard .profile-menu .ant-menu-item.ant-menu-item-selected svg path,
.w-secondary-menu .profile-menu .ant-menu-item.ant-menu-item-selected svg path {
    fill: #8775FA !important;
}

#busable-embed .SchoolStopList .filter-options-main.top-float {
    display: none !important;
}

.accessible-active path {
    fill: #6B54F9 !important;
}

.accessible-active svg path {
    fill: #6B54F9 !important;
}

.ant-switch {
    outline: none !important;
}

.ant-switch.verify-switch.ant-switch-checked {
    background: #7ED668;
}

.ant-switch.verify-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #52C41A;
}

.ant-switch.default-switch.ant-switch-checked {
    background: #6B54F9;
}

.ant-switch.default-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #5b45e9;
}

.EditStop {
    padding: 0 5px;
}

.FormGroup {
    margin-bottom: 16px;
}

.FormGroup .form-label {
    font-size: 14px;
    color: #888;
}

.FormGroup .ant-select,
.FormGroup .ant-picker {
    width: 100%;
}

.FormGroup .InputTimeField,
.FormGroup .form-control {
    width: 100%;
    border-radius: 6px;
    height: 32px;
    font-size: 14px;
    padding: 4px 10px;
    color: rgba(0, 0, 0, 0.88);
}

.FormGroup .InputTimeField:focus,
.FormGroup .form-control:focus {
    border-color: #4096ff;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    outline: 0;
}

.ButtonGroup {
    margin: 16px 0 0;
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.header-icon {
    width: 17px;
}

.header-icon.w-16 {
    width: 15px;
}

.header-icon path {
    fill: #ccc;
}

.FormGroup .InputTimeField {
    width: 100% !important;
    border: 1px solid #d9d9d9 !important;
}

.FormGroup .InputTimeField:focus {
    border-color: #4096ff !important;
}

.FormGroup .ant-input-group-wrapper .ant-input-group > .ant-input {
    text-align: center;
    padding-left: 4px;
    padding-right: 4px;
}

.FormGroup .ant-input-group-wrapper .ant-input-group .ant-input-group-addon {
    padding: 0 4px;
    color: #555;
    font-size: 13px;
}

.SchoolBellTimes {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.SchoolBellTimes li {
    text-align: center;
    flex-shrink: 0 !important;
}

.SchoolBellTimes li:first-child,
.SchoolBellTimes li:nth-child(3) {
    min-width: 66px;
    width: calc(20% - 7.5px);
}

.SchoolBellTimes li:nth-child(2) {
    min-width: 84px;
    width: calc(28% - 7.5px);
}

.SchoolBellTimes li:last-child {
    min-width: 98px;
    width: calc(32% - 7.5px);
}

.ant-popover-content .data-layers-wrap {
    width: 100%;
    display: flex;
    gap: 10px;
}

.ant-popover-content .data-layers-wrap button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    border: 1px solid #e5e5e5 !important;
    height: 74px;
    padding: 8px 8px;
    gap: 0 !important;
    color: #555 !important;
    background: #fff !important;
    font-weight: 500;
}

.ant-popover-content .data-layers-wrap button:hover,
.ant-popover-content .data-layers-wrap button.active {
    background: #E9E6FA !important;
    color: #6B54F9 !important;
    border-color: #E9E6FA !important;
}

.ant-popover-content .data-layers-wrap button svg {
    width: 40px;
    height: 34px;
}

.ControlPanel .CardHover,
.vehicle-card .ControlPanel {
    display: none !important;
    height: 0;
    visibility: hidden;
    animation: showfade .6s;
}

.ControlPanel .ant-row.CardHover {
    margin-top: 0 !important;
}

.ControlPanel .CardHover.hovered,
.vehicle-card .ControlPanel.ShowMoreInfo {
    display: flex !important;
    visibility: visible;
    height: auto;
}
.Dashboards {
    
}
.Dashboards.hide {
    animation: hidefade .6s;
    visibility: hidden;
    height: 0;
    display: none;
}
.Dashboards.show {
    animation: showfade .6s;
    visibility: visible;
    height: auto;
    display: flex;
}
@keyframes showfade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes hidefade {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.ControlPanel .ant-row.CardHover.hovered {
    margin-top: 14px !important;
}

.btn-more {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff !important;
    border: none;
    border-radius: 0 0 8px 8px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    box-shadow: none !important;
}

.btn-more svg path {
    stroke: #bbb;
}

.btn-more:hover,
.btn-more:active {
    background: #E9E6FA !important;
}

.btn-more:hover svg path,
.btn-more:active svg path {
    stroke: #6B54F9 !important;
}

.btn-more.opened svg {
    transform: rotate(180deg);
}


.nondroppable-row {
    transform: none !important;
}

.droppable-row {
    background: #1da1f2;
    transform: none !important;
    border: 1px solid #024f7a;
}

.dragging-row {
    background: rgba(255, 255, 255, .7) !important;
    border: 1px solid #f0f0f0 !important;
    border-radius: 18px;
    z-index: 9999;
    padding: 15px 20px;
    box-shadow: 0 0 15px rgba(107, 84, 249, .15) !important;
}

.dragged-row {
    background: #da9c9c !important;
    border: 1px solid #ff0000 !important;
    z-index: 9999;
}
.sb-edit-controls .sb-done,
.sb-edit-controls .sb-close {
    border: none !important;
    border-radius: 50px !important;
    width: 25px !important;
    height: 25px !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-shrink: 0 !important;
}
.sb-edit-controls .sb-done svg,
.sb-edit-controls .sb-close svg {
    height: 9px !important;
}
.sb-edit-controls .sb-done {
    background: #D9F3D2 !important;
}
.sb-edit-controls .sb-done svg path {
    stroke: #7ED668 !important;
}
.sb-edit-controls .sb-close {
    background: #FFE6E6 !important;
}
.sb-edit-controls .sb-close svg path {
    fill: #FF4545 !important;
}
.sb-edit-controls .sb-done:hover {
    background: #7ED668 !important;
}
.sb-edit-controls .sb-done:hover svg path {
    stroke: #fff !important;
}
.sb-edit-controls .sb-close:hover {
    background: #FF4545 !important;
}
.sb-edit-controls .sb-close:hover svg path {
    fill: #fff !important;
}
.Marketplace .icon-button:hover svg path,
.back-btn:hover svg path {
  stroke: #fff !important;
}
.back-btn {
    position: absolute;
    top: 15px;
    left: 15px;
}
.RequestQuota {
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    overflow: hidden;
}
.RequestQuota > .ant-row > .ant-col {
    min-height: 78px;
    padding: 0 25px;
}
.RequestQuota > .ant-row > .ant-col:last-of-type {
    background: #f6f6f6;
    border-left: 1px solid #d9d9d9;
}
.RequestQuota .icon-button {
    width: 30px !important;
    height: 30px !important;
}
.RequestQuota .icon-button svg {
    width: 12px;
    height: 12px;
}
.RequestQuota .icon-button svg path {
    fill: #6B54F9;
}
.RequestQuota .icon-button:hover svg path {
    fill: #fff;
}
.list-link {
    color: rgba(0, 0, 0, 0.88);
}
.list-link:hover {
    color: #6B54F9;
}
.MapSearch {
    position: absolute;
    z-index: 999;
    top: 8px;
    left: 155px;
    width: calc(60% - 175px);
}
.MapSearch > div > .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    width: 100%;
    min-width: 250px;
    max-width: none;
}
.MapSearch .mapboxgl-ctrl-geocoder--input {
    height: 40px;
}
.MapSearch .mapboxgl-ctrl-geocoder--icon {
    top: 10px;
}
.MapSearch.PointsMap {
    top: 30px;
    left: 44px;
}
.App.publicContainer .Timetable .MapPlaceholder div > .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    top: -95px;
}
@media screen and (min-width: 1024px) {
    .MapSearch.PointsMap > div > .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
        min-width: 30vw !important;
    }
    .Timetable .MapPlaceholder div > .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
        min-width: 30vw !important;
    }
}
.SwitchLarge .ant-switch-inner > span {
    font-size: 15px !important;
}
.EligibleSwitch label {
    text-align: right !important;
    align-items: end !important;
}
.EligibleSwitch strong {
    width: 100% !important;
    background: none !important;
}

.student-editor-wrapper {
    margin-top: 10px;
}
.student-editor-wrapper .rdw-editor-toolbar,
.student-editor-wrapper .student-editor,
.PrintWrapper .rdw-editor-toolbar,
.PrintWrapper .rdw-editor-main {
    border: 1px solid #ddd;
    border-radius: 8px;
}
.student-editor-wrapper .student-editor,
.PrintWrapper .rdw-editor-main {
    padding: 10px 20px;
}
.student-editor-wrapper .student-editor .rdw-suggestion-dropdown {
    min-width: 200px !important;
    max-height: 250px !important;
    box-shadow: 0 0 15px rgba(107, 84, 249, .2);
}
.misbehaviour-notes-editor {
    border: 1px solid #595959;
    border-radius: 10px;
    height: auto;
}
.PreviewWrapper {
    width: 100%;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    display: flex;
    padding: 40px 30px;
}
@media only screen {
    .PrintWrapper {
        width: 100%;
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        display: flex;
        min-height: 1120px;
      }
    .modal-info.modal-body-fixed .PrintWrapper {
      /* width: calc(100% + 2px); */
      margin: -1px 0;
    }
    .modal-info.modal-body-fixed .PrintWrapper:last-of-type {
        margin-bottom: 0 !important;
        border-bottom: none;
    }
}
.PrintWrapper .PrintHeader,
.PrintWrapper .PrintFooter {
    width: 100%;
    padding: 50px 50px 0;
}
.PrintWrapper .PrintHeader .text-right {
    text-align: right !important;
}
.PrintWrapper .PrintHeader .text-small,
.PrintWrapper .PrintHeader .text-small p,
.PrintWrapper .PrintHeader .text-small strong {
    font-size: 12px !important;
    line-height: 14px !important;
}
.PrintWrapper .PrintHeader .text-small p {
    margin-bottom: 5px !important;
}
.PrintWrapper .PrintHeader p {
    margin-bottom: 20px !important;
}
.PrintWrapper .PrintHeader p {
    font-weight: 400 !important;
}
.PrintWrapper .PrintFooter {
    padding-top: 15px;
}
.PrintWrapper .PrintFooter h3 {
    font-size: 18px !important;
    margin: 0 0 15px;
}
.PrintWrapper .PrintFooter p > span {
    min-width: 85px;
    display: inline-block;
    font-weight: 400;
    font-style: italic;
}
.PrintWrapper .PrintSheet .PrintPass {
    min-height: 275px;
    min-width: 300px;
}
.PrintWrapper .PrintSheet .PrintPass.LockRatio {
    height: auto !important;
    aspect-ratio: 16/9;
}

.charter-details .ant-carousel .slick-arrow,
.ControlPanel .ant-carousel .slick-arrow {
    color: #333;
    z-index: 16;
}
.charter-details .ant-carousel .slick-arrow:hover,
.ControlPanel .ant-carousel .slick-arrow:hover {
    transform: translateY(-50%) !important;
    color: #6B54F9;
}
.charter-details .ant-carousel .slick-arrow.slick-disabled,
.ControlPanel .ant-carousel .slick-arrow.slick-disabled {
    opacity: 0.15;
}

.charter-details .ant-carousel .slick-arrow.slick-next,
.charter-details .ant-carousel .slick-arrow.slick-prev {
    top: 0;
    bottom: auto;
}

.charter-details .ant-carousel .slick-arrow.slick-next {
    right: 0;
}
.charter-details .ant-carousel .slick-arrow.slick-prev {
    left: auto;
    right: 24px;
}
.charter-details .ant-carousel .slick-dots {
    display: none !important;
}
.ControlPanel .ant-carousel .slick-arrow.slick-prev,
.ControlPanel .ant-carousel .slick-arrow.slick-next {
    top: auto;
    bottom: 0;
}
.ControlPanel .ant-carousel .slick-arrow.slick-prev {
    left: 0;
}
.ControlPanel .ant-carousel .slick-arrow.slick-next {
    left: 16px;
}
.ControlPanel .ant-carousel .slick-dots {
    display: none !important;
}
.ControlPanel .ant-carousel .slick-dots li {
    background-color: #E8E6FA;
}
.ControlPanel .ant-carousel .slick-dots li:hover button {
    background-color: #6B54F9;
}
.ControlPanel .ant-carousel .slick-dots li.slick-active button {
    background-color: #6B54F9;
    border: 1px solid #6B54F9;
}
.ControlPanel .CarouselInfo {
    color: #999 !important;
    position: absolute;
    bottom: 20px;
    left: 60px;
}
.ControlPanel .CarouselActions {
    position: absolute;
    bottom: 13px;
    right: 14px;
    gap: 6px;
    display: flex;
}
.ControlPanel .archived-active {
    background-color: #5d47eb;
    color: white;
}
.text-xs-info {
    color: #ADADAD !important;
    font-size: 13px;
    line-height: 13px;
}
.text-title-sm {
    font-size: 14px;
    color: #333;
    line-height: 14px;
    font-weight: 500;
}
.icon-button.secondary-btn {
    background: none !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 6px !important;
    padding: 0 !important;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-button.secondary-btn:hover,
.icon-button.secondary-btn.active {
    background: #E9E6FA !important;
    border: 1px solid #DCD7FA !important;
}
.icon-button.secondary-btn:not(.icon-stroke) svg path {
    fill: #999;
}
.icon-button.secondary-btn.icon-stroke svg path {
    stroke: #999;
}
.icon-button.secondary-btn:not(.icon-stroke):hover svg path,
.icon-button.secondary-btn.active:not(.icon-stroke) svg path {
    fill: #6B54F9;
}
.icon-button.secondary-btn.icon-stroke:hover svg path,
.icon-button.secondary-btn.icon-stroke.active svg path {
    stroke: #6B54F9;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .NewCommentWrap .icon-button.secondary-btn {
    bottom: -30px;
    right: 2px;
    width: 24px !important;
    height: 24px !important;
}
.RouteListContainerItem .RouteTitle .RouteLogo-sm {
    width: 40px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    border-radius: 6px;
    border: 1px solid lightgray;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}
.RouteListContainerItem .RouteTitle .RouteLogo-sm img {
    max-height: 40px;
    max-width: 40px;
    padding: 1px;
    border-radius: 6px;
    margin: 0;
}
.RouteListContainerItem .RouteTitle .RouteNumber-sm {
    height: 28px;
    border-radius: 0 6px 6px 0;
}
.RouteListContainerItem .RouteTitle .RouteInfo-sm {
    margin-left: 12px !important;
    width: auto !important;
}
.RouteListContainerItem .RouteTitle .RouteInfo-sm h1 {
    font-size: 14px;
    font-weight: 500 !important;
    margin: 0;
    padding: 0;
}
.badge-beta:after {
    content: 'beta';
    font-size: 10px;
    line-height: 10px;
    font-weight: 600;
    background: rgba(255,255,255,1);
    color: #6B54F9;
    border-radius: 10px;
    padding: 1px 3px;
    text-transform: none;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translate(0,-50%);
}
.DateSelector {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    background: #fff;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(107, 84, 249, .1);
}
.DateSelector button {
    width: 26px;
    height: 26px;
    background: #D3CCFD;
    border: none;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    outline: none;
}
.DateSelector button svg path {
    fill: #fff;
}
.DateSelector button:hover {
    background: #6B54F9;
}
.DateSelector button:first-of-type svg {
    transform: rotate(180deg);
}
.DateSelector strong {
    color: #999;
    font-size: 14px;
    font-weight: 500;
}
.ShareModal > .row > [class^="col-"] {
    margin-bottom: 25px;
}
.VehicleBearing {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.VehicleBearingMarker {
    width: 56px;
    height: 56px;
    background: url(./assets/icons/Bearing.svg);
    background-size: 56px;
    background-position: center;
}
.fixed-tooltip .ant-tooltip-content {
    max-height: 400px;
    overflow-y: auto;
    border-radius: 6px;
}
.ant-tabs .ant-tabs-tab:hover,
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #6B54F9;
}
.icon-success {
    background: #7ED668;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-success svg {
    width: 10px !important;
}
.expand-button-wrap {
    width: 100%;
    text-align: center;
    margin-top: -16px;
}
.expand-button-wrap.w-line {
    border-top: 1px solid #f0f0f0;
    margin-top: 0;
}
.expand-button-wrap .icon-button {
    border-radius: 0 0 50px 50px !important;
    padding: 0 10px !important;
    height: auto !important;
    background: #f0f0f0 !important;
}
.expand-button-wrap .icon-button svg {
    width: 18px;
    height: 18px;
}
.expand-button-wrap .icon-button svg path {
    stroke: #a5a5a5;
}
.expand-button-wrap .icon-button:hover,
.expand-button-wrap .icon-button.active {
    background: #E9E6FA !important;
}
.expand-button-wrap .icon-button.active svg {
    transform: rotate(180deg);
}
.expand-button-wrap .icon-button:hover svg path,
.expand-button-wrap .icon-button.active svg path {
    stroke: #6B54F9;
}
.ant-card.body-100 .ant-card-body {
    height: calc(100% - 32px);
}
.multi-select .ant-select-selector {
    background: #fff !important;
    overflow: hidden;
}
.multi-select .ant-select-selector .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
    display: none !important;
}
.multi-select.rounded .ant-select-selector {
    border-radius: 50px;
}
.ant-select-outlined.ant-select-multiple .ant-select-selection-item {
    height: 20px;
    border-radius: 50px;
    margin: 1px;
}
.ant-select-outlined.ant-select-multiple .ant-select-selection-item .ant-select-selection-item-content {
    font-size: 12px !important;
    line-height: 18px !important;
}

.StepsMenu.ant-steps .ant-steps-item-process .ant-steps-item-icon {
background-color: #E9E6FA;
border-color: #C4BBFD;
}
.StepsMenu.ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
color: #6B54F9;
font-weight: 500;
}
.StepsMenu.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
background-color: #fff;
border-color: #E6E7EB;
}
.StepsMenu.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
color: #BABABA;
font-weight: 500;
}
.StepsMenu.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
background-color: #C4BBFD;
}
.StepsMenu.ant-steps.ant-steps-label-vertical .ant-steps-item-content {
margin-top: 8px;
}
.StepsMenu.ant-steps.ant-steps-label-vertical .ant-steps-item-content .ant-steps-item-title {
font-size: 14px;
font-weight: 500;
line-height: 18px;
}
.StepsMenu.ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.StepsMenu.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
color: #757575;
}
.StepsMenu.ant-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
color: #BABABA;
}
.StepsMenu.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
background-color: #F6FFED;
border-color: #B7EB8F;
}
.StepsMenu.ant-steps .ant-steps-item-finish .ant-steps-item-icon .ant-steps-finish-icon svg {
fill: #52C41A;
}
.StepsMenu.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
    background-color: #6B54F9 !important;
}

.StepsActions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin: 10px 0 30px;
}
.primary-btn-sm {
    background: #6B54F9;
    outline: none !important;
}
.primary-btn-sm:hover {
    background: #7b69f5 !important;
}
.secondary-btn-sm {
    background: none !important;
    outline: none !important;
}
.secondary-btn-sm:hover {
    color: #6B54F9 !important;
    border-color: #6B54F9 !important;
}
/* .page-title .page-subtitle {
    font-size: 18px !important;
    color: #a8a8a8 !important;
    margin-left: 20px;
} */
.tag-sm {
    border-radius: 50px;
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    padding: 0px 4px;
    height: auto;
    background: #c6cad0;
    border-color: #c1c5ce;
    color: #fff;
    margin-bottom: 2px;
}
@media print {
    .ant-modal-content .hide-print-wrap,
    .ant-modal-content .hide-print-wrap *,
    .hide-print-wrap {
        visibility: hidden !important;
    }
}
.VehicleCard .RouteLogo .ant-avatar .ant-avatar-string {
    font-weight: 600;
    font-size: 16px;
    background: rgba(255, 255, 255, .4);
    border-radius: 50px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.info-label {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: #ADADAD;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.primary-btn.btn-small,
.btn-secondary.btn-small {
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 6px 15px !important;
    min-width: auto !important;
    height: auto;
    border-radius: 8px;
}
.ColorSuccess.ant-switch-checked {
    background: #52C41A !important;
}
.top-float-title {
    position: absolute;
    top: 0;
    left: 16px;
}
@media screen and (min-width: 1024px) {
    .top-float-title {
        top: 25px;
        left: 195px;
    }
}
.info-content {
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;
}
.info-content > div {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}
.info-content > div > label {
    margin: 0;
    color: #666;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 6px;
    width: 110px;
    flex-shrink: 0;
    word-break: break-word;
}
.info-content > div > label svg {
    flex-shrink: 0;
}
.info-content > div {
    color: #444;
    font-weight: 500;
    line-height: 14px;
    gap: 8px;
    word-break: break-word;
}
.info-content > div > div {
    word-break: break-word;
}
.info-content .info-content-allocation {
    border-top: 1px solid #EDEFF0;
    padding-top: 12px;
}
.info-content .info-content-allocation > div {
    display: flex;
    gap: 6px;
    align-items: center;
}
.info-content .info-content-allocation > div strong {
    font-weight: 500;
}
.info-content .info-content-allocation .ant-avatar {
    line-height: 14px;
    flex-shrink: 0;
}
.info-content .info-content-allocation .ant-avatar svg path {
    fill: #fff;
}
.info-content .info-content-issues {
    background: #FDF2F0;
    border: 1px solid #FCC3C1;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.info-content .info-content-issues strong {
    width: 100%;
    color: #FF4D4F;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}
.info-content .info-content-issues div:before {
    content: '';
    width: 8px;
    height: 8px;
    background: #FD6868;
    display: inline-flex;
    border-radius: 50px;
    flex-shrink: 0;
    margin-top: 4px;
}
.info-content .info-content-issues div {
    width: 100%;
    color: #444;
    display: flex;
    font-weight: 400;
    gap: 6px;
}
.filters-popover {
    width: 260px;
}
.filters-popover .ant-popover-inner {
    padding: 18px 20px 20px;
}
.filters-popover .filter-wrap {
    padding-top: 10px;
}
.filters-popover .filter-wrap label {
    margin-bottom: 0;
}
.filters-popover .ant-popover-title > div {
    min-height: 26px;
}
.filters-popover .ant-popover-title .ant-btn.icon-button.btn-filled {
    box-shadow: none !important;
    background: #F4F4F4 !important;
    color: #888 !important;
    margin-right: 0;
    height: 26px !important;
}
.filters-popover .ant-popover-title .ant-btn.icon-button.btn-filled svg path {
    fill: #888 !important;
}
.filter-switches-sm {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 18px;
}
.filter-switches-sm > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.filter-switches-sm > div span {
    display: inline-flex;
    align-items: center;
    gap: 12px;
}
.filter-switches-sm > div span i {
    width: 10px;
    height: 10px;
    border-radius: 20px;
}
.filter-switches-sm > div span,
.filters-popover .filter-wrap label {
    font-weight: 500;
    color: #555;
    line-height: 16px;
}
.icon-light path {
    fill: #fff;
}
.icon-dark path {
    fill: #C1C5CE;
}
.error-tooltip .ant-tooltip-content .ant-tooltip-inner,
.error-tooltip .ant-tooltip-arrow:before {
    background: #FDF2F0 !important;
}
.error-tooltip .ant-tooltip-content .ant-tooltip-inner {
    padding: 3px;
}