.GridLayout {
    width: 100%;
    min-height: calc(100vh - 285px);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    display: flex;
}
.GridLayout.UnallocatedGrid {
    max-height: calc(40vh);
    min-height: calc(40vh);
    overflow-x: auto;
}
.ant-modal-body .GridLayout.UnallocatedGrid {
    max-height: calc(75vh);
    min-height: calc(75vh);
}
.GridLayout .GridWrap {
    padding-top: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: calc(100vh - 285px);
    position: relative;
}
.GridLayout .GridLabel {
    width: 130px;
    min-height: calc(100vh - 285px);
    flex-shrink: 0;
    position: relative;
    padding-top: 20px;
}
.GridLayout.UnallocatedGrid .GridWrap {
    overflow-x: visible;
    overflow-y: visible;
    min-height: auto;
}
.GridLayout .GridLabel > div {
    height: 80px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-size: 15px;
    text-transform: uppercase;
    color: #7C7D7E;
    font-weight: 500;
}
.GridLayout .GridLabel > div:first-of-type {
    margin-top: 9px;
}
.GridLayout .react-grid-layout {
    z-index: 1;
}
.GridLayout .react-grid-item:not(.react-draggable-dragging):hover {
    min-width: fit-content;
    z-index: 1;
    background: rgba(255, 255, 255, 0.95) !important;
}
.GridLayout .react-grid-item.ItemDeadRun,
.GridLayout .react-grid-item.suggested-rosters {
    background: #F8FAFB !important;
    box-shadow: none !important;
    border: 2px dashed #D5D1FB !important;
}
.GridLayout .react-grid-item.ItemDeadRun:not(.react-draggable-dragging):hover,
.GridLayout .react-grid-item.suggested-rosters:not(.react-draggable-dragging):hover {
    background: #F8FAFB !important;
}
.GridLayout .react-grid-item.ItemDeadRun .simple-tags svg path {
    fill: #FF8F8F;
}
.GridLayout .react-grid-item .DeadRunInfo {
    width: auto !important;
    background: #F8FAFB !important;
    border: 2px dashed #D5D1FB !important;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    gap: 4px !important;
}
.GridLayout .react-grid-item .DeadRunInfo .deadrun-distance,
.GridLayout .react-grid-item .DeadRunInfo .deadrun-time {
    font-size: 14px;
    color: #666;
}
.GridLayout .react-grid-item .DeadRunInfo .red {
    color: #FF5555;
}
.GridLayout .react-grid-item .DeadRunInfo .green {
    color: #51B238;
}
.GridLayout .react-grid-item.suggested-rosters .DeadRunInfo:first-of-type {
    border-left: none !important;
}
.GridLayout .react-grid-item.suggested-rosters .DeadRunInfo:last-of-type {
    border-right: none !important;
}
.GridLayout .react-grid-item .DeadRunInfo:first-of-type {
    border-radius: 20px 0 0 20px;
}
.GridLayout .react-grid-item .DeadRunInfo:last-of-type {
    border-radius: 0 20px 20px 0;
}
.GridLayout .react-grid-item:not(:hover) .DeadRunInfo:last-of-type {    
    position: absolute;
    right: 0;
}
.GridLayout .react-grid-item:not(.ShowingDeadRunInfo) > .ant-avatar {
    margin-left: 10px;
}
.GridLayout .react-grid-item:not(.ShowingDeadRunInfo) > div.simple-tags {
    margin-right: 10px;
}
.GridLayout .react-grid-item.ShowingDeadRunInfo:not(:hover) > div.simple-tags {
    margin-right: 65px;
}
.GridLayout .react-grid-item > .ant-avatar,
.GridLayout .react-grid-item > div.simple-tags {
    flex-shrink: 0;
}
.GridLayout .react-grid-item .ant-avatar,
.GridLayout .react-grid-item .ant-avatar .ant-avatar-string {
    font-size: 20px !important;
    font-weight: 500;
}
.GridLayout .react-grid-item > div:not(.simple-tags) {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.GridLayout .react-grid-item > div:not(.simple-tags) > strong,
.GridLayout.UnallocatedGrid .react-grid-item strong {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}
.GridLayout .react-grid-item > div:not(.simple-tags) > span,
.GridLayout.UnallocatedGrid .react-grid-item span {
    color: #666;
    line-height: 14px;
}
.GridLayout.UnallocatedGrid .react-grid-item,
.GridLayout .react-grid-item.unallocated-rosters {
    background: rgba(255, 242, 240, 0.8) !important;
    border-radius: 16px !important;
    border: 1px solid #FFCCC7 !important;
    box-shadow: none !important;
    padding: 15px !important;
    justify-content: flex-start;
}
.GridLayout.UnallocatedGrid .react-grid-item:not(.react-draggable-dragging):hover,
.GridLayout .react-grid-item.unallocated-rosters:not(.react-draggable-dragging):hover {
    background: rgba(255, 242, 240, 0.8) !important;
}
.GridLayout .GridLines {
    display: flex;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.GridLayout .GridLines > div {
    position: relative;
    width: calc(3000px / 24);
    min-height: 100%;
    text-align: center;
    flex-grow: 1;
}
.GridLayout .GridLabel > strong,
.GridLayout .GridLines > div {
    font-size: 12px;
    color: #989898;
    font-weight: 600;
    text-transform: uppercase;
}

.roster-header {
    text-align: center;
    color: #989898;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
}
.roster-cell-main {
    padding: 0 0 0 10px;
}
.roster-item {
    background: #fff !important;
    border-radius: 10px !important;
    border: 1px solid transparent !important;
    border-left: none !important;
    box-shadow: 0 0 10px rgba(107, 84, 249, .15) !important;
    overflow: hidden;
    flex-shrink: 0;
    width: 100%;
    cursor: pointer;
    position: relative;
}
.roster-item .roster-item-wrap {
    padding: 8px 8px 8px 10px !important;
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    min-height: 80px;
}
.roster-item .roster-item-wrap > .ant-checkbox-wrapper {
    position: absolute;
    z-index: 2;
    top: 1px;
    right: 4px;
}
.roster-item .roster-item-wrap > .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked) {
    display: none;
}
.roster-item .roster-item-wrap:hover > .ant-checkbox-wrapper {
    display: inline-flex;
}
.roster-item .roster-item-wrap > .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner,
.roster-cell-main .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6B54F9;
    border-color: #6B54F9;
}
.roster-item .roster-item-wrap > .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.roster-cell-main .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #8977ff;
    border-color: transparent;
}
.roster-item .roster-item-wrap > .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: #6B54F9;
}
.roster-item.item-unallocated .roster-item-wrap {
    flex-direction: row;
    align-items: center;
    min-height: 60px;
}
.roster-employee.item-compliance {
    border: 1px solid #ea3f2e !important;
}

.roster-item.item-optional,
.roster-item.item-compliance,
.roster-item.item-optional.item-compliance {
    background: rgba(255, 242, 240, 0.8) !important;
    border: 1px solid #FFCCC7 !important;
    border-left: none !important;
}
.roster-item.item-optional,
.roster-item.item-unallocated.item-selected,
.roster-item.item-selected,
.roster-item:hover {
    background: #E9E6FA !important;
    border: 1px solid #CBC2FF !important;
    border-left: none !important;
}
.roster-item.item-completed {
    background: #F6FFED !important;
    border: 1px solid #B7EB8F !important;
    border-left: none !important;
}
.roster-item.item-optional.item-selected {
    border: 2px dashed #CBC2FF !important;
    box-shadow: 0 0 15px rgba(107, 84, 249, .15) !important;
    background: rgba(255, 255, 255, 1) !important;
}
.item-unallocated {
    background: #EDF0F1 !important;
    border: 1px solid #CCD7DE !important;
    border-left: none !important;
}
.roster-item:not(.item-optional) .allocation-item.driver-error .RouteTitle .RouteLogo .ant-avatar,
.roster-item:not(.item-optional) .allocation-item.vehicle-error .RouteTitle {
    background: #FFE6E6 !important;
    color: #FF4D4D !important;
    border-color: #FFCCC7;
}
.roster-item .ant-avatar,
.employee-allocation .employee-list .employee-member .ant-avatar {
    flex-shrink: 0;
    z-index: 1;
}
.roster-item .ant-avatar,
.roster-item .ant-avatar .ant-avatar-string,
.employee-allocation .employee-list .employee-member .ant-avatar,
.employee-allocation .employee-list .item-unavailable .ant-avatar {
    font-size: 14px !important;
    font-weight: 500;
}
.roster-cell-main .roster-name {
    color: #999;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    word-break: break-word;
    min-width: 60px;
}
.roster-cell-main .roster-time {
    font-size: 13px;
    text-transform: none !important;
    font-weight: 500 !important;
    color: #aaa;
    line-height: 15px;
}
.roster-cell-main .roster-name .roster-time {
    min-height: 30px;
}
.roster-cell-main .RouteTitle {
    flex-direction: column !important;
    align-items: start !important;
}
.roster-cell-main .RouteLogo {
    width: 100% !important;
    justify-content: flex-start !important;
    gap: 6px;
}
.roster-cell-main .ant-avatar {
    flex-shrink: 0 !important;
}
.roster-cell-main .driver-name {
    color: #999;
    font-weight: 500;
    line-height: 14px;
}
.roster-cell-main .roster-employee .driver-name {
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    min-width: 85px;
    /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 80px; */
}
.roster-cell-main .roster-employee .allocation-item:hover .driver-name {
    color: #6B54F9;
}
.roster-cell-main .RouteNumber {
    width: auto !important;
    min-height: 30px !important;
}
.roster-cell-main .roster-employee {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 6px;
}
.roster-cell-main .roster-employee .allocation-item > span {
    display: flex;
    gap: 6px;
    align-items: center;
}
.roster-item .roster-type {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    /* border-radius: 20px; */
    /* right: 0px;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 2px; */
    /* transform: rotate(40deg); */
}
.roster-item .roster-name {
    color: #3C3C3C;
    line-height: 16px;
    font-weight: 500;
}
.roster-item .roster-time,
.roster-item .roster-pay {
    color: #888;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
}
.roster-item:hover .roster-time,
.roster-item:hover .roster-pay {
    color: #666;
}
.roster-item .roster-time svg,
.roster-item .roster-pay svg {
    flex-shrink: 0;
}
.roster-item .roster-time svg path,
.roster-item .roster-pay svg path {
    fill: #C1C5CE;
    fill-opacity: 1 !important;
}
.roster-item .roster-vehicle,
.roster-cell-main .roster-vehicle,
.vehicle-name {
    font-size: 12px;
    border-radius: 6px;
    min-width: 50px;
    max-width: fit-content;
    height: 28px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px !important;
    line-height: 10px;
    background-color: #C1C5CE;
    /* border: 1px solid #00aeef; */
}
.unallocated-roster-cell.expand {
    /* height: 30vh;
    overflow-y: auto; */
}
.allocation-item {
    cursor: pointer;
}
.allocation-item .RouteLogo,
.roster-item .RouteLogo {
    width: 28px;
    height: auto;
    border-radius: 50px;
    border-width: 0;
    position: relative;
    overflow: visible;
    text-align: left;
    margin: -1px 0 -1px -1px;
}
.allocation-item .RouteTitle {
    gap: 5px;
    border: 1px solid #C1C5CE;
    background: #F6F6F8;
    border-radius: 50px;
    padding: 0;
    width: calc(100% - 8px) !important;
    margin-left: -2px;
}
.allocation-item .RouteNumber,
.roster-item .RouteNumber,
.modal-info .vehicle-name {
    /* padding: 2px 6px !important; */
    /* border-radius: 6px !important; */
    width: 100%;
    font-size: 14px !important;
    line-height: 13px;
    background: none !important;
    /* border: 1px solid #e1e1fb; */
    color: #555 !important;
    text-align: left !important;
    font-weight: 500 !important;
    justify-content: start;
    height: auto !important;
    min-width: auto;
    min-height: 28px;
    padding: 0 !important;
    word-break: break-word;
}
.modal-info .vehicle-name {
    text-align: center !important;
    justify-content: center !important;
}
.roster-cell-main .vehicle-name {
    background: none !important;
    border: 1px solid #e1e1fb;
    color: #888 !important;
    min-width: auto !important;
    border-radius: 50px;
    padding: 0 8px !important;
}
/* TODO: Commented out below as it caused default allocations of rosters to fail */
/*.roster-cell-main > .allocation-item {*/
/*    display: none !important;*/
/*}*/
.allocation-item .RouteInfo .RouteDetails,
.roster-item .RouteInfo .RouteDetails {
    min-width: auto;
}
.item-allocation {
    min-height: 100%;
    position: relative;    
}
.item-allocation .item-list {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
}
.item-allocation .item-list .item-single,
.item-allocation .item-list .item-unavailable {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 0;
}
.item-allocation .item-list .item-single .ant-radio {
    display: none;
}
.item-allocation .item-list .item-unavailable {
    padding: 5px 10px;
}
.item-allocation .item-list .item-single .ant-radio-wrapper {
    width: 100%;
    padding: 4px 8px;
    cursor: pointer;
    /* border-radius: 6px; */
    margin-right: 0 !important;
}
.item-allocation .item-list .item-single .ant-radio-wrapper .staff-name {
    font-weight: 500;
    line-height: 14px;
    color: #666;
}
.item-allocation .item-list .item-single .ant-radio-wrapper .staff-time {
    line-height: 14px;
    color: #999;
}
.item-allocation .item-list .item-single .ant-radio-wrapper:hover,
.item-allocation .item-list .item-single .ant-radio-wrapper.ant-radio-wrapper-checked {
    background: #E6E2F9 !important;
}
.item-allocation .item-list .item-single .ant-radio-wrapper.ant-radio-wrapper-disabled:hover,
.item-allocation .item-list .item-single .ant-radio-wrapper.ant-radio-wrapper-disabled.ant-radio-wrapper-checked {
    background: #f2f2f2 !important;
}
.item-allocation .item-list .item-single .ant-radio-wrapper:not(.ant-radio-wrapper-disabled).ant-radio-wrapper-checked .staff-name {
    color: #6B54F9;
}
.item-allocation .item-list .item-single .ant-radio-wrapper:not(.ant-radio-wrapper-disabled).ant-radio-wrapper-checked .ant-avatar {
    background-color: #9BA4B8 !important;
}
.item-allocation .item-list .item-single .ant-radio-wrapper.ant-radio-wrapper-disabled .staff-name {
    color: #999;
}
.item-allocation .item-list .item-single .ant-radio-wrapper.ant-radio-wrapper-disabled .ant-avatar {
    background-color: #ddd !important;
}
.item-allocation .item-list .item-single .ant-radio-wrapper > span:not(.ant-radio),
.item-allocation .item-list .item-unavailable {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    width: 100%;
}
.item-allocation .item-list .item-single .ant-radio-wrapper > span:not(.ant-radio) {
    padding-right: 0;
    padding-left: 0;
}
.item-allocation .item-list .item-single .employee-name,
.item-allocation .item-list .item-unavailable .employee-name {
    font-weight: 500;
    color: #444;
}
.item-allocation .item-list .item-unavailable .employee-name {
    color: #777;
}
.item-allocation .item-list .item-single:hover .employee-name,
.item-allocation .item-list .item-single .ant-radio-wrapper.ant-radio-wrapper-checked .employee-name {
    color: #6B54F9;
}
.item-allocation .item-list .item-single .employee-time,
.item-allocation .item-list .item-unavailable .employee-status {
    color: #777;
}
.item-allocation .item-list .item-single:hover .employee-time,
.item-allocation .item-list .item-single .ant-radio-wrapper.ant-radio-wrapper-checked .employee-time {
    color: #444;
}
.item-allocation .item-list-scroll {
    overflow-y: auto;
    margin: 10px 0 0;
    border: 1px solid #EDEDED;
    border-radius: 6px;
}
.showUnavailable .item-allocation .item-list-scroll,
.hideUnavailable .item-allocation .item-list-scroll {
    max-height: 210px;
}
.showUnavailable .item-allocation.hideVehicleUnavailable .item-list-scroll,
.showUnavailable .item-allocation.hideStaffUnavailable .item-list-scroll {
    position: absolute;
    top: 32px;
    bottom: 0;
    left: 0;
    right: 0;
}
.item-allocation .item-list .item-single .ant-avatar {
    flex-shrink: 0;
    background-color: #C1C5CE;
}
.item-allocation .item-list .item-single .ant-avatar .ant-avatar-string {
    font-size: 16px;
    font-weight: 500;
}
.item-allocation .item-list-scroll .item-list {
    padding: 0;
}
.dispatch-list .ant-splitter .ant-splitter-bar {
    margin: 10px 0;
}
.weekly-roster-table thead.ant-table-thead > tr > th:not(:first-of-type) {
    text-align: center;
}
.weekly-roster-table.header-roster-table thead.ant-table-thead > tr > th {
    padding: 0 0 10px !important;
}
.weekly-roster-table thead.ant-table-thead > tr > th,
.weekly-roster-table tbody.ant-table-tbody > tr > td {
    width: 12.28%;
    min-width: 80px;
}
.weekly-roster-table thead.ant-table-thead > tr > th:first-child,
.weekly-roster-table tbody.ant-table-tbody > tr > td:first-child {
    width: 14%;
}
.weekly-roster-table tbody.ant-table-tbody > tr > td {
    padding: 0;
}
.weekly-roster-table.allocated-roster-table thead.ant-table-thead,
.weekly-roster-table.unallocated-roster-table thead.ant-table-thead,
/*.weekly-roster-table.header-roster-table tbody.ant-table-tbody {*/
/*    display: none !important;*/
/*}*/
.weekly-roster-table tbody.ant-table-tbody > tr > td.ant-table-cell {
    padding: 8px 5px;
    border-left: 1px solid #DBD9FB;
    border-bottom: 1px solid #ebeafa;
    vertical-align: top;
}
.weekly-roster-table tbody.ant-table-tbody > tr > td.ant-table-cell:first-of-type {
    border-left: none;
}
.weekly-roster-table tbody.ant-table-tbody > tr:last-of-type > td.ant-table-cell {
    border-bottom: none;
}
.weekly-roster-table .ant-table,
.weekly-roster-table .ant-table .ant-table-content table,
.weekly-roster-table .ant-table .ant-table-content table tr:hover {
    background: none !important;
}
.weekly-roster-table .roster-cell {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 6px;
}
.weekly-roster-table.allocated-roster-table .roster-cell-main .roster-name > div > span:hover {
    color: #6B54F9;
}
/* .roster-item .roster-card-menu {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 27px !important;
    height: 100%;
    flex-direction: column;
    gap: 1px;
    z-index: 2;
    display: none;
}
.roster-item:hover .roster-card-menu,
.roster-item.item-selected .roster-card-menu {
    display: flex;
}
.roster-item .roster-card-menu a,
.roster-item .roster-card-menu button {
    width: 100%;
    height: 25% !important;
    text-align: center;
    border-radius: 0 !important;
    background: rgba(255, 255, 255, 0.9);
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    outline: none !important;
}
.roster-item.item-unallocated .roster-card-menu a,
.roster-item.item-unallocated .roster-card-menu button {
    height: 33.33% !important;
}
.roster-item .roster-card-menu a:hover,
.roster-item .roster-card-menu button:hover {
    background: #6B54F9 !important;
}
.roster-item .roster-card-menu a svg path,
.roster-item .roster-card-menu button svg path {
    fill: #6B54F9 !important;
}
.roster-item .roster-card-menu a:hover svg path,
.roster-item .roster-card-menu button:hover svg path {
    fill: #fff !important;
}
.roster-item .roster-card-menu button.btn-filled svg {
    width: 12px !important;
}
.roster-item .roster-card-menu button span:not(.ant-btn-icon) {
    display: none !important;
} */
.roster-item .icon-warning {
    position: absolute;
    bottom: 4px;
    right: 4px;
}
/* .roster-item.item-selected .icon-warning,
.roster-item:hover .icon-warning {
    right: 34px;
} */
.roster-item .icon-warning svg {
    width: 16px;
}

.ant-table-cell .icon-success,
.roster-item .icon-success {
    display: none !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 28px;
    height: 28px;
    cursor: pointer;
}

.ant-table-cell .icon-success svg,
.roster-item .icon-success svg {
    width: 20px !important;
}
.ant-table-cell:hover .icon-success,
.roster-item.item-optional:hover .icon-success {
    display: flex !important;
}
.roster-item.item-optional:hover {
    opacity: 0.9;
}
/* .weekly-roster-table.allocated-roster-table .ant-table-thead tr > .ant-table-cell:first-of-type {
    vertical-align: middle;
} */
/* .weekly-roster-table.allocated-roster-table .ant-table-thead tr > .ant-table-cell:first-of-type,
.weekly-roster-table.allocated-roster-table .ant-table-tbody tr.ant-table-row > .ant-table-cell:first-of-type {
    padding-left: 0;
} */
/* .weekly-roster-table.allocated-roster-table .ant-table-thead tr > .ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
} */
.weekly-roster-table.allocated-roster-table .ant-table-cell:first-of-type .roster-cell-main .roster-name .btn-delete {
    display: none !important;
    position: absolute;
    bottom: 10px;
    left: 10px;
}
.weekly-roster-table.allocated-roster-table .ant-table-cell:first-of-type:hover .roster-name .btn-delete {
    display: flex !important;
}
.roster-item .driver-name {
    display: none !important;
}
.rosters-grid {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
}
.rosters-grid .rosters-grid-wrap {
    min-width: 1080px;
    width: 100%;
}
.weekly-roster-table thead.ant-table-thead > tr > th:not(:first-of-type) .day-column {
    display: flex;
    flex-direction: column;
    line-height: 16px;
}
.weekly-roster-table thead.ant-table-thead > tr > th:not(:first-of-type) .day-column span {
    font-weight: 500;
    color: #aaa;
}

/* vehicle modal */
.vehicle-allocation-container {
    width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
}

.search-container {
    margin-bottom: 20px;
}

.search-container input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
}

.vehicle-list, .unavailable-vehicle-list {
    margin-bottom: 20px;
}

.vehicle-btn {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
}

.unavailable-vehicle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.vehicle-reason {
    color: red;
    font-size: 14px;
}

.allocate-btn {
    width: 100%;
    padding: 10px;
    background-color: #d3d3e8;
    border: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
}

.allocate-btn:hover {
    background-color: #c0c0d1;
}

.work-diary-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    background: #f4f4f4;
    width: 100%;
    padding: 10px 25px;
}
.work-diary-title span {
    color: #777;
    font-weight: 500;
}

@media print {
    /* .ShiftBat.PrintView .sb-items-wrap {
        page-break-inside: initial !important;
    } */
    /* .ShiftBat.PrintView .sb-row .sb-row-routes > div > .row.justify-content-center {
        page-break-inside: initial !important;
        page-break-after: initial !important;
    } */
    .page-break {
        clear: both !important;
        page-break-after: always !important;
    }
}

.rosters-grid .weekly-roster-table.unallocated-roster-table .ant-table table > colgroup,
.rosters-grid .weekly-roster-table.allocated-roster-table .ant-table table > colgroup {
    width: 200px;
    max-width: 200px;
}

.driver-vehicle-allocation .vehicle-name .icon-button.btn-xs,
.driver-vehicle-allocation .driver-name .icon-button.btn-xs {
    width: 24px !important;
    height: 24px !important;
    margin: 0 0 0 6px !important;
    visibility: hidden !important;
}
.driver-vehicle-allocation:hover .vehicle-name .icon-button.btn-xs,
.driver-vehicle-allocation:hover .driver-name .icon-button.btn-xs {
    visibility: visible !important;
}
.roster-item-popover {
    width: 250px;
}
.roster-item-popover .ant-popover-inner {
    padding: 10px 14px 15px;
}
.roster-item-popover .ant-popover-inner .ant-popover-title {
    font-weight: 500;
    margin-bottom: 14px;
}
.roster-item-popover .ant-popover-inner .ant-popover-title .roster-name {
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 16px;
    cursor: pointer;
}
.roster-item-popover .ant-popover-inner .ant-popover-title .roster-name span {
    padding: 6px 8px;
    background: #007BFF;
    color: #fff;
    font-weight: 600;
    line-height: 16px;
    border-radius: 6px;
    flex-shrink: 0;
    max-width: 75px;
}
.dispatch-list .ant-tabs-nav {
    margin-bottom: 0;
}
.roster-row-color {
    position: absolute;
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
}