.customer-list {
  padding-top: 10px;
}
.customer-list .filter-options-main {
  margin-top: 40px;
}
.sb-time-edit {
    width: 56px !important;
    flex-shrink: 0 !important;
}
.sb-time-edit .ant-picker-input input {
    text-align: center;
}
.sb-time-edit .ant-picker-suffix,
.sb-row .sb-row-edit .sb-time-edit .ant-picker-clear {
    display: none !important;
}
.address-line-1 {
    margin-bottom: 10px;
}