@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
body.busable-app {
  margin: 0;
  padding: 0;
  color: #1A1A1A;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  background: #F8FAFB;
  -webkit-user-select: none;
          user-select: none;
}
body.busable-app,
body.busable-app h1, body.busable-app h2, body.busable-app h3, body.busable-app h4, body.busable-app h5, body.busable-app h6,
body.busable-app button,
body.busable-app .ant-collapse,
#busable-embed,
#busable-embed h1, #busable-embed h2, #busable-embed h3, #busable-embed h4, #busable-embed h5, #busable-embed h6,
#busable-embed button,
#busable-embed .ant-collapse,
body.busable-app .ant-input {
  font-family: 'Work Sans', sans-serif !important;
}
body.busable-app h1.page-title {
  font-size: 22px;
  line-height: 24px;
  font-weight: 600;
}
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}
body.busable-app input[type=file] {
  width: 100%;
}
body.busable-app a:hover {
  text-decoration: none !important;
}

.ErrorBoundary {
  padding-top: 100px;
}

.sm {
  color: #828282;
  font-size: 0.75rem;
  padding-top: 10px;
}

.lg {
  color: #828282;
  font-size: 1.5rem;
  padding-top: 10px;
}

.ShiftBat.PrintView {
    padding: 0 25px !important;
}
@media print {
    @page {
        size: A4 !important;
        margin: 10mm 0 !important;
    }
    @page:first {
        margin-top: 0 !important;
    }
    .ShiftBat.PrintView {
        padding: 0 45px !important;
    }
    body {
        zoom: 80%;
    }
    body {
        margin: 0 !important;
        padding: 0 !important;
    }
    .ShiftBat.PrintView .sb-items-wrap {
        page-break-inside: auto !important;
    }
    .ShiftBat.PrintView .sb-row .sb-row-routes > div > .row.justify-content-center {
        page-break-inside: avoid !important;
        page-break-after: auto !important;
    }
    .ShareModal {
        display: none !important;
    }
    .page-break {
        clear: both;
        page-break-after: always;
    }
}
.ShiftBat.PrintView * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
}
.ShiftBat.PrintView {
    width: 100% !important;
}
.ShiftBat.PrintView .header-row {
    padding-bottom: 10px !important;
}
.ShiftBat.PrintView-md .header-row {
    padding-bottom: 5px !important;
}
.ShiftBat.PrintView-sm .header-row {
    padding-bottom: 2px !important;
}
.ShiftBat.PrintView .header-row .RouteTitle {
    width: auto !important;
    padding: 0 !important;
    grid-gap: 15px;
    gap: 15px;
}
.ShiftBat.PrintView .header-row .customer-info-text {
    grid-gap: 30px;
    gap: 30px;
}
.ShiftBat.PrintView .header-row .customer-info-text .RouteTitle .RouteInfo {
    display: none !important;
}
.ShiftBat.PrintView .header-row .RouteTitle .RouteNumber {
    background: none !important;
    color: #1a1a1a !important;
    font-size: 36px !important;
    font-weight: bold !important;
    height: auto !important;
    border-radius: 0 !important;
    text-align: left !important;
    width: auto !important;
    line-height: 36px !important;
    padding: 0 !important;
}
.ShiftBat.PrintView .simple-tags {
    grid-gap: 4px !important;
    gap: 4px !important;
    flex-wrap: wrap !important;
}
.ShiftBat.PrintView .simple-tags .ant-tag {
    font-size: 9.6pt !important;
}
.ShiftBat.PrintView > .row.RouteTitle {
    padding: 8px 0 !important;
}
.ShiftBat.PrintView-md > .row.RouteTitle {
    padding: 6px 0 !important;
}
.ShiftBat.PrintView-sm > .row.RouteTitle {
    padding: 2px 0 !important;
}
.ShiftBat.PrintView > .row.RouteTitle .col {
    padding: 0 !important;
}
.ShiftBat.PrintView > .row.RouteTitle .col .RouteInfo,
.ShiftBat.PrintView .header-row .col-4.RouteTitle .RouteInfo {
    grid-gap: 0px !important;
    gap: 0px !important;
    display: flex !important;
    flex-direction: column !important;
}
.ShiftBat.PrintView .header-row .customer-info-text .RouteInfo {
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    gap: 2px;
}
.ShiftBat.PrintView > .row.RouteTitle .col .RouteInfo h1,
.ShiftBat.PrintView .header-row .customer-info-text .RouteInfo h1 {
    font-size: 16pt !important;
    font-weight: 600 !important;
    line-height: 22px !important;
}
.ShiftBat.PrintView > .row.RouteTitle .col .RouteInfo .RouteDetails,
.ShiftBat.PrintView .header-row .col-4.RouteTitle .RouteInfo .RouteDetails {
    font-size: 13pt !important;
    color: #1a1a1a !important;
    line-height: 16pt !important;
}
.ShiftBat.PrintView-md > .row.RouteTitle .col .RouteInfo .RouteDetails,
.ShiftBat.PrintView-md .header-row .col-4.RouteTitle .RouteInfo .RouteDetails {
    font-size: 12pt !important;
}
.ShiftBat.PrintView-sm > .row.RouteTitle .col .RouteInfo .RouteDetails,
.ShiftBat.PrintView-sm .header-row .col-4.RouteTitle .RouteInfo .RouteDetails {
    font-size: 10pt !important;
}
.ShiftBat.PrintView .header-row .col-4.RouteTitle .RouteInfo {
    text-align: center !important;
}
.ShiftBat.PrintView .RouteNumber,
.ShiftBat.PrintView .RouteNumber-sm {
    font-size: 12pt !important;
}
.ShiftBat.PrintView .header-row .RouteTitle .RouteLogo {
    width: 50px !important;
    height: 50px !important;
    border-width: 2px !important;
}
.ShiftBat.PrintView .header-row .RouteTitle .RouteLogo img {
    max-height: 60px !important;
    max-width: 60px !important;
}
.ShiftBat.PrintView .ShiftBat-Notes {
    margin: 15px 0 0 !important;
    color: #444 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}
.ShiftBat.PrintView .ShiftBat-Notes ul {
    padding: 0 0 0 20px !important;
    margin: 0 0 15px !important;
}
.ShiftBat.PrintView .ShiftBat-Notes p {
    margin: 0 0 15px !important;
}
.ShiftBat.PrintView .customer-info-logo {
    display: flex !important;
    justify-content: end;
    align-items: start;
    grid-gap: 30px;
    gap: 30px;
}
.ShiftBat.PrintView .header-row {
    position: relative;
}
/* .ShiftBat.PrintView .header-row:after {
    content: '';
    width: calc(100% - 30px) !important;
    height: 2px !important;
    background: #ddd !important;
    margin: 0 auto !important;
    position: absolute !important;
    bottom: 0 !important;
    left: 50%;
    transform: translate(-50%, 0);
} */
.ShiftBat.PrintView-md .header-row:after,
.ShiftBat.PrintView-sm .header-row:after {
    height: 1px !important;
}
.ShiftBat.PrintView .info-text-wrap.w-label .icon-info-text > div {
    font-size: 15px !important;
    font-weight: 600 !important;
    grid-gap: 1px !important;
    gap: 1px !important;
}
.ShiftBat.PrintView .info-text-wrap.w-label .icon-info-text > div > span {
    font-size: 13px !important;
    font-weight: 500 !important;
}
.ShiftBat.PrintView .info-text-wrap {
    margin: 6px 0 0 !important;
    grid-gap: 14px !important;
    gap: 14px !important;
}
.ShiftBat.PrintView .info-text-wrap .icon-info-text {
    padding: 5px 12px !important;
    background: none !important;
    border-color: #E9E5FE !important;
}
.ShiftBat.PrintView .sb-row {
    padding: 0 !important;
}
.ShiftBat.PrintView .sb-row .sb-info .RouteInfo h4.title,
.ShiftBat.PrintView .sb-row .sb-time span,
.ShiftBat.PrintView .sb-row .sb-info .RouteInfo .RouteDetails,
.ShiftBat.PrintView .sb-row .sb-row-routes .sb-comment-row .sb-comment-row-info,
.ShiftBat.PrintView .sb-row .RouteInfo .sb-transfer-row-info,
.ShiftBat.PrintView .sb-row .sb-info .RouteInfo .service-desc-wrap {
    font-size: 14pt !important;
    line-height: 16pt !important;
}
.ShiftBat.PrintView-md .sb-row .sb-info .RouteInfo h4.title,
.ShiftBat.PrintView-md .sb-row .sb-time span,
.ShiftBat.PrintView-ltwo .sb-row .sb-time span,
.ShiftBat.PrintView-md .sb-row .sb-info .RouteInfo .RouteDetails,
.ShiftBat.PrintView-md .sb-row .sb-row-routes .sb-comment-row .sb-comment-row-info,
.ShiftBat.PrintView-md .sb-row .RouteInfo .sb-transfer-row-info,
.ShiftBat.PrintView-md .sb-row .sb-info .RouteInfo .service-desc-wrap {
    font-size: 12pt !important;
    line-height: 13pt !important;
}
.ShiftBat.PrintView-sm .sb-row .sb-info .RouteInfo h4.title,
.ShiftBat.PrintView-sm .sb-row .sb-time span,
.ShiftBat.PrintView-sm .sb-row .sb-info .RouteInfo .RouteDetails,
.ShiftBat.PrintView-sm .sb-row .sb-row-routes .sb-comment-row .sb-comment-row-info,
.ShiftBat.PrintView-sm .sb-row .RouteInfo .sb-transfer-row-info,
.ShiftBat.PrintView-sm .sb-row .sb-info .RouteInfo .service-desc-wrap {
    font-size: 10pt !important;
    line-height: 10pt !important;
}
.ShiftBat.PrintView .sb-row.sb-row-service > .row .sb-info .RouteInfo h4.title {
    font-weight: 600 !important;
}
.ShiftBat.PrintView .sb-row .sb-info .RouteInfo .RouteDetails,
.ShiftBat.PrintView .sb-row .sb-row-routes .sb-comment-row .sb-comment-row-info {
    font-weight: 400 !important;
    padding: 0 !important;
    margin-top: 0 !important;
    color: #1a1a1a !important;
}
.ShiftBat.PrintView-sm .sb-row .sb-info .RouteInfo .RouteDetails,
.ShiftBat.PrintView-sm .sb-row .sb-row-routes .sb-comment-row .sb-comment-row-info {
    margin-top: 2px !important;
}
.ShiftBat.PrintView .sb-row .col-2.sb-icon,
.ShiftBat.PrintView .sb-row .col-2.sb-step-no,
.ShiftBat.PrintView .sb-row .sb-row-transfers .col-9.sb-info .RouteLogo,
.ShiftBat.PrintView .sb-row .RouteInfo .sb-transfer-row-info .RouteLogo {
    display: none !important;
}
.ShiftBat.PrintView .sb-row .sb-row-transfers .col-9.sb-info .RouteNumber,
.ShiftBat.PrintView .sb-row .RouteInfo .sb-transfer-row-info .RouteNumber {
    border-radius: 6px !important;
    padding: 2px 6px !important;
    margin: 0 !important;
    height: 22px !important;
    background: #1a1a1a !important;
}
.ShiftBat.PrintView .sb-row .RouteInfo .sb-transfer-row-info {
    grid-gap: 4px 6px !important;
    gap: 4px 6px !important;
    flex-wrap: wrap !important;
}
.ShiftBat.PrintView .sb-row .sb-row-routes > div > .row.justify-content-center {
    justify-content: flex-start !important;
    margin: 0 !important;
}
.ShiftBat.PrintView .sb-row .sb-row-routes > div:last-of-type > .row.justify-content-center {
    border-bottom: none !important;
}
.ShiftBat.PrintView .sb-row .sb-row-transfers {
    border: none !important;
    padding: 0 !important;
    margin-top: -1px !important;
    border-top: 1px solid #E9E5FE !important;
}
.ShiftBat.PrintView .sb-row .sb-row-transfers .col-3.sb-time {
    flex: 0 0 8.333333% !important;
    max-width: 8.333333% !important;
}
.ShiftBat.PrintView .sb-row .sb-row-routes .row:not(.sb-row-transfers) .sb-time,
.ShiftBat.PrintView .sb-row .sb-time,
.ShiftBat.PrintView .sb-row-transfers .sb-time {
    padding-left: 0 !important;
    text-align: center !important;
}
.ShiftBat.PrintView .sb-row .sb-row-transfers .col-9.sb-info .col-5 strong {
    font-size: 16px !important;
    color: #4D4D4D !important;
    font-weight: 600 !important;
}
.ShiftBat.PrintView .sb-row .sb-row-routes .sb-time span {
    background: none !important;
    border: none !important;
    width: 100% !important;
    padding-left: 0 !important;
    font-size: 13pt !important;
    line-height: 16px !important;
}
.ShiftBat.PrintView .sb-row .sb-row-routes {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
}
.ShiftBat.PrintView .sb-row .sb-row-routes .sb-row-transfers .sb-info {
    padding-right: 0 !important;
}
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info {
    flex: 0 0 91.666667% !important;
    max-width: 91.666667% !important;
}
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo,
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo .row {
    width: 100% !important;
}
.ShiftBat.PrintView .sb-row-service {
    position: relative;
}
.ShiftBat.PrintView .sb-row.sb-row-service .service-trip-bar {
    display: block !important;
    width: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -21px;
    background: #d9d9d9;
}
.ShiftBat.PrintView-ltwo .sb-row.sb-row-service .service-trip-bar {
    width: 12px;
    left: -13px;
}
.ShiftBat.PrintView .sb-row-service > .row {
    background: #fff !important;
    border-bottom: 1px solid #ddd !important;
}
.ShiftBat.PrintView .sb-row-service > .row > div.sb-info span {
    color: #555 !important;
}
.ShiftBat.PrintView .sb-header-info {
    padding: 0 15px 0px 15px !important;
    background: #f4f4f4;
    columns: 3;
    font-size: 13pt !important;
    border: 1px solid #ddd !important;
    border-bottom: none !important;
    padding: 8px 15px !important;
}
.ShiftBat.PrintView-md .sb-header-info,
.ShiftBat.PrintView-sm .sb-header-info {
    columns: 4;
}
.ShiftBat.PrintView-md .sb-header-info {
    padding: 4px 10px !important;
    font-size: 12pt !important;
    border-bottom: none !important;
}
.ShiftBat.PrintView-sm .sb-header-info {
    padding: 0px 10px !important;
    font-size: 10pt !important;
    border-bottom: none !important;
}
.ShiftBat.PrintView .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteNumber {
    font-size: 13pt !important;
}
.ShiftBat.PrintView-md .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteNumber {
    font-size: 12pt !important;
}
.ShiftBat.PrintView-sm .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteNumber {
    font-size: 10pt !important;
}
.ShiftBat.PrintView .sb-header-info strong {
    font-weight: 500 !important;
}
.ShiftBat.PrintView .header-row .QRCode {
    display: flex !important;
    overflow: hidden;
    height: 124px;
    width: 124px;
    position: relative;
}
.ShiftBat.PrintView .header-row .QRCode canvas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.ShiftBat.PrintView .sb-row .RouteInfo .col-7 .sb-comment-row .col-1.sb-time,
.ShiftBat.PrintView-ltwo .sb-row .RouteInfo .col-12 .sb-comment-row .col-1.sb-time {
    display: none !important;
}
.ShiftBat.PrintView .sb-row .RouteInfo .col-7 .sb-comment-row .col-9.sb-info,
.ShiftBat.PrintView-ltwo .sb-row .RouteInfo .col-12 .sb-comment-row .col-9.sb-info {
    flex: 0 0 100% !important;
    max-width: 100% !important;
}
.ShiftBat.PrintView-ltwo .sb-row .RouteInfo .col-12 .sb-comment-row .col-9.sb-info {
    padding-left: 15px !important;
}
.ShiftBat.PrintView .sb-row .RouteInfo .col-7 .sb-comment-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.ShiftBat.PrintView .sb-items-wrap,
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-5,
.ShiftBat.PrintView .sb-row .sb-row-transfers .col-9.sb-info .col-5 {
    border-right: 1px solid #ddd !important;
    border-left: 1px solid #ddd !important;
}
.ShiftBat.PrintView-ltwo .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-12,
.ShiftBat.PrintView-ltwo .sb-row .sb-row-transfers .col-9.sb-info .col-12 {
    border-left: 1px solid #ddd !important;
}
.ShiftBat.PrintView .sb-items-wrap {
    border-top: 1px solid #ddd !important;
}
.ShiftBat.PrintView .sb-items-wrap > .sb-row,
.ShiftBat.PrintView .sb-row .sb-row-routes > div > .row.justify-content-center {
    border-bottom: 1px solid #ddd !important;
}
.ShiftBat.PrintView .sb-row-service .col-9.RouteTitle.sb-info .RouteInfo .col-5,
.ShiftBat.PrintView .sb-row-service .sb-row-transfers .col-9.sb-info .col-5 {
    border-color: #ddd !important;
}
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-5,
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-7,
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-12 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    grid-gap: 4px !important;
    gap: 4px !important;
}
.ShiftBat.PrintView-md .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-5,
.ShiftBat.PrintView-md .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-7,
.ShiftBat.PrintView-md .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-12 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}
.ShiftBat.PrintView-sm .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-5,
.ShiftBat.PrintView-sm .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-7,
.ShiftBat.PrintView-sm .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-12,
.ShiftBat.PrintView-sm .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-7 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 10px;
    padding-right: 0px;
    grid-gap: 0px !important;
    gap: 0px !important;
}
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-5 > div.service-title-wrap,
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-12 > div.service-title-wrap {
    grid-gap: 6px !important;
    gap: 6px !important;
}
.ShiftBat.PrintView .sb-row .sb-row-transfers .col-9.sb-info .col-5 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}
.ShiftBat.PrintView .sb-row.sb-important h4.title,
.ShiftBat.PrintView .sb-row .sb-row-routes .sb-comment-row.sb-comment-important .sb-comment-row-info {
    color: #FD4B4B !important;
    font-weight: 600 !important;
}
.ShiftBat.PrintView .sb-row .sb-row-routes .sb-comment-row.sb-comment-important > .sb-info:before {
    content: '';
    background: url(/static/media/Warning.2988fccd.svg) no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    background-size: 16px auto;
    margin-right: 6px;
    flex-shrink: 0 !important;
}
.ShiftBat.PrintView .sb-row.sb-important .sb-row-warning {
    width: 16px !important;
    flex-shrink: 0 !important;
}
.ShiftBat.PrintView .sb-row.sb-row-service .RouteInfo .RouteIcon {
    margin-right: 0 !important;
    width: auto !important;
}
.ShiftBat.PrintView .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteLogo {
    background: #fff !important;
    width: 34px !important;
    height: 34px !important;
    border-radius: 6px 0 0 6px !important;
}
.ShiftBat.PrintView-md .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteLogo {
    width: 24px !important;
    height: 24px !important;
}
.ShiftBat.PrintView-sm .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteLogo {
    width: 18px !important;
    height: 18px !important;
}
.ShiftBat.PrintView .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteLogo img {
    max-width: 34px !important;
    max-height: 34px !important;
}
.ShiftBat.PrintView-md .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteLogo img {
    max-width: 24px !important;
    max-height: 24px !important;
}
.ShiftBat.PrintView-sm .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteLogo img {
    max-width: 18px !important;
    max-height: 18px !important;
}
.ShiftBat.PrintView .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteNumber {
    margin-right: 0 !important;
    height: 34px !important;
    padding: 0 7px !important;
}
.ShiftBat.PrintView-md .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteNumber {
    height: 24px !important;
}
.ShiftBat.PrintView-sm .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteNumber {
    height: 18px !important;
}
@media screen and (max-width: 540px) {
    .ShiftBat.PrintView {
        zoom: 60% !important;
    }
}
@media screen and (min-width: 541px) and (max-width: 720px) {
    .ShiftBat.PrintView {
        zoom: 70% !important;
    }
}
@media screen and (max-width: 720px) {
    .ShiftBat.PrintView .sb-row .sb-info .RouteInfo h4.title,
    .ShiftBat.PrintView .sb-row .sb-time span,
    .ShiftBat.PrintView .sb-row .sb-info .RouteInfo .RouteDetails,
    .ShiftBat.PrintView .sb-row .sb-row-routes .sb-comment-row .sb-comment-row-info,
    .ShiftBat.PrintView .sb-row .RouteInfo .sb-transfer-row-info,
    .ShiftBat.PrintView .sb-row .sb-info .RouteInfo .service-desc-wrap,
    .ShiftBat.PrintView .sb-header-info,
    .ShiftBat.PrintView .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteNumber,
    .ShiftBat.PrintView .RouteNumber,
    .ShiftBat.PrintView .RouteNumber-sm,
    .ShiftBat.PrintView > .row.RouteTitle .col .RouteInfo .RouteDetails,
    .ShiftBat.PrintView .header-row .col-4.RouteTitle .RouteInfo .RouteDetails {
        font-size: 10pt !important;
    }
    .ShiftBat.PrintView .header-row .RouteTitle .RouteNumber {
        font-size: 20pt !important;
    }
    .ShiftBat.PrintView > .row.RouteTitle .col .RouteInfo h1,
    .ShiftBat.PrintView .header-row .col-4.RouteTitle .RouteInfo h1 {
        font-size: 12pt !important;
    }
}
@media screen and (min-width: 721px) and (max-width: 1023px) {
    .ShiftBat.PrintView {
        zoom: 80% !important;
    }
    .ShiftBat.PrintView .sb-row .sb-info .RouteInfo h4.title,
    .ShiftBat.PrintView .sb-row .sb-time span,
    .ShiftBat.PrintView .sb-row .sb-info .RouteInfo .RouteDetails,
    .ShiftBat.PrintView .sb-row .sb-row-routes .sb-comment-row .sb-comment-row-info,
    .ShiftBat.PrintView .sb-row .RouteInfo .sb-transfer-row-info,
    .ShiftBat.PrintView .sb-row .sb-info .RouteInfo .service-desc-wrap,
    .ShiftBat.PrintView .sb-header-info,
    .ShiftBat.PrintView .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteNumber,
    .ShiftBat.PrintView .RouteNumber,
    .ShiftBat.PrintView .RouteNumber-sm,
    .ShiftBat.PrintView > .row.RouteTitle .col .RouteInfo .RouteDetails,
    .ShiftBat.PrintView .header-row .col-4.RouteTitle .RouteInfo .RouteDetails {
        font-size: 11pt !important;
    }
    .ShiftBat.PrintView .header-row .RouteTitle .RouteNumber {
        font-size: 24pt !important;
    }
    .ShiftBat.PrintView > .row.RouteTitle .col .RouteInfo h1,
    .ShiftBat.PrintView .header-row .col-4.RouteTitle .RouteInfo h1 {
        font-size: 13pt !important;
    }
}
@media screen and (max-width: 1023px) {
    .ShiftBat.PrintView {
        padding: 0 !important;
    }
    .ShiftBat.PrintView .sb-items-wrap .service-trip-bar {
        width: 11px !important;
        left: -12px !important;
    }
    .ShiftBat.PrintView .sb-row .col-1.sb-time {
        min-width: 84px !important;
    }
    .ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info {
        max-width: calc(100% - 84px) !important;
    }
    .ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-5 > div.service-title-wrap,
    .ShiftBat.PrintView .sb-row .RouteInfo .col-7 .sb-comment-row .col-9.sb-info {
        flex-direction: column !important;
        align-items: flex-start !important;
    }
}
.ShiftBat.PrintView-ltwo .sb-items-wrap {
    width: 100%;
    border-left: none !important;
    border-right: none !important;
}
@media screen and (min-width: 1024px) {
    .ShiftBat.PrintView-ltwo .sb-items-wrap {
        columns: 2;
    }
}
@media print {
    .ShiftBat.PrintView-ltwo .sb-items-wrap {
        columns: 2;
    }
}
.ShiftBat.PrintView-ltwo .sb-items-wrap > .sb-row {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
.ShiftBat.PrintView-ltwo .sb-row .sb-time {
    flex: 0 0 15% !important;
    max-width: 15% !important;
}
.ShiftBat.PrintView-ltwo .sb-row .col-9.RouteTitle.sb-info {
    flex: 0 0 85% !important;
    max-width: 85% !important;
}
.ShiftBat.PrintView .row-school-stop:before {
    content: 'S';
    background: #f0ad4e; /* #ffc107 , #faad14 */
    border-radius: 50px;
    border: 2px solid #fff;
    position: absolute;
    z-index: 9;
    top: 50%;
    transform: translate(0,-50%);
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    box-shadow: 0 0 10px rgba(107,84,249,.3);
    left: -24px;
    padding: 0 0 2px 1px;
    font-size: 16px;
    width: 25px;
    height: 25px;
}
.ShiftBat.PrintView-md.PrintView-ltwo .row-school-stop:before,
.ShiftBat.PrintView-sm.PrintView-ltwo .row-school-stop:before,
.ShiftBat.PrintView-ltwo .row-school-stop:before {
    left: -18px;
    padding: 1px 0 0 1px;
    font-size: 12px;
    width: 20px;
    height: 20px;
}
.ShiftBat.PrintView-md .row-school-stop:before,
.ShiftBat.PrintView-sm .row-school-stop:before {
    left: -21px;
    padding: 1px 0 0 1px;
    font-size: 12px;
    width: 20px;
    height: 20px;
}
.shift-bat-map {
    height: 800px;
}
.sb-items-wrap.edit-mode .sb-row:not(.current-edit) {
    /* filter: blur(1px);
    -webkit-filter: blur(1px);
    opacity: 0.5 !important; */
}
.sb-row {
    border-bottom: 1px solid #E9E5FE;
    padding: 10px 0;
}
/* .sb-row:last-of-type,
.sb-row-routes .sb-row {
    border-bottom: none !important;
} */
.ShiftBatView .sb-row,
.sb-info-wrap {
    margin: 0 -10px;
}
.ShiftBatView .sb-items-wrap.edit-mode .sb-row:last-of-type {
    margin-bottom: 70vh;
}
.sb-info-wrap {
    border-bottom: 1px solid #E9E5FE;
    background: #FFF;
    margin: 0 -20px;
    padding: 10px 10px 12px;
}
.ShiftBatView .sb-row .sb-row-routes .sb-row {
    margin: 0 -15px;
}
.ShiftBatView .sb-row .sb-row-routes .sb-row .sb-row-edit {
    margin: 0 -5px !important;
}
.sb-row .sb-time {
    color: #333;
    font-weight: 600;
    line-height: 16px;
    padding-left: 3px !important;
}
.sb-row.current-edit {
    padding-top: 0 !important;
}
.sb-row-routes .sb-row {
    padding: 0 !important;
}
.sb-row.current-edit .sb-time {
    width: 56px !important;
}
.sb-row .sb-icon {
    text-align: center !important;
}
.sb-row .sb-icon .RouteIcon .route-number-wrap {
    flex-direction: column !important;
}
.sb-row .sb-icon .RouteIcon .route-number-wrap .RouteLogo {
    width: 34px !important;
    height: 32px !important;
    border-radius: 6px 6px 0 0 !important;
}
.sb-row .sb-icon .RouteIcon .route-number-wrap .RouteLogo img {
    max-height: 28px !important;
    max-width: 28px !important;
}
.sb-row .sb-icon .RouteIcon .route-number-wrap .RouteNumber {
    font-size: 11px !important;
    min-width: 34px !important;
    height: 18px !important;
    border-radius: 0 0 6px 6px !important;
}
.sb-row .sb-info {
    padding: 0;
}
.sb-row .sb-info .RouteLogo {
    width: 30px !important;
    height: 30px !important;
    flex-shrink: 0 !important;
}
.sb-row .sb-info .RouteLogo img {
    max-width: 26px !important;
    max-height: 26px !important;
}
.sb-row .sb-info .RouteNumber {
    height: 20px !important;
    min-width: 40px !important;
    font-size: 12px !important;
    margin-right: 15px !important;
}
.sb-row .sb-info .RouteInfo {
    padding-left: 0 !important;
    line-height: 14px !important;
    flex-direction: column !important;
}
.sb-row .sb-info .RouteInfo h4.title {
    line-height: 18px !important;
}
.sb-row .sb-info .RouteInfo .RouteDetails {
    color: #808080 !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    display: flex !important;
    margin: 3px 0 0 !important;
}
.sb-row .sb-info .RouteInfo .RouteDetails > div:nth-of-type(2):before {
    content: '|';
    margin: 0 8px !important;
    color: #d0d0d0 !important;
}
.sb-row .sb-info .RouteDetails {
    padding-left: 0 !important;
    line-height: 14px !important;
    flex-direction: row !important;
}
.sb-row .sb-info .RouteIcon {
    width: 70px !important;
    margin-right: 15px !important;
    text-align: center !important;
    flex-shrink: 0 !important;
}
.sb-row .sb-info .RouteIcon > div.justify-content-center {
    justify-content: flex-start !important;
}
.sb-row .sb-info .icon-sm {
    margin-left: 12px !important;
    width: 15px !important;
    height: 15px !important;
}
.sb-row.sb-highlight:before {
    content: '';
    width: calc(100% + 20px);
    height: 100%;
    background-color: #F1F0FB;
    position: absolute;
    top: 0;
    left: -10px;
}
.sb-row.sb-important {
    /* background: #FED6F3 !important; */
    /* border-bottom: none !important; */
    /* margin: -1px -20px 0 -20px !important;
    padding-left: 10px !important;
    padding-right: 10px !important; */
}
.sb-row.sb-important .sb-info .RouteIcon {
    width: 75px !important;
}
.page-main-controls .icon-button.btn-success {
    margin-left: 20px !important;
}
.page-main-controls .icon-button .ant-btn-icon {
    margin-inline-end: 0 !important;
}
.sb-row .sb-row-edit {
    background: #F7F7F7 !important;
    margin: 0 -10px -10px !important;
    width: calc(100% + 20px) !important;
    max-width: none !important;
    padding: 10px 10px !important;
    flex: auto !important;
    display: flex !important;
    grid-gap: 8px !important;
    gap: 8px !important;
    flex-direction: column !important;
}
.sb-row .sb-row-edit .sb-time-edit {
    width: 56px !important;
    flex-shrink: 0 !important;
}
.sb-row .sb-row-edit .sb-time-edit .ant-picker-input input {
    text-align: center;
}
.sb-row .sb-row-edit .sb-time-edit .ant-picker-suffix,
.sb-row .sb-row-edit .sb-time-edit .ant-picker-clear {
    display: none !important;
}
.sb-row .sb-row-edit > div {
    display: flex !important;
    align-items: center;
    grid-gap: 8px !important;
    gap: 8px !important;
}
.sb-row .sb-row-edit > div:not(:first-of-type) {
    padding-left: 64px !important;
}
.sb-row .sb-row-edit .inputselect-small:not(.w-100),
.sb-row .sb-row-edit .ant-input.inputtext-small {
    flex-shrink: 0 !important;
    width: 50% !important;
}
.sb-row .sb-row-edit .ant-input.inputtext-small.input-desc {
    width: 100% !important;
}
.ShiftBatView .sb-row .sb-row-routes .sb-row .sb-row-edit .ant-input.inputtext-small {
    flex-shrink: unset !important;
    width: 100% !important;
}
.sb-row .sb-row-edit .sb-edit-controls {
    flex-shrink: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-end !important;
    grid-gap: 8px !important;
    gap: 8px !important;
}
.sb-row .sb-row-routes {
    background: #FAFAFA !important;
    border: 1px solid #E9E9E9 !important;
    border-radius: 6px !important;
    margin: 10px 0 0 !important;
    padding: 5px 20px 15px 20px !important;
}
.sb-row .sb-row-routes .sb-time {
    display: flex;
    padding: 0 !important;
    align-items: center !important;
}
.sb-row .sb-row-routes .sb-time span {
    padding: 0 19px !important;
    border: 1px solid #D9D9D9 !important;
    background: #fff !important;
    border-radius: 4px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    min-height: 20px !important;
    font-size: 12px !important;
    width: 34px !important;
    flex-shrink: 0 !important;
    line-height: 13px;
}
.sb-row .sb-row-routes .sb-step-no {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.sb-row .sb-row-routes .row:first-of-type .sb-step-no:before {
    top: 10px !important;
}
.sb-row .sb-row-routes .row:last-of-type .sb-step-no:before {
    /* height: calc(100% - 10px) !important; */
}
.sb-row .sb-row-routes .sb-step-no span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    color: #fff;
    font-size: 10px !important;
    font-weight: 900;
    font-family: 'Work Sans', sans-serif !important;
    flex-shrink: 0 !important;
    position: relative;
    z-index: 1;
}

.sb-row .sb-row-routes .sb-step-no div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 16px;
    border-radius: 50px;
    color: #fff;
    font-size: 10px !important;
    font-weight: 900;
    font-family: 'Work Sans', sans-serif !important;
    flex-shrink: 0 !important;
    position: relative;
    z-index: 1;
}
.sb-row .sb-row-routes .sb-step-no:before {
    content: '';
    width: 1px;
    height: 100%;
    background: #DFDFDF;
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    transform: translate(-50%,0);
    margin-left: 0px;
}
.sb-row .sb-row-routes .sb-info {
    padding-left: 0px !important;
}
.sb-row .sb-row-routes .sb-info .RouteInfo {
    display: flex !important;
    align-items: center !important;
    flex-direction: row !important;
    justify-content: space-between;
    width: 100%;
}
.sb-row .sb-row-routes .sb-comment-row .sb-comment-row-info {
    font-size: 13px !important;
    color: #7D7D7D !important;
    font-weight: 500 !important;
    padding: 5px 0 !important;
    margin: 0 !important;
}
.sb-row .sb-row-routes .sb-comment-row.sb-comment-important .sb-comment-row-info {
    color: #FD4B4B !important;
}
.sb-row .sb-row-routes .sb-row-transfers {
    background: #fff !important;
    border: 1px solid #E9E9E9 !important;
    border-radius: 6px !important;
    padding: 8px 0 !important;
    /* margin-top: 10px !important; */
}
.sb-row .sb-row-routes .sb-row-transfers .sb-time {
    padding-left: 10px !important;
}
.sb-row .sb-row-routes .sb-row-transfers .sb-info {
    padding-right: 10px !important;
    padding-left: 15px !important;
}
.sb-row .sb-row-routes .sb-row-transfers .sb-transfer-row-info,
.sb-comment-row .sb-transfer-row-info {
    font-weight: 600 !important;
    color: #4D4D4D !important;
    font-size: 13px !important;
    width: 100% !important;
    display: flex !important;
    flex-wrap: wrap !important;
    grid-gap: 4px !important;
    gap: 4px !important;
}
.sb-row .sb-row-routes .sb-row-transfers .sb-transfer-row-info .RouteNumber {
    margin-right: 0 !important;
}
.sb-row .sb-row-routes .sb-row-transfers .sb-transfer-row-info span {
    background: #E9E6FA !important;
    border-radius: 50px !important;
    padding: 1px 5px !important;
    font-size: 12px !important;
    margin: 0 8px 6px 0;
}
.sb-row .sb-row-routes .sb-row-transfers .sb-transfer-row-info span:first-of-type {
    margin-left: 6px;
}
.sb-row .sb-row-routes .sb-row-transfers .sb-time span {
    border: none !important;
    padding: 0 !important;
    background: none !important;
    width: auto !important;
}
.sb-row .sb-row-routes .sb-row-edit {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    background: #F2F2F2 !important;
    padding: 7px !important;
}
.sb-row .sb-row-routes .sb-row-edit .inputtext-small,
.sb-row .sb-row-routes .sb-row-edit .inputselect-small .ant-select-selector {
    height: 24px !important;
}
.sb-row .sb-row-routes .sb-row-edit .inputselect-small .ant-select-selector .ant-select-selection-item {
    line-height: 22px !important;
}
.sb-row .sb-row-routes .sb-row-edit .inputselect-small {
    width: 100% !important;
}
.sb-row .sb-row-routes .sb-row-edit .sb-edit-controls .sb-done,
.sb-row .sb-row-routes .sb-row-edit .sb-edit-controls .sb-close {
    width: 20px !important;
    height: 20px !important;
}
.sb-row .sb-row-routes .sb-row-edit .sb-edit-controls .sb-done svg,
.sb-row .sb-row-routes .sb-row-edit .sb-edit-controls .sb-close svg {
    height: 8px !important;
}
.service-trip-bar {
    display: none !important;
}
.ShiftBat .list-group .list-group-item .RouteNumber,
.ShiftBat .list-group .list-group-item .RouteNumber-sm {
    line-height: 12px;
    height: 32px;
}
.sb-row-trim-buttons-wrap {
    display: none;
}
.sb-row-trim-buttons-wrap .ant-btn {
    border: none;
    background: none;
    width: 24px;
    height: 24px;
}
.sb-row-trim-buttons-wrap .ant-btn svg path {
    fill: #888;
}
.sb-row-trim-buttons-wrap .ant-btn:hover svg path {
    fill: #6B54F9;
}
.sb-row-routes > div:hover .sb-row-trim-buttons-wrap {
    display: flex;
}
@property --p {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}
.pie {
  --p: 20;
  --b: 30px;
  --c: #6B54F9;
  --w: 200px;
  width:var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  place-content: center;
  font-size: 32px;
  font-weight: 600;
}
.pie:before,
.pie:after {
  content:"";
  position: absolute;
  border-radius: 50%;
}
.pie:before {
  inset: 0;
  background:
    radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p)*1%),#EBEBEB 0);
  -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
          mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
}
.pie:after {
  inset: calc(50% - var(--b)/2);
  background: var(--c);
  transform: rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
}
.animate {
  animation: p 1s .5s both;
}
.no-round:before {
  background-size: 0 0,auto;
}
.no-round:after {
  content: none;
}
@keyframes p {
  from{--p:0}
}
body.busable-app .card-quota h4.title {
  font-size: 16px !important;
  font-weight: 500 !important;
}
.card-quota .text-info {
  font-weight: 400 !important;
  color: #888 !important;
}
.SwitchGroup {
  display: flex;
  align-items: center;
  grid-gap: 40px;
  gap: 40px;
}
.SwitchGroup > div {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #555;
}
.SelectLarge [class^="ant-select"],
.SelectLarge [class*=" ant-select"] {
  font-size: 16px;
}
.SelectLarge .ant-select-selector {
  padding: 7px 11px !important;
}
.ant-input-data-count {
  color: #989898 !important;
  font-size: 14px !important;
  line-height: 20px;
}
.AppSMS .ant-btn.primary-btn {
  margin-top: 0 !important;
  display: block;
  min-width: 100% !important;
  height: 44px !important;
}
.AppSMS .ant-btn.primary-btn:disabled {
  background: #ddd !important;
}
.leave-pending {
    background: #666 !important;
    color: #FFF;
    opacity: 0.8 !important;
    text-align: center;
    padding-top: 30px;
}
.leave-approved {
    background: #52C41A  !important;
    color: #FFF;
    opacity: 0.8 !important;
    text-align: center;
    padding-top: 30px;
}
.leave-rejected {
    background: #FF4D4F  !important;
    color: #FFF;
    opacity: 0.8 !important;
    text-align: center;
    padding-top: 30px;
}
.card-height-auto {
    height: auto !important;
}
.btn-disabled {
    background: #EAEAEA !important;
    color: #666 !important;
    border: 1px solid #EAEAEA !important;
    cursor: not-allowed !important;
}
.upload:hover {
    cursor: pointer;
}
.LoaderButton .spinning {
  margin-right: 7px;
  top: 2px;
  width: 20px;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

.CharterForm {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.CharterForm .card-main.ant-card .ant-card-body {
  padding: 40px 80px 60px !important;
}
.CharterForm .card-title {
  text-align: center;
  color: #1A1A1A !important;
  font-size: 22px !important;
  text-transform: none !important;
  margin-bottom: 40px;
}
.CharterForm .ant-steps {
  max-width: 400px;
  margin: 0 auto 40px;
}
.CharterForm .ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #E9E6FA;
  border-color: #C4BBFD;
}
.CharterForm .ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #6B54F9;
  font-weight: 500;
}
.CharterForm .ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: #E6E7EB;
}
.CharterForm .ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #BABABA;
  font-weight: 500;
}
.CharterForm .ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
  background-color: #C4BBFD;
}
.CharterForm .ant-steps.ant-steps-label-vertical .ant-steps-item-content {
  margin-top: 8px;
}
.CharterForm .ant-steps.ant-steps-label-vertical .ant-steps-item-content .ant-steps-item-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.CharterForm .ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.CharterForm .ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: #757575;
}
.CharterForm .ant-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #BABABA;
}
.CharterForm .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #F6FFED;
  border-color: #B7EB8F;
}
.CharterForm .ant-steps .ant-steps-item-finish .ant-steps-item-icon .ant-steps-finish-icon svg {
  fill: #52C41A;
}
.CharterWrap {
  width: 100%;
}
.CharterWrap .CharterRow {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
  position: relative;
}
.CharterWrap.CharterDetails .CharterRow:first-of-type:before,
.CharterWrap.CharterDetails .CharterRow:nth-of-type(2):before {
  content: '';
  position: absolute;
  background: url(/static/media/dots.90f8b59f.svg) no-repeat center;
  width: 3px;
  height: 100%;
  top: 30px;
  left: 6px;
}
.CharterWrap .CharterRow .CharterRowHeader {
  display: flex;
  align-items: center;
}
.CharterWrap .CharterRowIcon {
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
}
.CharterWrap .CharterRow .CharterRowHeader > h4 {
  font-size: 15px;
  margin: 0;
  color: #757575;
}
.CharterWrap .CharterRow .CharterRowHeader > span {
  color: #A3A3A3;
  margin-left: 25px;
}
.CharterWrap .CharterRow .CharterRowHeader > span > strong {
  font-weight: 500;
}
.CharterWrap .CharterRowContent {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  padding-left: 41px;
}
.CharterWrap .CharterRowContent.pl-0 {
  grid-gap: 0;
  gap: 0;
}
.CharterRowIcon .IconCircle {
  background: #C1C5CE;
  width: 10px;
  height: 10px;
  border-radius: 50px;
}
.CharterWrap .CharterRowContent .ant-picker.ant-picker-large {
  min-width: 140px;
}
.CharterWrap .btn-close {
  margin-left: auto;
  background: #FFF2F0;
  border: 1px solid #FFCCC7;
  width: 18px;
  height: 18px;
  padding: 0;
  outline: none;
}
.CharterWrap .btn-close svg {
  width: 10px;
  height: 10px;
}
.CharterWrap .btn-close:hover {
  background: #FF4D4F !important;
  border-color: #FF4D4F !important;
}
.CharterWrap .btn-close:hover svg path {
  fill: #fff;
}
.primary-btn.btn-lg {
  font-size: 18px !important;
  height: 52px !important;
  min-width: 250px !important;
}
.CharterForm .primary-btn.btn-lg:last-of-type {
  margin-left: auto;
}
.CharterForm .btn-back {
  position: absolute;
  top: 38px;
  left: 30px;
  width: 30px;
  height: 30px;
  padding: 0;
  background: #F3F4F6;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CharterForm .btn-back:hover {
  background: #E9E6FA !important;
  outline: none;
}
.CharterForm .btn-back:hover svg path {
  stroke: #6B54F9;
}
.sb-time-edit {
  width: 56px !important;
  flex-shrink: 0 !important;
}
.sb-time-edit .ant-picker-input input {
  text-align: center;
}
.sb-time-edit .ant-picker-suffix,
.sb-row .sb-row-edit .sb-time-edit .ant-picker-clear,
.print-title {
  display: none !important;
}
@media print {
  @page {
    size: A4 !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  html, body {
    overflow: hidden !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .print-area,
  .print-area *,
  .print-title {
    visibility: visible !important;
  }
  .print-area {
    width: 100%;
    padding: 20px !important;
  }
  .print-area .ant-card.card-main {
    border: 1px solid #EFEDFD !important;
    box-shadow: none !important;
  }
  .app-main .app-sider + .app-layout {
    margin: 0 !important;
  }
  .app-main .app-layout .app-header, .no-print,
  .page-main-controls.top-float, .filter-options-main.top-float {
    display: none !important;
  }
  .print-title {
    margin: 50px 20px 10px;
    display: block !important;
    font-size: 24px !important;
    line-height: 24px !important;
    font-weight: 600;
  }
}

/* .pdf-styles {
  width: 100% !important;
  padding: 20px !important;
} */
.print-area .ant-card.card-main {
  border: 1px solid #EFEDFD !important;
  box-shadow: none !important;
}

.charter-row{
  cursor: pointer;
}
.RouteTitle {
    padding: 8px 0px;
    margin: 0px;
}

.RouteHeadsign, .RouteDetails {
    min-width: 200px;
    font-weight: 500;
    color: #989898;
    font-size: 13px;
    line-height: 16px;
}

.RouteDetails-sm, .RouteHeadsign-sm {
    min-width: 150px;
    margin-bottom: 10px;
    font-weight: 300;
    color: #828282;
    font-size: 10px;
}

.RouteNumber,
.RouteNumber-sm {
    color: #fff;
    font-size: 12px;
    min-width: 56px;
    height: 28px;
    text-align: center;
    border-radius: 0 6px 6px 0;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px !important;
    line-height: 10px;
}
input.RouteNumber,
input.RouteNumber-sm {
    border: none !important;
}
.RouteNumber-sm {
    font-size: 12px;
    border-radius: 6px;
    min-width: 50px;
    height: 26px;
}
/* .RouteInfo {
    padding-left: 20px;
} */
.ShiftBatView .RouteInfo .RouteDetails .RouteHeadsign {
    margin: 4px 0 0 !important;
}
.ShiftBatView .RouteInfo h1[contenteditable="true"],
.ShiftBatView .RouteInfo .RouteDetails[contenteditable="true"],
.ShiftBatView .RouteInfo .RouteHeadsign[contenteditable="true"] {
    margin: 0 0 0 8px !important;
}
.ShiftBatView .RouteInfo h1[contenteditable="true"] {
    margin-bottom: 6px !important;
}
.ShiftBatView .RouteTitle.pt-0 {
    padding-bottom: 6px !important;
}
@media screen and (min-width: 1300px) {
    /* .ShiftBatView .RouteInfo .RouteDetails,
    .ShiftBatView .RouteInfo .RouteHeadsign {
        margin: 0 0 0 20px !important;
    } */
}

.RouteInfo h1,
.students-list .list-group-item h2 {
    font-size: 15px;
    font-weight: 500 !important;
    margin: 0;
    vertical-align: baseline;
    margin-bottom: 0;
    line-height: 20px;
}
.students-list .list-group-item h2 {
    color: #000;
}
.RouteInfo h1[contenteditable="true"],
.RouteInfo .RouteDetails[contenteditable="true"],
.RouteInfo .RouteHeadsign[contenteditable="true"]{
    border: 1px solid #D9D9D9 !important;
    border-radius: 6px !important;
    padding: 4px 10px !important;
    outline: none !important;
}
.RouteTitle .RouteNumber[contenteditable="true"] {
    outline: none !important;
}
.RouteInfo h1[contenteditable="true"]:focus,
.RouteInfo .RouteDetails[contenteditable="true"]:focus,
.RouteInfo .RouteHeadsign[contenteditable="true"]:focus,
.RouteTitle .RouteNumber[contenteditable="true"]:focus {
    box-shadow: 0 0 3px 0 rgba(195,186,252,0.8) !important;
    border-color: #C3BAFC !important;
}
.RouteInfo .RouteHeadsign[contenteditable="true"].warning {
    box-shadow: none;
    border-color: #f8b34b !important;
}
.RouteInfo .RouteHeadsign[contenteditable="true"]:focus.warning {
    box-shadow: 0 0 3px 0 rgba(234, 135, 62, 0.8) !important;
}

.RouteInfo h4 {
    font-size: 14px;
    margin-top: 0;
    vertical-align: baseline;
    margin-bottom: 0;
}

.RouteInfo-sm h1 {
    font-size: 14px;
    font-weight: 300;
    /* margin-top: 0; */
    /* margin-bottom: 0; */
    padding-top: 5px;
    padding-left: 7px;
}

.RouteLogo {
    width: 40px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    border-radius: 6px;
    border: 1px solid lightgray;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.RouteLogo img {
    max-height: 40px;
    max-width: 40px;
    padding: 1px;
    border-radius: 6px;
}

.RouteLogo-sm img {
    max-height: 32px;
    max-width: 32px;
    margin-left: 2px;
}


.EditRouteLogo {
    width: 102px;
    height: 102px;
    text-align: center;
    vertical-align: middle;
    border-radius: 6px;
    border: 1px solid lightgray;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.EditRouteLogo.Edit-sm,
.EditRouteLogo.Edit-sm .ant-upload-list-item-container,
.EditRouteLogo.Edit-sm .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.EditRouteLogo.Edit-sm .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item::before {
    width: 50px !important;
    height: 50px !important;
}
.EditRouteLogo.Edit-sm .ant-upload-list-item.ant-upload-list-item-done,
.EditRouteLogo.Edit-sm .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item,
.EditRouteLogo.Edit-sm .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item,
.EditRouteLogo.Edit-sm .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item {
    padding: 0 !important;
    border: none !important;
}
.EditRouteLogo.Edit-sm .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select span div div {
    font-size: 12px !important;
    line-height: 14px !important;
    margin-top: 0 !important;
    text-transform: capitalize !important;
}
.EditRouteLogo.Edit-sm .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item .ant-upload-list-item-thumbnail {
    padding: 5px !important;
}

.EditRouteLogo img {
    /*max-height: 40px;*/
    /*max-width: 40px;*/
    /*padding: 1px;*/
    /*border-radius: 6px;*/
}

.ant-upload-list-item-container {
    margin-block: 0 !important;
}

.ant-upload.ant-upload-select {
    margin-inline-end: 0 !important;
    margin-bottom: 0 !important;
    border: none !important;
}
.RouteNumberListWrap {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 0 10px 0;
    margin: 0 0 -10px;
}
.RouteNumberListWrap a {
    text-decoration: none !important;
}
.customer-list {
  padding-top: 10px;
}
.customer-list .filter-options-main {
  margin-top: 40px;
}
.sb-time-edit {
    width: 56px !important;
    flex-shrink: 0 !important;
}
.sb-time-edit .ant-picker-input input {
    text-align: center;
}
.sb-time-edit .ant-picker-suffix,
.sb-row .sb-row-edit .sb-time-edit .ant-picker-clear {
    display: none !important;
}
.address-line-1 {
    margin-bottom: 10px;
}
.BusRouteDetails .FormFooter {
  margin-top: 50px;
}

.BusRouteDetails .DoneBtn {
  color: #40a9ff;
  font-weight: bold;
  font-size: 0.75rem;
  margin-left: 25px;
  cursor: pointer;
}

.BusRouteDetails .DoneBtn:hover {
  color: #3098dd;
}

.BusRouteDetails .FormFooter {
  margin-top: 50px;
}

.RightContainer {
  border: 0.5px solid #eff0f0;
  border-radius: 0px 8px 8px 0px;
  box-shadow: -4px 0px 20px -9px gray;
  padding: 20px 20px;
}

.BusRouteDetails tr.Selected {
  background-color: #007BFF !important;
  color: white !important;
}

.BusRouteDetails tr:nth-child(2n) {
  background-color: #efefef;
}

.BusRouteDetails tr:hover {
  background-color: lightgray !important;
  color: black !important;

}

.TP {
  font-weight: 600;
}

.TimetableSchedule {
  width: 100%;
}

.TimetableSchedule .ant-tabs-nav .ant-tabs-extra-content > .ant-btn.ant-btn-default {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding: 0 3px 0 10px !important;
  height: 38px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
}
.TimetableSchedule .ant-tabs-nav .ant-tabs-extra-content > .ant-btn.ant-btn-default svg {
  width: 13px !important;
  height: 15px !important;
}
.TimetableSchedule .ant-tabs-nav .ant-tabs-extra-content > .ant-btn.ant-btn-default svg path {
  fill: #777 !important;
}
.TimetableSchedule .ant-tabs-nav .ant-tabs-extra-content > .ant-btn.ant-btn-default:hover {
  background: #E9E6FA !important;
  border-color: #6B54F9 !important;
  color: #6B54F9 !important;
}
.TimetableSchedule .ant-tabs-nav .ant-tabs-extra-content > .ant-btn.ant-btn-default:hover svg path {
  fill: #6B54F9 !important;
}

.TimetableTripBadge {
  font-size: 14px;
  padding: 10px;
  font-weight: normal;
}

.BusRouteDetails table {
  border: 1px solid #D8D8D8;
  width: 100%;
}

.BusRouteDetails table th {
  background: #696969;
  border: 1px solid #696969;
  color: white;
  padding: 10px 10px;
}

.BusRouteDetails table td {
  padding: 0px 10px;
}

.Headsign.warning {
  box-shadow: none;
  border-color: #f8b34b !important;
}
.Headsign:focus.warning {
  box-shadow: 0 0 3px 0 rgba(234, 135, 62, 0.8) !important;
}

.ServiceRouteDetails {
  padding: 15px 10px 10px;
  position: relative;
}
.ServiceRouteDetails .ServiceDetailsMenu,
.ServiceTripDetails .ServiceDetailsMenu {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  padding: 15px 5px;
  position: absolute;
  top: 0;
  left: -40px;
  background: rgba(255,255,255,0.8);
  width: 40px;
  border-radius: 0 0 0 4px;
  z-index: 99;
}
.ServiceTripDetails .ServiceDetailsMenu {
  border-radius: 4px 0 0 0;
  bottom: 20px;
  padding-top: 5px;
}
@media screen and (max-width: 1023px) {
  .ServiceRouteDetails,
  .ServiceTripDetails {
    padding-left: 40px !important;
  }
  .ServiceRouteDetails .ServiceDetailsMenu,
  .ServiceTripDetails .ServiceDetailsMenu {
    left: 0;
  }
}
@media screen and (min-width: 1024px) {
  .ServiceRouteDetails .ServiceDetailsMenu,
  .ServiceTripDetails .ServiceDetailsMenu {
    display: none;
  }
  .ServiceRouteDetails:hover .ServiceDetailsMenu,
  .ServiceRouteDetails .ServiceDetailsMenu:hover,
  .ServiceTripDetails:hover .ServiceDetailsMenu,
  .ServiceTripDetails .ServiceDetailsMenu:hover,
  .ServiceRouteDetails .ServiceDetailsMenu.colorpicker-open {
    display: flex;
  }
}
.ServiceColorPicker input.color-picker,
.ServiceColorPicker .ant-color-picker-trigger {
  border: none !important;
  background: none !important;
  outline: none !important;
  box-shadow: none !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 30px !important;
  height: 30px !important;
  opacity: 0;
}
.ServiceTripDetails .AddNewRow {
  padding: 8px 10px !important;
  margin: 0 !important;
}
.ServiceTripDetails .AddNewRow .sb-edit-controls {
  padding-left: 6px !important;
  grid-gap: 4px !important;
  gap: 4px !important;
  align-items: center !important;
}
.ServiceColorPicker > span {
  display: block;
  width: 18px;
  height: 3px;
  margin: -6px 0 0;
}
.ServiceTripDetails {
  padding: 0 10px 0;
  margin: 5px 0 0;
  position: relative;
  height: calc(100% - 96px);
}
.ServiceRouteDetails .ServiceDetailsMenu .icon-button,
.ServiceTripDetails .ServiceDetailsMenu .icon-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff !important;
  border-radius: 6px !important;
  width: 30px;
  height: 30px;
  padding: 0 !important;
  position: relative;
  overflow: hidden;
}
.ServiceRouteDetails .ServiceDetailsMenu .icon-button svg path,
.ServiceTripDetails .ServiceDetailsMenu .icon-button svg path {
  fill: #888 !important;
}
.ServiceTripDetails .ServiceDetailsMenu .icon-button svg g,
.ServiceTripDetails .ServiceDetailsMenu .icon-button svg circle,
.ServiceTripDetails .ServiceDetailsMenu .icon-button svg polyline {
  stroke: #888 !important;
}
.ServiceRouteDetails .ServiceDetailsMenu .icon-button:hover svg path,
.ServiceTripDetails .ServiceDetailsMenu .icon-button:hover svg path,
.ServiceTripDetails .ServiceDetailsMenu .icon-button svg.active path {
  fill: #fff !important;
}
.ServiceTripDetails .ServiceDetailsMenu .icon-button:hover svg g,
.ServiceRouteDetails .ServiceDetailsMenu .icon-button:hover svg g,
.ServiceTripDetails .ServiceDetailsMenu .icon-button:hover svg circle,
.ServiceTripDetails .ServiceDetailsMenu .icon-button:hover svg polyline {
  stroke: #fff !important;
}
.ServiceTripDetails .ServiceDetailsMenu .icon-button:disabled svg path {
  fill: #ccc !important;
}
.ServiceTripDetails .ServiceDetailsMenu .icon-button.icon-switch.active {
  background: #E9E6FA !important;
}
.ServiceTripDetails .ServiceDetailsMenu .icon-button.icon-switch.active svg path {
  fill: #6B54F9 !important;
}
.ServiceRouteDetails .ant-row .ant-col .row.RouteTitle {
  padding: 0;
}
.ServiceRouteDetails .ant-row .ant-col .row.RouteTitle > .d-flex.flex-column.ml-3 {
  margin-left: 0 !important;
  padding: 0 8px;
}
.ServiceRouteDetails .ant-row .ant-col .row.RouteTitle .RouteHeadsign, 
.ServiceRouteDetails .ant-row .ant-col .row.RouteTitle .RouteDetails {
  min-width: auto !important;
}
.info-icon-button,
.info-icon-button-wrap button {
  border: none !important;
  align-items: center;
  outline: none !important;
  grid-gap: 8px;
  gap: 8px;
  color: #666 !important;
  flex-shrink: 0;
}
.info-icon-button.light,
.info-icon-button-wrap.light button {
  background: none !important;
  border: 1px solid #d9d9d9 !important;
}
.info-icon-button.light.active {
  background: #F8F7FE !important;
  border-color: #c7c0f3 !important;
}
.info-icon-button:hover,
.info-icon-button.active,
.info-icon-button-wrap button:hover {
  color: #6B54F9 !important;
  border-color: #CBC2FF !important;
}
.info-icon-button,
.info-icon-button-wrap button,
.ServiceRouteDetails .ant-row .ant-col:nth-of-type(2) .icon-button,
.ServiceTripDetails .TripDetailsInfo .icon-button {
  border-radius: 6px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  padding: 0 8px !important;
  height: 32px !important;
  justify-content: center !important;
  display: flex !important;
  margin: 0 0 8px !important;
  min-width: 100% !important;
}
.info-icon-button-wrap button {
  font-size: 14px !important;
  margin: 0 !important;
}
.info-icon-button-wrap button.icon-button.btn-filled:hover {
  background: none !important;
}
.info-icon-button.btn-error {
  color: #FF4D4D !important;
  border-color: #FF4D4D !important;
}
.info-icon-button.btn-error svg path,
.info-icon-button.btn-error:hover svg path {
  fill: #FF4D4D !important;
}
.info-icon-button.btn-error:hover {
  background-color: #FFE6E6 !important;
}
.ServiceTripDetails .TripDetailsInfo {
  grid-gap: 8px;
  gap: 8px;
}
.info-icon-button,
.ServiceTripDetails .TripDetailsInfo .icon-button {
  margin: 0 !important;
  min-width: auto !important;
}
.info-icon-button:hover svg path,
.ServiceTripDetails .TripDetailsInfo .icon-button:hover svg path {
  fill: #fff !important;
}
.info-icon-button,
.ServiceTripDetails .icon-button.accessible-icon:not(.accessible-active) {
  background: #eee !important;
}
.info-icon-button-wrap button.icon-button.btn-filled svg path {
  fill: #888 !important;
}
.info-icon-button:hover svg path,
.info-icon-button.active svg path,
.info-icon-button-wrap button.icon-button.btn-filled:hover svg path,
.ServiceTripDetails .icon-button.accessible-icon:not(.accessible-active):hover svg path {
  fill: #6B54F9 !important;
}
.ServiceRouteDetails .ant-row .ant-col:nth-of-type(2) .ant-select.ant-select-single {
  min-width: 100%;
  height: 26px !important;
}
.ServiceRouteDetails .ant-row .ant-col:nth-of-type(2) .ant-select.ant-select-single > .ant-select-selector {
  height: 26px !important;
}
.ServiceTripDetails .InputTimeField .ant-input-sm.inputtext-small  {
  border-radius: 6px 0 0 6px !important;
  padding: 0 !important;
  width: 100% !important;
}
.ServiceTripDetails .InputTimeField:last-of-type .ant-input-sm.inputtext-small {
  border-radius: 0 6px 6px 0 !important;
  border-left: none !important;
}
.ServiceTripDetails .TimetableSchedule > .row > .col-lg-12 .ant-tabs .ant-tabs-content,
.ServiceTripDetails .TimetableSchedule > .row > .col-lg-12 .ant-tabs .ant-tabs-content .ant-tabs-tabpane.ant-tabs-tabpane-active {
  height: 100%;
}
.TripsWrap {
  height: 86%;
}
@media screen and (min-width: 1024px) {
  .TripsWrap {
    height: 90%;
  }
}
.TripsWrap .StopTimes .sb-row:last-of-type {
  border-bottom: none;
}
.TripsWrap .sb-row-heading {
  box-shadow: 0 3px 5px rgba(0,0,0,0.05);
  z-index: 1;
  position: relative;
}
.BusRouteDetails .card-main.map-holder-wrap.no-pad {
  grid-gap: 0 !important;
  gap: 0 !important;
  padding-right: 0 !important;
}
.ServiceTripDetails .service-rows-wrap > .ant-row.sb-row {
  cursor: pointer;
  padding: 8px 10px !important;
}
.ServiceTripDetails .service-rows-wrap > .transfer-row-info {
  margin: 8px 10px !important;
  width: calc(100% - 20px) !important;
}
.ServiceTripDetails .service-rows-wrap > .ant-row.sb-row.Selected {
  background: #E9E6FA;
}
.StopInfo .header {
    padding: 15px;
}
.StopInfo .body {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}
.StopInfo Button {
    margin-left: 15px;
}

.MapContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.control-panel {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 380px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
    padding: 12px 24px;
    margin: 20px;
    font-size: 12px;
    line-height: 1;
    color: #6b6b76;
    /*text-transform: uppercase;*/
    outline: none;
}

.control-panel .footer {
    margin-top:10px;
}


.FocusStop {
    z-index: 10;
}

.MapMenu {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    background-color: white;
    border-radius: 5px;
    padding: 15px;
}

.MapMenu .FilterResult {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: small;
}

.MapMenu .MenuHeader {
    padding-top: 15px;
    width: 100%;
}

/*.MapMenu .ant-switch {*/
/*    width: 100px;*/
/*}*/

.MapMenu .Filter label {
    width: 100px;
    padding-left: 10px;
}

.ControlPanelContainer {
    z-index: 12;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 85vh;
}
@media screen and (min-width: 1024px) {
    .ControlPanelContainer {
        right: 35px;
    }
    .Timetable .ControlPanelContainer,
    .StopManagerContainer .ControlPanelContainer {
        right: 0;
    }
}
.ControlPanel {
    background: #fff;
    box-shadow: 0 0 4px rgba(0,0,0,0.2);
    padding: 14px 14px 30px 14px;
    margin: 0 0 10px;
    font-size: 12px;
    line-height: 1;
    color: #6b6b76;
    outline: none;
    border-radius: 8px;
    width: 280px;
    position: relative;
}
.ControlPanelContainer .ControlPanel.CommentsPanel {
    padding: 6px 14px 10px 14px;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .RouteTitle {
    padding-bottom: 6px;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .RouteTitle .RouteNumber {
    min-width: 42px;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .RouteTitle .RouteInfo {
    margin-left: 12px !important;
    grid-gap: 2px !important;
    gap: 2px !important;
    width: auto !important;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .RouteTitle .RouteInfo .RouteDetails {
    min-width: auto;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .ant-avatar.ant-avatar-circle {
    background: #C1C5CE;
    flex-shrink: 0;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .ant-avatar.ant-avatar-circle .ant-avatar-string {
    font-size: 18px;
    text-align: center;
    line-height: 18px;
    font-weight: 500;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .ant-list .ant-list-items .ant-list-item {
    border-bottom: 1px solid #ECE8FE;
    padding: 14px 0;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .ant-list .ant-list-items .ant-list-item:last-child {
    border-bottom: none;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .ant-carousel .slick-slider .slick-slide {
    padding-bottom: 30px;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .NewCommentWrap {
    width: 100%;
    position: relative;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .NewCommentWrap .ant-input {
    border-radius: 50px;
    width: 100%;
    position: absolute;
    z-index: 1;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .ant-input.CommenterName {
    border-radius: 50px;
    width: 100%;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    margin-bottom: 5px;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .NewCommentWrap .ant-btn {
    border-radius: 50px;
    position: absolute;
    right: 0;
    z-index: 2;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .NewCommentWrap .ant-btn:not(:disabled) {
    background: #D9F3D2;
    border: 1px solid #7ED668;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .NewCommentWrap .ant-btn:not(.icon-button) svg path {
    stroke: #ccc;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .NewCommentWrap .ant-btn:not(:disabled):not(.icon-button) svg path {
    stroke: #7ED668;
}
.ControlPanel .Footer {
    margin-top: -6px;
    text-align: right;
}
.ControlPanel h5 {
    font-size: 18px !important;
    font-weight: 600;
    margin-bottom: 0 !important;
    line-height: 20px;
}
.ControlPanel .row strong {
    margin: 12px 0 5px !important;
    display: inline-block !important;
    width: 100% !important;
}
.ControlPanel > .ant-row {
    margin-top: 14px;
}
.ControlPanel > .ant-row:first-of-type {
    margin-top: 0;
}
.ControlPanel > .ant-row > .ant-col {
    display: flex;
    flex-direction: column;
}
.ControlPanel > .ant-row > .ant-col {
    color: rgb(0, 0, 0, 0.8);
    word-wrap: break-word;
}
.ControlPanel > .ant-row > .ant-col > strong {
    font-weight: 400;
    color: #888;
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 3px;
}
.ControlPanel > .ant-row > .ant-col > div > .btn.btn-sm {
    margin: 0 10px 0 0;
}
.ControlPanel .PointErrors {
    grid-gap: 10px;
    gap: 10px;
}
.ControlPanel .PointErrors > div {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    width: 100%;
    word-wrap: break-word;
    line-height: 16px;
}
.ControlPanel > .ant-row.stop-sequence {
    margin-bottom: -48px;
}
.BusRouteDetails {
  width: 100%;
  margin-top: 10px;
  padding-bottom: 17px;
}
@media screen and (max-width: 1023px) {
  .MapPlaceholder {
    height: 50vh;
  }
}
.BusRouteDetails .FormFooter {
  margin-top: 50px;
}

.BusRouteDetails .DoneBtn {
  color: #40a9ff;
  font-weight: bold;
  font-size: 0.75rem;
  margin-left: 25px;
  cursor: pointer;
}

.BusRouteDetails .DoneBtn:hover {
  color: #3098dd;
}

.BusRouteDetails .FormFooter {
  margin-top: 50px;
}


.BusRouteDetails tr.Selected {
  background-color: #74d8ff;
}

.BusRouteDetails tr:nth-child(2n) {
  background-color: #efefef;
}

.BusRouteDetails tr:hover {
  background-color: #d0eeff;
}

.BusRouteDetails .TP {
  font-weight: 600;
}

.StopListContainer {
  /* border-bottom: 1px solid #ccc; */
  height: 85vh;
  overflow-y: scroll;
  border: 0.5px solid #eff0f0;
  border-radius: 0px 8px 8px 0px;
  box-shadow: -4px 0px 20px -9px gray;
  padding: 15px 0;
}
.DuplicateStopList li:hover {
    background-color: #d0eeff;
}
.FilteredStopList {
    width: 100%;
}
.FilteredStopTable {
    width: 100%;
}
.FilteredStopTable .ant-table tbody.ant-table-tbody tr.ant-table-row .ant-table-cell {
    padding: 12px 0;
}
.FilteredStopTable .FilteredStopRow {
    padding: 0 10px 0 2px;
}
.FilteredStopTable .ant-table-pagination.ant-pagination {
    margin: 20px 0;
    padding: 0 10px;
}

.SchedulesDropdown {
    margin: 0 10px 5px 0 !important;
}
.accessible-active path {
    fill: #6B54F9 !important;
}


.StopTimes .sb-edit-controls .sb-done,
.StopTimes .sb-edit-controls .sb-close {
    border: none !important;
    border-radius: 50px !important;
    width: 25px !important;
    height: 25px !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-shrink: 0 !important;
}
.StopTimes .sb-edit-controls {
    padding-left: 16px;
    flex-shrink: 0 !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-end !important;
    grid-gap: 8px !important;
    gap: 8px !important;
}
.StopTimes .sb-edit-controls .sb-done svg,
.StopTimes .sb-edit-controls .sb-close svg {
    height: 9px !important;
}
.StopTimes .sb-edit-controls .sb-done {
    background: #D9F3D2 !important;
}
.StopTimes .sb-edit-controls .sb-done svg path {
    stroke: #7ED668 !important;
}
.StopTimes .sb-edit-controls .sb-close {
    background: #FFE6E6 !important;
}
.StopTimes .sb-edit-controls .sb-close svg path {
    fill: #FF4545 !important;
}
.StopTimes .sb-edit-controls .sb-done:hover {
    background: #7ED668 !important;
}
.StopTimes .sb-edit-controls .sb-done:hover svg path {
    stroke: #fff !important;
}
.StopTimes .sb-edit-controls .sb-close:hover {
    background: #FF4545 !important;
}
.StopTimes .sb-edit-controls .sb-close:hover svg path {
    fill: #fff !important;
}

.Shift {}

.Shift .ShiftInput {
    margin-left: 5px;
    margin-right: 5px;
    height: 32px;
    padding: 0 11px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Timetable {
    overflow: visible;
    /*height: calc(100vh - 100px);*/
}

.Timetable .MapPlaceholder {
    /*width: calc(100% - 30px);*/
    /*margin-top: 25px;*/
    /* height: calc(100vh - 100px); */
    /*padding-left: 15px;*/
    /*padding-right: 15px;*/
    /*border: 1px solid red;*/
}

.Timetable .RouteListContainer {
    /* padding-top: 10px;
    border: 0.5px solid #eff0f0;
    border-radius: 0px 8px 8px 0px;
    box-shadow: -4px 0px 20px -9px gray;
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
    overflow-x: hidden; */
}
.Timetable .RouteListContainer .TimetableSchedule {
    padding-left: 15px;
    padding-right: 15px;
}
.Timetable .RouteListContainer .timetable-schedule {
    background: #FAFAFA !important;
    border: 1px solid #E9E9E9 !important;
    border-radius: 6px !important;
    margin: 2px 0 10px !important;
    padding: 5px 15px 15px 15px !important;
}

.Timetable .SearchContainer {
    /*max-height: 320px;*/
    /*padding-bottom: 15px;*/
    /*border-bottom: 1px solid lightgrey;*/
    /*overflow-y: scroll;*/
    /*overflow-x: hidden;*/
}

.Timetable .TripContainer {
    /*overflow-y: scroll;*/
    /*max-height: 60vh;*/
    /*margin-top: 15px;*/
}

.Timetable h2.Days {
    font-size: 15px;
    font-weight: 300;
    padding-top: 10px;
    margin: 0px;
}

.Timetable .ServiceNotes {
    color: #828282;
    font-weight: 300;
}

.Timetable .Times {
    /*padding: 10px 0px;*/
    /* border: 1px solid #D8D8D8; */
    /* width: 100%; */
}

td.Times {
    /* border: 1px solid #aaa; */
    text-align: center;
}

th.Times {
    text-align: center;
}

.Timetable tr:nth-child(even) {
    background-color: #efefef;
}

table td {
    padding: 5px 10px;
}

table tr.selected {
    background-color: #007BFF !important;
    color: white;
}

table:not(.ant-picker-content) tr:hover {
    background-color: lightgray !important;
}

.Timetable .Schedule {
    /*margin-top: 15px;*/
}

.Timetable .TimingPoint td {
    font-weight: 800;
}

.Timetable .RouteTitle {
    /* margin-bottom: 25px; */
}

.Timetable .Exclusions {
    color: #888;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.2px;
}

.Timetable .MuteStop {
    color: lightgrey;
}

.Timetable .mapboxgl-ctrl-geocoder {
    width: 100%;
    max-width: none;
    min-width: 140px;
}

.TargetBtn {
    border-radius: 50%;
    cursor: pointer;
    width: 32px;
    margin-top: 2px;
}

.TargetBtn:hover {
    background-color: #d6d8db;
}
.ConnectDetails {
    width: 40px;
    margin-top: -5px;
    color: #000;
    font-size: 10px;
}
.TimetableSchedule .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 10px;
}
.timetable-row {
    border-bottom: 1px solid #E9E5FE;
}
.timetable-row:last-of-type {
    border-bottom: none;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #6B54F9;
}
.ant-tabs-tabpane.ant-tabs-tabpane-active tr th {
    color: #989898;
    font-weight: 600 !important;
    font-size: 12px;
    text-transform: uppercase;
}
@media screen and (max-width: 1023px) {
    .Timetable .filter-options-main.top-float > .justify-content-end.mr-3 {
        display: none;
    }
    .Timetable .filter-options-main.top-float {
        flex-direction: row !important;
        margin-bottom: 20px;
    }
    .Timetable .filter-options-main.top-float .filter-calendar.mr-3 {
        margin-right: 0 !important;
    }
    .Timetable .filter-options-main.top-float .filter-calendar .ant-picker {
        width: 100%;
    }
    .Timetable .filter-options-main.top-float .filter-calendar.w-separator:after {
        display: none !important;
    }
    .Timetable .filter-options-main.top-float .filter-dropdown {
        width: -moz-fit-content;
        width: fit-content;
    }
    .ant-layout.app-main:not(.ant-layout-has-sider) .Timetable h1.page-title.header-title,
    .ant-layout.app-main:not(.ant-layout-has-sider) .Timetable .filter-options-main.top-float {
        padding: 0 15px;
    }
    .ant-layout.app-main:not(.ant-layout-has-sider) .app-header {
        padding-bottom: 5px !important;
    }
    .Timetable .card-main.map-holder-wrap {
        grid-gap: 0 !important;
        gap: 0 !important;
    }
    .Timetable .card-main.map-holder-wrap {
        border-radius: 10px !important;
        overflow: hidden !important;
    }
    .Timetable .card-main.map-holder-wrap .map-holder,
    .Timetable .card-main.map-holder-wrap .map-items.RouteListContainer {
        border-radius: 0 !important;
    }
    .Timetable .card-main.map-holder-wrap .MapPlaceholder,
    .Timetable .card-main.map-holder-wrap .map-items {
        height: 50vh;
        min-height: 400px;
    }
}
.StopPopup {
  width: 300px;
  padding:12px;
  z-index: 10;
}

.mapboxgl-popup-content {
  box-shadow: 0 1px 10px 6px rgb(0 0 0 / 10%);
  margin: -1px;
  border-radius: 8px;
  padding: 14px;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  filter: drop-shadow(0px -10px 6px rgba(0,0,0,.5));
    }
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  filter: drop-shadow(0px 10px 6px rgba(0,0,0,.5));
    }
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  filter: drop-shadow(-10px 0px 6px rgba(0,0,0,.5));
    }
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  filter: drop-shadow(10px 0px 6px rgba(0,0,0,.5));
    }

.StopPopupHeader {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 15px;
  padding-top: 15px;
}

.RouteListContainerItem:hover {
  cursor: pointer;
}

.print-pdf {
  width: 210mm;
  min-height: 297mm;
  padding: 15mm 10mm;
  margin: 0 auto;
  background: white;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

.quote-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 2px solid #EFEDFD;
}

.quote-header-left {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}

.logo-container {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.logo-container svg {
  display: block;
  width: 134px;
  height: 26px;
  fill: currentColor;
}

.quote-title-section {
  text-align: left;
}

.quote-meta {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  margin-top: 8px;
}

.company-details {
  flex: 0 0 auto;
  text-align: right;
}

.company-details h1 {
  margin: 0 0 10px 0;
  font-size: 24px;
  color: #333;
}

.company-details p {
  margin: 0;
  font-size: 12px;
  color: #666;
  line-height: 1.4;
}

.quote-number, .quote-date {
  text-align: left;
}

.quote-number label, .quote-date label {
  display: block;
  font-size: 11px;
  color: #666;
  margin-bottom: 2px;
}

.quote-number strong, .quote-date strong {
  display: block;
  font-size: 13px;
  color: #333;
}

.print-pdf .pdf-title {
  margin: 0;
  display: block !important;
  font-size: 20px !important;
  line-height: 32px !important;
  font-weight: 500;
  color: #666;
}

.print-pdf .ant-card.card-main {
  margin-bottom: 15px;
  box-shadow: none !important;
  border: 1px solid #EFEDFD !important;
}

.print-pdf .ant-card-head {
  border-bottom: 1px solid #EFEDFD;
}

.print-pdf .ant-card-head-title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

/* .print-pdf .ant-card-body {
  padding: 16px;
} */

.print-pdf label {
  display: block;
  font-size: 11px;
  color: #666;
  margin-bottom: 2px;
}

.print-pdf strong {
  display: block;
  font-size: 13px;
  color: #333;
  margin-bottom: 2px;
}

.print-pdf small {
  display: block;
  font-size: 11px;
  color: #666;
  margin-bottom: 2px;
}

/* .print-pdf .card-row-header {
  padding: 12px 0;
  border-bottom: 1px solid #e8e8e8;
  font-weight: 600;
  color: #333;
} */

/* .print-pdf .card-row-content {
  padding: 12px 0;
  border-bottom: 1px solid #e8e8e8;
} */

/* .print-pdf .card-row-content.no-border {
  border-bottom: none;
} */

/* .print-pdf .border-full {
  border-bottom: 1px solid #e8e8e8;
} */

.print-pdf .text-right {
  text-align: right;
}

.print-pdf .text-left {
  text-align: left;
}

.print-pdf .align-items-center {
  display: flex;
  align-items: center;
}

/* Screen-specific styles */
@media screen {
  .print-pdf {
    width: 210mm;
    min-height: 297mm;
    margin: 20px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}

/* Print-specific styles */
@media print {
  .print-pdf {
    width: 210mm;
    min-height: 297mm;
    padding: 15mm 10mm;
    margin: 0;
    box-shadow: none;
  }

  @page {
    size: A4 portrait;
    margin: 0;
  }

  .print-pdf .ant-card {
    page-break-inside: avoid;
    break-inside: avoid;
    page-break-inside: avoid;
  }

  .print-pdf .card-row-content {
    page-break-inside: avoid;
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
.GridLayout {
    width: 100%;
    min-height: calc(100vh - 285px);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    display: flex;
}
.GridLayout.UnallocatedGrid {
    max-height: calc(40vh);
    min-height: calc(40vh);
    overflow-x: auto;
}
.ant-modal-body .GridLayout.UnallocatedGrid {
    max-height: calc(75vh);
    min-height: calc(75vh);
}
.GridLayout .GridWrap {
    padding-top: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: calc(100vh - 285px);
    position: relative;
}
.GridLayout .GridLabel {
    width: 130px;
    min-height: calc(100vh - 285px);
    flex-shrink: 0;
    position: relative;
    padding-top: 20px;
}
.GridLayout.UnallocatedGrid .GridWrap {
    overflow-x: visible;
    overflow-y: visible;
    min-height: auto;
}
.GridLayout .GridLabel > div {
    height: 80px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-size: 15px;
    text-transform: uppercase;
    color: #7C7D7E;
    font-weight: 500;
}
.GridLayout .GridLabel > div:first-of-type {
    margin-top: 9px;
}
.GridLayout .react-grid-layout {
    z-index: 1;
}
.GridLayout .react-grid-item:not(.react-draggable-dragging):hover {
    min-width: -moz-fit-content;
    min-width: fit-content;
    z-index: 1;
    background: rgba(255, 255, 255, 0.95) !important;
}
.GridLayout .react-grid-item.ItemDeadRun,
.GridLayout .react-grid-item.suggested-rosters {
    background: #F8FAFB !important;
    box-shadow: none !important;
    border: 2px dashed #D5D1FB !important;
}
.GridLayout .react-grid-item.ItemDeadRun:not(.react-draggable-dragging):hover,
.GridLayout .react-grid-item.suggested-rosters:not(.react-draggable-dragging):hover {
    background: #F8FAFB !important;
}
.GridLayout .react-grid-item.ItemDeadRun .simple-tags svg path {
    fill: #FF8F8F;
}
.GridLayout .react-grid-item .DeadRunInfo {
    width: auto !important;
    background: #F8FAFB !important;
    border: 2px dashed #D5D1FB !important;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    grid-gap: 4px !important;
    gap: 4px !important;
}
.GridLayout .react-grid-item .DeadRunInfo .deadrun-distance,
.GridLayout .react-grid-item .DeadRunInfo .deadrun-time {
    font-size: 14px;
    color: #666;
}
.GridLayout .react-grid-item .DeadRunInfo .red {
    color: #FF5555;
}
.GridLayout .react-grid-item .DeadRunInfo .green {
    color: #51B238;
}
.GridLayout .react-grid-item.suggested-rosters .DeadRunInfo:first-of-type {
    border-left: none !important;
}
.GridLayout .react-grid-item.suggested-rosters .DeadRunInfo:last-of-type {
    border-right: none !important;
}
.GridLayout .react-grid-item .DeadRunInfo:first-of-type {
    border-radius: 20px 0 0 20px;
}
.GridLayout .react-grid-item .DeadRunInfo:last-of-type {
    border-radius: 0 20px 20px 0;
}
.GridLayout .react-grid-item:not(:hover) .DeadRunInfo:last-of-type {    
    position: absolute;
    right: 0;
}
.GridLayout .react-grid-item:not(.ShowingDeadRunInfo) > .ant-avatar {
    margin-left: 10px;
}
.GridLayout .react-grid-item:not(.ShowingDeadRunInfo) > div.simple-tags {
    margin-right: 10px;
}
.GridLayout .react-grid-item.ShowingDeadRunInfo:not(:hover) > div.simple-tags {
    margin-right: 65px;
}
.GridLayout .react-grid-item > .ant-avatar,
.GridLayout .react-grid-item > div.simple-tags {
    flex-shrink: 0;
}
.GridLayout .react-grid-item .ant-avatar,
.GridLayout .react-grid-item .ant-avatar .ant-avatar-string {
    font-size: 20px !important;
    font-weight: 500;
}
.GridLayout .react-grid-item > div:not(.simple-tags) {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 6px;
    gap: 6px;
}
.GridLayout .react-grid-item > div:not(.simple-tags) > strong,
.GridLayout.UnallocatedGrid .react-grid-item strong {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}
.GridLayout .react-grid-item > div:not(.simple-tags) > span,
.GridLayout.UnallocatedGrid .react-grid-item span {
    color: #666;
    line-height: 14px;
}
.GridLayout.UnallocatedGrid .react-grid-item,
.GridLayout .react-grid-item.unallocated-rosters {
    background: rgba(255, 242, 240, 0.8) !important;
    border-radius: 16px !important;
    border: 1px solid #FFCCC7 !important;
    box-shadow: none !important;
    padding: 15px !important;
    justify-content: flex-start;
}
.GridLayout.UnallocatedGrid .react-grid-item:not(.react-draggable-dragging):hover,
.GridLayout .react-grid-item.unallocated-rosters:not(.react-draggable-dragging):hover {
    background: rgba(255, 242, 240, 0.8) !important;
}
.GridLayout .GridLines {
    display: flex;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.GridLayout .GridLines > div {
    position: relative;
    width: calc(3000px / 24);
    min-height: 100%;
    text-align: center;
    flex-grow: 1;
}
.GridLayout .GridLabel > strong,
.GridLayout .GridLines > div {
    font-size: 12px;
    color: #989898;
    font-weight: 600;
    text-transform: uppercase;
}

.roster-header {
    text-align: center;
    color: #989898;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
}
.roster-cell-main {
    padding: 0 0 0 10px;
}
.roster-item {
    background: #fff !important;
    border-radius: 10px !important;
    border: 1px solid transparent !important;
    border-left: none !important;
    box-shadow: 0 0 10px rgba(107, 84, 249, .15) !important;
    overflow: hidden;
    flex-shrink: 0;
    width: 100%;
    cursor: pointer;
    position: relative;
}
.roster-item .roster-item-wrap {
    padding: 8px 8px 8px 10px !important;
    display: flex;
    flex-direction: column;
    grid-gap: 6px;
    gap: 6px;
    width: 100%;
    min-height: 80px;
}
.roster-item .roster-item-wrap > .ant-checkbox-wrapper {
    position: absolute;
    z-index: 2;
    top: 1px;
    right: 4px;
}
.roster-item .roster-item-wrap > .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked) {
    display: none;
}
.roster-item .roster-item-wrap:hover > .ant-checkbox-wrapper {
    display: inline-flex;
}
.roster-item .roster-item-wrap > .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner,
.roster-cell-main .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6B54F9;
    border-color: #6B54F9;
}
.roster-item .roster-item-wrap > .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.roster-cell-main .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #8977ff;
    border-color: transparent;
}
.roster-item .roster-item-wrap > .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: #6B54F9;
}
.roster-item.item-unallocated .roster-item-wrap {
    flex-direction: row;
    align-items: center;
    min-height: 60px;
}
.roster-employee.item-compliance {
    border: 1px solid #ea3f2e !important;
}

.roster-item.item-optional,
.roster-item.item-compliance,
.roster-item.item-optional.item-compliance {
    background: rgba(255, 242, 240, 0.8) !important;
    border: 1px solid #FFCCC7 !important;
    border-left: none !important;
}
.roster-item.item-optional,
.roster-item.item-unallocated.item-selected,
.roster-item.item-selected,
.roster-item:hover {
    background: #E9E6FA !important;
    border: 1px solid #CBC2FF !important;
    border-left: none !important;
}
.roster-item.item-completed {
    background: #F6FFED !important;
    border: 1px solid #B7EB8F !important;
    border-left: none !important;
}
.roster-item.item-optional.item-selected {
    border: 2px dashed #CBC2FF !important;
    box-shadow: 0 0 15px rgba(107, 84, 249, .15) !important;
    background: rgba(255, 255, 255, 1) !important;
}
.item-unallocated {
    background: #EDF0F1 !important;
    border: 1px solid #CCD7DE !important;
    border-left: none !important;
}
.roster-item:not(.item-optional) .allocation-item.driver-error .RouteTitle .RouteLogo .ant-avatar,
.roster-item:not(.item-optional) .allocation-item.vehicle-error .RouteTitle {
    background: #FFE6E6 !important;
    color: #FF4D4D !important;
    border-color: #FFCCC7;
}
.roster-item .ant-avatar,
.employee-allocation .employee-list .employee-member .ant-avatar {
    flex-shrink: 0;
    z-index: 1;
}
.roster-item .ant-avatar,
.roster-item .ant-avatar .ant-avatar-string,
.employee-allocation .employee-list .employee-member .ant-avatar,
.employee-allocation .employee-list .item-unavailable .ant-avatar {
    font-size: 14px !important;
    font-weight: 500;
}
.roster-cell-main .roster-name {
    color: #999;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    word-break: break-word;
    min-width: 60px;
}
.roster-cell-main .roster-time {
    font-size: 13px;
    text-transform: none !important;
    font-weight: 500 !important;
    color: #aaa;
    line-height: 15px;
}
.roster-cell-main .roster-name .roster-time {
    min-height: 30px;
}
.roster-cell-main .RouteTitle {
    flex-direction: column !important;
    align-items: start !important;
}
.roster-cell-main .RouteLogo {
    width: 100% !important;
    justify-content: flex-start !important;
    grid-gap: 6px;
    gap: 6px;
}
.roster-cell-main .ant-avatar {
    flex-shrink: 0 !important;
}
.roster-cell-main .driver-name {
    color: #999;
    font-weight: 500;
    line-height: 14px;
}
.roster-cell-main .roster-employee .driver-name {
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    min-width: 85px;
    /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 80px; */
}
.roster-cell-main .roster-employee .allocation-item:hover .driver-name {
    color: #6B54F9;
}
.roster-cell-main .RouteNumber {
    width: auto !important;
    min-height: 30px !important;
}
.roster-cell-main .roster-employee {
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
}
.roster-cell-main .roster-employee .allocation-item > span {
    display: flex;
    grid-gap: 6px;
    gap: 6px;
    align-items: center;
}
.roster-item .roster-type {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    /* border-radius: 20px; */
    /* right: 0px;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 2px; */
    /* transform: rotate(40deg); */
}
.roster-item .roster-name {
    color: #3C3C3C;
    line-height: 16px;
    font-weight: 500;
}
.roster-item .roster-time,
.roster-item .roster-pay {
    color: #888;
    display: inline-flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
}
.roster-item:hover .roster-time,
.roster-item:hover .roster-pay {
    color: #666;
}
.roster-item .roster-time svg,
.roster-item .roster-pay svg {
    flex-shrink: 0;
}
.roster-item .roster-time svg path,
.roster-item .roster-pay svg path {
    fill: #C1C5CE;
    fill-opacity: 1 !important;
}
.roster-item .roster-vehicle,
.roster-cell-main .roster-vehicle,
.vehicle-name {
    font-size: 12px;
    border-radius: 6px;
    min-width: 50px;
    max-width: -moz-fit-content;
    max-width: fit-content;
    height: 28px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px !important;
    line-height: 10px;
    background-color: #C1C5CE;
    /* border: 1px solid #00aeef; */
}
.unallocated-roster-cell.expand {
    /* height: 30vh;
    overflow-y: auto; */
}
.allocation-item {
    cursor: pointer;
}
.allocation-item .RouteLogo,
.roster-item .RouteLogo {
    width: 28px;
    height: auto;
    border-radius: 50px;
    border-width: 0;
    position: relative;
    overflow: visible;
    text-align: left;
    margin: -1px 0 -1px -1px;
}
.allocation-item .RouteTitle {
    grid-gap: 5px;
    gap: 5px;
    border: 1px solid #C1C5CE;
    background: #F6F6F8;
    border-radius: 50px;
    padding: 0;
    width: calc(100% - 8px) !important;
    margin-left: -2px;
}
.allocation-item .RouteNumber,
.roster-item .RouteNumber,
.modal-info .vehicle-name {
    /* padding: 2px 6px !important; */
    /* border-radius: 6px !important; */
    width: 100%;
    font-size: 14px !important;
    line-height: 13px;
    background: none !important;
    /* border: 1px solid #e1e1fb; */
    color: #555 !important;
    text-align: left !important;
    font-weight: 500 !important;
    justify-content: start;
    height: auto !important;
    min-width: auto;
    min-height: 28px;
    padding: 0 !important;
    word-break: break-word;
}
.modal-info .vehicle-name {
    text-align: center !important;
    justify-content: center !important;
}
.roster-cell-main .vehicle-name {
    background: none !important;
    border: 1px solid #e1e1fb;
    color: #888 !important;
    min-width: auto !important;
    border-radius: 50px;
    padding: 0 8px !important;
}
/* TODO: Commented out below as it caused default allocations of rosters to fail */
/*.roster-cell-main > .allocation-item {*/
/*    display: none !important;*/
/*}*/
.allocation-item .RouteInfo .RouteDetails,
.roster-item .RouteInfo .RouteDetails {
    min-width: auto;
}
.item-allocation {
    min-height: 100%;
    position: relative;    
}
.item-allocation .item-list {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
}
.item-allocation .item-list .item-single,
.item-allocation .item-list .item-unavailable {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 0;
}
.item-allocation .item-list .item-single .ant-radio {
    display: none;
}
.item-allocation .item-list .item-unavailable {
    padding: 5px 10px;
}
.item-allocation .item-list .item-single .ant-radio-wrapper {
    width: 100%;
    padding: 4px 8px;
    cursor: pointer;
    /* border-radius: 6px; */
    margin-right: 0 !important;
}
.item-allocation .item-list .item-single .ant-radio-wrapper .staff-name {
    font-weight: 500;
    line-height: 14px;
    color: #666;
}
.item-allocation .item-list .item-single .ant-radio-wrapper .staff-time {
    line-height: 14px;
    color: #999;
}
.item-allocation .item-list .item-single .ant-radio-wrapper:hover,
.item-allocation .item-list .item-single .ant-radio-wrapper.ant-radio-wrapper-checked {
    background: #E6E2F9 !important;
}
.item-allocation .item-list .item-single .ant-radio-wrapper.ant-radio-wrapper-disabled:hover,
.item-allocation .item-list .item-single .ant-radio-wrapper.ant-radio-wrapper-disabled.ant-radio-wrapper-checked {
    background: #f2f2f2 !important;
}
.item-allocation .item-list .item-single .ant-radio-wrapper:not(.ant-radio-wrapper-disabled).ant-radio-wrapper-checked .staff-name {
    color: #6B54F9;
}
.item-allocation .item-list .item-single .ant-radio-wrapper:not(.ant-radio-wrapper-disabled).ant-radio-wrapper-checked .ant-avatar {
    background-color: #9BA4B8 !important;
}
.item-allocation .item-list .item-single .ant-radio-wrapper.ant-radio-wrapper-disabled .staff-name {
    color: #999;
}
.item-allocation .item-list .item-single .ant-radio-wrapper.ant-radio-wrapper-disabled .ant-avatar {
    background-color: #ddd !important;
}
.item-allocation .item-list .item-single .ant-radio-wrapper > span:not(.ant-radio),
.item-allocation .item-list .item-unavailable {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    justify-content: space-between;
    width: 100%;
}
.item-allocation .item-list .item-single .ant-radio-wrapper > span:not(.ant-radio) {
    padding-right: 0;
    padding-left: 0;
}
.item-allocation .item-list .item-single .employee-name,
.item-allocation .item-list .item-unavailable .employee-name {
    font-weight: 500;
    color: #444;
}
.item-allocation .item-list .item-unavailable .employee-name {
    color: #777;
}
.item-allocation .item-list .item-single:hover .employee-name,
.item-allocation .item-list .item-single .ant-radio-wrapper.ant-radio-wrapper-checked .employee-name {
    color: #6B54F9;
}
.item-allocation .item-list .item-single .employee-time,
.item-allocation .item-list .item-unavailable .employee-status {
    color: #777;
}
.item-allocation .item-list .item-single:hover .employee-time,
.item-allocation .item-list .item-single .ant-radio-wrapper.ant-radio-wrapper-checked .employee-time {
    color: #444;
}
.item-allocation .item-list-scroll {
    overflow-y: auto;
    margin: 10px 0 0;
    border: 1px solid #EDEDED;
    border-radius: 6px;
}
.showUnavailable .item-allocation .item-list-scroll,
.hideUnavailable .item-allocation .item-list-scroll {
    max-height: 210px;
}
.showUnavailable .item-allocation.hideVehicleUnavailable .item-list-scroll,
.showUnavailable .item-allocation.hideStaffUnavailable .item-list-scroll {
    position: absolute;
    top: 32px;
    bottom: 0;
    left: 0;
    right: 0;
}
.item-allocation .item-list .item-single .ant-avatar {
    flex-shrink: 0;
    background-color: #C1C5CE;
}
.item-allocation .item-list .item-single .ant-avatar .ant-avatar-string {
    font-size: 16px;
    font-weight: 500;
}
.item-allocation .item-list-scroll .item-list {
    padding: 0;
}
.dispatch-list .ant-splitter .ant-splitter-bar {
    margin: 10px 0;
}
.weekly-roster-table thead.ant-table-thead > tr > th:not(:first-of-type) {
    text-align: center;
}
.weekly-roster-table.header-roster-table thead.ant-table-thead > tr > th {
    padding: 0 0 10px !important;
}
.weekly-roster-table thead.ant-table-thead > tr > th,
.weekly-roster-table tbody.ant-table-tbody > tr > td {
    width: 12.28%;
    min-width: 80px;
}
.weekly-roster-table thead.ant-table-thead > tr > th:first-child,
.weekly-roster-table tbody.ant-table-tbody > tr > td:first-child {
    width: 14%;
}
.weekly-roster-table tbody.ant-table-tbody > tr > td {
    padding: 0;
}
.weekly-roster-table.allocated-roster-table thead.ant-table-thead,
.weekly-roster-table.unallocated-roster-table thead.ant-table-thead,
/*.weekly-roster-table.header-roster-table tbody.ant-table-tbody {*/
/*    display: none !important;*/
/*}*/
.weekly-roster-table tbody.ant-table-tbody > tr > td.ant-table-cell {
    padding: 8px 5px;
    border-left: 1px solid #DBD9FB;
    border-bottom: 1px solid #ebeafa;
    vertical-align: top;
}
.weekly-roster-table tbody.ant-table-tbody > tr > td.ant-table-cell:first-of-type {
    border-left: none;
}
.weekly-roster-table tbody.ant-table-tbody > tr:last-of-type > td.ant-table-cell {
    border-bottom: none;
}
.weekly-roster-table .ant-table,
.weekly-roster-table .ant-table .ant-table-content table,
.weekly-roster-table .ant-table .ant-table-content table tr:hover {
    background: none !important;
}
.weekly-roster-table .roster-cell {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 6px;
    gap: 6px;
}
.weekly-roster-table.allocated-roster-table .roster-cell-main .roster-name > div > span:hover {
    color: #6B54F9;
}
/* .roster-item .roster-card-menu {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 27px !important;
    height: 100%;
    flex-direction: column;
    gap: 1px;
    z-index: 2;
    display: none;
}
.roster-item:hover .roster-card-menu,
.roster-item.item-selected .roster-card-menu {
    display: flex;
}
.roster-item .roster-card-menu a,
.roster-item .roster-card-menu button {
    width: 100%;
    height: 25% !important;
    text-align: center;
    border-radius: 0 !important;
    background: rgba(255, 255, 255, 0.9);
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    outline: none !important;
}
.roster-item.item-unallocated .roster-card-menu a,
.roster-item.item-unallocated .roster-card-menu button {
    height: 33.33% !important;
}
.roster-item .roster-card-menu a:hover,
.roster-item .roster-card-menu button:hover {
    background: #6B54F9 !important;
}
.roster-item .roster-card-menu a svg path,
.roster-item .roster-card-menu button svg path {
    fill: #6B54F9 !important;
}
.roster-item .roster-card-menu a:hover svg path,
.roster-item .roster-card-menu button:hover svg path {
    fill: #fff !important;
}
.roster-item .roster-card-menu button.btn-filled svg {
    width: 12px !important;
}
.roster-item .roster-card-menu button span:not(.ant-btn-icon) {
    display: none !important;
} */
.roster-item .icon-warning {
    position: absolute;
    bottom: 4px;
    right: 4px;
}
/* .roster-item.item-selected .icon-warning,
.roster-item:hover .icon-warning {
    right: 34px;
} */
.roster-item .icon-warning svg {
    width: 16px;
}

.ant-table-cell .icon-success,
.roster-item .icon-success {
    display: none !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 28px;
    height: 28px;
    cursor: pointer;
}

.ant-table-cell .icon-success svg,
.roster-item .icon-success svg {
    width: 20px !important;
}
.ant-table-cell:hover .icon-success,
.roster-item.item-optional:hover .icon-success {
    display: flex !important;
}
.roster-item.item-optional:hover {
    opacity: 0.9;
}
/* .weekly-roster-table.allocated-roster-table .ant-table-thead tr > .ant-table-cell:first-of-type {
    vertical-align: middle;
} */
/* .weekly-roster-table.allocated-roster-table .ant-table-thead tr > .ant-table-cell:first-of-type,
.weekly-roster-table.allocated-roster-table .ant-table-tbody tr.ant-table-row > .ant-table-cell:first-of-type {
    padding-left: 0;
} */
/* .weekly-roster-table.allocated-roster-table .ant-table-thead tr > .ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
} */
.weekly-roster-table.allocated-roster-table .ant-table-cell:first-of-type .roster-cell-main .roster-name .btn-delete {
    display: none !important;
    position: absolute;
    bottom: 10px;
    left: 10px;
}
.weekly-roster-table.allocated-roster-table .ant-table-cell:first-of-type:hover .roster-name .btn-delete {
    display: flex !important;
}
.roster-item .driver-name {
    display: none !important;
}
.rosters-grid {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
}
.rosters-grid .rosters-grid-wrap {
    min-width: 1080px;
    width: 100%;
}
.weekly-roster-table thead.ant-table-thead > tr > th:not(:first-of-type) .day-column {
    display: flex;
    flex-direction: column;
    line-height: 16px;
}
.weekly-roster-table thead.ant-table-thead > tr > th:not(:first-of-type) .day-column span {
    font-weight: 500;
    color: #aaa;
}

/* vehicle modal */
.vehicle-allocation-container {
    width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
}

.search-container {
    margin-bottom: 20px;
}

.search-container input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
}

.vehicle-list, .unavailable-vehicle-list {
    margin-bottom: 20px;
}

.vehicle-btn {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
}

.unavailable-vehicle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.vehicle-reason {
    color: red;
    font-size: 14px;
}

.allocate-btn {
    width: 100%;
    padding: 10px;
    background-color: #d3d3e8;
    border: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
}

.allocate-btn:hover {
    background-color: #c0c0d1;
}

.work-diary-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    background: #f4f4f4;
    width: 100%;
    padding: 10px 25px;
}
.work-diary-title span {
    color: #777;
    font-weight: 500;
}

@media print {
    /* .ShiftBat.PrintView .sb-items-wrap {
        page-break-inside: initial !important;
    } */
    /* .ShiftBat.PrintView .sb-row .sb-row-routes > div > .row.justify-content-center {
        page-break-inside: initial !important;
        page-break-after: initial !important;
    } */
    .page-break {
        clear: both !important;
        page-break-after: always !important;
    }
}

.rosters-grid .weekly-roster-table.unallocated-roster-table .ant-table table > colgroup,
.rosters-grid .weekly-roster-table.allocated-roster-table .ant-table table > colgroup {
    width: 200px;
    max-width: 200px;
}

.driver-vehicle-allocation .vehicle-name .icon-button.btn-xs,
.driver-vehicle-allocation .driver-name .icon-button.btn-xs {
    width: 24px !important;
    height: 24px !important;
    margin: 0 0 0 6px !important;
    visibility: hidden !important;
}
.driver-vehicle-allocation:hover .vehicle-name .icon-button.btn-xs,
.driver-vehicle-allocation:hover .driver-name .icon-button.btn-xs {
    visibility: visible !important;
}
.roster-item-popover {
    width: 250px;
}
.roster-item-popover .ant-popover-inner {
    padding: 10px 14px 15px;
}
.roster-item-popover .ant-popover-inner .ant-popover-title {
    font-weight: 500;
    margin-bottom: 14px;
}
.roster-item-popover .ant-popover-inner .ant-popover-title .roster-name {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    line-height: 16px;
    cursor: pointer;
}
.roster-item-popover .ant-popover-inner .ant-popover-title .roster-name span {
    padding: 6px 8px;
    background: #007BFF;
    color: #fff;
    font-weight: 600;
    line-height: 16px;
    border-radius: 6px;
    flex-shrink: 0;
    max-width: 75px;
}
.dispatch-list .ant-tabs-nav {
    margin-bottom: 0;
}
.roster-row-color {
    position: absolute;
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
}
.ShowSecondaryMenu .ant-layout.app-main > .ant-layout.app-layout {
  padding-left: 50px;
}
.busable-app.ShowSecondaryMenu .app-main > .ant-layout.app-layout {
  position: relative;
}
.SecondaryMenu {
  position: fixed;
  top: 17px;
  bottom: 17px;
  z-index: 99;
  width: 50px;
  grid-gap: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  margin-left: -64px;
}
.SecondaryMenu .SecondaryMenuWrap {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  padding: 8px;
  background: #E6E2F9;
  width: 100%;
  border-radius: 8px;
}
.SecondaryMenu .SecondaryMenuWrap:last-of-type {
  height: 100%;
}
.SecondaryMenu .SecondaryMenuWrap .Separator {
  height: 2px;
  width: 100%;
  background: #D4D0E5;
  margin: 2px 0;
}
.SecondaryMenu .icon-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff !important;
  border-radius: 6px !important;
  width: 34px !important;
  height: 34px !important;
  padding: 0 !important;
  position: relative;
  overflow: hidden;
}
.SecondaryMenu .icon-button:not(.no-fill) svg path {
  fill: #888 !important;
  fill-opacity: 1 !important;
}
.SecondaryMenu .icon-button.no-fill svg path {
  stroke: #888;
}
.SecondaryMenu .icon-button.no-fill:disabled svg path {
  stroke: #ccc;
}
.SecondaryMenu .icon-button svg g,
.SecondaryMenu .icon-button svg circle,
.SecondaryMenu .icon-button svg polyline {
  stroke: #888 !important;
}
.SecondaryMenu .icon-button:not(.no-fill):hover svg path,
.SecondaryMenu .icon-button:not(.no-fill) svg.active path,
.SecondaryMenu .icon-button.ant-popover-open svg path {
  fill: #fff !important;
}
.SecondaryMenu .icon-button.no-fill:hover svg path,
.SecondaryMenu .icon-button.no-fill svg.active path {
  stroke: #fff !important;
}
.SecondaryMenu .icon-button:hover svg g,
.SecondaryMenu .icon-button:hover svg circle,
.SecondaryMenu .icon-button:hover svg polyline {
  stroke: #fff !important;
}
.SecondaryMenu .icon-button:not(.no-fill):disabled svg path {
  fill: #ccc !important;
}
.SecondaryMenu .icon-button.icon-switch.active,
.SecondaryMenu .icon-button.ant-popover-open {
  background: #6B54F9 !important;
}
.SecondaryMenu .icon-button.icon-switch.active svg path {
  fill: #fff !important;
}
.SecondaryMenuPopup {
  display: flex;
  flex-direction: column;
}
.SecondaryMenuOverlay {
  width: 200px;
}
.SecondaryMenu .icon-button + .ant-badge-count {
  box-shadow: none !important;
  background: #FF4444;
  border-radius: 50px;
  font-weight: 500;
  top: 3px;
  right: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SecondaryMenu .icon-button + .ant-badge-count .ant-scroll-number-only {
  height: auto;
}
.IconCheck {
  background: #ccc;
  border-radius: 50px;
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.IconCheck.active {
  background: #52C41A;
}
.IconCheck svg path {
  stroke: #fff;
}
.twentyPx {
    width: 20px;
}

.fiftyPx {
    width: 50px;
}

.sixtyPx {
    width: 60px;
}

.seventyPx {
    width: 70px;
}

.hundredPx1-5 {
    width: 150px;
}

.hundredPx2 {
    width: 200px;
}

.Version {
    color: #645F88;
    font-size: 0.6rem;
    margin: auto;
    margin-top: -5px;
}

.btn-light {
    color: rgba(0, 0, 0, .5) !important;
    background-color: transparent !important;
    border-color: transparent !important;
    margin: auto;
}

.btn-light.transfer-icon button,
.btn-light.transfer-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-light.transfer-icon button,
.btn-light.transfer-icon {
    outline: none;
    box-shadow: none;
    border: none;
}

.btn-light.transfer-icon.ant-popover-disabled-compatible-wrapper,
.btn-light.transfer-icon button:disabled {
    color: rgba(0, 0, 0, .3) !important;
    background: none !important;
}
.btn-light.btn-light-active {
    color: #fff !important;
    background-color: #6B54F9 !important;
    border-color: #6B54F9 !important;
}
.btn-light.btn-light-active svg path {
    fill: #fff;
}
.dropdown-item {
    color: rgba(0, 0, 0, .5) !important;;
}

.btn-outline-dark {
    color: rgba(0, 0, 0, .5) !important;
    border-color: rgba(0, 0, 0, .5) !important;
}

.btn-outline-dark:hover {
    color: #fff !important;
}
.btn-outline-lg {
    border-color: #6B54F9;
    color: #6B54F9;
    font-weight: 500;
    width: 100%;
    height: 42px;
    outline: none !important;
}
.btn-outline-lg:hover {
    background: #6B54F9 !important;
    color: #fff !important;
    border-color: #6B54F9 !important;
}
.dropdown-item.active {
    background: rgba(0, 0, 0, .5) !important;
    color: #fff !important;
}

.lander {
    padding: 80px 0;
    text-align: center;
}

.lander h1 {
    font-weight: 600;
}

body.busable-app .app-main {
    min-height: 100vh;
}

body.busable-app .ant-layout-sider.ant-layout-sider-collapsed .sider-logo .navbar-brand > img:nth-of-type(2) {
    display: none !important;
}

body.busable-app .sider-logo {
    padding: 20px 20px 30px;
}

body.busable-app .sider-logo .navbar-brand {
    margin: 0 !important;
    padding: 0 !important;
}

body.busable-app .sider-logo .navbar-brand > img:nth-of-type(2) {
    margin-left: 10px;
}

body.busable-app .app-main .app-sider {
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    min-height: 740px !important;
    overflow-x: auto;
}

@media screen and (max-width: 991px) {
    body.busable-app .app-main .app-sider {
        overflow: visible !important;
    }
}

body.busable-app .app-main .app-sider .ant-layout-sider-children {
    min-height: 800px !important;
}

@media screen and (min-height: 740px) {
    body.busable-app .app-main .app-sider .ant-layout-sider-children {
        min-height: inherit !important;
    }
}

body.busable-app .app-main .app-sider .dropdown-menu.show,
body.busable-app .app-main .app-sider .region-dropdown .dropdown-menu {
    left: 235px !important;
    z-index: 99999 !important;
    top: 0 !important;
}

body.busable-app .app-main .app-sider.ant-layout-sider-collapsed .region-dropdown .dropdown-menu {
    left: 66px !important;
}

body.busable-app .app-main .app-sider,
body.busable-app .app-main .app-sider .ant-menu.ant-menu-dark {
    background: #6B54F9 !important;
}

.app-main .app-sider .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.app-main .app-sider .ant-menu-dark .ant-menu-sub .ant-menu-item:hover,
    /* .app-main .app-sider .ant-menu-dark .ant-menu-submenu:hover, */
body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical .ant-menu-item:hover,
body.busable-app .ant-menu-item-selected a, .ant-menu-item-selected a:hover,
body.busable-app .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
    /* .app-main .app-sider .ant-menu-dark .ant-menu-submenu-selected, */
body.busable-app .ant-menu.ant-menu-vertical .ant-menu-submenu-selected,
.app-main .app-sider .operator-switch:hover {
    background: #6753ea !important;
}

body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical,
.app-main .app-sider .region-dropdown .dropdown-menu {
    background: #6B54F9 !important;
}

.app-main .app-sider .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #745ffc !important;
}

.app-main .app-sider .ant-menu-dark .ant-menu-item:hover,
body.busable-app .ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover,
body.busable-app .ant-menu-dark > .ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover,
body.busable-app .ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:active,
body.busable-app .ant-menu-dark > .ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:active,
body.busable-app .ant-menu-dark > .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-submenu-title {
    background: #614CE1 !important;
}

.app-main .app-sider .ant-menu-dark .ant-menu-item,
.app-main .app-sider .ant-menu-dark .ant-menu-inline.ant-menu-sub > li,
body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical li,
body.busable-app .ant-menu-inline .ant-menu-submenu-title,
body.busable-app .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    margin: 0 !important;
}

body.busable-app .ant-menu-inline .ant-menu-submenu-title,
body.busable-app .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    color: #fff;
}

.app-sider .ant-menu-inline .ant-menu-submenu-title,
.app-sider .ant-menu.ant-menu-sub .ant-menu-item,
.app-sider .ant-menu-dark .ant-menu-item,
body.busable-app .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title,
.app-sider.ant-layout-sider-collapsed .ant-menu-dark .ant-menu-submenu-selected,
body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical li {
    width: 100% !important;
    border-radius: 0 !important;
}

.app-main .app-sider.ant-layout-sider-collapsed .ant-menu-dark .ant-menu-item,
body.busable-app .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    text-align: center !important;
}

.app-main .app-sider .ant-menu-dark .ant-menu-item,
.app-main .app-sider .ant-menu-dark .ant-menu-item > a,
.app-main .app-sider .ant-menu-dark .ant-menu-item > span > a,
body.busable-app .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub,
body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical li,
.app-main .app-sider .operator-switch a {
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
}

.app-main .app-sider .ant-menu-dark .ant-menu-item > a,
.app-main .app-sider .ant-menu-dark .ant-menu-item > span > a,
body.busable-app .ant-menu-dark .ant-menu-item > span > a,
body.busable-app .ant-tooltip .ant-tooltip-inner > a {
    padding: 0 !important;
}

body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical li a {
    display: inline-block !important;
}

.app-main .app-sider.ant-layout-sider-collapsed + .app-layout {
    margin-left: 66px;
}

.app-main .app-sider + .app-layout {
    margin-left: 235px;
}

.app-main .app-layout .app-content {
    margin: 0;
    overflow: initial;
    padding-bottom: 30px;
}

@media screen and (min-width: 1024px) {
    .app-main .app-layout .app-content {
        margin: 0 16px;
    }
}

.app-main .app-sider img.ant-menu-item-icon {
    height: 19.5px;
}

.app-main .app-sider .region-dropdown {
    position: absolute !important;
    width: 100% !important;
}

.app-main .app-sider .region-dropdown .dropdown-menu {
    position: fixed !important;
    height: 100vh !important;
    border: none !important;
    border-left: 1px solid #6B54F9 !important;
    border-radius: 0 !important;
    transform: none !important;
    padding: 0 !important;
    overflow-y: auto !important;
    min-width: 240px !important;
}

.app-main .app-sider .region-dropdown .dropdown-menu ul a {
    font-size: 13px !important;
    color: #fff !important;
    font-weight: 500 !important;
    padding: 5px 20px 5px 24px !important;
}

.app-main .app-sider .region-dropdown .dropdown-menu ul a:hover,
.app-main .app-sider .region-dropdown .region-toggle:hover,
.app-main .app-sider .region-dropdown.show .region-toggle {
    background: #614CE1 !important;
    text-decoration: none;
}

.app-main .app-sider .region-dropdown .dropdown-menu ul a.new-region {
    display: flex !important;
    align-items: center !important;
    background: rgba(0, 0, 0, 0.1);
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    /* justify-content: center !important;
    color: rgba(0,0,0,.6) !important;
    font-size: 14px !important;
    background: none !important;
    font-weight: bold !important;
    border: 2px solid rgba(0,0,0,.35) !important;
    border-radius: 50px !important;
    width: 24px !important;
    height: 24px !important;
    padding: 0 !important;
    position: absolute !important;
    top: 15px !important;
    right: 20px !important; */
}

.app-main .app-sider .region-dropdown .dropdown-menu ul a.new-region svg {
    margin-right: 6px !important;
}

/* .app-main .app-sider .region-dropdown .dropdown-menu ul a.new-region:hover {
    color: #fff !important;
    border-color: #fff !important;
} */
.app-main .app-sider .region-dropdown .dropdown-menu > input {
    background: rgba(255, 255, 255, .6) !important;
    margin: 12px 18px !important;
    padding: 0 10px !important;
    height: 28px !important;
    border: none !important;
    border-radius: 50px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    outline: none !important;
    max-width: 165px !important;
}

.app-main .app-sider .region-dropdown .dropdown-menu > input:focus {
    box-shadow: none !important;
    background: rgba(255, 255, 255, .9) !important;
}

.app-main .app-sider .region-dropdown .region-toggle {
    position: relative;
    width: 100%;
    display: flex;
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    padding: 10px 24px !important;
    align-items: center !important;
    border-radius: 0 !important;
}

.app-main .app-sider .region-dropdown .region-toggle svg {
    width: 18px !important;
    height: 18px !important;
    flex-shrink: 0 !important;
}

.app-main .app-sider .region-dropdown .region-toggle svg g {
    stroke: #c4bbfd !important;
}

.app-main .app-sider .region-dropdown .region-toggle span {
    margin-left: 15px !important;
    text-align: left !important;
    line-height: 12px !important;
}

.app-main .app-sider.ant-layout-sider-collapsed .region-dropdown .region-toggle span,
.app-main .app-sider.ant-layout-sider-collapsed .region-dropdown .region-toggle i {
    display: none !important;
}

.app-main .app-sider .menu-icon-right {
    opacity: 0.45;
    transition: all 0.3s;
    position: absolute;
    top: 50%;
    right: 14px;
    width: 10px;
    color: rgba(0, 0, 0, 0.85);
    transform: translateY(-50%);
}

.app-main .app-sider .menu-icon-right:before,
.app-main .app-sider .menu-icon-right:after {
    background: #fff;
    position: absolute;
    width: 6px;
    height: 1.5px;
    border-radius: 2px;
    content: '';
    color: #fff;
}

.app-main .app-sider .menu-icon-right:before {
    transform: rotate(135deg) translateX(2.5px);
}

.app-main .app-sider .menu-icon-right:after {
    transform: rotate(45deg) translateX(-2.5px);
}

.app-main .ant-menu-item .ant-menu-item-icon + span,
.app-main .ant-menu-submenu-title .ant-menu-item-icon + span,
.app-main .ant-menu-item .anticon + span,
.app-main .ant-menu-submenu-title .anticon + span {
    margin-left: 15px !important;
}

.app-main .ant-menu.ant-menu-sub .ant-menu-item {
    padding-left: 40px !important;
}

body.busable-app .ant-menu.ant-menu-sub .ant-menu-item .ant-menu-title-content {
    font-size: 13px !important;
}

body.busable-app .ant-layout .ant-layout-sider-trigger {
    background: #614CE1;
}

body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical {
    border-radius: 6px !important;
    margin-left: -2px !important;
}

.app-main .app-sider:not(.ant-layout-sider-collapsed) .ant-menu-item .ant-menu-item-icon,
.app-main .app-sider:not(.ant-layout-sider-collapsed) .ant-menu-submenu .ant-menu-item-icon {
    position: absolute;
}

.app-main .app-sider:not(.ant-layout-sider-collapsed) .ant-menu-item .ant-menu-item-icon + span,
.app-main .app-sider:not(.ant-layout-sider-collapsed) .ant-menu-submenu .ant-menu-item-icon + span,
body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical li .ant-menu-item-icon + span {
    margin-left: 34px !important;
}

.app-main .app-sider:not(.ant-layout-sider-collapsed) .ant-menu-sub .ant-menu-item-icon + span {
    margin-left: 30px !important;
}

body.busable-app .ant-menu.ant-menu-sub.ant-menu-vertical li .ant-menu-item-icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

@media screen and (max-width: 1023px) {
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child {
        position: absolute;
        bottom: 55px;
    }
}

@media screen and (max-height: 740px) {
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child {
        bottom: 125px;
    }
}

@media screen and (min-width: 1024px) {
    /* .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child,
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(2),
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(3),
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(4),
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(5),
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(6),
    .app-main .app-sider .operator-switch {
        position: absolute;
        width: 100%;
    } */
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom {
        position: absolute;
        width: 100%;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom:last-child {
        bottom: 50px;
        overflow: visible !important;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom:nth-last-child(2) {
        bottom: 145px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom:nth-last-child(3) {
        bottom: 190px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom:nth-last-child(4) {
        bottom: 235px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom:nth-last-child(5) {
        bottom: 280px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom:nth-last-child(6) {
        bottom: 325px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom:nth-last-child(7) {
        bottom: 370px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li.MenuBottom:nth-last-child(8) {
        bottom: 415px;
    }

    /* .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child {
        bottom: 50px;
        overflow: visible !important;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(2) {
        bottom: 145px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(3) {
        bottom: 190px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(4) {
        bottom: 235px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(5) {
        bottom: 280px;
    }
    .app-main .app-sider .ant-layout-sider-children > .ant-menu > li:nth-last-child(6) {
        bottom: 325px;
    } */

    .app-main .app-sider .operator-switch,
    .app-main .app-sider .region-dropdown {
        bottom: 100px;
    }
}

.app-main .app-sider.ant-layout-sider-collapsed .ant-layout-sider-children > .ant-menu > li:last-child,
.app-main .app-sider.ant-layout-sider-collapsed .ant-layout-sider-children > .ant-menu > li:nth-last-child(2),
.app-main .app-sider.ant-layout-sider-collapsed .ant-layout-sider-children > .ant-menu > li:nth-last-child(3),
.app-main .app-sider.ant-layout-sider-collapsed .ant-layout-sider-children > .ant-menu > li:nth-last-child(4) {
    width: 66px;
}

@media screen and (max-width: 1023px) {
    .app-main .app-sider.ant-layout-sider-collapsed .ant-layout-sider-children,
    .app-main .app-sider.ant-layout-sider-collapsed .region-dropdown {
        overflow: hidden;
    }
}

.app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child .user-image {
    overflow: hidden;
    border-radius: 50px;
    position: relative;
    margin: 8px 0 0 0;
}

.app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child .user-image,
.app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child .user-image svg {
    display: block;
    width: 24px;
    height: 24px;
}

.app-main .app-sider:not(.ant-layout-sider-collapsed) .ant-layout-sider-children > .ant-menu > li:last-child .user-image {
    margin: 0px 0 0 -6px;
}

.app-main .app-sider:not(.ant-layout-sider-collapsed) .ant-layout-sider-children > .ant-menu > li:last-child :not(.user-image) svg {
    position: absolute;
    right: 20px !important;
    top: 50%;
    transform: translate(0, -50%);
}

.app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child .ant-menu-title-content {
    margin-left: 14px !important;
}

.app-main .app-sider.ant-layout-sider-collapsed .ant-layout-sider-children > .ant-menu > li .ant-menu-title-content {
    width: 66px !important;
    left: 0 !important;
    margin-left: 0 !important;
    position: absolute !important;
    top: 0 !important;
}

.app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child .user-image img {
    height: 100%;
    width: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.app-main .app-sider .operator-switch {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 20px;
    color: #fff;
}

.app-main .app-sider .operator-switch svg {
    margin: 0 0 0 5px;
    position: absolute;
}

.app-main .app-sider .operator-switch svg polyline,
.app-main .app-sider .operator-switch svg line {
    stroke: #C4BBFD;
}

.app-main .app-sider .operator-switch > a {
    padding-left: 36px;
    background: none !important;
    position: relative;
    z-index: 2;
}

.app-main .app-sider.ant-layout-sider-collapsed .operator-switch > a {
    text-indent: -99999px;
}

.app-main .app-sider .ant-layout-sider-children > .ant-menu > li:last-child::before {
    content: '';
    width: calc(100% - 40px) !important;
    height: 1px !important;
    background: #8976FA;
    opacity: 1 !important;
    transform: none !important;
    top: -5px !important;
    left: 20px;
    position: absolute;
}

.app-main .app-sider .menu-version {
    line-height: 16px;
    display: flex;
    flex-direction: column;
}

.app-main .app-sider .menu-version span {
    font-size: 10px;
    width: 100%;
    color: #C4BBFD;
}

.ant-tooltip.ant-menu-inline-collapsed-tooltip .menu-version span,
.ant-tooltip.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner svg {
    display: none !important;
}

.app-main,
.app-main .app-layout {
    background: #F8FAFB !important;
}

.app-main .app-layout .app-header {
    padding: 15px 15px !important;
    background: transparent !important;
    height: auto !important;
    position: relative;
}

@media screen and (min-width: 1024px) {
    .app-main .app-layout .app-header {
        padding: 27px 30px 15px !important;
    }
}

.app-main .app-layout .app-header .app-breadcrumb {
    margin: 10px 0 0;
    font-size: 13px;
}

.app-main .app-layout .app-header .app-breadcrumb li:last-child {
    color: #6B54F9 !important;
}

.app-main .app-layout .App.container {
    max-width: 100%;
}

.filter-switches > button.ant-switch,
.filter-switches > .filter-switches-popup button.ant-switch,
.filter-switches > span > button.ant-switch,
.filter-switches > .ant-radio-group > .ant-radio-button-wrapper,
.filter-dropdown .filter-dropdown-overlay button.ant-switch {
    margin: 2.5px !important;
    padding: 0 10px !important;
    background: none !important;
    outline: none !important;
    border: none !important;
    height: 22px !important;
    line-height: 22px !important;
    font-size: 12px !important;
    border-radius: 100px !important;
}

.filter-switches > .ant-radio-group > .ant-radio-button-wrapper {
    display: inline-flex !important;
    align-items: center !important;
    box-shadow: none !important;
}

.filter-switches > .ant-radio-group > .ant-radio-button-wrapper:not(:first-child)::before {
    display: none !important;
}

@media screen and (min-width: 1024px) {
    .filter-switches > button.ant-switch,
    .filter-switches > .filter-switches-popup button.ant-switch,
    .filter-switches > span > button.ant-switch,
    .filter-switches > .ant-radio-group > .ant-radio-button-wrapper,
    .filter-dropdown .filter-dropdown-overlay button.ant-switch {
        padding: 0 12px !important;
        margin: 0 8px 0 0 !important;
        height: 26px !important;
    }
}

.filter-switches > button.ant-switch:last-of-type,
.filter-switches > .filter-switches-popup button.ant-switch:last-of-type,
.filter-switches > .ant-radio-group > .ant-radio-button-wrapper:last-of-type,
.filter-dropdown .filter-dropdown-overlay button.ant-switch {
    margin-right: 0 !important;
}

.filter-switches > button.ant-switch > .ant-switch-handle,
.filter-switches > .filter-switches-popup button.ant-switch > .ant-switch-handle,
.filter-switches > span > button.ant-switch > .ant-switch-handle,
.filter-dropdown .filter-dropdown-overlay button.ant-switch > .ant-switch-handle {
    display: none !important;
}

.filter-switches > button.ant-switch > .ant-switch-inner,
.filter-switches > .filter-switches-popup button.ant-switch > .ant-switch-inner,
.filter-switches > span > button.ant-switch > .ant-switch-inner,
.filter-switches > .ant-radio-group > .ant-radio-button-wrapper > span:not(.ant-radio-button),
.filter-switches > .ant-switch .ant-switch-inner .ant-switch-inner-checked,
.filter-switches > .ant-switch .ant-switch-inner .ant-switch-inner-unchecked,
.filter-switches > .filter-switches-popup .ant-switch .ant-switch-inner .ant-switch-inner-checked,
.filter-switches > .filter-switches-popup .ant-switch .ant-switch-inner .ant-switch-inner-unchecked,
.filter-dropdown .filter-dropdown-overlay .ant-switch > .ant-switch-inner,
.filter-dropdown .filter-dropdown-overlay .ant-switch > .ant-switch-inner .ant-switch-inner-checked,
.filter-dropdown .filter-dropdown-overlay .ant-switch > .ant-switch-inner .ant-switch-inner-unchecked {
    margin: 0 !important;
    font-weight: 600 !important;
    color: #7C7D7D !important;
    text-transform: uppercase !important;
    outline: none !important;
}

.filter-switches > .ant-switch .ant-switch-inner .ant-switch-inner-checked,
.filter-switches > .filter-switches-popup .ant-switch .ant-switch-inner .ant-switch-inner-checked,
.filter-switches > span > .ant-switch .ant-switch-inner .ant-switch-inner-checked,
.filter-dropdown .filter-dropdown-overlay .ant-switch > .ant-switch-inner .ant-switch-inner-checked {
    height: 26px !important;
    display: flex !important;
    align-items: center !important;
}

.filter-switches > .ant-switch.ant-switch-checked .ant-switch-inner,
.filter-switches > .ant-switch .ant-switch-inner,
.filter-switches > .filter-switches-popup .ant-switch.ant-switch-checked .ant-switch-inner,
.filter-switches > .filter-switches-popup .ant-switch .ant-switch-inner,
.filter-switches > span > .ant-switch.ant-switch-checked .ant-switch-inner,
.filter-switches > span > .ant-switch .ant-switch-inner,
.filter-dropdown .filter-dropdown-overlay .ant-switch.ant-switch-checked .ant-switch-inner,
.filter-dropdown .filter-dropdown-overlay .ant-switch .ant-switch-inner {
    padding: 0 !important;
}

.filter-switches > button.ant-switch:hover,
.filter-switches > button.ant-switch.ant-switch-checked,
.filter-switches > .filter-switches-popup button.ant-switch:hover,
.filter-switches > .filter-switches-popup button.ant-switch.ant-switch-checked,
.filter-switches > span > button.ant-switch:hover,
.filter-switches > span > button.ant-switch.ant-switch-checked,
.filter-switches > .ant-radio-group > .ant-radio-button-wrapper:hover,
.filter-switches > .ant-radio-group > .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
.filter-dropdown .filter-dropdown-overlay button.ant-switch:hover,
.filter-dropdown .filter-dropdown-overlay button.ant-switch.ant-switch-checked {
    background: #E9E6FA !important;
}

.filter-switches > button.ant-switch.ant-switch-checked > .ant-switch-inner,
.filter-switches > .filter-switches-popup button.ant-switch.ant-switch-checked > .ant-switch-inner,
.filter-switches > span > button.ant-switch.ant-switch-checked > .ant-switch-inner,
.filter-switches > .ant-radio-group > .ant-radio-button-wrapper.ant-radio-button-wrapper-checked > span:not(.ant-radio-button),
.filter-switches > .ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-checked,
.filter-switches > .filter-switches-popup .ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-checked,
.filter-switches > span > .ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-checked,
.filter-dropdown .filter-dropdown-overlay .ant-switch.ant-switch-checked > .ant-switch-inner,
.filter-dropdown .filter-dropdown-overlay .ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-checked {
    color: #6B54F9 !important;
}

.filter-switches {
    position: relative;
}

.filter-switches .filter-overlay {
    position: absolute !important;
    width: 300px !important;
    right: 0 !important;
    z-index: 9;
    background: #fff !important;
    padding: 4px 25px !important;
    box-shadow: 0 0 15px rgba(107, 84, 249, .2);
    border-radius: 8px;
    margin-top: 5px;
}

.filter-switches .filter-overlay-stop-type {
    position: absolute !important;
    width: 300px !important;
    right: 0 !important;
    z-index: 9;
    padding: 8px 0 8px 8px !important;
    background: #fff !important;
    box-shadow: 0 0 15px rgba(107, 84, 249, .2);
    border-radius: 8px;
    margin-top: 5px;
}

.filter-switches .filter-overlay-stop-type .ant-select .ant-select-selector {
    height: auto !important;
    border-radius: 0 !important;
    box-shadow: none !important;
}

.filter-switches .filter-overlay.overlay-center,
.filter-switches .filter-overlay-stop-type.overlay-center {
    left: 50%;
    transform: translate(-50%, 0);
}

.filter-switches .icon-button {
    color: #7C7D7D !important;
    background: none !important;
    height: 26px !important;
    padding: 0 12px !important;
}

.filter-switches .icon-button:hover,
.filter-switches .icon-button.ant-dropdown-open {
    color: #6B54F9 !important;
    background: #E9E6FA !important;
}

.points-filter-options .filter-switches {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    grid-gap: 8px;
    gap: 8px;
}

.points-filter-options .filter-switches > .filter-switches-popup button,
.points-filter-options .filter-switches > button {
    margin: 0 !important;
}

.points-filter-options .filter-switches > .filter-switches-popup {
    position: relative;
}

.filter-options-main,
.page-main-controls {
    margin-bottom: 25px;
}

.page-main-controls .page-sec-controls {
    grid-gap: 10px;
    gap: 10px;
    flex-wrap: wrap;
}

.page-main-controls.top-float,
.filter-options-main.top-float {
    margin-bottom: 10px;
}

.page-main-controls.top-float .page-sec-controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

@media screen and (min-width: 1180px) {
    .page-main-controls.top-float {
        position: absolute;
        top: 25px;
        right: 30px;
    }
}

@media screen and (min-width: 1024px) {
    .filter-options-main.top-float {
        position: absolute;
        top: 25px;
        right: 30px;
    }

    .App.publicContainer .filter-options-main.top-float {
        top: 20px !important;
    }

    .App.publicContainer .Timetable {
        margin-top: 20px !important;
    }
}

@media screen and (max-width: 1023px) {
    .filter-options-main {
        flex-direction: column !important;
        align-items: flex-start !important;
        grid-gap: 15px !important;
        gap: 15px !important;
    }

    .filter-options-main > div {
        width: 100%;
    }
}

.filter-options-main .search-filter input,
.filter-options-main .search-filter .input-group-text {
    border: none !important;
    background: none !important;
    outline: none !important;
    border-radius: 50px !important;
    box-shadow: none !important;
}

.filter-options-main .search-filter,
.filter-calendar > .ant-picker,
.filter-options-main .ant-select .ant-select-selector {
    background: #fff !important;
    border-radius: 50px !important;
    box-shadow: 0 0 10px rgba(107, 84, 249, .08) !important;
}

.filter-options-main .search-filter input {
    font-size: 12px !important;
    font-weight: 500 !important;
    padding: 0 5px 0 15px !important;
}

.filter-options-main .ant-select .ant-select-selector {
    height: 28px;
}

.filter-options-main .ant-select .ant-select-selector input {
    font-size: 12px !important;
    height: auto !important;
}

.filter-options-main .search-filter .input-group-text {
    padding: 0 10px 0 0 !important;
    color: #B3B3B3 !important;
}

.filter-options-main .search-filter input:focus + .input-group-text {
    color: #6B54F9 !important;
}

.filter-options-main .ant-select .ant-select-selector {
    border: none;
}

.StopManagerContainer .filter-options-main .search-filter {
    border: 1px solid transparent;
}

.StopManagerContainer .filter-options-main .search-filter.search-filter-filled {
    border-color: #6B54F9;
}

.filter-options-main .w-separator {
    padding-right: 15px;
    margin-right: 15px;
    position: relative;
}

.filter-options-main .w-separator:after {
    content: '';
    position: absolute;
    background: #DBD9FB;
    width: 1px;
    height: 80%;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}

.filter-calendar > .ant-picker {
    border: none !important;
}

.filter-calendar > .ant-picker input {
    font-size: 13px;
}

.filter-calendar > .ant-picker input::placeholder {
    color: #6c6c6c !important;
}

.filter-dropdown .icon-button.btn-filled svg {
    width: 13px;
    height: 13px;
}

.filter-dropdown .icon-button.btn-filled svg path,
.filter-dropdown .icon-button.btn-filled:hover svg path {
    fill: none !important;
}

.filter-dropdown .icon-button.btn-filled svg path,
.filter-dropdown .icon-button.btn-filled svg line {
    stroke: #999;
}

.filter-dropdown .icon-button.btn-filled:hover,
.filter-dropdown .icon-button.ant-dropdown-open {
    background: #6B54F9 !important;
    outline: none !important;
    color: #fff !important;
}

.filter-dropdown .icon-button.btn-filled {
    outline: none !important;
}

.filter-dropdown .icon-button:hover svg path,
.filter-dropdown .icon-button:hover svg line,
.filter-dropdown .icon-button.ant-dropdown-open svg path,
.filter-dropdown .icon-button.ant-dropdown-open svg line {
    stroke: #fff;
}

.filter-dropdown-overlay > .ant-radio-group,
.filter-dropdown .filter-dropdown-overlay {
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 0 15px rgba(107, 84, 249, .15);
    border-radius: 8px;
    overflow: hidden;
    margin-top: 4px;
    min-width: 150px;
}

.filter-dropdown-overlay > .ant-radio-group .ant-radio-button-wrapper,
.filter-dropdown .filter-dropdown-overlay button.ant-switch {
    border: none;
    border-radius: 0 !important;
    background: none !important;
    padding-inline: 20px !important;
    height: 40px !important;
    line-height: 40px !important;
    border-bottom: 1px solid #f0edff !important;
}

.filter-dropdown .filter-dropdown-overlay button.ant-switch {
    display: flex;
    align-items: center;
}

.filter-dropdown .filter-dropdown-overlay .ant-switch > .ant-switch-inner {
    width: 100%;
}

.filter-dropdown .filter-dropdown-overlay .ant-switch > .ant-switch-inner .ant-switch-inner-checked,
.filter-dropdown .filter-dropdown-overlay .ant-switch > .ant-switch-inner .ant-switch-inner-unchecked {
    font-size: 14px !important;
    font-weight: 400 !important;
    text-transform: none !important;
    color: rgba(0, 0, 0, 0.88) !important;
    width: 100% !important;
    display: block !important;
    text-align: left !important;
}

.filter-dropdown-overlay > .ant-radio-group .ant-radio-button-wrapper:last-of-type,
.filter-dropdown .filter-dropdown-overlay button.ant-switch:last-of-type {
    border-bottom: none !important;
}

.filter-dropdown-overlay > .ant-radio-group .ant-radio-button-wrapper:before {
    display: none !important;
}

.filter-dropdown-overlay > .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    color: #6B54F9 !important;
    background: #E9E6FA !important;
}

.filter-dropdown .filter-dropdown-overlay.filter-slider {
    min-width: 350px;
    padding: 10px 30px;
}

.icon-button {
    border-radius: 50px !important;
    background: #E9E6FA !important;
    color: #6B54F9 !important;
    text-transform: uppercase !important;
    border: none !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    padding: 0 15px !important;
    line-height: 16px !important;
    display: inline-flex !important;
    align-items: center !important;
    box-shadow: none !important;
    text-shadow: none !important;
    height: 28px !important;
    outline: none !important;
}

.icon-button.btn-icon-only {
    padding: 0 !important;
    width: 28px !important;
    justify-content: center !important;
}

.icon-button:hover,
.ant-btn-primary.icon-button.active {
    background: #6B54F9 !important;
    color: #fff !important;
}

.icon-button .anticon {
    line-height: 10px !important;
}

.icon-button.inline-icon {
    grid-gap: 6px;
    gap: 6px;
}

.icon-button.inline-icon svg {
    height: 12px;
}

.icon-button.inline-icon svg g {
    stroke: #6B54F9 !important;
}

.icon-button.inline-icon:hover svg g {
    stroke: #fff !important;
}

.icon-button.btn-filled {
    background: #fff !important;
    box-shadow: 0 0 10px rgba(107, 84, 249, .10) !important;
    grid-gap: 8px !important;
    gap: 8px !important;
    flex: none;
}

.icon-button.btn-filled.btn-fade {
    background: rgba(255, 255, 255, .5) !important;
    color: #888 !important;
}

.icon-button.btn-filled.btn-fade:hover {
    color: #fff !important;
}

.icon-button.btn-filled.btn-fade svg path {
    fill: #888 !important;
}

.icon-button.btn-filled > span.ant-btn-icon {
    margin-right: 0 !important;
}

.icon-button.btn-filled > span:not(.ant-btn-icon) {
    margin-left: 0;
}

.icon-button.btn-filled svg path,
.icon-button.print svg path,
.mapboxgl-ctrl.mapboxgl-ctrl-group .ant-btn.transfer-icon.icon-button.btn-filled.btn-fade:hover svg path,
.mapboxgl-ctrl.mapboxgl-ctrl-group .ant-btn.icon-button.btn-filled.transfer-icon:hover svg path {
    fill: #6B54F9 !important;
}

.icon-button.print svg {
    width: 16px;
    height: 16px;
}

.icon-button.btn-filled:hover {
    background: #6B54F9 !important;
}

.icon-button.btn-filled:hover svg path,
.icon-button.print:hover svg path {
    fill: #fff !important;
}
.mapboxgl-ctrl.mapboxgl-ctrl-group .ant-btn.transfer-icon.icon-button.btn-filled.btn-fade,
.mapboxgl-ctrl.mapboxgl-ctrl-group .ant-btn.icon-button.btn-filled.transfer-icon {
    background: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    text-align: center;
    display: flex !important;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.mapboxgl-ctrl.mapboxgl-ctrl-group .ant-btn.transfer-icon.icon-button.btn-filled.btn-fade svg path,
.mapboxgl-ctrl.mapboxgl-ctrl-group .ant-btn.icon-button.btn-filled.transfer-icon svg path {
    fill: rgba(0, 0, 0, 0.4) !important;
}
.icon-check-success,
.icon-button.btn-success {
    background: #7ED668 !important;
    color: #fff !important;
}

.icon-check-success {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}

.icon-check-success svg {
    width: 12px;
    height: 12px;
}

.icon-button.btn-success:hover {
    background: #55be3b !important;
}

.icon-button.btn-success svg path,
.icon-button.btn-success:hover svg path {
    fill: none !important;
    stroke: #fff !important;
}

.icon-button svg.spinning path {
    fill: #6B54F9 !important;
    stroke: #6B54F9 !important;
    margin: 0 !important;
}

.icon-button.btn-success svg.spinning path {
    fill: #fff !important;
    stroke: #fff !important;
}

.icon-button.btn-error {
    color: #FF4D4D !important;
}

.icon-button.btn-error svg path {
    fill: #FF4D4D !important;
}

.icon-button.btn-error:hover {
    background: #FF4D4D !important;
    color: #fff !important;
}

.icon-button.sb-add-btn {
    float: right !important;
    margin: 0 -10px 0 0 !important;
}

.icon-button.btn-outline {
    border: 1px solid #6a54f942 !important;
    background: none !important;
    height: 25px !important;
    padding: 0 12px !important;
    font-size: 11px !important;
    align-items: center !important;
}

.icon-button.btn-outline:hover {
    background: #6B54F9 !important;
}
.btn-secondary.icon-button.btn-outline {
    border-radius: 5px !important;
    height: 32px !important;
    font-size: 14px !important;
    color: #555 !important;
}
.btn-secondary.icon-button.btn-outline:hover {
    color: #fff !important;
}
.btn-secondary.icon-button.btn-outline:hover svg path {
    fill: #fff;
}
.btn-secondary.icon-button.btn-outline.btn-lg,
body.busable-app .btn-secondary.icon-button.btn-outline.btn-lg {
    height: 52px !important;
    font-size: 15px !important;
    text-transform: none !important;
    width: 100%;
    font-weight: 500 !important;
    color: #666 !important;
    background: #fff !important;
    border: 1px solid #6a54f942 !important;
}
.btn-secondary.icon-button.btn-outline.btn-lg:hover,
body.busable-app .btn-secondary.icon-button.btn-outline.btn-lg:hover {
    color: #6B54F9 !important;
    background: #E9E6FA !important;
}
.btn-secondary.icon-button.btn-outline.btn-lg svg path {
    fill: #C1C5CE !important;
}
.btn-secondary.icon-button.btn-outline.btn-lg.icon-dark svg path {
    fill: #999 !important;
}
.btn-secondary.icon-button.btn-outline.btn-lg:hover svg path {
    fill: #6B54F9 !important;
}
.btn-secondary.icon-button.btn-outline.btn-lg.LoaderButton svg {
    margin-right: 8px;
}
.share-btn-wrap .icon-button.btn-outline {
    border-radius: 50px !important;
}

.icon-button.btn-sm {
    font-size: 11px !important;
    padding: 0 10px !important;
    height: 21px !important;
    line-height: 11px !important;
}

.icon-button.btn-sm svg {
    width: 11px;
    height: 11px;
}

.icon-button.btn-xs {
    font-size: 12px !important;
    padding: 0 10px !important;
    height: 28px !important;
    line-height: 11px !important;
    text-transform: none !important;
    margin: 0 15px 0 0;
}

.icon-button.btn-xs svg {
    width: 8px;
    height: 8px;
}

.icon-button.btn-filled.btn-error {
    background: #FFE6E6 !important;
    box-shadow: none !important;
}

.icon-button.btn-filled.btn-error.active,
.icon-button.btn-filled.btn-error:hover {
    background: #FF4D4D !important;
}
.icon-button.btn-filled.btn-error i {
    background: #FF4D4D !important;
    color: #fff !important;
    font-style: normal !important;
    border-radius: 20px;
    padding: 1px 2px;
}
.icon-button.btn-filled.btn-error:hover i,
.icon-button.btn-filled.btn-error.active i {
    background: #fff !important;
    color: #FF4D4D !important;
}
.app-sider.ant-layout-sider-collapsed {
    /* overflow-y: hidden; */
}

@media screen and (max-width: 1023px) {
    .app-sider.ant-layout-sider-collapsed,
    .app-sider.ant-layout-sider-collapsed .ant-layout-sider-trigger {
        width: 0 !important;
        min-width: 0 !important;
    }

    .app-main .app-sider.ant-layout-sider-collapsed + .app-layout,
    .app-main .app-sider + .app-layout {
        margin-left: 0 !important;
    }

    .menu-sm-open {
        position: absolute;
        z-index: 999;
        right: 20px;
        top: 20px;
        padding: 8px 10px !important;
        background: #DBD9FB !important;
        color: #6B54F9 !important;
        border: none !important;
        box-shadow: none !important;
        line-height: 10px !important;
    }

    .menu-sm-open:hover,
    .menu-sm-open.menu-expanded {
        color: #fff !important;
        background: #6B54F9 !important;
    }
}

@media screen and (min-width: 1024px) {
    .menu-sm-open {
        display: none !important;
    }

    .filter-options-main .w-separator {
        padding-right: 25px;
        margin-right: 25px;
    }
}

.App.container .list-group .list-group-item,
.card-main:not(.card-pass) {
    background: #fff !important;
    border-radius: 20px !important;
    border: none !important;
    box-shadow: 0 0 15px rgba(107, 84, 249, .15) !important;
    margin-bottom: 10px !important;
    padding: 10px 20px !important;
}
.CardTable .ant-table {
    background: none;
}
.CardTable .ant-table .ant-table-tbody > tr {
    box-shadow: none !important;
}
.CardTable .ant-table .ant-table-tbody > tr td {
    background: #fff !important;
    border-top: 1px solid #f0f0f0 !important;
    border-bottom: 1px solid #f0f0f0 !important;
}
.CardTable .ant-table .ant-table-tbody {
    z-index: 1;
    position: relative;
}
.CardTable.ReviewTable .ant-table .ant-table-tbody > tr td {
    border: none !important;
    padding: 0;
}
.CardTable.ReviewTable .ant-table table {
    border-spacing: 0 20px;
}
.CardTable.ReviewTable .ant-table .ant-table-thead,
.CardTable.ReviewTable .ant-table .ant-card.card-main.card-info .ant-card-head:before,
.CardTable.ReviewTable .ant-table .ant-card.card-main.card-info .ant-card-head:after,
.CardTable.ReviewTable .ant-table .ant-card.card-main.card-info .ant-card-body:before,
.CardTable.ReviewTable .ant-table .ant-card.card-main.card-info .ant-card-body:after {
    display: none;
}
.CardTable.ReviewTable .ant-table .ant-card.card-main.card-info .ant-card-body > .ant-row:not(:last-of-type) {
    margin-bottom: 20px;
}
.ant-card.card-main.card-info .CardHeaderInfo {
    font-size: 14px;
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    font-weight: 400;
    color: #888;
}
.ant-card.card-main.card-info .CardHeaderInfo label {
    color: #888;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}
.CardTable .ant-table .ant-table-tbody > tr td:first-child {
    border-radius: 20px 0 0 20px !important;
}
.CardTable .ant-table .ant-table-tbody > tr td:last-child {
    border-radius: 0 20px 20px 0 !important;
}
.CardTable .ant-table .ant-table-tbody > tr.dragged-row td {
    background: #FAFCFC !important;
}
.CardTable .ant-table .ant-table-tbody > tr td .ant-btn.ant-btn-link {
    outline: none !important;
    font-weight: 500 !important;
    color: rgba(0, 0, 0, 0.88) !important;
    box-shadow: none !important;
    border: none !important;
}
.CardTable .ant-table .ant-table-tbody > tr td .ant-btn.ant-btn-link span {
    outline: none !important;
}
.CardTable .ant-table .ant-table-tbody > tr td .ant-btn.ant-btn-link:hover {
    color: #6B54F9 !important;
}
.CardTable .ant-table .ant-table-tbody > tr.dragged-row,
.CardTable .ant-table .ant-table-tbody > tr.droppable-row {
    background: none !important;
}
.CardTable .ant-table .ant-table-tbody > tr.droppable-row td {
    background: #E8E7FC !important;
}
.CardTable .ant-table table {
    width: 100% !important;
    border-spacing: 0 10px;
    margin-top: -10px;
}
.CardTable .ant-table .ant-table-measure-row {
    display: none !important;
}
.CardTable .ant-table-wrapper .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    display: none !important;
}
.CardTable .ant-table .ant-table-thead > tr {
    background: none !important;
}
.CardTable .ant-table .ant-table-thead > tr > th {
    background: none !important;
    border-bottom: none !important;
    padding-bottom: 5px !important;
}
.CardTable .ant-table .ant-table-thead > tr > th:before {
    display: none !important;
}
.App.container .list-group .list-group-item {
    color: #666;
}

@media screen and (max-width: 991px) {
    .App.container .list-group .list-group-item .col-sm-4 {
        padding: 4px 0;
    }
}

#busable-embed .SchoolStopList ul,
.SchoolStopList > ul {
    list-style: none !important;
    padding: 0 !important;
    margin: 0 !important;
    display: flex !important;
    flex-wrap: wrap !important;
    grid-gap: 16px !important;
    gap: 16px !important;
}

.SchoolStopList li,
.SchoolStopList.SchoolViewList li,
#busable-embed .SchoolStopList.SchoolViewList ul li {
    width: 100% !important;
    border: none !important;
    padding: 0 !important;
}

@media screen and (min-width: 992px) {
    .SchoolStopList.SchoolViewGrid li,
    #busable-embed .SchoolStopList.SchoolViewGrid ul li {
        width: calc(50% - 8px) !important;
    }
}

@media screen and (min-width: 1400px) {
    .SchoolStopList.SchoolViewGrid li,
    #busable-embed .SchoolStopList.SchoolViewGrid ul li {
        width: calc(33.33% - 11px) !important;
    }
}

#busable-embed .SchoolStopList ul li a,
.SchoolStopList li a {
    color: #565f71 !important;
    font-size: 17px !important;
    font-weight: 400 !important;
    width: 100% !important;
    background: #fff !important;
    border-radius: 20px !important;
    padding: 20px 22px !important;
    display: flex !important;
    grid-gap: 20px !important;
    gap: 20px !important;
    align-items: center !important;
    line-height: 20px !important;
    border: none !important;
    box-shadow: 0 0 15px rgba(107, 84, 249, .1) !important;
    text-align: left !important;
}

.SchoolStopList li a:hover,
#busable-embed .SchoolStopList ul li a:hover {
    color: #6B54F9 !important;
    box-shadow: 0 0 15px rgba(107, 84, 249, .2) !important;
}

.SchoolStopList li a svg,
#busable-embed .SchoolStopList ul li a svg {
    opacity: 0.6 !important;
}

.SchoolStopList li a:hover svg,
#busable-embed .SchoolStopList ul li a:hover svg {
    opacity: 1 !important;
}

/* #busable-embed .SchoolStopList ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
#busable-embed .SchoolStopList ul li {
    border-bottom: 2px solid #d4ecfb;
    padding: 7px 0;
}
#busable-embed .SchoolStopList ul li:last-child {
    border-bottom: none;
}
#busable-embed .SchoolStopList ul li a {
    font-size: 16px;
    font-weight: 500;
} */
.card-main.mb-5 {
    margin-bottom: 3rem !important;
}

.card-main.pa-3 {
    padding: 30px 25px !important;
}

.card-main.no-pad,
.card-main.ant-card {
    padding: 0 !important;
}

.card-main.ant-card {
    margin: 0 !important;
    height: 100%;
}

.card-main p {
    color: #666;
    font-size: 15px;
}

.card-main.ant-card .ant-card-head {
    border-bottom: none !important;
    padding-top: 20px !important;
    min-height: auto !important;
    margin: 0 !important;
}

.card-main.ant-card .ant-card-head .ant-card-head-title,
.card-main .card-row-header h3 {
    font-size: 18px !important;
    overflow: visible !important;
    white-space: unset !important;
    line-height: 24px !important;
    font-weight: 600;
}

.card-main.ant-card .ant-card-body {
    padding-top: 20px !important;
}

.card-main.ant-card.body-pad-0 .ant-card-body {
    padding: 0 !important;
}

.card-main.card-stats h4 {
    font-size: 13px !important;
    color: #7C7D7E !important;
    text-transform: uppercase !important;
    margin: 0 !important;
}

.card-main.card-stats .ant-row .ant-col:first-of-type {
    padding-right: 30px !important;
}

.card-main.card-stats.card-stats-flex .ant-card-body {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    justify-content: flex-start;
}
.card-main.card-stats.card-stats-flex .ant-card-body:before {
    display: none;
}

.card-main.card-stats .ant-row .ant-col:first-of-type:after {
    content: "";
    background: #DBD9FB !important;
    width: 1px;
    height: 49px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}

.card-main.card-stats:not(.card-stats-profit) .ant-row .ant-col:last-of-type {
    padding-left: 30px !important;
}

.card-main.card-stats.card-stats-profit .ant-row .ant-col:last-of-type .text-lg,
.card-main.card-stats.card-stats-profit .ant-card-body > div:last-of-type .text-lg {
    color: #52C41A !important;
}
.card-main.card-stats.card-stats-profit .ant-row .ant-col .text-lg.text-light {
    color: #D9D3FD !important;
    padding-top: 14px;
}
.card-main.card-stats .ant-row .ant-col .text-lg span {
    font-size: 32px;
}
@media screen and (max-width: 1200px) {
    .card-main.card-stats h4 {
        font-size: 12px !important;
    }
    .card-main.card-stats .ant-row .ant-col:first-of-type {
        padding-right: 15px !important;
    }
    .card-main.card-stats:not(.card-stats-profit) .ant-row .ant-col:last-of-type {
        padding-left: 15px !important;
    }
    .card-main.card-stats .ant-card-body {
        padding: 15px 20px !important;
    }
    .card-main.card-stats .ant-row .ant-col .text-lg {
        font-size: 38px !important;
    }
    .card-main.card-stats .ant-row .ant-col .text-lg span {
        font-size: 28px !important;
    }
}
.card-main.card-stats .ant-card-body svg {
    flex-shrink: 0;
}
.card-main.card-stats.card-error .ant-row .ant-col .text-lg {
    color: #FF5555 !important;
}
.card-main.card-stats.card-success .ant-row .ant-col .text-lg {
    color: #51B238 !important;
}
.card-main.card-stats.card-warning .ant-row .ant-col .text-lg {
    color: #F29F18 !important;
}
.card-main.card-stats.card-links {
    cursor: pointer;
    border: 1px solid transparent !important;
}
.card-main.card-stats.card-links.active {
    background: #F1F0FB !important;
    box-shadow: none !important;
    border: 1px solid #E9E6FA !important;
}
.card-main.CardMenu {
    overflow: hidden;
}
.card-main.CardMenu .ant-card-body {
    display: flex;
}
.card-main.CardMenu .ant-card-body .profile-summary {
    padding: 0;
}
.card-main.CardMenu .ant-card-body .profile-summary h2 {
    margin: 0 !important;
}
.card-main.CardMenu .ant-menu.profile-menu {
    display: flex;
}
.card-main.CardMenu .ant-menu.profile-menu .ant-menu-item {
    border-top: none !important;
    border-left: 1px solid rgba(5, 5, 5, 0.06);
    height: 60px !important;
}
.card-main.CardMenu .ant-menu.profile-menu .ant-menu-item:last-of-type {
    border-radius: 0 !important;
}
.card-main.CardMenu .ant-menu.profile-menu .ant-menu-item span {
    font-size: 15px;
}
.card-main.CardMenu .ant-menu.profile-menu .ant-menu-item:first-of-type {
    border-left: none;
}
.card-main .card-row-header {
    padding-bottom: 20px;
}

.card-main .card-row-header h3 {
    margin: 0 !important;
}

.card-main .card-row-header > .ant-col:first-of-type {
    grid-gap: 20px !important;
    gap: 20px !important;
}

.card-main .card-row-header > .ant-col:not(:first-of-type),
.card-main .card-row-content > .ant-col:not(:first-of-type) {
    text-align: center !important;
}

.card-main .card-row-header > .ant-col.text-left,
.card-main .card-row-content > .ant-col.text-left {
    text-align: left !important;
}

.card-main .card-row-header > .ant-col.text-right,
.card-main .card-row-content > .ant-col.text-right {
    text-align: right !important;
}

.card-main .card-row-header > .ant-col > span {
    color: #ACACAC !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px;
    display: inline-flex;
}

.card-main .card-row-content {
    position: relative;
    padding: 10px 0;
}

.card-main .card-row-content > .ant-col:first-of-type {
    display: flex !important;
    flex-direction: column !important;
    line-height: 18px;
    word-wrap: break-word;
}

.card-main .card-row-content > .ant-col:first-of-type strong {
    font-weight: 500 !important;
}

.card-main .card-row-content > .ant-col:first-of-type span {
    color: #666 !important;
}
.card-main a.table-row-link {
    color: rgba(26, 26, 26, 0.9);
}

/* .card-main .card-row-content.border-full:last-of-type {
    padding-bottom: 0 !important;
} */
.card-main .card-row-content:after {
    content: "";
    background: #EFEDFD;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.card-main .card-row-content.border-full:after {
    width: calc(100% + 48px);
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}
.card-main .card-row-content.border-full.no-border:after {
    display: none !important;
}

.card-main .card-row-content .row-col-actions {
    display: flex !important;
    grid-gap: 12px !important;
    gap: 12px !important;
    justify-content: center !important;
}

.modal-info > .ant-row > .ant-col,
.card-main.card-info .ant-card-body > .ant-row > .ant-col,
.card-main .ant-card-body > .ant-form .ant-row .ant-col .ant-form-item .ant-row.ant-form-item-row {
    display: flex !important;
    flex-direction: column !important;
    line-height: 22px !important;
}
.card-main .ant-card-body > .ant-form .ant-row .ant-col .ant-form-item {
    margin-bottom: 0;
}
.card-main .ant-card-body > .ant-form .ant-row .ant-col .ant-form-item .ant-form-item-label {
    text-align: left !important;
    padding: 0 !important;
}
.card-main .ant-card-body > .ant-form .ant-row .ant-col .ant-form-item .ant-form-item-label label,
.card-main .ant-card-body > .ant-form .ant-row .ant-col .ant-form-item .ant-form-item-control-input {
    height: auto !important;
    min-height: auto !important;
}
.card-main .ant-card-body > .ant-form .ant-row .ant-col .ant-form-item .ant-form-item-label label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    display: none !important;
}
.card-main .ant-card-body > .ant-form .ant-row .ant-col .ant-form-item .ant-form-item-label label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
    visibility: visible;
}
.modal-info > .ant-row > .ant-col.flex-row {
    flex-direction: row !important;
    grid-gap: 10px;
    gap: 10px;
}
.modal-info > .ant-row > .ant-col label,
.card-main .ant-card-body > .ant-row .ant-col label,
.card-main .ant-card-body > .ant-form .ant-row .ant-col label {
    font-size: 14px !important;
    color: #888 !important;
    margin: 0 !important;
}

.modal-info > .ant-row > .ant-col strong,
.card-main.card-info .ant-card-body > .ant-row > .ant-col strong,
.card-main .ant-card-body > .ant-form .ant-row .ant-col .ant-form-item .ant-form-item-control strong {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: rgba(26, 26, 26, 0.9);
    word-wrap: break-word;
}
.modal-info.text-light > .ant-row > .ant-col strong,
.card-main.card-info.text-light .ant-card-body > .ant-row > .ant-col strong {
    font-weight: 400 !important;
    color: rgba(26, 26, 26, 0.9);
}
.modal-info > .ant-row > .ant-col p,
.card-main.card-info .ant-card-body > .ant-row > .ant-col p {
    font-weight: 500;
    margin-bottom: 10px;
}

.modal-info > .ant-row > .ant-col a:not(.rdw-dropdown-selectedtext),
.card-main.card-info .ant-card-body > .ant-row > .ant-col a {
    font-size: 15px;
    font-weight: 500;
    color: #6B54F9;
    text-decoration: underline;
    display: inline-block;
    width: -moz-fit-content;
    width: fit-content;
}
.modal-info-rows {
    padding: 3px 0;
}
.modal-info-rows .ant-row {
    border-bottom: 1px solid #F1F1F2;
    padding: 5px 24px;
}
.modal-info-rows .ant-row:last-of-type {
    border-bottom: none;
}
.modal-info-rows .info-col-status {
    font-weight: 500;
}
.modal-info-rows .info-col-status.success {
    color: #52C41A;
}
.modal-info-rows .info-col-status.error {
    color: #FF4D4F;
}
.modal-info .ant-list {
    overflow: hidden;
}
.modal-info .ant-list .ant-list-item {
    padding: 0 6px 0 0;
}
.modal-info .ant-list .ant-list-item > div {
    padding: 10px 20px;
    width: 100%;
}
.modal-info .ant-list .ant-list-item:hover {
    background: #F8F7FE;
}
.modal-info .ant-list .ant-list-item:hover > div {
    color: #442ed3;
}
.modal-info .ant-list .ant-list-item .ant-list-item-action {
    margin-left: 0;
}
.modal-info .ant-list .ant-list-item .ant-list-item-action .btn-primary {
    background: none !important;
    border: none !important;
    padding: 2px !important;
}
.modal-info .ant-list .ant-list-item .ant-list-item-action .btn-primary svg path {
    fill: #BEBEBE;
}
.ant-modal-body.modal-info.modal-body-fixed {
    overflow-x: auto;
    max-height: calc(100vh - 180px);
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 8px;
    margin-top: 24px;
}
.collapse-body-0 .ant-collapse-item .ant-collapse-content-box {
    padding: 0 !important;
}
.card-main.card-col .ant-card-body > .ant-row:not(.card-row-header) > .ant-col {
    border-right: 1px solid #EFEDFD;
}

.card-main.card-col .ant-card-body > .ant-row:not(.card-row-header) > .ant-col:last-of-type {
    border: none !important;
}

.card-main.row-separator > .ant-card-body > .ant-row:first-of-type:before,
.card-main.row-separator > .ant-card-body > .ant-form > .ant-row:first-of-type:before {
    display: none;
}

.card-main.row-separator > .ant-card-body > .ant-row:before,
.card-main.row-separator > .ant-card-body > .ant-form > .ant-row:before {
    content: '';
    width: calc(100% - 30px);
    height: 1px;
    background: #EFEDFD;
    margin: 30px auto 0;
}
.card-main.row-separator > .ant-card-body > .ant-form > .ant-row:before {
    width: calc(100%);
}
.card-main.school-card .school-logo {
    width: 100%;
    height: 165px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F8FAFB;
    border: 1px solid #EFEDFD;
    border-radius: 8px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, .04);
}

.card-main.school-card .school-logo img {
    height: 80%;
}

.card-main.school-card .ant-card-body > .ant-row > .ant-col {
    flex-direction: row !important;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    font-size: 15px;
    font-weight: 500;
    color: rgba(26, 26, 26, 0.9);
}

.card-main.school-card .ant-card-body > .ant-row > .ant-col > svg path {
    fill: #C1C5CE;
}

.card-main.school-card .ant-card-body > .ant-row > .ant-col > a {
    color: #6B54F9;
}

.card-main.school-card .ant-card-body > .ant-row > .ant-col > a:hover {
    text-decoration: underline !important;
}

.card-main.card-pass {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 275px;
    min-width: 300px;
}

.card-main.card-pass .pass-year-sm {
    background: #fff;
    text-align: center;
    font-size: 24px !important;
    font-weight: bold;
    padding: 10px 0;
}

.card-main.card-pass .pass-body {
    background: #fff;
    margin: 15px 0 0;
    padding: 5px 18px;
}

.modal-info > .ant-row > .ant-col,
.card-main.card-info .ant-card-body > .ant-row > .ant-col {
    font-size: 16px;
}

.card-main.card-pass .ant-card-body > .ant-row > .ant-col strong {
    font-size: 18px !important;
}

.card-main.card-pass .ant-card-body > .ant-row > .ant-col strong.text-bold {
    font-weight: 600 !important;
}

.card-main.card-pass .pass-body .ant-qrcode {
    border: 2px solid #654595;
    margin: 10px 0;
    padding: 0 !important;
    width: 100px !important;
    height: 94px !important;
    border-radius: 3px;
}

.card-main.card-pass .pass-body .ant-qrcode canvas {
    width: 90px !important;
    height: 90px !important;
}

.cards-wrap {
    display: flex !important;
    flex-wrap: wrap !important;
    grid-gap: 10px !important;
    gap: 10px !important;
}

.cards-wrap .card-main {
    width: 100% !important;
    margin: 0 !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

@media screen and (min-width: 1024px) {
    .cards-wrap .card-main:not(.w-100) {
        width: calc(50% - 5px) !important;
    }
}

.App.container .list-group .list-group-item.active {
    background: #C4BBFD !important;
    font-weight: 500 !important;
    color: #262626 !important;
}

.App.container .StopListContainer .list-group .list-group-item,
.App.container .DuplicateStopList.list-group .list-group-item {
    margin-bottom: 6px !important;
    padding: 10px 12px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}

.App.container .list-group .list-group-item.mb-5 {
    margin-bottom: 36px !important;
}

.App.container .DuplicateStopList.list-group > .list-group-item {
    cursor: pointer;
}

.App.container .StopListContainer .list-group .list-group-item .route-step-name {
    padding-left: 8px !important;
}

.App.container .list-group .list-group-item > .row:not(.align-items-start) {
    align-items: center !important;
}

.btn-icon-control {
    background: none !important;
    border: none !important;
    margin: 0 !important;
    outline: none !important;
}

.btn-icon-control:hover svg path {
    fill: #8976FA !important;
}

.btn-icon-control.btn-delete:hover svg path {
    fill: #dd2a2d !important;
}

.App.container .DuplicateStopList.list-group .list-group-item.active .icon-button.btn-sm svg path,
.App.container .list-group .list-group-item.active svg path {
    fill: #fff !important;
}

.App.container .DuplicateStopList.list-group .list-group-item.active svg path {
    fill: #6B54F9 !important;
}

.btn-icon-control:focus {
    box-shadow: none !important;
}

.App.container .list-group .list-group-header {
    padding: 0 20px 15px !important;
}
.App.container .list-group .list-group-header,
.CardTable .ant-table .ant-table-thead tr th,
.CardTable .ant-table .ant-table-column-title {
    color: #989898;
    font-weight: 600 !important;
    font-size: 12px !important;
    text-transform: uppercase;
}
.CardTable .ant-table-wrapper .ant-table.ant-table-middle .ant-table-cell,
.CardTable .ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody>tr>td {
    padding: 16px;
}

@media screen and (max-width: 991px) {
    .App.container .list-group .list-group-header {
        display: none !important;
    }
}

@media screen and (max-width: 1023px) {
    .App.container .list-group .list-group-header {
        flex-wrap: nowrap !important;
    }

    .card-main {
        flex-direction: column !important;
        align-items: flex-start !important;
        grid-gap: 15px !important;
        gap: 15px !important;
    }

    .ShiftBat .list-group-item .RouteTitle.flex-nowrap {
        flex-wrap: wrap !important;
    }

    .ShiftBat .list-group-item .RouteTitle.flex-nowrap .RouteInfo {
        padding: 10px 0 5px !important;
        width: 100% !important;
    }

    .ShiftBat .list-group-item .simple-tags {
        justify-content: flex-start !important;
    }

    .ShiftBat .list-group-item .sb-controls {
        justify-content: flex-end !important;
        position: absolute;
        top: 23px;
        right: 0;
    }
}

.simple-tags .ant-tag {
    margin: 0 !important;
    font-weight: 500 !important;
    border-radius: 50px !important;
}
.tag-lg {
    font-size: 16px;
    font-weight: 600;
    border-radius: 50px;
    border: none;
}
.tag-lg.ant-tag.ant-tag-error {
    color: #FF5555;
    background: #FFECEC;
    padding: 5px 12px;
}
.tag-lg.ant-tag.ant-tag-success {
    color: #51B238;
    background: #E5F3E1;
    padding: 5px 12px;
}
.tag-lg.ant-tag.ant-tag-warning {
    color: #F29F18;
    background: #FDF1DC;
    padding: 5px 12px;
}
.info-text-wrap {
    grid-gap: 8px;
    gap: 8px;
    margin-top: 4px;
}

.info-text-wrap .icon-info-text {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
    font-weight: 500;
    color: #555;
    background: #F9F7FD;
    padding: 3px 6px;
    border-radius: 6px;
    border: 1px solid #F1EEFB;
}
.info-text-wrap .icon-info-text.no-bg {
    background: none;
    border: none;
    padding: 0;
}

.info-text-wrap.w-label .icon-info-text svg {
    width: 18px !important;
}
@media screen and (max-width: 1200px) {
    .info-text-wrap.w-label.FlexColumn > div {
        grid-gap: 4px !important;
        gap: 4px !important;
    }
    .info-text-wrap.w-label.FlexColumn .icon-info-text {
        flex-direction: column;
        grid-gap: 4px;
        gap: 4px;
    }
}
.info-text-wrap.w-label .icon-info-text > div {
    display: flex !important;
    flex-direction: column !important;
    line-height: 16px !important;
}

.info-text-wrap.w-label .icon-info-text > div > span {
    font-size: 12px !important;
    color: #888 !important;
}
.map-info.info-text-wrap {
    position: absolute;
    top: 8px;
    left: 50px;
    display: flex;
    margin-top: 0;
}
.map-info.info-text-wrap .icon-info-text {
    background: rgba(255,255,255,.8);
    border: 2px solid #D7D8D8;
}
body.busable-app h2.title {
    font-size: 20px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
    margin-bottom: 0 !important;
}
body.busable-app h3.title,
.card-title {
    font-size: 16px !important;
    text-transform: uppercase !important;
    color: #888 !important;
    font-weight: 600;
}

h3.title.mt-4 {
    margin-top: 30px !important;
}

body.busable-app h4.title {
    font-size: 14px !important;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
    font-weight: 600;
}

body.busable-app h5.title {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #8C8C8C !important;
    font-weight: 600;
}

.inputtext-small,
.inputselect-small .ant-select-selector,
.inputtext-small .ant-input-group input,
.inputtext-small .ant-input-group > input + .ant-input-group-addon {
    border-radius: 50px !important;
    font-size: 12px !important;
    height: 28px !important;
    padding: 0 8px !important;
    border-width: 1px !important;
    border-color: #DAD9D9 !important;
    border-style: solid !important;
}

.inputselect-small .ant-select-selector input {
    height: 28px !important;
}

.inputselect-small .ant-select-selector .ant-select-selection-item {
    line-height: 26px !important;
}

.inputtext-small:hover,
.inputtext-small:focus,
.inputtext-small.ant-input-focused,
.inputselect-small:not(.ant-select-disabled):hover .ant-select-selector,
.inputselect-small.ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
    border-color: #C3BAFC !important;
}

.inputtext-small:focus,
.inputtext-small.ant-input-focused,
.inputselect-small.ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
    box-shadow: 0 0 3px 0 rgba(195, 186, 252, 0.8) !important;
    border-right-width: 1px;
    outline: 0 !important;
}

.inputtext-small.ant-input-group-wrapper {
    padding: 0 !important;
}

.inputtext-small .ant-input-group > input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.inputtext-small .ant-input-group > input + .ant-input-group-addon {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    background: #EFECFB !important;
}

.App.container .list-group .list-group-item .RouteLogo {
    flex-shrink: 0 !important;
}

.row-col-actions {
    padding-left: 0px !important;
    padding-right: 0px !important;
    flex-wrap: wrap !important;
}
.row-col-actions.icon-only > .icon-button.btn-filled {
    background: none !important;
    box-shadow: none !important;
    height: auto !important;
}
.row-col-actions.icon-only > .icon-button.btn-filled svg path {
    fill: #999 !important;
}
.row-col-actions.icon-only > .icon-button.btn-filled:hover svg path {
    fill: #6B54F9 !important;
}
.list-row-wrap {
    position: relative !important;
}

.list-row-wrap .btn-float {
    position: absolute !important;
    right: 5px;
    top: calc(50% - 5px);
    transform: translate(0, -50%);
}

@media screen and (min-width: 1024px) {
    .list-row-wrap .btn-float {
        right: 10px;
    }
}

@media screen and (min-width: 1380px) {
    .list-row-wrap .btn-float {
        right: 20px;
    }
}

@media screen and (min-width: 1500px) {
    .list-row-wrap .btn-float {
        right: 30px;
    }
}

.row-col-actions .btn-icon-control {
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.row-col-actions.gap-8 .btn-icon-control {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.row-col-actions .btn-icon-control svg {
    height: 18px;
}

.row-col-actions .btn-icon-control .anticon.anticon-eye svg {
    width: 18px;
}

.row-col-actions .btn-icon-control svg path {
    fill: #BEBEBE;
}

.app-main .app-layout .app-header .public-header {
    width: calc(100% + 30px) !important;
    background: #6B54F9 !important;
    padding: 0 15px !important;
    margin: -15px -15px 0 -15px !important;
    text-align: center !important;
}

.app-main .app-layout .app-header .public-header > img:nth-of-type(2) {
    margin-left: 10px;
}

@media screen and (min-width: 1024px) {
    .app-main .app-layout .app-header .public-header {
        margin: -27px -30px 0 !important;
        width: calc(100% + 60px) !important;
    }
}

.app-main .app-layout .app-content .Login .btn-primary,
.app-main .app-layout .app-content .ResetPassword .btn-primary {
    background: #6B54F9 !important;
    border: none !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    text-transform: uppercase !important;
}

.app-main .app-layout .app-content .Login .btn-primary:hover,
.app-main .app-layout .app-content .ResetPassword .btn-primary:hover {
    background: #816EFA !important;
}

.app-main .app-layout .app-content .Login form > a,
.app-main .app-layout .app-content .ResetPassword p > a {
    color: #6B54F9 !important;
    font-weight: 500 !important;
    margin-bottom: 15px !important;
    display: inline-block !important;
}

.app-main .app-layout .app-content .Login form > a:hover,
.app-main .app-layout .app-content .ResetPassword p > a:hover {
    color: #816EFA !important;
}

.app-loading {
    text-align: center;
    padding: 60px 0 !important;
}

.app-loading .app-loading-logo {
    text-align: center;
}

.app-loading .app-loading-text {
    text-align: center;
    color: #9F9F9F !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
}

input.color-picker {
    width: 53px !important;
    height: 28px !important;
    padding: 0 !important;
    background-color: transparent;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    border-radius: 6px !important;
    flex-shrink: 0 !important;
}

.icon-10 svg {
    width: 10px !important;
}

.icon-12 svg {
    width: 12px !important;
}

.shift-bat-map .map-shiftbats,
.map-holder-wrap .map-items {
    padding: 20px;
    box-shadow: 0 0 20px rgba(107, 84, 249, 0.15);
    border-radius: 0 20px 20px 0;
    max-height: 100% !important;
    min-height: 100% !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    height: 85vh;
}
.map-holder-wrap .map-items.scroll-inner {
    padding: 0;
    overflow: hidden !important;
    height: auto;
}
.map-holder-wrap .map-items.scroll-inner .scroll-item {
    padding: 0 20px 20px;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    height: calc(85vh - 183px);
    border-top: 1px solid #E9E5FE;
}
.shift-bat-map .map-shiftbats {
    background: #f7f9fa !important;
    padding-top: 0 !important;
}

@media screen and (max-width: 1023px) {
    .shift-bat-map .map-shiftbats,
    .shift-bat-map .map-holder,
    .map-holder-wrap .map-holder,
    .map-holder-wrap .map-items {
        width: 100% !important;
    }
}

.icon-logo .ant-upload-list-picture-card-container {
    margin: 0 !important;
    width: 75px !important;
    height: 75px !important;

}

.icon-logo .ant-upload-list-picture-card-container .ant-upload-list-item-list-type-picture-card {
    padding: 0 !important;
    border-radius: 6px !important;
}

.icon-logo .ant-upload-list-picture-card-container .ant-upload-list-item-list-type-picture-card .ant-upload-list-item-thumbnail img {
    border-radius: 6px !important;
}

.badge-outline {
    background: none !important;
    color: #6c757d !important;
    border: 1px solid #6c757d !important;
    text-align: center !important;
    white-space: break-spaces !important;
}
.App.container .list-group .badge-outline .list-group-item {
    background: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
}
.badge-outline div div.ml-1 {
    text-align: left !important;
}

.badge-outline.badge-light {
    color: #899197 !important;
    border: 1px solid #E5E5E5 !important;
}
.calendar-tags .ant-tag {
    white-space: normal !important;
    line-height: 14px;
    text-align: center;
}
.App.container .list-group .list-group-item.active .badge-outline.badge-light {
    color: #6c757d !important;
    border: 1px solid #E9E6FA !important;
    background: #E9E6FA !important;
}

@media screen and (min-width: 1024px) {
    .col-separator > div[class^='col-']:not(:last-of-type),
    .col-separator > div[class*=' col-']:not(:last-of-type) {
        border-right: 1px solid #E1DDFE;
    }
}

@media screen and (max-width: 1023px) {
    .col-separator > div[class^='col-']:last-of-type,
    .col-separator > div[class*=' col-']:last-of-type {
        margin-top: 40px;
    }

    .col-separator > div.px-4 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

body.busable-app .app-list {
    margin: 0 !important;
    padding: 0 0 0 17px !important;
    list-style: none !important;
}

body.busable-app .app-list li {
    margin-bottom: 10px;
    position: relative;
}

body.busable-app .app-list li:before {
    content: "\2022";
    font-size: 20px;
    line-height: normal;
    color: #6B54F9;
    position: absolute;
    left: -18px;
}

body.busable-app .app-list.text-md {
    font-size: 16px;
}

body.busable-app .app-list strong {
    font-weight: 600;
    background: #f2f2f2;
    padding: 0 6px;
    border-radius: 5px;
    color: #333;
    font-size: 15px;
}

.MapMenu .Filter label {
    width: auto !important;
}

.BusRouteDetails .MapPlaceholder,
.map-holder-wrap .MapPlaceholder {
    width: 100%;
    height: 85vh;
    overflow: hidden;
    position: relative;
}

.loading-spin {
    width: 100% !important;
    padding: 0 0 20px !important;
}

.loading-spin svg {
    height: 34px !important;
}

.btn-edit-controls {
    flex-shrink: 0 !important;
    display: flex !important;
    grid-gap: 8px !important;
    gap: 8px !important;
}

.btn-edit-controls .btn-icon-fill {
    border: none !important;
    border-radius: 50px !important;
    width: 25px !important;
    height: 25px !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-shrink: 0 !important;
    overflow: hidden !important;
}

.btn-edit-controls .btn-icon-fill svg {
    height: 9px !important;
    flex-shrink: 0 !important;
}

.btn-edit-controls .btn-icon-fill.btn-save svg:nth-of-type(2) {
    display: none !important;
}

.btn-edit-controls .btn-icon-fill.btn-save {
    background: #D9F3D2 !important;
}

.btn-edit-controls .btn-icon-fill.btn-save:hover {
    background: #7ED668 !important;
}

.btn-edit-controls .btn-icon-fill.btn-save svg path {
    stroke: #7ED668 !important;
}

.btn-edit-controls .btn-icon-fill.btn-save:hover svg path {
    stroke: #fff !important;
}

.btn-edit-controls .btn-icon-fill.btn-save svg.spinning {
    height: 16px !important;
}

.btn-edit-controls .btn-icon-fill.btn-close {
    background: #FFE6E6 !important;
}

.btn-edit-controls .btn-icon-fill.btn-close svg path {
    fill: #FF4545 !important;
}

.btn-edit-controls .btn-icon-fill.btn-close:hover {
    background: #FF4545 !important;
}

.btn-edit-controls .btn-icon-fill.btn-close:hover svg path {
    fill: #fff !important;
}

.App.container .Transfers .connect-transfer-arrow {
    display: flex !important;
    padding: 0 6px !important;
    align-items: center !important;
}

.App.container .Transfers .connect-transfer-arrow svg path {
    fill: #bbb !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active,
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #6B54F9 !important;
    border-color: #6B54F9 !important;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: #8976FA !important;
}

.timetable-schedule .Schedule .ant-radio-group .ant-radio-button-wrapper {
    border-radius: 20px !important;
    margin-right: 8px !important;
}

.timetable-schedule .Schedule .ant-radio-group .ant-radio-button-wrapper:last-of-type {
    margin-right: 0 !important;
}

.timetable-schedule .Schedule .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
    display: none !important;
}

.timetable-schedule .Schedule .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover {
    color: #fff !important;
}

.ant-radio-button-wrapper:hover {
    color: #6B54F9 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
    background-color: #6B54F9 !important;
}

.sb-row .sb-controls {
    display: flex !important;
    flex-shrink: 0 !important;
    align-items: center !important;
}

.sb-row .sb-info .sb-controls {
    margin-left: auto !important;
}

.sb-row .sb-controls button,
.sb-row button.sb-add-comment {
    border: none !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-shrink: 0 !important;
    border-radius: 0 !important;
}

.sb-row .sb-controls button {
    padding: 0 !important;
    background: none !important;
    box-shadow: none !important;
    width: 22px !important;
    height: 20px !important;
    outline: none !important;
}

.sb-row .sb-controls button:hover {
    background: none !important;
}

.sb-row button.sb-add-comment {
    flex-shrink: 0 !important;
}

.sb-row .sb-controls button svg {
    width: 13px !important;
    height: 13px !important;
}

.sb-row .sb-controls button.sb-edit-row svg path,
.sb-row .sb-controls button.sb-directions svg path {
    fill: #bebebe !important;
}

.sb-row button.sb-add-comment:hover svg path,
.sb-row .sb-controls button.sb-edit-row:hover svg path,
.sb-row .sb-controls button.sb-directions:hover svg path {
    fill: #6B54F9 !important;
}

.sb-row button.sb-add-row:hover svg path,
.sb-row button.sb-add-comment:hover svg path,
.add-initial-row .sb-add-row:hover svg path {
    fill: #fff !important;
}

.sb-row button.sb-add-row:hover,
.sb-row button.sb-add-comment:hover,
.add-initial-row .sb-add-row:hover {
    background: #6B54F9 !important;
    border-color: #6B54F9 !important;
}

.sb-row .sb-controls button.sb-delete-row:hover svg path {
    fill: #FF4545 !important;
}

.sb-row,
.sb-row .sb-row-routes .row,
.add-initial-row {
    position: relative;
}

.sb-row button.sb-add-row,
.sb-row button.sb-add-comment,
.add-initial-row .sb-add-row {
    background: #E9E6FA !important;
    border-radius: 50px !important;
    width: 19px !important;
    height: 19px !important;
    padding: 0 0 0.5px 0.5px !important;
    align-items: center !important;
    justify-content: center !important;
    position: absolute !important;
    bottom: -9.5px !important;
    left: 50% !important;
    transform: translate(-50%, 0) !important;
    z-index: 2 !important;
    outline: none !important;
    display: none !important;
}

.add-initial-row .sb-add-row:hover {
    transform: translate(-50%, 0) !important;
}

.sb-items-wrap.edit-mode .sb-row .sb-row-routes > div > .row:not(.sb-row-transfers) {
    border-bottom: 1px solid transparent !important;
}

.sb-items-wrap.edit-mode .sb-row .sb-row-routes .row:not(.sb-row-transfers):hover {
    border-bottom-color: #E9E5FE !important;
}

.sb-row .sb-row-routes .row .sb-info {
    position: static !important;
}

.sb-row:hover > button.sb-add-row,
.sb-row .sb-row-routes .row:hover button.sb-add-row,
.sb-row .sb-row-routes .row:hover button.sb-add-comment,
.sb-items-wrap.edit-mode:hover .add-initial-row .sb-add-row {
    display: flex !important;
}

.sb-row button.sb-add-row svg,
.sb-row button.sb-add-comment svg,
.add-initial-row .sb-add-row svg {
    width: 20px !important;
}

.sb-row .sb-row-routes .sb-controls button {
    width: 20px !important;
    height: 18px !important;
}

.map-shiftbats .sb-first-row {
    text-align: center !important;
    padding: 50px 0 !important;
}

.map-shiftbats .sb-first-row div {
    width: 100% !important;
    color: #999 !important;
    margin-bottom: 10px !important;
}

.map-shiftbats .sb-first-row button {
    float: none !important;
}

.map-shiftbats .sb-first-row button:disabled {
    opacity: 0.6 !important;
}

.sb-row-heading {
    padding: 8px 0 !important;
}

.sb-row-heading,
.info-heading {
    font-size: 12px !important;
    text-transform: uppercase !important;
    color: #989898 !important;
    font-weight: 600 !important;
    line-height: 14px !important;
}
.sb-row-heading .ant-col {
    font-size: 12px !important;
}
.StopTimes .sb-row {
    padding: 8px 0 !important;
}

.input-btn-wrap {
    width: 100% !important;
    position: relative;
}

.input-btn-wrap input {
    padding-right: 32px !important;
}

.input-btn-wrap button {
    position: absolute !important;
    top: 1px !important;
    right: 1px !important;
    border-radius: 50px !important;
    width: 26px !important;
    height: 26px !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background: #E9E5FE !important;
    border: none !important;
    outline: none !important;
}

.input-btn-wrap button:hover {
    background: #6B54F9 !important;
}

.input-btn-wrap button svg {
    width: 14px !important;
}

.input-btn-wrap button svg path {
    fill: #6a54f9a5 !important;
}

.input-btn-wrap button:hover svg path {
    fill: #fff !important;
}

@media screen and (min-width: 1024px) {
    .tab-menu-float > .ant-tabs-nav {
        position: absolute !important;
        top: 26px !important;
        margin: 0 0 0 186px !important;
    }
}

.tab-menu-float > .ant-tabs-nav:before {
    display: none !important;
}

.tab-menu-float .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background: #8775F9 !important;
    height: 26px !important;
    z-index: -1 !important;
    border-radius: 50px !important;
}

.tab-menu-float .ant-tabs-tab {
    padding: 3px 15px !important;
}

.tab-menu-float .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 10px !important;
}

.tab-menu-float .ant-tabs-tab .ant-tabs-tab-btn {
    color: #7C7D7E !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
    font-size: 13px !important;
}

.tab-menu-float .ant-tabs-tab:hover .ant-tabs-tab-btn {
    color: #6B54F9 !important;
}

.tab-menu-float .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
}
.tab-menu-float.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tab-menu.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}
.ant-tab-menu.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
    line-height: 21px;
}
.ant-tab-menu.ant-tabs.ant-tabs-top >.ant-tabs-nav:before {
    display: none;
}
.primary-btn {
    min-width: 100px !important;
    text-align: center !important;
    font-size: 15px !important;
    background: #6B54F9 !important;
    font-weight: 600 !important;
    border: none !important;
    margin-top: 10px !important;
    padding: 7px 20px !important;
    border-radius: 8px !important;
    outline: none !important;
    height: auto !important;
}
.primary-btn.secondary-btn {
    background: #fff !important;
    border: 1px solid #d9d9d9 !important;
    color: #777 !important;
    font-weight: 500 !important;
}
.primary-btn.secondary-btn:hover {
    background: #fff !important;
    border-color: #5b45e9 !important;
    color: #5b45e9 !important;
}
.primary-btn:hover,
.primary-btn:focus {
    background: #5b45e9 !important;
    color: #fff !important;
}
.primary-btn.mt-0 {
    margin-top: 0 !important;
}
.card-main .card-info-box {
    background: #F7F7F7 !important;
    padding: 10px 15px !important;
    border: 1px solid #eee !important;
    border-radius: 6px !important;
    font-size: 15px !important;
    display: block !important;
    word-wrap: break-word !important;
}
.info-box {
    background: #fff !important;
    padding: 40px 50px !important;
    border: 1px solid #eee !important;
    border-radius: 20px !important;
    width: 100%;
    grid-gap: 30px;
    gap: 30px;
    flex-direction: column;
    align-items: center;
}
.card-main code.card-info-box {
    font-size: 14px !important;
}

.copy-btn-wrap {
    position: relative !important;
}

.copy-btn-wrap .copy-btn {
    position: absolute !important;
    right: 1px !important;
    bottom: 1px !important;
    background: #E9E6FA !important;
    padding: 3px 6px !important;
    cursor: pointer !important;
    border-radius: 6px 0 6px 0 !important;
}

.copy-btn-wrap .copy-btn svg path {
    fill: #6B54F9 !important;
}

.copy-btn-wrap .copy-btn:hover {
    background: #8775F9 !important;
}

.copy-btn-wrap .copy-btn:hover svg path {
    fill: #fff !important;
}

.stop-tt-row .stop-tt-time {
    font-size: 12px !important;
    padding: 0 4px !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    border: 1px solid #D9D9D9 !important;
    background: #fff !important;
    border-radius: 4px !important;
    min-width: 42px !important;
}

.stop-tt-row .stop-tt-name {
    font-size: 13px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
}

.stop-tt-row.stop-tt-line div:nth-child(2):after {
    content: '' !important;
    background: #DFDFDF !important;
    width: 1px !important;
    height: 14px !important;
}

.StopTimesTimetable > div:last-of-type > .stop-tt-row.stop-tt-line div:nth-child(2):after {
    background: transparent !important;
}

.TimetableSchedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 6px 6px 0 0 !important;
    padding: 4px !important;
    background-color: #e9e9e9 !important;
}

.TimetableSchedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
    margin-left: 0 !important;
}

.TimetableSchedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #fff !important;
    color: #6B54F9 !important;
    border-color: #e9e9e9;
    border-bottom-color: #fff !important;
}

.TimetableSchedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab:hover {
    color: #6B54F9 !important;
}

.TimetableSchedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn .ant-input,
.ant-tabs-dropdown .ant-tabs-dropdown-menu-item .ant-input {
    border-radius: 6px !important;
    padding: 0 !important;
    background: none !important;
    height: 25px !important;
    border: 1px solid #e9e9e9 !important;
    cursor: pointer;
}
.TimetableSchedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .ant-input {
    cursor: auto;
}

.TimetableSchedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn .ant-input.error {
    border-color: #ff4d4f !important;
    background-color: rgba(255, 77, 79, 0.1) !important;
    color: #FF4545 !important;
}
.TimetableSchedule .ant-tabs-card > .ant-tabs-nav:before {
    border-color: #e9e9e9;
}
.TimetableSchedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-nav-more {
    padding: 0 6px;
    color: #999;
}
.row.row-compact {
    margin: 0 -3px !important;
    width: 100% !important;
}

.row.row-compact > div[class^='col-'],
.row.row-compact > div.col {
    padding: 0 3px !important;
}

.row > .row-col-gap {
    display: flex !important;
    flex-wrap: wrap !important;
    grid-gap: 6px;
    gap: 6px;
}

.sb-row .sb-row-edit .row > .row-col-gap .inputselect-small:not(.w-100),
.sb-row .sb-row-edit .row > .row-col-gap .ant-input.inputtext-small:not(.input-desc) {
    width: calc(50% - 3px) !important;
}

.sb-row .sb-row-edit .row.row-compact .sb-time {
    padding: 5px 0 0 !important;
    display: inline-block !important;
    width: auto !important;
}

.sb-row .sb-row-edit .row.row-compact .ant-input-group-wrapper.inputtext-small {
    width: 48% !important;
}

.sb-edit-controls.dir-row {
    flex-direction: row !important;
}

.Home .list-group .list-group-item .RouteInfo.w-100,
.Timetable .timetable-row .RouteInfo.w-100,
.ShiftBat .list-group .list-group-item .RouteInfo.w-100 {
  grid-gap: 0 !important;
  gap: 0 !important;
}
.ShiftBat .list-group .list-group-item .RouteInfo.w-100 {
    width: calc(100% - 112px) !important;
}
.ShiftBat .list-group .list-group-item .RouteInfo.w-100 .RouteDetails {
    min-width: 100% !important;
}
.Timetable .timetable-row .RouteInfo .RouteDetails {
    min-width: auto;
}
.transfer-row-info {
    background: #fff !important;
    margin: 10px 0 0 !important;
    padding: 5px 0 !important;
    width: 100% !important;
    border: 1px solid #E9E9E9 !important;
    border-radius: 6px !important;
    grid-gap: 8px !important;
    gap: 8px !important;
    color: #777 !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    letter-spacing: 0.1px !important;
    flex-wrap: wrap !important;
    cursor: pointer;
    position: relative;
}

.transfer-row-info .show-transfers {
    position: absolute;
    left: 10px;
}

.transfer-row-info .show-transfers svg path {
    fill: #bbb;
}

.transfer-row-info.Selected {
    background: rgba(106, 84, 249, 0.12) !important;
    border-color: rgba(106, 84, 249, 0.12) !important;
    color: #555 !important;
}

.transfer-single {
    padding: 3px;
    border: 1px solid transparent;
    border-radius: 6px;
}

.transfer-row-info:not(.Selected) .transfer-single.Selected {
    background: rgba(106, 84, 249, 0.14);
    border-color: rgba(106, 84, 249, 0.14);
}

.transfer-row-info.Selected .show-transfers svg path {
    fill: #6B54F9;
}

.transfer-row-info .RouteLogo {
    background: #fff;
}

.share-btn-wrap {
    /* position: absolute;
    right: 6px;
    top: 7px;
    cursor: pointer !important;
    padding: 0 5px 3px !important; */
    width: 100% !important;
    display: flex !important;
    justify-content: flex-end !important;
}

.share-btn-wrap .icon-button svg path {
    stroke: #6B54F9 !important;
}

.share-btn-wrap .icon-button:hover svg path {
    stroke: #fff !important;
}

/* .share-btn-wrap:hover {
    background: #6B54F9 !important;
    border-radius: 100px !important;
}
.share-btn-wrap:hover svg path {
    stroke: #fff !important;
}
.share-btn-wrap button {
    padding: 0 !important;
}*/
body.busable-app .header-title {
    margin: 0 0 15px !important;
}

@media screen and (min-width: 1024px) {
    body.busable-app .header-title {
        margin: -40px 0 25px !important;
    }
}

.logo-upload svg path {
    fill: #999 !important;
}

.BusRouteDetails .map-items .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px !important;
}

.BusRouteDetails .map-items .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
    line-height: 26px !important;
}

.map-wp-selector {
    position: absolute !important;
    bottom: 40px !important;
    left: 10px !important;
    right: 10px !important;
    background: rgba(255, 255, 255, .6) !important;
    border-radius: 50px !important;
    padding: 0 1px 0 12px !important;
    border: 1px solid #ddd !important;
    display: flex !important;
    align-items: center !important;
}

.map-wp-selector .ant-slider.ant-slider-horizontal {
    width: 90% !important;
    margin-right: 15px !important;
}

.map-wp-selector .ant-slider.ant-slider-horizontal .ant-slider-rail {
    background-color: #ddd !important;
}

.map-wp-selector .map-wp-selector-actions button {
    background: #E9E6FA !important;
    border-color: #fff !important;
    border-radius: 30px;
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 32px !important;
    width: 38px !important;
    outline: none !important;
    box-shadow: none !important;
}

.map-wp-selector .ant-slider.ant-slider-horizontal .ant-slider-track,
.map-wp-selector .map-wp-selector-actions button:hover {
    background: #6B54F9 !important;
}

.map-wp-selector .map-wp-selector-actions button.actio-btn-close svg {
    width: 11px !important;
}

.map-wp-selector .map-wp-selector-actions button svg path {
    fill: #6B54F9 !important;
}

.map-wp-selector .map-wp-selector-actions button:hover svg path {
    fill: #fff !important;
}

.map-wp-selector .ant-slider.ant-slider-horizontal .ant-slider-handle:after {
    box-shadow: 0 0 0 2px #6B54F9 !important
}

.map-wp-selector .ant-slider.ant-slider-horizontal .ant-slider-handle:hover::after,
.map-wp-selector .ant-slider.ant-slider-horizontal .ant-slider-handle:active::after,
.map-wp-selector .ant-slider.ant-slider-horizontal .ant-slider-handle:focus::after {
    box-shadow: 0 0 0 4px #6B54F9 !important
}

.service-rows-wrap {
    height: 100% !important;
    overflow-y: scroll !important;
    overflow-x: hidden;
    width: calc(100% + 20px);
    margin: 0 -10px;
    padding: 0 0 50px;
}

.Export .ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff !important;
}

.Export .ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper:hover {
    border-color: #6B54F9 !important;
}

body.busable-app .ant-modal .ant-modal-header .ant-modal-title,
#busable-embed .ant-modal .ant-modal-header .ant-modal-title {
    font-size: 18px !important;
}

.ant-modal .btn-primary,
.btn-secondary,
body.busable-app .ant-modal .ant-modal-footer .ant-btn-primary,
body.busable-app .ant-modal .ant-modal-footer .ant-btn-default,
#busable-embed .ant-modal .ant-modal-footer .ant-btn-primary,
#busable-embed .ant-modal .ant-modal-footer .ant-btn-default,
.ButtonGroup .btn-primary {
    box-shadow: none !important;
    outline: none !important;
}

body.busable-app .ant-modal .btn-primary:not(.btn-icon-control),
body.busable-app .ant-modal .ant-modal-footer .ant-btn-primary,
#busable-embed .ant-modal .btn-primary,
#busable-embed .ant-modal .ant-modal-footer .ant-btn-primary,
.ButtonGroup .btn-primary,
.ant-modal-header.hide-print .btn-primary.icon-btn,
.info-box .btn-primary {
    background: #6B54F9 !important;
    border-color: #6B54F9 !important;
    color: #fff !important;
    border-radius: 5px;
}

.ant-modal .btn-primary:not(.btn-icon-control):hover,
body.busable-app .ant-modal .ant-modal-footer .ant-btn-primary:hover,
#busable-embed .ant-modal .ant-modal-footer .ant-btn-primary:hover,
.ButtonGroup .btn-primary:hover,
.info-box .btn-primary:hover {
    background: #624aff !important;
    border-color: #624aff !important;
}

.ant-modal .btn-primary.icon-btn {
    text-transform: uppercase;
    font-weight: 600;
}

.ant-modal .btn-primary.icon-btn svg {
    height: 16px;
}

.ant-modal .btn-primary.icon-btn svg path,
.ant-modal-header.hide-print .btn-primary.icon-btn svg path {
    fill: #fff;
}
.ant-modal-header.hide-print .ant-modal-title {
    font-size: 18px;
    font-weight: 600;
}

.ant-modal-body.letter-modal {
    background: #F8FAFB;
    padding: 50px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 14px;
}

.ant-modal-body.letter-modal .letter-content {
    font-size: 16px;
    line-height: 26px;
}

.btn-secondary,
body.busable-app .ant-modal .ant-modal-footer .ant-btn-default,
#busable-embed .ant-modal .ant-modal-footer .ant-btn-default,
.ButtonGroup .btn-secondary {
    color: #444 !important;
    border-color: #d9d9d9 !important;
    background: none !important;
    border-radius: 5px;
}

.btn-secondary:hover,
body.busable-app .ant-modal .ant-modal-footer .ant-btn-default:hover,
#busable-embed .ant-modal .ant-modal-footer .ant-btn-default:hover,
.ButtonGroup .btn-secondary:hover,
.info-box .btn-secondary:hover {
    color: #6B54F9 !important;
    border-color: #6B54F9 !important;
    background: none !important;
}

body.busable-app .ant-modal .ant-modal-close,
#busable-embed .ant-modal .ant-modal-close {
    border-radius: 50px !important;
    width: 24px !important;
    height: 24px !important;
}

body.busable-app .ant-modal .ant-modal-close:hover,
#busable-embed .ant-modal .ant-modal-close:hover {
    background: #E9E6FA !important
}

body.busable-app .ant-modal .ant-modal-close svg,
#busable-embed .ant-modal .ant-modal-close svg {
    font-size: 16px !important;
}

body.busable-app .ant-modal .ant-modal-close:hover svg,
#busable-embed .ant-modal .ant-modal-close:hover svg {
    fill: #6B54F9 !important;
}

body.busable-app .ant-collapse .anticon.ant-collapse-arrow svg,
#busable-embed .ant-collapse .anticon.ant-collapse-arrow svg {
    fill: #999 !important;
}

svg.icon-grey path {
    fill: rgba(0, 0, 0, 0.4) !important;
}

svg.icon-grey-stroke path {
    stroke: rgba(0, 0, 0, 0.4) !important;
}

.text-primary {
    color: #8876FA !important;
    font-weight: 600 !important;
    font-size: 14px !important;
}

.text-info {
    color: #ACACAC !important;
    font-weight: 600 !important;
    font-size: 14px !important;
}
.text-info-md {
    color: #989898 !important;
    font-size: 14px !important;
    line-height: 20px;
}
.text-info-lg {
    color: #888;
    font-size: 15px;
    line-height: 22px;
}
.text-title {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
}
.text-sm-info {
    color: #777 !important;
}

.text-lg {
    font-size: 42px !important;
    line-height: 42px !important;
    color: #6B54F9 !important;
    font-weight: 600 !important;
}
.text-xl {
    font-size: 52px !important;
    line-height: 52px !important;
    color: #6B54F9 !important;
    font-weight: 600 !important;
}
.text-xl > span {
    font-size: 42px;
}
.DataTable table .ant-table-thead th.ant-table-cell,
.DataTable table .ant-table-tbody td.ant-table-cell {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.DataTable table tr.RowModified {
    background: #f1fce8 !important;
}

.DataTable table .ant-typography.ant-typography-success {
    color: #389e0d !important;
}

.DataTable .ant-pagination {
    grid-gap: 5px;
    gap: 5px;
}

.DataTable .ant-pagination li a {
    font-size: 13px;
    color: #989898;
}

.DataTable .ant-pagination li.ant-pagination-item-active {
    border-color: #6B54F9;
}

.DataTable .ant-pagination li.ant-pagination-item-active a {
    color: #6B54F9;
}

.transfers-modal .DataTable {
    height: 527px;
    overflow-y: scroll;
}

.transfers-modal .DataTable table .ant-table-thead th.ant-table-cell {
    line-height: 16px;
}

.transfers-modal .DataTable table .ant-table-thead {
    height: 54px;
}

.sb-row.alert-danger,
.ant-input.alert-danger {
    color: #FF4545 !important;
    background-color: #FEE5E5 !important;
    border-color: #ffd4d4 !important;
}

.ant-input.alert-danger {
    font-weight: 600 !important;
}

.graph-wrap .graph-info {
    grid-gap: 15px;
    gap: 15px;
    margin: 0 0 30px !important;
}

.graph-wrap .graph-info strong,
.graph-wrap .graph-info-lg strong {
    font-size: 28px !important;
    line-height: 28px !important;
    color: #6B54F9;
}

.graph-wrap .graph-info-lg strong {
    font-size: 42px !important;
    line-height: 42px !important;
}

.graph-wrap .graph-info .ant-tag {
    border-radius: 50px !important;
    color: #51B238 !important;
    font-weight: 600 !important;
}

.graph-wrap canvas {
    max-height: 280px !important;
}

.graph-wrap .graph-legend label {
    margin: 0 !important;
    font-size: 13px !important;
}

.graph-wrap .graph-legend strong {
    font-size: 20px !important;
    color: #5A5A5A !important;
    line-height: 22px !important;
}

.icon-switch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}

.icon-switch svg path {
    fill: #c8c8c8 !important;
    fill-opacity: 1 !important;
}

.icon-switch svg.active path {
    fill: #6B54F9 !important;
}

.icon-switch > .ant-switch {
    position: absolute;
    opacity: 0 !important;
}

.employee-dashboard .ant-input.ant-input-status-error {
    background: #FEE5E5 !important;
    color: #FF4545 !important;
}

.card-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    box-shadow: none;
}

.upload-btn-wrap {
    width: 100%;
    background: #f4f4f4;
    padding: 25px 20px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.info-blocks {
    text-align: center;
    margin: 35px 0;
}

.info-blocks strong {
    font-size: 42px;
    line-height: 42px;
    color: #6B54F9;
    font-weight: 600;
}

.color-error,
.info-blocks .ant-col:first-of-type strong {
    color: #FF4545;
}

.color-warning,
.info-blocks .ant-col:last-of-type strong {
    color: #fd7e14;
}

.transfers-header .route-step-number svg {
    width: 25px;
    height: 25px;
}

.transfers-header .route-step-number svg circle {
    transform: scale(1.2, 1.2);
}

.transfers-header .route-step-number svg text {
    font-size: 13px;
}

.transfers-modal .RouteLogo {
    background: #fff;
}

.transfers-modal .row.RouteTitle {
    padding: 4px 0;
}

.transfers-modal .row.RouteTitle .RouteInfo {
    grid-gap: 2px !important;
    gap: 2px !important;
}

.transfers-modal .DataTable table .ant-table-row .ant-table-cell:nth-last-of-type(2),
.transfers-modal .DataTable table .ant-table-thead .ant-table-cell:nth-last-of-type(2) .ant-table-column-title,
.transfers-modal .DataTable table .ant-table-row .ant-table-cell:last-of-type,
.transfers-modal .DataTable table .ant-table-thead .ant-table-cell:last-of-type .ant-table-column-title,
.transfers-modal .ant-row .ant-col:last-of-type .DataTable table .ant-table-row .ant-table-cell:nth-last-of-type(3),
.transfers-modal .ant-row .ant-col:last-of-type .DataTable table .ant-table-thead .ant-table-cell:nth-last-of-type(3) .ant-table-column-title {
    text-align: center;
}

.transfers-modal .DataTable table .ant-table-row .ant-table-cell:nth-last-of-type(2),
.transfers-modal .DataTable table .ant-table-row .ant-table-cell:last-of-type,
.transfers-modal .ant-row .ant-col:last-of-type .DataTable table .ant-table-row .ant-table-cell:nth-last-of-type(3) {
    padding-left: 20px;
    padding-right: 20px;
}

.row.sb-row.Selected {
    background-color: #E9E6FA;
}

.BusRouteDetails .map-items {
    position: relative;
    overflow: visible !important;
    padding: 0 !important;
}
.BusRouteDetails .card-main.map-holder-wrap {
    overflow: hidden;
}

.timetable-footer {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 1px;
    gap: 1px;
}

.timetable-footer .icon-switch,
.timetable-footer .ant-btn.transfer-icon,
.timetable-footer .btn.icon-button.inline-icon {
    background: #E9E6FA !important;
    border-radius: 0 !important;
    padding: 0 !important;
    width: 44px !important;
    height: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timetable-footer .icon-switch svg {
    width: 14px;
}

.timetable-footer .icon-switch svg path,
.timetable-footer .ant-btn.transfer-icon svg path {
    fill: #999 !important;
}

.timetable-footer .icon-switch:hover svg path,
.timetable-footer .icon-switch svg.active path,
.timetable-footer .ant-btn.transfer-icon:hover svg path {
    fill: #6B54F9 !important;
}

.timetable-footer .btn.icon-button.inline-icon svg g {
    stroke: #999 !important;
}

.timetable-footer .btn.icon-button.inline-icon:hover svg g {
    stroke: #6B54F9 !important;
}

.timetable-footer .transfer-icon.ant-popover-disabled-compatible-wrapper {
    background: #f2f2f2 !important;
}

.timetable-footer .transfer-icon.ant-popover-disabled-compatible-wrapper button {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 30px !important;
}

body.busable-app .ant-alert.alert-warning,
#busable-embed .ant-alert.alert-warning {
    padding: 10px 15px !important;
    border-radius: 6px !important;
    border: 1px solid #ffd494 !important;
    background: #fffaea !important;
}

body.busable-app .ant-alert.alert-warning .anticon.ant-alert-icon,
#busable-embed .ant-alert.alert-warning .anticon.ant-alert-icon {
    width: 20px;
}

body.busable-app .ant-alert.alert-warning .ant-alert-content .ant-alert-message,
#busable-embed .ant-alert.alert-warning .ant-alert-content .ant-alert-message {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 15px;
}

.ShiftBatView .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl,
.TripPlanner .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl,
.StopManagerContainer .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl,
.Timetable .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    top: -130px;
    min-width: 30vw !important;
}

@media screen and (max-width: 1023px) {
    .ShiftBatView .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--icon-search,
    .TripPlanner .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--icon-search,
    .Timetable .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--icon-search {
        left: 7px;
        width: 20px;
        height: 20px;
        top: 8px;
    }

    .ShiftBatView .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--input,
    .TripPlanner .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--input,
    .Timetable .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--input {
        font-size: 15px !important;
        padding: 6px 35px !important;
        height: 36px !important;
    }

    .ShiftBatView .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--pin-right .mapboxgl-ctrl-geocoder--icon-close,
    .TripPlanner .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--pin-right .mapboxgl-ctrl-geocoder--icon-close,
    .Timetable .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .mapboxgl-ctrl-geocoder--pin-right .mapboxgl-ctrl-geocoder--icon-close {
        width: 16px;
        height: 16px;
        margin-top: 3px;
        margin-right: 0;
    }


    .ShiftBatView .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .suggestions-wrapper .suggestions,
    .TripPlanner .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .suggestions-wrapper .suggestions,
    .StopManagerContainer .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .suggestions-wrapper .suggestions,
    .Timetable .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .suggestions-wrapper .suggestions {
        font-size: 13px !important;
        height: 400px;
        overflow-y: scroll;
    }

    .ShiftBatView .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl,
    .TripPlanner .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl,
    .Timetable .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
        min-width: calc(100vw - 100px) !important;
    }
}

@media screen and (min-width: 1024px) {
    .ShiftBatView .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .suggestions-wrapper .suggestions,
    .TripPlanner .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .suggestions-wrapper .suggestions,
    .StopManagerContainer .MapPlaceholder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .suggestions-wrapper .suggestions,
    .Timetable .mapboxgl-ctrl-geocoder.mapboxgl-ctrl .suggestions-wrapper .suggestions {
        height: 600px;
        overflow-y: scroll;
    }
}

.user-list-icon {
    width: auto;
    height: 46px;
}

.user-list-icon path {
    fill: #EAEAEA;
}

@media screen and (min-width: 992px) {
    .employee-dashboard > .ant-row:not(.w-100) > .ant-col:first-of-type,
    .w-secondary-menu > .ant-row:not(.w-100) > .ant-col:first-of-type {
        max-width: 277px !important;
    }

    .employee-dashboard > .ant-row:not(.w-100) > .ant-col:last-of-type,
    .w-secondary-menu > .ant-row:not(.w-100) > .ant-col:last-of-type {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 277px);
        max-width: calc(100% - 277px) !important;
    }
}

.employee-dashboard .profile-summary,
.w-secondary-menu .profile-summary {
    padding: 20px 20px 15px;
    text-align: center;
}

.employee-dashboard .profile-summary h2,
.w-secondary-menu .profile-summary h2 {
    font-size: 20px;
    margin: 10px 0 0 !important;
}

.employee-dashboard .profile-summary .ant-badge sup.ant-badge-dot,
.w-secondary-menu .profile-summary .ant-badge sup.ant-badge-dot {
    bottom: 8px !important;
    top: auto !important;
    right: 8px !important;
    width: 15px !important;
    height: 15px !important;
    transform: translate(0) !important;
}

.employee-dashboard .profile-summary .ant-badge.success sup.ant-badge-dot,
.w-secondary-menu .profile-summary .ant-badge.success sup.ant-badge-dot {
    background: #52C41A !important;
}

.employee-dashboard .profile-summary .ant-badge .ant-avatar,
.w-secondary-menu .profile-summary .ant-badge .ant-avatar {
    background: #EAEAEA;
}

.employee-dashboard .profile-menu,
.w-secondary-menu .profile-menu {
    border-radius: 0 0 20px 20px !important;
}

.employee-dashboard .profile-menu .ant-menu-item,
.w-secondary-menu .profile-menu .ant-menu-item {
    border-top: 1px solid #EFEDFD;
    margin: 0 !important;
    padding: 0 20px !important;
    border-radius: 0 !important;
    height: 50px !important;
    width: 100% !important;
}

.employee-dashboard .profile-menu .ant-menu-item:last-of-type,
.w-secondary-menu .profile-menu .ant-menu-item:last-of-type {
    border-radius: 0 0 20px 20px !important;
}

.employee-dashboard .profile-menu .ant-menu-item span,
.w-secondary-menu .profile-menu .ant-menu-item span {
    color: #666 !important;
    font-weight: 500 !important;
}

.employee-dashboard .profile-menu .ant-menu-item svg path,
.w-secondary-menu .profile-menu .ant-menu-item svg path {
    fill: #BEBEBE !important;
    fill-opacity: 1 !important;
}

.employee-dashboard .profile-menu .ant-menu-item:hover,
.employee-dashboard .profile-menu .ant-menu-item.ant-menu-item-selected,
.w-secondary-menu .profile-menu .ant-menu-item:hover,
.w-secondary-menu .profile-menu .ant-menu-item.ant-menu-item-selected {
    background: #F8F7FE !important;
}

.employee-dashboard .profile-menu .ant-menu-item.ant-menu-item-selected span,
.w-secondary-menu .profile-menu .ant-menu-item.ant-menu-item-selected span {
    color: #6B54F9 !important
}

.employee-dashboard .profile-menu .ant-menu-item.ant-menu-item-selected svg path,
.w-secondary-menu .profile-menu .ant-menu-item.ant-menu-item-selected svg path {
    fill: #8775FA !important;
}

#busable-embed .SchoolStopList .filter-options-main.top-float {
    display: none !important;
}

.accessible-active path {
    fill: #6B54F9 !important;
}

.accessible-active svg path {
    fill: #6B54F9 !important;
}

.ant-switch {
    outline: none !important;
}

.ant-switch.verify-switch.ant-switch-checked {
    background: #7ED668;
}

.ant-switch.verify-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #52C41A;
}

.ant-switch.default-switch.ant-switch-checked {
    background: #6B54F9;
}

.ant-switch.default-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #5b45e9;
}

.EditStop {
    padding: 0 5px;
}

.FormGroup {
    margin-bottom: 16px;
}

.FormGroup .form-label {
    font-size: 14px;
    color: #888;
}

.FormGroup .ant-select,
.FormGroup .ant-picker {
    width: 100%;
}

.FormGroup .InputTimeField,
.FormGroup .form-control {
    width: 100%;
    border-radius: 6px;
    height: 32px;
    font-size: 14px;
    padding: 4px 10px;
    color: rgba(0, 0, 0, 0.88);
}

.FormGroup .InputTimeField:focus,
.FormGroup .form-control:focus {
    border-color: #4096ff;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    outline: 0;
}

.ButtonGroup {
    margin: 16px 0 0;
    width: 100%;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    justify-content: flex-end;
}

.header-icon {
    width: 17px;
}

.header-icon.w-16 {
    width: 15px;
}

.header-icon path {
    fill: #ccc;
}

.FormGroup .InputTimeField {
    width: 100% !important;
    border: 1px solid #d9d9d9 !important;
}

.FormGroup .InputTimeField:focus {
    border-color: #4096ff !important;
}

.FormGroup .ant-input-group-wrapper .ant-input-group > .ant-input {
    text-align: center;
    padding-left: 4px;
    padding-right: 4px;
}

.FormGroup .ant-input-group-wrapper .ant-input-group .ant-input-group-addon {
    padding: 0 4px;
    color: #555;
    font-size: 13px;
}

.SchoolBellTimes {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.SchoolBellTimes li {
    text-align: center;
    flex-shrink: 0 !important;
}

.SchoolBellTimes li:first-child,
.SchoolBellTimes li:nth-child(3) {
    min-width: 66px;
    width: calc(20% - 7.5px);
}

.SchoolBellTimes li:nth-child(2) {
    min-width: 84px;
    width: calc(28% - 7.5px);
}

.SchoolBellTimes li:last-child {
    min-width: 98px;
    width: calc(32% - 7.5px);
}

.ant-popover-content .data-layers-wrap {
    width: 100%;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.ant-popover-content .data-layers-wrap button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    border: 1px solid #e5e5e5 !important;
    height: 74px;
    padding: 8px 8px;
    grid-gap: 0 !important;
    gap: 0 !important;
    color: #555 !important;
    background: #fff !important;
    font-weight: 500;
}

.ant-popover-content .data-layers-wrap button:hover,
.ant-popover-content .data-layers-wrap button.active {
    background: #E9E6FA !important;
    color: #6B54F9 !important;
    border-color: #E9E6FA !important;
}

.ant-popover-content .data-layers-wrap button svg {
    width: 40px;
    height: 34px;
}

.ControlPanel .CardHover,
.vehicle-card .ControlPanel {
    display: none !important;
    height: 0;
    visibility: hidden;
    animation: showfade .6s;
}

.ControlPanel .ant-row.CardHover {
    margin-top: 0 !important;
}

.ControlPanel .CardHover.hovered,
.vehicle-card .ControlPanel.ShowMoreInfo {
    display: flex !important;
    visibility: visible;
    height: auto;
}
.Dashboards {
    
}
.Dashboards.hide {
    animation: hidefade .6s;
    visibility: hidden;
    height: 0;
    display: none;
}
.Dashboards.show {
    animation: showfade .6s;
    visibility: visible;
    height: auto;
    display: flex;
}
@keyframes showfade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes hidefade {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.ControlPanel .ant-row.CardHover.hovered {
    margin-top: 14px !important;
}

.btn-more {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff !important;
    border: none;
    border-radius: 0 0 8px 8px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    box-shadow: none !important;
}

.btn-more svg path {
    stroke: #bbb;
}

.btn-more:hover,
.btn-more:active {
    background: #E9E6FA !important;
}

.btn-more:hover svg path,
.btn-more:active svg path {
    stroke: #6B54F9 !important;
}

.btn-more.opened svg {
    transform: rotate(180deg);
}


.nondroppable-row {
    transform: none !important;
}

.droppable-row {
    background: #1da1f2;
    transform: none !important;
    border: 1px solid #024f7a;
}

.dragging-row {
    background: rgba(255, 255, 255, .7) !important;
    border: 1px solid #f0f0f0 !important;
    border-radius: 18px;
    z-index: 9999;
    padding: 15px 20px;
    box-shadow: 0 0 15px rgba(107, 84, 249, .15) !important;
}

.dragged-row {
    background: #da9c9c !important;
    border: 1px solid #ff0000 !important;
    z-index: 9999;
}
.sb-edit-controls .sb-done,
.sb-edit-controls .sb-close {
    border: none !important;
    border-radius: 50px !important;
    width: 25px !important;
    height: 25px !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-shrink: 0 !important;
}
.sb-edit-controls .sb-done svg,
.sb-edit-controls .sb-close svg {
    height: 9px !important;
}
.sb-edit-controls .sb-done {
    background: #D9F3D2 !important;
}
.sb-edit-controls .sb-done svg path {
    stroke: #7ED668 !important;
}
.sb-edit-controls .sb-close {
    background: #FFE6E6 !important;
}
.sb-edit-controls .sb-close svg path {
    fill: #FF4545 !important;
}
.sb-edit-controls .sb-done:hover {
    background: #7ED668 !important;
}
.sb-edit-controls .sb-done:hover svg path {
    stroke: #fff !important;
}
.sb-edit-controls .sb-close:hover {
    background: #FF4545 !important;
}
.sb-edit-controls .sb-close:hover svg path {
    fill: #fff !important;
}
.Marketplace .icon-button:hover svg path,
.back-btn:hover svg path {
  stroke: #fff !important;
}
.back-btn {
    position: absolute;
    top: 15px;
    left: 15px;
}
.RequestQuota {
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    overflow: hidden;
}
.RequestQuota > .ant-row > .ant-col {
    min-height: 78px;
    padding: 0 25px;
}
.RequestQuota > .ant-row > .ant-col:last-of-type {
    background: #f6f6f6;
    border-left: 1px solid #d9d9d9;
}
.RequestQuota .icon-button {
    width: 30px !important;
    height: 30px !important;
}
.RequestQuota .icon-button svg {
    width: 12px;
    height: 12px;
}
.RequestQuota .icon-button svg path {
    fill: #6B54F9;
}
.RequestQuota .icon-button:hover svg path {
    fill: #fff;
}
.list-link {
    color: rgba(0, 0, 0, 0.88);
}
.list-link:hover {
    color: #6B54F9;
}
.MapSearch {
    position: absolute;
    z-index: 999;
    top: 8px;
    left: 155px;
    width: calc(60% - 175px);
}
.MapSearch > div > .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    width: 100%;
    min-width: 250px;
    max-width: none;
}
.MapSearch .mapboxgl-ctrl-geocoder--input {
    height: 40px;
}
.MapSearch .mapboxgl-ctrl-geocoder--icon {
    top: 10px;
}
.MapSearch.PointsMap {
    top: 30px;
    left: 44px;
}
.App.publicContainer .Timetable .MapPlaceholder div > .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    top: -95px;
}
@media screen and (min-width: 1024px) {
    .MapSearch.PointsMap > div > .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
        min-width: 30vw !important;
    }
    .Timetable .MapPlaceholder div > .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
        min-width: 30vw !important;
    }
}
.SwitchLarge .ant-switch-inner > span {
    font-size: 15px !important;
}
.EligibleSwitch label {
    text-align: right !important;
    align-items: end !important;
}
.EligibleSwitch strong {
    width: 100% !important;
    background: none !important;
}

.student-editor-wrapper {
    margin-top: 10px;
}
.student-editor-wrapper .rdw-editor-toolbar,
.student-editor-wrapper .student-editor,
.PrintWrapper .rdw-editor-toolbar,
.PrintWrapper .rdw-editor-main {
    border: 1px solid #ddd;
    border-radius: 8px;
}
.student-editor-wrapper .student-editor,
.PrintWrapper .rdw-editor-main {
    padding: 10px 20px;
}
.student-editor-wrapper .student-editor .rdw-suggestion-dropdown {
    min-width: 200px !important;
    max-height: 250px !important;
    box-shadow: 0 0 15px rgba(107, 84, 249, .2);
}
.misbehaviour-notes-editor {
    border: 1px solid #595959;
    border-radius: 10px;
    height: auto;
}
.PreviewWrapper {
    width: 100%;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    display: flex;
    padding: 40px 30px;
}
@media only screen {
    .PrintWrapper {
        width: 100%;
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        display: flex;
        min-height: 1120px;
      }
    .modal-info.modal-body-fixed .PrintWrapper {
      /* width: calc(100% + 2px); */
      margin: -1px 0;
    }
    .modal-info.modal-body-fixed .PrintWrapper:last-of-type {
        margin-bottom: 0 !important;
        border-bottom: none;
    }
}
.PrintWrapper .PrintHeader,
.PrintWrapper .PrintFooter {
    width: 100%;
    padding: 50px 50px 0;
}
.PrintWrapper .PrintHeader .text-right {
    text-align: right !important;
}
.PrintWrapper .PrintHeader .text-small,
.PrintWrapper .PrintHeader .text-small p,
.PrintWrapper .PrintHeader .text-small strong {
    font-size: 12px !important;
    line-height: 14px !important;
}
.PrintWrapper .PrintHeader .text-small p {
    margin-bottom: 5px !important;
}
.PrintWrapper .PrintHeader p {
    margin-bottom: 20px !important;
}
.PrintWrapper .PrintHeader p {
    font-weight: 400 !important;
}
.PrintWrapper .PrintFooter {
    padding-top: 15px;
}
.PrintWrapper .PrintFooter h3 {
    font-size: 18px !important;
    margin: 0 0 15px;
}
.PrintWrapper .PrintFooter p > span {
    min-width: 85px;
    display: inline-block;
    font-weight: 400;
    font-style: italic;
}
.PrintWrapper .PrintSheet .PrintPass {
    min-height: 275px;
    min-width: 300px;
}
.PrintWrapper .PrintSheet .PrintPass.LockRatio {
    height: auto !important;
    aspect-ratio: 16/9;
}

.charter-details .ant-carousel .slick-arrow,
.ControlPanel .ant-carousel .slick-arrow {
    color: #333;
    z-index: 16;
}
.charter-details .ant-carousel .slick-arrow:hover,
.ControlPanel .ant-carousel .slick-arrow:hover {
    transform: translateY(-50%) !important;
    color: #6B54F9;
}
.charter-details .ant-carousel .slick-arrow.slick-disabled,
.ControlPanel .ant-carousel .slick-arrow.slick-disabled {
    opacity: 0.15;
}

.charter-details .ant-carousel .slick-arrow.slick-next,
.charter-details .ant-carousel .slick-arrow.slick-prev {
    top: 0;
    bottom: auto;
}

.charter-details .ant-carousel .slick-arrow.slick-next {
    right: 0;
}
.charter-details .ant-carousel .slick-arrow.slick-prev {
    left: auto;
    right: 24px;
}
.charter-details .ant-carousel .slick-dots {
    display: none !important;
}
.ControlPanel .ant-carousel .slick-arrow.slick-prev,
.ControlPanel .ant-carousel .slick-arrow.slick-next {
    top: auto;
    bottom: 0;
}
.ControlPanel .ant-carousel .slick-arrow.slick-prev {
    left: 0;
}
.ControlPanel .ant-carousel .slick-arrow.slick-next {
    left: 16px;
}
.ControlPanel .ant-carousel .slick-dots {
    display: none !important;
}
.ControlPanel .ant-carousel .slick-dots li {
    background-color: #E8E6FA;
}
.ControlPanel .ant-carousel .slick-dots li:hover button {
    background-color: #6B54F9;
}
.ControlPanel .ant-carousel .slick-dots li.slick-active button {
    background-color: #6B54F9;
    border: 1px solid #6B54F9;
}
.ControlPanel .CarouselInfo {
    color: #999 !important;
    position: absolute;
    bottom: 20px;
    left: 60px;
}
.ControlPanel .CarouselActions {
    position: absolute;
    bottom: 13px;
    right: 14px;
    grid-gap: 6px;
    gap: 6px;
    display: flex;
}
.ControlPanel .archived-active {
    background-color: #5d47eb;
    color: white;
}
.text-xs-info {
    color: #ADADAD !important;
    font-size: 13px;
    line-height: 13px;
}
.text-title-sm {
    font-size: 14px;
    color: #333;
    line-height: 14px;
    font-weight: 500;
}
.icon-button.secondary-btn {
    background: none !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 6px !important;
    padding: 0 !important;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-button.secondary-btn:hover,
.icon-button.secondary-btn.active {
    background: #E9E6FA !important;
    border: 1px solid #DCD7FA !important;
}
.icon-button.secondary-btn:not(.icon-stroke) svg path {
    fill: #999;
}
.icon-button.secondary-btn.icon-stroke svg path {
    stroke: #999;
}
.icon-button.secondary-btn:not(.icon-stroke):hover svg path,
.icon-button.secondary-btn.active:not(.icon-stroke) svg path {
    fill: #6B54F9;
}
.icon-button.secondary-btn.icon-stroke:hover svg path,
.icon-button.secondary-btn.icon-stroke.active svg path {
    stroke: #6B54F9;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .NewCommentWrap .icon-button.secondary-btn {
    bottom: -30px;
    right: 2px;
    width: 24px !important;
    height: 24px !important;
}
.RouteListContainerItem .RouteTitle .RouteLogo-sm {
    width: 40px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    border-radius: 6px;
    border: 1px solid lightgray;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}
.RouteListContainerItem .RouteTitle .RouteLogo-sm img {
    max-height: 40px;
    max-width: 40px;
    padding: 1px;
    border-radius: 6px;
    margin: 0;
}
.RouteListContainerItem .RouteTitle .RouteNumber-sm {
    height: 28px;
    border-radius: 0 6px 6px 0;
}
.RouteListContainerItem .RouteTitle .RouteInfo-sm {
    margin-left: 12px !important;
    width: auto !important;
}
.RouteListContainerItem .RouteTitle .RouteInfo-sm h1 {
    font-size: 14px;
    font-weight: 500 !important;
    margin: 0;
    padding: 0;
}
.badge-beta:after {
    content: 'beta';
    font-size: 10px;
    line-height: 10px;
    font-weight: 600;
    background: rgba(255,255,255,1);
    color: #6B54F9;
    border-radius: 10px;
    padding: 1px 3px;
    text-transform: none;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translate(0,-50%);
}
.DateSelector {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 12px;
    gap: 12px;
    background: #fff;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(107, 84, 249, .1);
}
.DateSelector button {
    width: 26px;
    height: 26px;
    background: #D3CCFD;
    border: none;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    outline: none;
}
.DateSelector button svg path {
    fill: #fff;
}
.DateSelector button:hover {
    background: #6B54F9;
}
.DateSelector button:first-of-type svg {
    transform: rotate(180deg);
}
.DateSelector strong {
    color: #999;
    font-size: 14px;
    font-weight: 500;
}
.ShareModal > .row > [class^="col-"] {
    margin-bottom: 25px;
}
.VehicleBearing {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.VehicleBearingMarker {
    width: 56px;
    height: 56px;
    background: url(/static/media/Bearing.b7f2a704.svg);
    background-size: 56px;
    background-position: center;
}
.fixed-tooltip .ant-tooltip-content {
    max-height: 400px;
    overflow-y: auto;
    border-radius: 6px;
}
.ant-tabs .ant-tabs-tab:hover,
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #6B54F9;
}
.icon-success {
    background: #7ED668;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-success svg {
    width: 10px !important;
}
.expand-button-wrap {
    width: 100%;
    text-align: center;
    margin-top: -16px;
}
.expand-button-wrap.w-line {
    border-top: 1px solid #f0f0f0;
    margin-top: 0;
}
.expand-button-wrap .icon-button {
    border-radius: 0 0 50px 50px !important;
    padding: 0 10px !important;
    height: auto !important;
    background: #f0f0f0 !important;
}
.expand-button-wrap .icon-button svg {
    width: 18px;
    height: 18px;
}
.expand-button-wrap .icon-button svg path {
    stroke: #a5a5a5;
}
.expand-button-wrap .icon-button:hover,
.expand-button-wrap .icon-button.active {
    background: #E9E6FA !important;
}
.expand-button-wrap .icon-button.active svg {
    transform: rotate(180deg);
}
.expand-button-wrap .icon-button:hover svg path,
.expand-button-wrap .icon-button.active svg path {
    stroke: #6B54F9;
}
.ant-card.body-100 .ant-card-body {
    height: calc(100% - 32px);
}
.multi-select .ant-select-selector {
    background: #fff !important;
    overflow: hidden;
}
.multi-select .ant-select-selector .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
    display: none !important;
}
.multi-select.rounded .ant-select-selector {
    border-radius: 50px;
}
.ant-select-outlined.ant-select-multiple .ant-select-selection-item {
    height: 20px;
    border-radius: 50px;
    margin: 1px;
}
.ant-select-outlined.ant-select-multiple .ant-select-selection-item .ant-select-selection-item-content {
    font-size: 12px !important;
    line-height: 18px !important;
}

.StepsMenu.ant-steps .ant-steps-item-process .ant-steps-item-icon {
background-color: #E9E6FA;
border-color: #C4BBFD;
}
.StepsMenu.ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
color: #6B54F9;
font-weight: 500;
}
.StepsMenu.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
background-color: #fff;
border-color: #E6E7EB;
}
.StepsMenu.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
color: #BABABA;
font-weight: 500;
}
.StepsMenu.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
background-color: #C4BBFD;
}
.StepsMenu.ant-steps.ant-steps-label-vertical .ant-steps-item-content {
margin-top: 8px;
}
.StepsMenu.ant-steps.ant-steps-label-vertical .ant-steps-item-content .ant-steps-item-title {
font-size: 14px;
font-weight: 500;
line-height: 18px;
}
.StepsMenu.ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.StepsMenu.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
color: #757575;
}
.StepsMenu.ant-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
color: #BABABA;
}
.StepsMenu.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
background-color: #F6FFED;
border-color: #B7EB8F;
}
.StepsMenu.ant-steps .ant-steps-item-finish .ant-steps-item-icon .ant-steps-finish-icon svg {
fill: #52C41A;
}
.StepsMenu.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
    background-color: #6B54F9 !important;
}

.StepsActions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin: 10px 0 30px;
}
.primary-btn-sm {
    background: #6B54F9;
    outline: none !important;
}
.primary-btn-sm:hover {
    background: #7b69f5 !important;
}
.secondary-btn-sm {
    background: none !important;
    outline: none !important;
}
.secondary-btn-sm:hover {
    color: #6B54F9 !important;
    border-color: #6B54F9 !important;
}
/* .page-title .page-subtitle {
    font-size: 18px !important;
    color: #a8a8a8 !important;
    margin-left: 20px;
} */
.tag-sm {
    border-radius: 50px;
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    padding: 0px 4px;
    height: auto;
    background: #c6cad0;
    border-color: #c1c5ce;
    color: #fff;
    margin-bottom: 2px;
}
@media print {
    .ant-modal-content .hide-print-wrap,
    .ant-modal-content .hide-print-wrap *,
    .hide-print-wrap {
        visibility: hidden !important;
    }
}
.VehicleCard .RouteLogo .ant-avatar .ant-avatar-string {
    font-weight: 600;
    font-size: 16px;
    background: rgba(255, 255, 255, .4);
    border-radius: 50px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.info-label {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: #ADADAD;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}
.primary-btn.btn-small,
.btn-secondary.btn-small {
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 6px 15px !important;
    min-width: auto !important;
    height: auto;
    border-radius: 8px;
}
.ColorSuccess.ant-switch-checked {
    background: #52C41A !important;
}
.top-float-title {
    position: absolute;
    top: 0;
    left: 16px;
}
@media screen and (min-width: 1024px) {
    .top-float-title {
        top: 25px;
        left: 195px;
    }
}
.info-content {
    display: flex;
    flex-direction: column;
    grid-gap: 14px;
    gap: 14px;
    width: 100%;
}
.info-content > div {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}
.info-content > div > label {
    margin: 0;
    color: #666;
    font-weight: 400;
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    width: 110px;
    flex-shrink: 0;
    word-break: break-word;
}
.info-content > div > label svg {
    flex-shrink: 0;
}
.info-content > div {
    color: #444;
    font-weight: 500;
    line-height: 14px;
    grid-gap: 8px;
    gap: 8px;
    word-break: break-word;
}
.info-content > div > div {
    word-break: break-word;
}
.info-content .info-content-allocation {
    border-top: 1px solid #EDEFF0;
    padding-top: 12px;
}
.info-content .info-content-allocation > div {
    display: flex;
    grid-gap: 6px;
    gap: 6px;
    align-items: center;
}
.info-content .info-content-allocation > div strong {
    font-weight: 500;
}
.info-content .info-content-allocation .ant-avatar {
    line-height: 14px;
    flex-shrink: 0;
}
.info-content .info-content-allocation .ant-avatar svg path {
    fill: #fff;
}
.info-content .info-content-issues {
    background: #FDF2F0;
    border: 1px solid #FCC3C1;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.info-content .info-content-issues strong {
    width: 100%;
    color: #FF4D4F;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}
.info-content .info-content-issues div:before {
    content: '';
    width: 8px;
    height: 8px;
    background: #FD6868;
    display: inline-flex;
    border-radius: 50px;
    flex-shrink: 0;
    margin-top: 4px;
}
.info-content .info-content-issues div {
    width: 100%;
    color: #444;
    display: flex;
    font-weight: 400;
    grid-gap: 6px;
    gap: 6px;
}
.filters-popover {
    width: 260px;
}
.filters-popover .ant-popover-inner {
    padding: 18px 20px 20px;
}
.filters-popover .filter-wrap {
    padding-top: 10px;
}
.filters-popover .filter-wrap label {
    margin-bottom: 0;
}
.filters-popover .ant-popover-title > div {
    min-height: 26px;
}
.filters-popover .ant-popover-title .ant-btn.icon-button.btn-filled {
    box-shadow: none !important;
    background: #F4F4F4 !important;
    color: #888 !important;
    margin-right: 0;
    height: 26px !important;
}
.filters-popover .ant-popover-title .ant-btn.icon-button.btn-filled svg path {
    fill: #888 !important;
}
.filter-switches-sm {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
    margin-top: 18px;
}
.filter-switches-sm > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.filter-switches-sm > div span {
    display: inline-flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
}
.filter-switches-sm > div span i {
    width: 10px;
    height: 10px;
    border-radius: 20px;
}
.filter-switches-sm > div span,
.filters-popover .filter-wrap label {
    font-weight: 500;
    color: #555;
    line-height: 16px;
}
.icon-light path {
    fill: #fff;
}
.icon-dark path {
    fill: #C1C5CE;
}
.error-tooltip .ant-tooltip-content .ant-tooltip-inner,
.error-tooltip .ant-tooltip-arrow:before {
    background: #FDF2F0 !important;
}
.error-tooltip .ant-tooltip-content .ant-tooltip-inner {
    padding: 3px;
}
.App.publicContainer {
    padding: 0 !important;
}
.TripPlanner {
    font-family: 'Inter', sans-serif !important;
    font-weight: 400 !important;
    overflow: visible !important;
    min-height: 100vh !important;
}
.TripPlanner button,
.TripPlanner button:focus {
    outline: none !important;
    box-shadow: none !important;
}
.TripPlanner > .row {
    margin: 0 !important;
}
.TripPlanner > .row > .col-md-8 {
    padding: 0 !important;
    overflow: hidden !important;
}
.TripPlanner .MapPlaceholder {
    border: none !important;
}
@media screen and (max-width: 991px) {
    .TripPlanner:not(.PrintView) .MapPlaceholder,
    .TripPlanner:not(.PrintView) .MapPlaceholder .close-map {
        display: none !important;
    }
    .TripPlanner .MapPlaceholder.ShowMap {
        display: block !important;
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        z-index: 99998 !important;
        height: 100% !important;
        width: 100% !important;
    }
    .TripPlanner .MapPlaceholder.ShowMap .close-map {
        display: block !important;
        position: fixed !important;
        top: 15px !important;
        right: 20px !important;
        z-index: 99999 !important;
        cursor: pointer !important;
    }
    .TripPlanner .MapPlaceholder.ShowMap .close-map svg {
        width: 20px !important;
        height: 20px !important;
    }
    #busable-embed {
        position: relative;
    }
    #busable-embed .TripPlanner .MapPlaceholder.ShowMap,
    #busable-embed .TripPlanner .MapPlaceholder.ShowMap .close-map {
        position: absolute !important;
    }
    .TripPlanner .m-md-0.col-lg-8 {
        position: unset !important;
    }
    .TripPlanner:not(.PrintView) .RouteListContainer .TripContainer .TripOverview .show-map-btn {
        display: block !important;
    }
}
.TripPlanner:not(.PrintView) .RouteListContainer {
    padding: 0 !important;
    max-height: 100vh !important;
    min-height: 100vh !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    background: #f7f9fa !important;
}
@media screen and (min-width: 992px) {
    .TripPlanner .RouteListContainer {
        box-shadow: 0 0 20px rgba(0,0,0,0.1) !important;
    }
}
.TripPlanner .RouteListContainer .SearchContainer {
    padding: 14px 0 0 !important;
    background: #fff !important;
}
.TripPlanner .RouteListContainer .SearchContainer > .row {
    margin: 0 !important;
    width: 100% !important;
}
.TripPlanner .RouteListContainer .SearchContainer .mapboxgl-ctrl-geocoder {
    box-shadow: none !important;
    border: 1px solid #D9D9D9 !important;
    border-radius: 6px !important;
    width: 100% !important;
    max-width: 100% !important;
}
.TripPlanner .RouteListContainer .SearchContainer .from-col-1 button,
.TripPlanner .RouteListContainer .SearchContainer .to-col-1 button {
    padding: 0 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .Walking svg {
    height: 22px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .Driving svg {
    width: 32px !important;
    height: 21px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .from-col-1 .ConnectDetails,
.TripPlanner .RouteListContainer .SearchContainer .to-col-1 .ConnectDetails {
    width: max-content !important;
    margin-top: -2px !important;
    color: #000 !important;
    font-size: 11px !important;
    line-height: 15px !important;
    border: 1px solid #D9D9D9 !important;
    padding: 0 !important;
    width: 48px !important;
    border-radius: 4px !important;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
    text-align: center !important;
    background-color: #fff !important;
}
.TripPlanner .RouteListContainer .SearchContainer .Driving .ConnectDetails {
    margin-top: -5px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .from-col-1 button:hover .ConnectDetails,
.TripPlanner .RouteListContainer .SearchContainer .to-col-1 button:hover .ConnectDetails,
.TripPlanner .RouteListContainer .SearchContainer .switch-col-1 .SwitchBtn:hover {
    border-color: #6B54F9 !important;
    background-color: #E9E6FA !important;
    color: #3d29bb !important;
}
.TripPlanner .RouteListContainer .SearchContainer .from-col-2,
.TripPlanner .RouteListContainer .SearchContainer .to-col-2 {
    width: 100% !important;
    padding: 0 0 0 8px !important;
    position: relative !important;
}
.TripPlanner .RouteListContainer .SearchContainer .from-col-2 {
    z-index: 9 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .mapboxgl-ctrl-geocoder {
    min-width: auto !important;
}
.TripPlanner .RouteListContainer .SearchContainer .to-col-2 {
    z-index: 8 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .from-col-2 input,
.TripPlanner .RouteListContainer .SearchContainer .to-col-2 input {
    padding: 8px 35px !important;
    outline: none !important;
    font-size: 14px !important;
    height: 36px !important;
    background-color: transparent !important;
    color: #1A1A1A !important;
    border: none !important;
}
.TripPlanner .RouteListContainer .SearchContainer .from-col-2 .ant-select,
.TripPlanner .RouteListContainer .SearchContainer .to-col-2 .ant-select {
    padding: 8px 35px !important;
    outline: none !important;
    font-size: 14px !important;
    height: 36px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .from-col-3,
.TripPlanner .RouteListContainer .SearchContainer .to-col-3 {
    width: 50px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .mapboxgl-ctrl-geocoder--icon-search {
    top: 8px !important;
    left: 7px !important;
    width: 20px !important;
    height: 20px !important;
}
.TripPlanner .RouteListContainer .SearchContainer > .row > .flex-row {
    padding: 6px 10px !important;
    width: 100% !important;
    align-items: center !important;
}
@media screen and (min-width: 768px) {
    .TripPlanner .RouteListContainer .SearchContainer > .row > .flex-row {
        padding: 6px 18px !important;
    }
}
@media screen and (min-width: 992px) {
    .TripPlanner > .row > .col-md-8 {
        border-radius: 20px 0 0 20px !important;
    }
    .TripPlanner .RouteListContainer {
        border-radius: 0px 20px 20px 0px !important;
    }
}
.TripPlanner .TargetBtn {
    cursor: pointer !important;
    width: 32px !important;
    height: 36px !important;
    position: absolute !important;
    z-index: 99 !important;
    right: 1px !important;
    top: 1px !important;
    background: #F7F7F7 !important;
    margin: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 0 6px 6px 0 !important;
}
.TripPlanner .TargetBtn:hover {
    background-color: #E9E6FA !important;
}
.TripPlanner .TargetBtn svg path {
    fill: #ADADAD !important;
}
.TripPlanner .RouteListContainer .SearchContainer .Driving button:hover svg path,
.TripPlanner .TargetBtn:hover svg path {
    fill: #6B54F9 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .Walking button:hover svg path,
.TripPlanner .RouteListContainer .SearchContainer .Walking button:hover svg polygon {
    fill: #6B54F9 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .Walking button:hover svg path:first-of-type {
    fill: none !important;
    stroke: #6B54F9 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .switch-col-1:before,
.TripPlanner .RouteListContainer .SearchContainer .switch-col-1:after {
    content: '' !important;
    width: 5px !important;
    height: 5px !important;
    background: #E8E8E8 !important;
    display: block !important;
    border-radius: 50px !important;
    position: absolute !important;
    left: 50% !important;
    transform: translate(-50%,0) !important;
}
.TripPlanner .RouteListContainer .SearchContainer .switch-col-1:before {
    top: 0 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .switch-col-1:after {
    bottom: 0 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .switch-col-1 {
    position: absolute !important;
    right: 6px !important;
    z-index: 999 !important;
    width: 35px !important;
    height: 65px !important;
    top: 50% !important;
    transform: translate(0,-50%) !important;
}
@media screen and (min-width: 768px) {
    .TripPlanner .RouteListContainer .SearchContainer .switch-col-1 {
        right: 15px !important;
    }
}
.TripPlanner .RouteListContainer .SearchContainer .switch-col-1 .SwitchBtn {
    width: 35px !important;
    height: 35px !important;
    border: 1px solid #E8E8E8 !important;
    border-radius: 50px !important;
    background: #fff !important;
    color: #444 !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.TripPlanner .RouteListContainer .SearchContainer .switch-col-1 .switch-line {
    height: 65px !important;
    width: 1px !important;
    background: #E8E8E8 !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: -1 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 {
    margin: 10px 0 0 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .flex-row {
    padding-top: 12px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .flex-row .btn-wrap {
    display: flex !important;
    width: 100% !important;
    position: relative !important;
    height: 26px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .pill-field-btn {
    color: #444 !important;
    font-weight: 500 !important;
    text-decoration: none !important;
    background: #fff !important;
    width: 100% !important;
    height: 26px !important;
    border: 1px solid #D9D9D9 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .pill-btn,
.TripPlanner .RouteListContainer .TripContainer .TripOverview .show-map-btn {
    background: #E9E6FA !important;
    justify-content: center !important;
    color: #6B54F9 !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
    letter-spacing: 0.2px !important;
    height: 24px !important;
    top: 1px !important;
    right: 1px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .btn-wrap:hover .pill-btn,
.TripPlanner .RouteListContainer .TripContainer .TripOverview .show-map-btn:hover {
    background: #6B54F9 !important;
    color: #fff !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .btn-wrap:hover .pill-btn svg path,
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .btn-wrap:hover .pill-btn svg line {
    stroke: #fff !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .pill-btn svg {
    margin-right: 6px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .show-map-btn svg {
    margin-right: 3px !important;
    width: 18px !important;
    height: 18px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .show-map-btn:hover svg path {
    fill: #fff !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .pill-field-btn,
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .pill-btn,
.TripPlanner .RouteListContainer .TripContainer .TripOverview .show-map-btn {
    border-radius: 50px !important;
    font-size: 11px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    position: absolute !important;
    padding: 0 12px !important;
    line-height: 16px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .show-map-btn {
    background: #F2F2F2 !important;
    padding: 0 8px 0 4px !important;
    position: unset !important;
    top: unset !important;
    right: unset !important;
    letter-spacing: 0.1px !important;
    margin: 0 !important;
    min-width: 94px !important;
}
@media screen and (min-width: 992px) {
    .TripPlanner .RouteListContainer .TripContainer .TripOverview .show-map-btn,
    .TripPlanner .MapPlaceholder .close-map {
        display: none !important;
    }
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1,
.TripPlanner .RouteListContainer .SearchContainer .filter-row-2 {
    background: #F5F5F5 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-2 {
    padding: 6px 3px 12px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-2 small {
    font-size: 11px !important;
    margin: 0 !important;
    width: 55px !important;
    line-height: 12px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-2 .ant-select.ant-select-show-arrow {
    width: 100% !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-2 .ant-select.ant-select-show-arrow .ant-select-selector {
    border-radius: 6px !important;
    border: 1px solid #D9D9D9 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-2 .ant-select.ant-select-show-arrow .ant-select-selection-item,
.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft .ant-select-item-option-content {
    font-size: 11px !important;
    font-weight: 500 !important;
    color: #444 !important;
    line-height: 22px !important;
    text-transform: capitalize !important;
}
.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
    padding: 0 !important;
    border-radius: 6px !important;
}
.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #E9E6FA !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-2 .col-sm-6 {
    display: flex !important;
    align-items: center !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-2 .col-sm-6:first-of-type {
    margin-bottom: 8px !important;
}
@media screen and (max-width: 767px) {
    .TripPlanner .RouteListContainer .SearchContainer .filter-row-2 > .col,
    .TripPlanner .RouteListContainer .SearchContainer .filter-row-2 > .col-auto {
        padding-left: 8px;
        padding-right: 8px;
    }
}
@media screen and (min-width: 576px) {
    .TripPlanner .RouteListContainer .SearchContainer .filter-row-2 .col-sm-6:first-of-type {
        margin-bottom: 0 !important;
    }
}
.TripPlanner .RouteListContainer .msg-warning {
    padding: 20px 18px !important;
}
.TripPlanner .RouteListContainer .msg-warning p {
    border-radius: 6px !important;
    border: 1px solid #ffd494 !important;
    background: #fffaea !important;
    padding: 8px 12px !important;
    font-size: 13px !important;
    line-height: 19px !important;
}
.TripPlanner .RouteListContainer .msg-warning p:first-of-type {
    border-radius: 6px !important;
    border: 1px solid #ffbaaa !important;
    background: #fff0ee !important;
}
.TripPlanner .RouteListContainer .msg-warning p button {
    font-size: 13px !important;
    line-height: 19px !important;
    padding: 0 !important;
    border: none !important;
}
.TripPlanner .RouteListContainer .msg-warning p button,
.TripPlanner .RouteListContainer .msg-warning p a {
    color: #3d29bb !important;
}
.TripPlanner .RouteListContainer .msg-warning p button:hover,
.TripPlanner .RouteListContainer .msg-warning p a:hover {
    text-decoration: underline !important;
}

.TripPlanner .RouteListContainer .TripContainer {
    padding: 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-wrap {
    width: 100% !important;
    margin: 0 !important;
    padding: 8px 18px 14px !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-wrap > .row > .col {
    padding: 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-wrap {
    background: #FAFAFA !important;
    border: 1px solid #E9E9E9 !important;
    padding: 15px !important;
    border-radius: 6px !important;
    margin: 6px 0 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-block {
    position: relative !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-time-block {
    font-weight: 600 !important;
    font-style: italic !important;
    font-size: 11px !important;
    padding: 1px 4px !important;
    color: rgb(0,0,0,0.5) !important;
    position: relative !important;
    z-index: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-time-block:after {
    content: '' !important;
    border: 1px solid #D9D9D9 !important;
    background: #fff !important;
    border-radius: 4px !important;
    min-width: 42px !important;
    position: absolute !important;
    top: 0 !important;
    left: 50% !important;
    transform: translate(-50%,0) !important;
    height: 100% !important;
    width: 100% !important;
    z-index: -1 !important;
}

.TripPlanner .RouteListContainer .TripContainer .trip-expanded-time-block.TimingPoint {
    color: #000 !important;
    font-style: normal !important;
}

.TripPlanner .RouteListContainer .TripContainer .trip-expanded-depart {
    margin: 0px !important;
    position: absolute !important;
    top: -17px !important;
    left: -16px !important;
    display: none !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-arrive {
    margin: 0px !important;
    position: absolute !important;
    bottom: 42px !important;
    left: -14px !important;
    display: none !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-line-row {
    height: 18px !important;
    flex-wrap: nowrap !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-line-row .col-11 {
    padding-right: 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-line {
    margin: 0 0 0 9px !important;
    border-left: 1px solid #DFDFDF !important;
    height: 100% !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-stops-link {
    margin: 0 !important;
    position: absolute !important;
    top: 38px !important;
    left: 82px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .leaving-wrap {
    border-right: 1px solid #EBEBEB !important;
    padding-right: 10px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .leaving-text {
    font-size: 11px !important;
    color: #7F7F7F !important;
    font-weight: 500 !important;
    width: 52px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .leaving-time {
    font-size: 26px !important;
    color: #000 !important;
    font-weight: 700 !important;
    letter-spacing: -2px !important;
    line-height: 24px !important;
    margin-top: 2px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-details-wrap,
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer,
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer-arrow {
    padding: 6px 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .route-number-wrap .RouteLogo-sm {
    width: 44px !important;
    height: 44px !important;
    border-radius: 6px !important;
    border-width: 1px !important;
    border-style: solid !important;
    overflow: hidden !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background: #fff !important;
}
.TripPlanner .RouteListContainer .TripContainer .route-number-wrap .RouteLogo-sm img {
    width: auto !important;
    height: 94% !important;
    margin: 0 !important;
    max-width: none !important;
    max-height: 100% !important;
}
.TripPlanner .RouteListContainer .TripContainer .route-number-wrap .RouteLogo-sm img.BusIcon {
    height: 70% !important;
}
.TripPlanner .RouteListContainer .TripContainer .route-number-wrap .RouteNumber-sm {
    color: #fff !important;
    text-align: center !important;
    border-radius: 0 6px 6px 0 !important;
    font-weight: 700 !important;
    padding: 2px 6px !important;
    font-size: 11px !important;
    letter-spacing: 0.2px !important;
    line-height: 15px !important;
    height: auto !important;
    text-shadow: 0 0 5px rgba(0,0,0,0.25) !important;
    min-width: 44px !important;
    margin: -1px 0 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .DetailsLabel {
    margin: 5px 0 0 !important;
    color: #7F7F7F !important;
    font-size: 11px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap {
    width: 100% !important;
    padding: 0 0 0 10px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-duration-wrap {
    margin: 4px 0 !important;
    display: flex !important;
    align-items: flex-start !important;
    justify-content: space-between !important;
    text-align: left !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-duration-wrap strong {
    font-size: 14px !important;
    font-weight: 600 !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-duration-wrap div span {
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 0 0 0 10px !important;
    color: #666 !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-stops-link,
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-full-link {
    font-size: 11px !important;
    font-weight: 600 !important;
    border-radius: 50px !important;
    color: #6B54F9 !important;
    background: #E9E6FA !important;
    text-transform: uppercase !important;
    padding: 4px 12px !important;
    line-height: 12px !important;
    letter-spacing: 0.2px !important;
    transition: none !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-stops-link:hover,
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-full-link:hover {
    color: #fff !important;
    background: #6B54F9 !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-full-link {
    margin: 15px 0 10px !important;
    display: inline-block !important;
}
.TripPlanner .RouteListContainer .TripContainer .route-step-name {
    color: #000 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    padding: 2px 0 0 8px !important;
    text-align: left !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-wrap .row:not(.route-change-bus-row) {
    width: 100% !important;
    margin: 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .route-change-bus-row {
    background: #fff !important;
    margin: 10px 0 !important;
    padding: 5px 0 !important;
    width: 100% !important;
    border: 1px solid #E9E9E9 !important;
    border-radius: 6px !important;
}
.TripPlanner .RouteListContainer .TripContainer .route-change-bus-info {
    font-size: 13px !important;
    color: #666 !important;
    font-weight: 500 !important;
    color: #000 !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer div:first-of-type {
    height: 62px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer div:first-of-type svg,
.TripPlanner .RouteListContainer .TripContainer .route-change-bus-icon .connect-transfer-walking svg {
    width: 20px !important;
    height: 28px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer div:first-of-type svg path,
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer div:first-of-type svg polygon {
    fill: #bbb !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer div:first-of-type svg path:first-of-type {
    fill: none !important;
    stroke: #bbb !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .Leg {
    margin: 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer-arrow {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 8px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer-arrow svg {
    margin: 30px 0 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer-arrow svg path {
    fill: #bbb !important;
}
.TripPlanner .RouteListContainer .TripContainer .route-change-bus-icon {
    opacity: 0.3 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .suggestions-wrapper .suggestions {
    font-size: 13px !important;
}
.TripPlanner .RouteListContainer .stop-times-explainer {
    font-weight: 600 !important;
    font-style: italic !important;
    border: 1px solid #D9D9D9 !important;
    background: #fff !important;
    border-radius: 4px !important;
    font-size: 11px !important;
    padding: 2px 4px !important;
    color: #A9A9A9 !important;
}
.TripPlanner .print-tripplan {
    position: absolute !important;
    top: -2px !important;
    right: -2px !important;
    border-radius: 50px !important;
    padding: 4px !important;
}
.TripPlanner .print-tripplan:hover {
    background: #6B54F9 !important;
}
.TripPlanner .print-tripplan svg {
    width: 24px !important;
    height: 24px !important;
}
.TripPlanner .print-tripplan:hover svg path {
    fill: #fff !important;
}
@media screen and (max-width: 991px) {
    .TripPlanner .print-tripplan {
        top: 38px !important;
    }
}
.TripPlanner .pos-static {
    position: static !important;
}
@media print {
    @page {
        size: A4 !important;
        margin: 10mm 0 !important;
    }
    html, body {
        border: 1px solid white !important;
        overflow: hidden !important;
    }
    body * {
        visibility: hidden !important;
    }
    body {
        background: #fff !important;
    }
    .TripOverview .show-map-btn, .ant-modal-close, .TripPlanner .RouteListContainer .TripContainer .TripOverview .show-map-btn,
    .mapboxgl-ctrl, .ant-modal-header, .ant-modal-footer {
        display: none !important;
        visibility: hidden !important;
    }
    .ant-modal-content, .ant-modal-content *,
    #shiftbat-view.ShiftBat.PrintView *,
    #shiftbat-view.Itinerary.PrintView * {
        visibility: visible !important;
    }
    #shiftbat-view.ShiftBat.PrintView,
    #shiftbat-view.Itinerary.PrintView {
        padding-top: 20px !important;
    }
    .ant-modal-content {
        position: absolute !important;
        width: 100% !important;
        left: 0 !important;
        top: 0 !important;
        right: 0 !important;
        background: #fff !important;
        padding: 20px 0 0 !important;
        min-height: auto !important;
    }
    .ant-modal,
    .ant-modal-content {
        position: static !important;
    }
    .ant-modal {
        top: 0 !important;
        max-width: 100% !important;
        padding-bottom: 0 !important;
    }
    .ant-modal-wrap,
    .ant-modal-body,
    .ant-modal-root {
        overflow: visible !important;
    }
    .ant-modal-wrap {
        position: absolute !important;
        left: 0 !important;
        top: 0 !important;
        margin: 0 !important;
        padding: 0 !important;
    }
}
.TripPlanner.PrintView .MapPlaceholder {
    height: 665px !important;
    overflow: hidden !important;
}
.TripPlanner.PrintView .trip-expanded-block .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.TripPlanner.PrintView .MapPlaceholder #deckgl-wrapper {
    border-radius: 8px !important;
}
.TripPlanner.PrintView .RouteListContainer {
    box-shadow: none !important;
}
.TripPlanner.PrintView .RouteListContainer {
    border-radius: 0 !important;
    max-height: none !important;
    min-height: auto !important;
    overflow-y: visible !important;
    overflow-x: visible !important;
}
.TripPlanner.PrintView .RouteListContainer .trip-wrap {
    border: none !important;
    width: 100% !important;
}
.TripPlanner.PrintView .RouteListContainer .trip-wrap .TripContainer {
    width: 100% !important;
}
.TripPlanner.PrintView .TripOverview .show-map-btn {
    display: none !important;
}
.TripPlanner.PrintView .RouteListContainer .TripContainer .route-change-bus-row .flex-row.justify-content-center.align-items-center {
    justify-content: flex-start !important;
}
.TripPlanner.PrintView .font-weight-bolder.strong {
    margin: 30px 0 10px -15px !important;
    display: inline-block !important;
    padding: 0 8px !important;
    font-size: 17px !important;
}
.TripPlanner.PrintView .font-weight-bolder:focus {
    outline: #E9E6FA 0.5px auto !important;
    border-radius: 50px !important;
}
.TripPlanner.PrintView .customer-info-logo {
    margin-bottom: 10px !important;
}
.TripPlanner.PrintView .customer-info-logo .col,
.TripPlanner.PrintView .customer-info-text .col-12 {
    padding: 0 !important;
}

.button:hover, .button--round:hover, .button--transparent:hover, .button--blue:hover, .button--white:hover, button:hover, [type=button]:hover, [type=reset]:hover, [type=submit]:hover {
    transform: none !important;
}
#busable-embed .ant-switch.ant-switch-small,
button.ant-switch.ant-switch-small {
    padding: 0 !important;
}
#busable-embed .mapboxgl-ctrl-geocoder--input {
    border: 0 !important;
    background-color: transparent !important;
    margin: 0 !important;
    color: #404040 !important;
}
#busable-embed .btn.btn-link.btn-sm {
    background: none !important;
    color: #007bff !important;
}
#busable-embed .btn.btn-link.btn-sm small {
    font-size: 13px !important;
    font-weight: 500 !important;
}
#busable-embed .btn.btn-light.btn-sm .anticon {
    font-size: 18px !important;
}
#busable-embed .ant-modal-close {
    background: none !important;
    padding: 0 !important;
}
.ant-modal-wrap .ant-modal-close:hover {
    background: none !important;
}
#busable-embed .btn-group-sm > .btn,
#busable-embed .ant-modal-footer .btn.btn-sm {
    padding: 4px 8px !important;
    font-size: 14px !important;
}
#busable-embed .ant-modal-body h5,
#busable-embed .ant-modal-body h6 {
    font-size: 16px !important;
}
.TripPlanner .MapPlaceholder .mapboxgl-ctrl-group .mapbox-ctrl-icon.btn .anticon.anticon-global,
.TripPlanner .MapPlaceholder .mapboxgl-ctrl-group .mapbox-ctrl-icon.btn .anticon.anticon-search,
.TripPlanner .MapPlaceholder .mapboxgl-ctrl-group .mapbox-ctrl-icon.btn .anticon.anticon-filter {
    font-size: 16px !important;
}
.TripPlanner .MapPlaceholder .mapboxgl-ctrl-geocoder--input {
    height: 36px !important;
    padding: 6px 35px !important;
    background-color: transparent !important;
    color: #1A1A1A !important;
    border: none !important;
}
div[popper] .row button.btn.btn-primary.btn-sm,
div[popper] .row .btn-group button.btn.btn-warning.btn-sm,
div[popper] .row .btn-group button.btn.btn-success.btn-sm {
    font-size: 14px !important;
}
.TripPlanner .TripContainer .TripOverview .leaving-text {
    word-wrap: normal !important;
}

.TripOverview {
    width: 100%;
}

.TripOverview .Arrow {
    margin-right: 10px;
}

.TripOverview .DetailsLabel {
    /*margin: 10px 10px;*/
    font-size: x-small;
}

.TripOverview .ConnectStart {
}
.TripOverview .ConnectEnd {
    margin-left: 10px;
}
.TripOverview .Leg {
    margin-left: 10px;
}

.TripOverview .EstimatedStopTimes {
    margin-top: -10px;
}

.TimeLabel {
    display: none;
}

@media (max-width: 576px) {
    .TimeLabel {
        display: block;
        float: left;
        line-height: 24px;
        font-weight: 500;
        width: 70px;
        margin-top: 3px;
        text-align: right;
        padding-right: 10px;
    }
    .TimeLabel.Walking {
        padding-top: 10px;
    }
}
.Transfers .UpdatingIndicator {
    position: absolute;
    right: 0;
    top: 0;
}

.Transfers .LoaderButton {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.transfer-warning {
    border: 1px solid #FF4545;
    background-color: #FEE5E5;
    border-radius: 6px;
    padding: 4px 5px;
}
.transfer-warning .RouteLogo {
    background: #fff;
}
