.print-pdf {
  width: 210mm;
  min-height: 297mm;
  padding: 15mm 10mm;
  margin: 0 auto;
  background: white;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

.quote-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 2px solid #EFEDFD;
}

.quote-header-left {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.logo-container {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.logo-container svg {
  display: block;
  width: 134px;
  height: 26px;
  fill: currentColor;
}

.quote-title-section {
  text-align: left;
}

.quote-meta {
  display: flex;
  gap: 40px;
  margin-top: 8px;
}

.company-details {
  flex: 0 0 auto;
  text-align: right;
}

.company-details h1 {
  margin: 0 0 10px 0;
  font-size: 24px;
  color: #333;
}

.company-details p {
  margin: 0;
  font-size: 12px;
  color: #666;
  line-height: 1.4;
}

.quote-number, .quote-date {
  text-align: left;
}

.quote-number label, .quote-date label {
  display: block;
  font-size: 11px;
  color: #666;
  margin-bottom: 2px;
}

.quote-number strong, .quote-date strong {
  display: block;
  font-size: 13px;
  color: #333;
}

.print-pdf .pdf-title {
  margin: 0;
  display: block !important;
  font-size: 20px !important;
  line-height: 32px !important;
  font-weight: 500;
  color: #666;
}

.print-pdf .ant-card.card-main {
  margin-bottom: 15px;
  box-shadow: none !important;
  border: 1px solid #EFEDFD !important;
}

.print-pdf .ant-card-head {
  border-bottom: 1px solid #EFEDFD;
}

.print-pdf .ant-card-head-title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

/* .print-pdf .ant-card-body {
  padding: 16px;
} */

.print-pdf label {
  display: block;
  font-size: 11px;
  color: #666;
  margin-bottom: 2px;
}

.print-pdf strong {
  display: block;
  font-size: 13px;
  color: #333;
  margin-bottom: 2px;
}

.print-pdf small {
  display: block;
  font-size: 11px;
  color: #666;
  margin-bottom: 2px;
}

/* .print-pdf .card-row-header {
  padding: 12px 0;
  border-bottom: 1px solid #e8e8e8;
  font-weight: 600;
  color: #333;
} */

/* .print-pdf .card-row-content {
  padding: 12px 0;
  border-bottom: 1px solid #e8e8e8;
} */

/* .print-pdf .card-row-content.no-border {
  border-bottom: none;
} */

/* .print-pdf .border-full {
  border-bottom: 1px solid #e8e8e8;
} */

.print-pdf .text-right {
  text-align: right;
}

.print-pdf .text-left {
  text-align: left;
}

.print-pdf .align-items-center {
  display: flex;
  align-items: center;
}

/* Screen-specific styles */
@media screen {
  .print-pdf {
    width: 210mm;
    min-height: 297mm;
    margin: 20px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}

/* Print-specific styles */
@media print {
  .print-pdf {
    width: 210mm;
    min-height: 297mm;
    padding: 15mm 10mm;
    margin: 0;
    box-shadow: none;
  }

  @page {
    size: A4 portrait;
    margin: 0;
  }

  .print-pdf .ant-card {
    break-inside: avoid;
    page-break-inside: avoid;
  }

  .print-pdf .card-row-content {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}