.ShowSecondaryMenu .ant-layout.app-main > .ant-layout.app-layout {
  padding-left: 50px;
}
.busable-app.ShowSecondaryMenu .app-main > .ant-layout.app-layout {
  position: relative;
}
.SecondaryMenu {
  position: fixed;
  top: 17px;
  bottom: 17px;
  z-index: 99;
  width: 50px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  margin-left: -64px;
}
.SecondaryMenu .SecondaryMenuWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  background: #E6E2F9;
  width: 100%;
  border-radius: 8px;
}
.SecondaryMenu .SecondaryMenuWrap:last-of-type {
  height: 100%;
}
.SecondaryMenu .SecondaryMenuWrap .Separator {
  height: 2px;
  width: 100%;
  background: #D4D0E5;
  margin: 2px 0;
}
.SecondaryMenu .icon-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff !important;
  border-radius: 6px !important;
  width: 34px !important;
  height: 34px !important;
  padding: 0 !important;
  position: relative;
  overflow: hidden;
}
.SecondaryMenu .icon-button:not(.no-fill) svg path {
  fill: #888 !important;
  fill-opacity: 1 !important;
}
.SecondaryMenu .icon-button.no-fill svg path {
  stroke: #888;
}
.SecondaryMenu .icon-button.no-fill:disabled svg path {
  stroke: #ccc;
}
.SecondaryMenu .icon-button svg g,
.SecondaryMenu .icon-button svg circle,
.SecondaryMenu .icon-button svg polyline {
  stroke: #888 !important;
}
.SecondaryMenu .icon-button:not(.no-fill):hover svg path,
.SecondaryMenu .icon-button:not(.no-fill) svg.active path,
.SecondaryMenu .icon-button.ant-popover-open svg path {
  fill: #fff !important;
}
.SecondaryMenu .icon-button.no-fill:hover svg path,
.SecondaryMenu .icon-button.no-fill svg.active path {
  stroke: #fff !important;
}
.SecondaryMenu .icon-button:hover svg g,
.SecondaryMenu .icon-button:hover svg circle,
.SecondaryMenu .icon-button:hover svg polyline {
  stroke: #fff !important;
}
.SecondaryMenu .icon-button:not(.no-fill):disabled svg path {
  fill: #ccc !important;
}
.SecondaryMenu .icon-button.icon-switch.active,
.SecondaryMenu .icon-button.ant-popover-open {
  background: #6B54F9 !important;
}
.SecondaryMenu .icon-button.icon-switch.active svg path {
  fill: #fff !important;
}
.SecondaryMenuPopup {
  display: flex;
  flex-direction: column;
}
.SecondaryMenuOverlay {
  width: 200px;
}
.SecondaryMenu .icon-button + .ant-badge-count {
  box-shadow: none !important;
  background: #FF4444;
  border-radius: 50px;
  font-weight: 500;
  top: 3px;
  right: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SecondaryMenu .icon-button + .ant-badge-count .ant-scroll-number-only {
  height: auto;
}
.IconCheck {
  background: #ccc;
  border-radius: 50px;
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.IconCheck.active {
  background: #52C41A;
}
.IconCheck svg path {
  stroke: #fff;
}